import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Button, Checkbox } from 'antd';

import { IAnswer } from 'utils/quizUtils';

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');

interface IQuizAnswers {
  answers: IAnswer[],
  currentAnswer?: string,
  subAnswer?: string,
  onSelectAnswer: (answer: string, multiple?: boolean) => void,
  onSelectSubAnswer: (answer: string) => void,
  loadingState: boolean,
}

const StyledQuizWrapper = styled.div`
  .ant-btn {
    border: 1px solid #E8E8E8;
    font-size: 14px;
    line-height: 22px;
    height: auto;
    white-space: normal;
    color: #121324 !important;
    :not(.disabled) {
      &:hover, &:focus {
        background: rgba(154, 145, 255, 0.06);
      }
    }
    &.ant-btn-primary {
      color: #FFF !important;
      &:hover, &:focus {
        background: #675BF5;
      }
      &.sub-answers {
        color: #121324 !important;
        background: rgba(121, 110, 255, 0.25);
        &:hover, &:focus {
          background: rgba(121, 110, 255, 0.25);
        }
      }
    }
  }
`;

const StyledSubOptionWrapper = styled.div`
  max-width: 95%;
`;

const QuizAnswers: React.FC<IQuizAnswers> = ({ answers, currentAnswer, subAnswer, onSelectAnswer, onSelectSubAnswer, loadingState }) => {
  return (
    <StyledQuizWrapper className="mb-4">
      {answers.map(({ label, multiple, answers: subOptions }, idx) => {
        const selected = currentAnswer === ALPHABET[idx];
        return (
          <div key={idx}>
            <Button
              block
              size="large"
              className="p-3 mb-1 text-left font-weight-normal"
              onClick={() => onSelectAnswer(ALPHABET[idx], multiple)}
              disabled={loadingState}
              type={selected ? 'primary' : 'default'}
            >
              {label}
            </Button>
            {(multiple && subOptions) && (
              <div className="d-flex justify-content-end">
                <StyledSubOptionWrapper className="w-100">
                  {subOptions.map(({ label: innerLabel }, innerIdx) => {
                    const selectedSubAnswers = subAnswer?.split(',') || [];
                    const answerLetterIndex = `${ALPHABET[idx]}${innerIdx + 1}`;
                    return (
                      <Button
                        block
                        key={`${idx}${innerIdx}`}
                        className={cx({
                          "mb-1 text-left font-weight-normal d-flex p-0 sub-answers": true,
                          "disabled": !selected,
                        })}
                        onClick={() => onSelectSubAnswer(answerLetterIndex)}
                        disabled={loadingState || !selected}
                        type={selectedSubAnswers.includes(answerLetterIndex) ? 'primary' : 'default'}
                      >
                        <div style={{ padding: 11, borderRight: '1px solid #E8E8E8' }}>
                          <Checkbox
                            checked={selectedSubAnswers.includes(answerLetterIndex)}
                          />
                        </div>
                        <span style={{ padding: 11 }}>{innerLabel}</span>
                      </Button>
                    );
                  })}
                </StyledSubOptionWrapper>
              </div>
            )}
          </div>
        );
      })}
    </StyledQuizWrapper>
  )
}

export default QuizAnswers;
