import Root from 'pages/Root';
import SignIn from 'pages/LogIn';
import Signup from 'pages/Signup';
import Resources from 'pages/Resources';
import Videos from 'pages/Videos';
import Screener from 'pages/Screener';
import Quiz from 'pages/Quiz';
import SEO from 'pages/SEO';

const routes = [
  {
    exact: true,
    component: Root,
    path: "/",
  },
  {
    exact: true,
    component: SignIn,
    path: "/login",
  },
  {
    component: Signup,
    path: "/signup",
  },
  {
    component: Resources,
    path: "/resources",
  },
  {
    component: Videos,
    path: "/reitscreener-tv",
  },
  {
    component: Screener,
    path: "/screener",
  },
  {
    component: Quiz,
    path: "/quiz",
  },
  {
    component: SEO,
    path: "/seo",
  },
];

export default routes;
