import React from "react";
import styled from "styled-components";
import { Row, Col, List, Avatar, Typography } from "antd";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { useUserContextValue } from "contexts/UserContext";
import { PREMIUM_USER_INDEX } from "utils/appUtils";

const CHECK_YELLOW = "/assets/images/checkYellow.svg";
const StyledCTAWrapper = styled.div`
  color: #fff;
  background: #121324;
  padding: 80px 0;
  .max-width-1200 {
    .ant-card {
      z-index: 1;
      top: 75px;
      right: 0;
      max-width: 650px;
      .ant-card-body {
        padding: 40px 48px;
        .ant-btn.bg-primary-dark {
          &:hover {
            background-color: #595959;
            border-color: #595959;
          }
          &:active {
            background-color: #4942cf;
            border-color: #4942cf;
          }
        }
      }
    }
  }
  .ant-list-item-meta {
    align-items: center;
    img {
      width: 16px;
      height: 12px;
    }
  }
  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-list-item-meta-description {
    color: white;
  }
  .ant-list-item-meta-avatar {
    margin-right: 5px;
  }
  .ant-btn {
    max-width: 234px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;    
    margin-top: -10px;
    margin-right: 52px;  
    display: block;
    width: 356px;
    height: 70px;    
    background-image: url("/assets/images/dots-yellow.png");
    background-repeat: repeat-x;
    background-position: center 10px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;    
    margin-right: 200px;  
    margin-bottom: 10px;
    display: block;
    width: 356px;
    height: 72px; 
    background-image: url("/assets/images/dots-space.png");
    background-repeat: repeat-x;
    background-position: center 10px;
  }
`;
const data = [
  "Gain Access To Global REIT Markets",
  "Uncover Hidden Gems with our Premium Screening Strategies",
  "Unlock Advance Screening Filters",
  "Construct your REITs Watchlist",
];

//for now set CTA to hide after user is premium
//upgrade to PRO still no details

const { Text } = Typography;
const CTAUpsell: React.FC<RouteComponentProps> = ({ location, match }) => {
  localStorage.setItem("prevPath", location.pathname);
  const { account } = useUserContextValue();
  return (
    <>
      {account?.level && account.level < PREMIUM_USER_INDEX && (
        <StyledCTAWrapper className="position-relative">
          <div className="max-width-1200 mx-auto">
            <Row className="px-3 px-xl-0 justify-content-between">
              <Col xs={24} sm={24} md={14}>
                <Text
                  className="fs-20 d-block mb-2 text-uppercase"
                  style={{ color: "#FEC669" }}
                >
                  level up
                </Text>
                <h2 className="text-white fs-30">
                  Improve your odds of outperforming the market.
                </h2>
                <List
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item className="py-1 border-0 mb-2">
                      <List.Item.Meta
                        avatar={<Avatar src={CHECK_YELLOW} />}
                        description={item}
                      />
                    </List.Item>
                  )}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={10}
                className="mt-5 mt-md-0 d-flex align-items-center justify-content-center"
              >
                <div className="w-100" style={{ maxWidth: "384px" }}>
                  <h3 className="text-white mb-4">
                    Unlock the full potential of REITScreener
                  </h3>
                  <Link
                    className="ant-btn ant-btn-primary ant-btn-lg py-3"
                    type="primary"
                    to="/subscription/premium"
                  >
                    Upgrade to premium
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </StyledCTAWrapper>
      )}
    </>
  );
};

export default withRouter(CTAUpsell);
