import React, { PropsWithChildren, Suspense } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import routes from './config/routes';
import preRenderRoutes from './config/preRenderRoutes';
import FourOhFour from './pages/FourOhFour';
import { MessageProvider } from 'contexts/MessageContext';
import env from 'config/env';
import PageSpinner from 'components/PageSpinner';

type TComponentRoute = {
  component: any;
  path: string;
  exact?: boolean;
}

const stripePromise = loadStripe(
  process.env[`REACT_APP_${env}_STRIPE_KEY`] ||
    process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY
);
const AppProviders: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <MessageProvider>
    <Elements stripe={stripePromise}>{children}</Elements>
  </MessageProvider>
);

const derivedRoutes: TComponentRoute[] = navigator.userAgent === 'ReactSnap' ? preRenderRoutes : routes;

const App = () => (
  <AppProviders>
    <Suspense fallback={<PageSpinner />}>
      <Switch>
        {derivedRoutes.map(({ component: Component, path, exact, ...rest }) => (
          <Route
            exact={exact}
            key={path}
            path={path}
            render={(props: RouteComponentProps & any) => (
              <Component {...props} {...rest} />
            )}
          />
        ))}
        {/* <Redirect exact from="/" to="/dashboard"/> */}
        <Route component={FourOhFour} />
      </Switch>
    </Suspense>
  </AppProviders>
);

export default App;
