import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, Button } from 'antd';

import { StyledModal, StyledUL } from '../styled';
import { ModalCloseIcon } from 'utils/iconUtils';

const { Text, Title } = Typography;

const StyledButton = styled(Button)`
  &.ant-btn {
    height: auto;
    min-height: auto;
    padding: 0;
    background: transparent;
    margin: 0 8px;
    font-size: 14px;
  }
`;

const TermsOfService = () => {
  const [visisbility, setVisibility] = useState(false);
  return (
    <>
      <StyledButton type="text" onClick={() => setVisibility(true)}>Terms of Service</StyledButton>
      <StyledModal
        // centered
        wrapClassName="legalities"
        closeIcon={<ModalCloseIcon />}
        visible={visisbility}
        onCancel={() => setVisibility(false)}
        title="Terms of Service"
        footer={false}
      >
        <div className="scrollable-area">
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Last Updated: Effective Date: January 2018
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            PLEASE READ THESE TERMS CAREFULLY, INCLUDING THE MANDATORY ARBITRATION PROVISION WHICH REQUIRES THAT DISPUTES ARE RESOLVED BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL AND NOT A CLASS-WIDE OR CONSOLIDATED BASIS.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            BY ACCESSING OR USING OUR SERVICES (DEFINED BELOW), YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE OUR SERVICES.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            These Terms of Service (“Terms”) apply when you access or use the websites (collectively, the “Site”) of REITScreener Pte Ltd. (“Company,” “we,” “us” or “our”), our mobile applications, and the services, content and materials made available via the Site or mobile applications (collectively, the “Services”). These Terms do not alter in any way the terms or conditions of any other agreement you may have with Company for products, services or otherwise.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            We reserve the right to change or modify these Terms at any time and in our sole discretion. If we make changes to these Terms, we will provide notice of such changes, such as by sending you an email notification, providing notice through the Services or updating the “Last Updated” date at the top of these Terms. Your continued use of the Services following our notice of the amended Terms will confirm your acceptance of the amended Terms. If you do not agree to the amended Terms, you may not continue accessing or using the Services.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            All questions or comments about the Services should be directed to  support@reitscreener.com
          </Text>
          <Title className="mb-3" level={4}>1. Privacy Policy</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Please refer to our Privacy Policy for information about how Company collects, uses and discloses information about you.
          </Text>
          <Title className="mb-3" level={4}>2. Eligibility</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            The Services are not targeted towards, nor intended for use by, anyone under the age of 18. By using the Services, you represent and warrant that you: (a) are 18 years of age or older; (b) have not been previously suspended from using the Services; and (c) have full power and authority to enter into these Terms and that, in doing so, you will not violate any other agreement to which you are a party.
          </Text>
          <Title className="mb-3" level={4}>3. Account</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            In order to access and use certain areas or features of the Services, you may be required to register for an account. If you create an account via our Services, you agree to: (a) provide accurate, current and complete information; (b) maintain and promptly update your account information to keep it accurate, current and complete; (c) maintain the security of your account and accept all risks of unauthorized access to your account and the information you provide to us; and (d) immediately notify us if you discover or otherwise suspect any security breaches related to your account or the Services.
          </Text>
          <Title className="mb-3" level={4}>4. Terms of Sale</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Access to certain materials, video and other course content on the Services is made available for purchase (“Premium Services”). Your payment for any access to any Premium Services is subject to the following terms:
          </Text>
          <StyledUL>
            <li>
              <Text className="fs-20">
                <b>Price; Payment Plans.</b> The price for any Premium Services will be made available via the Services at time of purchase. You may pay for access to the Premium Services in full at the time of your purchase or pursuant to any installment payment plan that we make available
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Installment Payment Plans.</b> If you select an installment payment plan, you hereby grant Company permission to automatically charge the applicable Premium Services fee to your designated payment method at the beginning of each applicable payment period until all payments have been completed. If you select an installment payment plan, you agree to keep your designated payment method information, including all billing information, current, complete and accurate.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Valid Payment Methods.</b> Only valid payment methods acceptable to us, or our designated payment processors, may be used to purchase access to our Premium Services. By submitting your order to purchase access to our Premium Services, you represent and warrant that you are authorized to use your designated payment method and authorize us, or our designated payment processors, to charge your purchase to that method. If your payment method cannot be verified or is invalid, your order may be suspended or cancelled automatically. You must resolve any problem we, or our designated payment processors, encounter in order to proceed with your order.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>No Cancellations.</b> All sales are final and we do not offer any refunds or cancellations. If you select an installment payment plan, you will be obligated to complete all installment payments.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Failure to Pay.</b> A failure to pay an installment payment related to any of the Services may result in the immediate suspension or termination of all Services. Upon suspension or termination, you will no longer be able to access your account and any Services. To maintain access to your account and all corresponding Services, your account and payments must be current and in good standing for all programs and Services for which you have registered. Pursuant to our Refund Policy, if your account is suspended or terminated for a failure to pay, you will not receive any refund except at our sole discretion and any scheduled automatic renewals will not occur.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Errors in Charges.</b> In the event of an error that results in an incorrect charge, we reserve the right to correct such error and revise your order accordingly if necessary (including charging the correct price) or to cancel the order and refund any erroneous amount charged. In addition, we may, in lieu of a refund as provided in this paragraph, opt to provide you with a service credit, with a value equal to the amount charged to your payment method.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Taxes.</b> You are responsible for any applicable sales or use tax, duties, or other governmental taxes or fees payable in connection with your purchase. If you do not pay such sales or other tax or fee on a transaction, you will be responsible for such taxes or fees in the event that they are later determined to be payable on such sale, and Company reserves the right to collect such taxes or other fees from you at any time.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Automatic Renewal Terms.</b> Certain Services, including REITScreener Premium, REITScreener TV, REITScreener Data Boost, are ongoing subscriptions (“Subscriptions”). By enrolling in a Subscription program, you agree that a Subscription fee will be billed at the price you agreed to when subscribing to the payment you provide for the then-current Subscription period on a recurring basis until you cancel. If you do not wish for your account to renew automatically, or if you want to change or cancel your Subscription, you can do so by unsubscribing from Account/Subscription page, or emailing us at support@reitscreener.com. If you cancel your Subscription after the 30 or 365 day period specified above, you may use your Subscription until the end of your then-current subscription term and your Subscription will not be renewed thereafter. You won’t, however, be eligible for a prorated refund of any portion of the subscription fee paid for the then-current Subscription period.
              </Text>
            </li>
          </StyledUL>
          <Title className="mb-3" level={4}>5. Copyright and Limited License</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Unless otherwise indicated, the Services, including all content, video and other materials on or made available via the Services, are the proprietary property of Company and its licensors and are protected by international copyright laws.  Any use, copying, redistribution and/or publication of any part of the Services, other than as authorized by these Terms or expressly authorized in writing by us, is strictly prohibited. In addition, the look and feel of the Services, including all page headers, custom graphics, button icons and scripts, is the proprietary property of Company and may not be copied, imitated or used, in whole or in part, without our prior written permission. You do not acquire any ownership rights to any content, video and other materials on or made available via the Services, and we reserve all rights not expressly granted in these Terms.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            You are granted a limited, non-transferable, non-exclusive, revocable right to access and use the Services solely for your own personal purposes; provided, however, that such license is subject to these Terms and does not include the right to: (a) resell, lease, rent or sublicense any Services or any access to the Services or any content, video and other materials on or made available via the Services; (b) copy, distribute, publicly perform or publicly display any Services or any content, video and other materials on or made available via the Services; (c) modify or otherwise make any derivative uses of any Services or any content, video and other materials on or made available via the Services; (d) download (other than page caching) any content, video and other materials on or made available via the Services, except as expressly permitted in connection with the Services; or (e) use the Services or any content, video and other materials on or made available via the Services other than for their intended purposes.  Except as explicitly stated herein, nothing in these Terms shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise.
          </Text>
          <Title className="mb-3" level={4}>6. Trademarks</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Company logos, the look and feel of the Services, and any other product or service name, logo or slogan contained in the Services are trademarks, service marks and/or trade dress of Company or our suppliers or licensors and may not be copied, imitated or used, in whole or in part, without the prior written authorization of Company or the applicable trademark holder.  Any authorized use of such trademarks, service marks and/or trade dress must be in accordance with any guidelines provided by Company.
          </Text>
          <Title className="mb-3" level={4}>7. Confidential Information; Non-Disclosure.</Title>
          <StyledUL>
            <li>
              <Text className="fs-20">
                You acknowledge that certain content, videos and other materials on or made available via the Services constitute the Confidential Information of Company.  “Confidential Information” refers to certain information that is marked as “Confidential” or “Proprietary” that we reasonably regard as proprietary or confidential relating our courses, business, products, processes and techniques, including without limitation information relating to our trade secrets, business plans, strategies, methods and/or practices that is not generally known to the public and is disclosed to you pursuant to your express agreement to maintain the confidentiality of the Confidential Information.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Except as expressly allowed herein, you agree to hold in confidence and not disclose any such Confidential Information except in accordance with this Agreement.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                The foregoing obligations shall not apply to the extent that Confidential Information: (i) must be disclosed to comply with any requirement of law or order of a court or administrative body; (ii) is known to or in your or our possession prior to receiving the disclosure of such Confidential Information as documented by notes or records; (iii) is known or generally available to the public through no act or omission of you or us in breach of this Agreement; or (iv) is made available free of any legal restriction by a third party. The duties and requirements under this section shall survive termination of this Agreement.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                You hereby agree that any unauthorized disclosure of Company’s Confidential Information may cause immediate and irreparable injury to Company and that, in the event of such breach, Company will be entitled, in addition to any other available remedies, to immediate injunctive and other equitable relief, without bond and without the necessity of showing actual monetary damages.
              </Text>
            </li>
          </StyledUL>
          <Title className="mb-3" level={4}>8. Hyperlinks</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            You are granted a limited, non-exclusive right to create a text hyperlink to the Site for noncommercial purposes, provided such link does not portray Company or the Services in a false, misleading, derogatory or otherwise defamatory manner and provided further that the linking website does not contain any illegal material or any material that is offensive, harassing or otherwise objectionable. This limited right may be revoked at any time. You may not use a Company logo or other proprietary graphic of Company to link to the Site without the express written permission of Company. Further, you may not use, frame or utilize framing techniques to enclose any Company trademark, logo or other proprietary information, including the images found within the Services, the content of any text or the layout/design of any page or form contained within the Services, without Company’s express written consent. Except as expressly stated in these Terms, you are not conveyed any right or license by implication, estoppel or otherwise in or under any intellectual property right of Company or any third party.
          </Text>
          <Title className="mb-3" level={4}>9. Third-Party Content, Advertisements and Promotions</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Company may provide third-party content on the Services, including without limitation advertisements and promotional offers, and may provide links to web pages and content of third parties (collectively the “Third-Party Content”). Company does not control, endorse or adopt any Third-Party Content and makes no representation or warranties of any kind regarding the Third-Party Content, including without limitation regarding its accuracy or completeness. You acknowledge and agree that Company is not responsible or liable in any manner for any Third-Party Content and undertakes no responsibility to update or review any Third-Party Content. Your use of any Third-Party Content is at your own risk. The inclusion of Third-Party Content on the Services does not imply affiliation, endorsement or adoption by Company of any Third-Party Content or any information contained therein. Your business dealings or correspondence with, or participation in the promotional offers of, any third party responsible for Third-Party Content, and any terms, conditions, warranties or representations associated with such dealings or promotional offers, are solely between you and such third party. When you leave the Services, you should be aware that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from the Services.
          </Text>
          <Title className="mb-3" level={4}>10. Disclaimer</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            THE SERVICES ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE CONSTRUED AS LEGAL, FINANCIAL OR OTHER PROFESSIONAL ADVICE OR, UNLESS OTHERWISE EXPRESSLY STATED, AS COMPANY’S OFFICIAL POSITION ON ANY SUBJECT MATTER. THE SERVICES SHOULD NOT BE RELIED UPON FOR PURPOSES OF TRANSACTING IN SECURITIES OR OTHER INVESTMENTS. COMPANY DOES NOT REPRESENT OR WARRANT THAT (A) THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE, OR (B) THE SERVICES OR OUR SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD FROM THE SERVICES
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY COMPANY, THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, AND, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, COMPANY DISCLAIMS ALL STATUTORY AND IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </Text>
          <Title className="mb-3" level={4}>11. Indemnification</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            You agree to defend, indemnify and hold harmless Company, our independent contractors, service providers and consultants, and our and their respective directors, officers, employees and agents (collectively, the “Company Parties”) from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys’ fees) arising out of or related to (a) your use of the Services, (b) any Feedback you provide, (c) your breach of any of these Terms, or (d) your violation of the rights of any third party.
          </Text>
          <Title className="mb-3" level={4}>12. Limitation of Liability</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW: (A) IN NO EVENT SHALL THE COMPANY PARTIES BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING AS A RESULT OF COMPANY’S NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE TERMS OR THE USE OF OR INABILITY TO USE THE SERVICES; AND (B) IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE COMPANY PARTIES, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING AS A RESULT OF COMPANY’S ALLEGED NEGLIGENCE), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THESE TERMS OR THE USE OF OR INABILITY TO USE THE SERVICES EXCEED ANY COMPENSATION YOU PAY, IF ANY, TO COMPANY FOR ACCESS TO OR USE OF THE SERVICES.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            YOU ACKNOWLEDGE AND AGREE THAT COMPANY HAS OFFERED THE SERVICES, SET ITS PRICES, AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THESE TERMS, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND COMPANY. COMPANY WOULD NOT BE ABLE TO PROVIDE THE SERVICES ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.
          </Text>
          <Title className="mb-3" level={4}>13. No Third-Party Beneficiaries</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            These Terms are for the benefit of, and will be enforceable by, Company and you only. These Terms are not intended to confer any right or benefit on any third party or to create any obligations to any such third party.
          </Text>
          <Title className="mb-3" level={4}>14. Modifications to the Services</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Company reserves the right to modify or discontinue, temporarily or permanently, the Services or any features or portions thereof without prior notice. You agree that Company will not be liable for any modification, suspension or discontinuance of the Services or any part thereof.
          </Text>
          <Title className="mb-3" level={4}>15. Terminations</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            Company reserves the right, without advance notice and in its sole discretion, to terminate your license to use the Services, and to block or prevent your future access to and use of the Services.
          </Text>
          <Title className="mb-3" level={4}>16. Severability</Title>
          <Text className="fs-20">
            Company reserves the right to modify or discontinue, temporarily or permanently, the Services or any features or portions thereof without prior notice. You agree that Company will not be liable for any modification, suspension or discontinuance of the Services or any part thereof.
          </Text>
        </div>
      </StyledModal>
    </>
  );
}

export default TermsOfService;
