import React from "react";
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import AppLayout from "components/AppLayout";
import CTAUnregistered from "components/CTAUnregistered";
import CTAUpsell from "components/CTAUpsell";
import FourOhFour from "pages/FourOhFour";
import EventsList from "./components/EventsList";
import { isLoggedIn } from "utils/userUtils";

const Events: React.FC<RouteComponentProps> = ({ match }) => (
  <AppLayout>
    <Switch>
      <Route exact path={match.path} component={EventsList} />
      <Route component={FourOhFour} />
    </Switch>
    {isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}
  </AppLayout>
);

export default withRouter(Events);
