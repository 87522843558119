import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import { Card, Typography, Row, Col } from 'antd';

import SignUpForm from 'components/SignUpForm';
import SentMagicLinkModal from 'components/SentMagicLinkModal';
import { IRegisterFormValues } from 'components/SignUpForm/SignUpForm';
import { CheckGold } from 'utils/iconUtils';
import { SEND_MAGIC_LINK } from 'apollo/mutations/user';
import { useMessageContextValue } from 'contexts/MessageContext';

const CONTENT_BLOCKER = '/assets/images/content-blocker.png';
const { Text, Title } = Typography;

const FEATURES = [{
  label: 'Personalised',
  description: 'Get a complimentary assessment to determine your ideal REIT investment strategy along with a watchlist of REITs best suited to your portfolio',
}, {
  label: 'REITScreener',
  description: 'Gain actionable market insights with our screener tailored specifically to screen for REITs that suite your investment profile',
}, {
  label: 'eBooks',
  description: 'Receive a complimentary copy of our eBook entitled "8 Myths People Still Believe About REITs"',
}, {
  label: 'Webinar',
  description: 'Unlock access our most popular REIT webinars.',
}, {
  label: 'eResource',
  description: 'Gain unrestricted access to our extensive online educational REIT articles and resources',
}];

const StyledCard = styled(Card)`
  &.ant-card {
    margin-top: -20px;
    border-radius: 8px;
    .ant-card-body {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      .ant-row {
        .ant-col {
          &.with-border-right {
            border-right: 1px solid #262626;
          }
          .form-wrapper {
            .ant-form {
              .ant-form-item {
                .ant-form-item-label > label {
                  color: #FFF;
                }
                input {
                  border: 1px solid #121324;
                  &::placeholder {
                    color: #BFBFBF;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StyledBottomCover = styled.div`
  top: -150px;
  left: 0;
  right: 0;
  height: 150px;
  background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(256,256,256,0));
`;

const ArticleBlocker: React.FC<RouteComponentProps> = ({ location }) => {
  const [sendMagicLinkMutation] = useMutation(SEND_MAGIC_LINK);
  const [visibility, setVisibility] = useState(false);
  const [email, setEmail] = useState('');
  const { alertError } = useMessageContextValue();

  const handleSubmit = async (values: IRegisterFormValues, setSubmitting: (issSubmitting: boolean) => void) => {
    try {
      await sendMagicLinkMutation({
        variables: {
          input: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            redirectUrl: location.pathname,
          }
        },
      });
      setSubmitting(false);
      setVisibility(true);
      setEmail(values.email);
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
      setSubmitting(false);
    }
  }

  return (
    <div
      className="position-relative pr-2"
      style={{
        backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,1), rgba(256,256,256,0))'
      }}
    >
      <StyledBottomCover className="position-absolute" />
      <StyledCard className="bg-primary-dark mx-auto custom-shadow mb-3" bodyStyle={{ padding: 32 }}>
        <div style={{ marginBottom: 54 }} className="cta-header d-flex align-items-center">
          <img className="mr-4" width={54} height={54} src={CONTENT_BLOCKER} draggable={false} alt="Content blocker icon" />
          <div>
            <Title level={3} className="text-white mb-2">This content is exclusive to registered users!</Title>
            <Text className="text-grey-5">Register and unlock this content and much much more...</Text>
          </div>
        </div>
        <Row gutter={64}>
          <Col
            className="with-border-right"
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 1, span: 12 }}
          >
            {FEATURES.map(({ label, description }, idx) => (
              <div key={idx} className="d-flex mb-4">
                <div style={{ marginRight: 12 }}>
                  <CheckGold />
                </div>
                <div>
                  <Text strong className="d-block fs-16 text-white">{label}</Text>
                  <Text className="d-block fs-12 text-grey-4">{description}</Text>
                </div>
              </div>
            ))}
          </Col>
          <Col
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 2, span: 12 }}
            lg={{ order: 2, span: 12 }}
          >
            <div className="form-wrapper w-100 mx-auto">
              <SignUpForm onSubmit={handleSubmit} />
            </div>
          </Col>
        </Row>
        <SentMagicLinkModal visibility={visibility} setVisibility={setVisibility} email={email} />
      </StyledCard>
    </div>
  );
}

export default withRouter(ArticleBlocker);
