import React from "react";
import { useQuery } from "react-apollo";
import styled from "styled-components";

import AppLayout from "components/AppLayout";
import BlogGrid from "./components/BlogGrid";
import CTAUnregistered from "components/CTAUnregistered";
import CTAUpsell from "components/CTAUpsell";
import { Spinner } from "components/PageSpinner";
import { isLoggedIn } from "utils/userUtils";
import { CATEGORY_POSTS } from "apollo/queries/resources";
import { IWPPost } from "apollo/types/cms";

interface IBlogQueryResult {
  posts: {
    slug: string;
    posts: IWPPost[];
  }[];
}

const StyledBlogWrapper = styled.div`
  &.blog-wrapper {
    min-height: 250px;
  }
`;

const Blog = () => {
  const { data, loading } = useQuery<undefined | IBlogQueryResult>(
    CATEGORY_POSTS,
    {
      variables: {
        categorySlugs: ["blog"],
      },
    }
  );
  const posts = data?.posts || [];

  return (
    <AppLayout>
      {loading ? (
        <Spinner />
      ) : (
        <StyledBlogWrapper className="mt-5 px-3 blog-wrapper">
          <div className="max-width-1200 mx-auto">
            {Boolean(posts.length) ? (
              <BlogGrid posts={posts[0].posts} />
            ) : (
              <div>No blogs posted yet.</div>
            )}
          </div>
        </StyledBlogWrapper>
      )}
      {isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}
    </AppLayout>
  );
};

export default Blog;
