import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, Card, Button } from 'antd';

import ApppLayout from 'components/AppLayout';
import { useUserContextValue } from 'contexts/UserContext';
import { PremiumStrategyIcon } from 'utils/iconUtils';
import { scrollToTop } from 'utils/windowUtils';
import { INVESTMENT_STRATEGIES, PREMIUM_USER_INDEX, INVESTOR_PROFILES } from 'utils/appUtils';

const { Text, Title } = Typography;

interface IResultSection {
  subTitle: React.ReactNode,
  icon?: React.ReactNode,
  title: string,
  description: string,
  footerContent?: React.ReactNode,
  isLast?: boolean,
}

const StyledQuizCompleteWrapper = styled.div`
  margin-bottom: 30px;
  h3.text-center {
    margin-top: 40px;
    margin-bottom: 32px;
  }
  .ant-card {
    width: 70%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .not-last {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #E8E8E8;
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 12px 30px;
  height: 54px;
  font-size: 18px;
  line-height: 18px;
  &.custom-default {
    color: #9587ff;
    border-color: #9587ff;
  }
`;

const ResultSection: React.FC<IResultSection> = ({ subTitle, icon, title, description, footerContent, isLast }) => {
  return (
    <div
      className={cx({
        "result-section": true,
        "not-last": !isLast,
      })}
    >
      <Text type="secondary" className="fs-16">
        {subTitle}
        <span className="ml-2" role="img" aria-label="hand-down">👇</span>
      </Text>
      <div className="mt-3 mb-4 d-flex align-items-center">
        {icon && <div className="mr-3 d-flex">{icon}</div>}
        <Title className="mb-0 text-capitalize" level={2}>{title}</Title>
      </div>
      <div style={{ marginBottom: footerContent ? 32 : 0 }}>
        {description}
      </div>
      {footerContent}
    </div>
  );
};

const QuizComplete = () => {
  const { account } = useUserContextValue();

  useEffect(() => {
    scrollToTop();
  }, []);

  if (!account || (account && !account.quiz)) return <Redirect to="/quiz/1" />

  const isUserPremium = account.level >= PREMIUM_USER_INDEX;
  const enabledStrategies = INVESTMENT_STRATEGIES.filter(({ minimumPlan }) => account.level >= minimumPlan);
  const enabledStrategyResult = enabledStrategies.find(({ name }) => name === account.quiz?.strategy)!;
  const userHasUnaccessiblePremiumStrategy = !isUserPremium && !enabledStrategyResult;
  const premiumUserHasFreeStrategy = isUserPremium && enabledStrategyResult.minimumPlan < PREMIUM_USER_INDEX;

  const suggestedUserStrategy = INVESTMENT_STRATEGIES.find(({ name }) => name === account.quiz!.strategy)!;
  const userDefaultStrategy = userHasUnaccessiblePremiumStrategy ? enabledStrategies[0] : suggestedUserStrategy;

  const userProfile = INVESTOR_PROFILES.find(({ name }) => name === account.quiz!.profile)!;

  const subTitle = isUserPremium && !premiumUserHasFreeStrategy ?
    'Your suggested premium investment strategy is' :
    `${userHasUnaccessiblePremiumStrategy ? 'Not ready to upgrade? ' : ''}Your suggested FREE investment strategy is`;
  
  return (
    <ApppLayout>
      <StyledQuizCompleteWrapper className="mx-auto max-width-1200">
        <Title className="text-center" level={3}>Your quiz results are here!</Title>
        <Card className="mx-auto" bodyStyle={{ padding: '40px 72px' }}>
          <ResultSection
            subTitle="Your investor profile is"
            icon={userProfile.icon}
            title={userProfile.name.toLowerCase()}
            description={userProfile.description}
          />
          {userHasUnaccessiblePremiumStrategy && (
            <ResultSection
              subTitle="Your suggested premium investment strategy is"
              icon={<PremiumStrategyIcon />}
              title={suggestedUserStrategy.name.replace(/_/g, ' ').toLowerCase()}
              description="This strategy aims to sniff out the highest potential growth REITs while being content with a lower overall dividend yield. The stock prices may not be at a bargain, but as long as they are reasonable, the potential upside gains should more than amply reward the investor."
              footerContent={(
                <div>
                  <Link to="/subscription/premium">
                    <StyledButton className="custom-default">Upgrade to Premium</StyledButton>
                  </Link>
                </div>
              )}
            />
          )}
          <ResultSection
            isLast
            {...((isUserPremium && !premiumUserHasFreeStrategy) && {
              icon: <PremiumStrategyIcon />,
            })}
            subTitle={subTitle}
            title={userDefaultStrategy.name.replace(/_/g, ' ').toLowerCase()}
            description={userDefaultStrategy.description}
            footerContent={(
              <div>
                <Link className="mb-3 d-block" to="/screener/table">
                  <StyledButton type="primary">Start using screener</StyledButton>
                </Link>
                <Text className="mr-2" type="secondary">Not satisfied with your strategy?</Text>
                <Link to="/quiz/1" style={{ textDecoration: 'underline' }}>Retake the Quiz</Link>
              </div>
            )}
          />
        </Card>
      </StyledQuizCompleteWrapper>
    </ApppLayout>
  );
}

export default QuizComplete;
