import React, { useState } from "react";
import { Modal, Button, Typography } from "antd";
import styled from "styled-components";
import { ExpandAltOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";

import { AreaChart, SmallChart, BarChart } from "components/Charts";
import { truncateDecimals, formatCurrency } from "utils/numberUtils";
import DeviationChart from "../DeviationChart";
import TradingView from "../TradingView";
import LockReitModal from "components/LockReitmodal";
import { useUserContextValue } from "contexts/UserContext";
import { REGISTERED_USER_INDEX, PREMIUM_USER_INDEX } from "utils/appUtils";

interface IChartProps {
  type?: "bar" | "area";
  chartStd?: boolean;
  chartStdType?: "dividends" | "priceToBookRatio";
  dataSource?: Array<{ value: number; label: string }>;
  unit?: "Decimal" | "Percent" | "Currency";
  chartLabel?: string;
  chartMeta?: string;
  decimalPlaces?: number;
  hasSmallChart?: boolean;
  value?: number | string;
  isTradingView?: boolean;
  isDataBoostFeature?: boolean;
  isNavDPU?: boolean;
  stockCode?: string;
  currency?: string;
  valueSmallFont?: boolean;
  xTickLabelFormatter?: (value: string) => string;
}

const { Text } = Typography;

const StyledButton = styled(Button)`
  transition: none;
  opacity: unset;
  background: transparent;
  &:active,
  &:focus {
    background: transparent;
  }
  &:hover {
    background-color: rgb(222, 213, 255, 0.2);
    .anticon-expand-alt {
      display: inline-block;
    }
  }
  &::after,
  &::before {
    display: none;
  }
  .anticon {
    z-index: 5;
  }
  .anticon-expand-alt {
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledModal = styled(Modal)`
  overflow: hidden;
  top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  .ant-modal,
  .ant-modal-content,
  .ant-modal-body {    
    height: 100%;
    width: 100%;
  }
  .ant-modal-body {
    padding: 32px;
  }
`;

const HistoricalChart: React.FC<IChartProps> = ({
  type = "area",
  children,
  dataSource,
  unit = "Decimal",
  chartLabel,
  chartMeta,
  chartStd = false,
  decimalPlaces = 2,
  hasSmallChart = true,
  chartStdType,
  value,
  isTradingView = false,
  isNavDPU = false,
  valueSmallFont = false,
  currency = "",
  stockCode = "",
  xTickLabelFormatter,
  isDataBoostFeature = true,
}) => {
  //acount subscription whether subscribed to data boost or nah
  const [showModal, setShowModal] = useState(false);
  const { account } = useUserContextValue();

  let proUser = (account?.level || 0) > PREMIUM_USER_INDEX;

  const isBarChartException =
    isNavDPU && (account?.level || 0) >= REGISTERED_USER_INDEX;

  const isTradingViewException =
    isTradingView && (account?.level || 0) >= REGISTERED_USER_INDEX;

  const showCharts =
    account?.subscription.dataBoost.active ||
    isBarChartException ||
    isTradingViewException ||
    proUser;

  const convertValues = (
    unit: string,
    value: number,
    decimalPlaces?: number
  ): string => {
    let result = "";
    switch (unit) {
      case "Decimal":
        result = truncateDecimals(value, decimalPlaces);
        break;
      case "Percent":
        result = `${truncateDecimals(value * 100)}%`;
        break;
      case "Currency":
        result = formatCurrency(value);
        break;
      default:
    }
    return result;
  };

  return (
    <>
      {hasSmallChart ? (
        <>
          {account?.subscription.dataBoost.active ||
          proUser ||
          isTradingViewException ? (
            <StyledButton
              className="p-0 border-0 d-flex align-items-center position-relative"
              onClick={() => setShowModal(!showModal)}
            >
              <ExpandAltOutlined className="position-absolute" />
              <SmallChart dataSource={dataSource || []} />
            </StyledButton>
          ) : (
            <LockReitModal
              isDataBoostFeature={isDataBoostFeature}
              minimumPlan={account?.level || 0}
              wrapChildren={true}
            >
              <StyledButton className="p-0 border-0 d-flex align-items-center position-relative">
                <ExpandAltOutlined className="position-absolute" />
                <SmallChart dataSource={dataSource || []} />
              </StyledButton>
            </LockReitModal>
          )}
          {value && (
            <Text
              className={
                valueSmallFont
                  ? "ml-2 fs-14 font-weight-normal"
                  : "ml-2 fs-18 font-weight-normal"
              }
            >
              {convertValues(unit, Number(value))}
            </Text>
          )}
        </>
      ) : (
        <>
          {account?.subscription.dataBoost.active ||
          isBarChartException ||
          proUser ||
          isTradingViewException ? (
            <span onClick={() => setShowModal(!showModal)}>{children}</span>
          ) : (
            <LockReitModal
              isDataBoostFeature={isDataBoostFeature}
              minimumPlan={account?.level || 0}
              wrapChildren={true}
            >
              <span onClick={() => setShowModal(!showModal)}>{children}</span>
            </LockReitModal>
          )}
        </>
      )}

      {showCharts && (
        <StyledModal
          visible={showModal}
          footer={false}
          onCancel={() => setShowModal(false)}
          className="bg-primary"
          destroyOnClose
        >
          {type !== "area" ? (
            <BarChart
              dataSource={dataSource || []}
              chartLabel={chartLabel}
              yTickLabelFormatter={(value: number) => {
                return convertValues(unit, value, decimalPlaces);
              }}
              xTickLabelFormatter={xTickLabelFormatter}
            />
          ) : (
            <>
              {chartStd || isTradingView ? (
                <>
                  {isTradingView ? (
                    <TradingView externalStck={stockCode} />
                  ) : (
                    <DeviationChart
                      type={chartStdType || ""}
                      stockCode={stockCode}
                    />
                  )}
                </>
              ) : (
                <AreaChart
                  dataSource={dataSource || []}
                  chartLabel={chartLabel}
                  yTickLabelFormatter={(value: number) => {
                    return convertValues(unit, value, decimalPlaces);
                  }}
                  xTickLabelFormatter={xTickLabelFormatter}
                />
              )}
            </>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default HistoricalChart;
