import React from "react";
import { useQuery } from "react-apollo";
import { Typography, Row, Col } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import DataSlider from "components/DataSlider";
import { Spinner } from "components/PageSpinner";
import { REIT_KEY_STATISTICS_INFO } from "apollo/queries/reit";
import { formatCurrency, truncateDecimals } from "utils/numberUtils";
import { HistoricalChart } from "components/Charts";
import { useUserContextValue } from "contexts/UserContext";
import { KEY_STATISTICS_FRAME } from "utils/reitUtils";
import LockReitmodal from "components/LockReitmodal";
import { ChartIcon, BarChartIcon } from "utils/iconUtils";
import { getCurrency } from "utils/reitUtils";

// import {
//   ANONYMOUS_USER_INDEX,
//   REGISTERED_USER_INDEX,
//   PREMIUM_USER_INDEX,
// } from "utils/appUtils";

interface IKeyStatisticsDetails {
  profile: {
    exchange: string;
  };
  keyStatistics: {
    marketCap: number;
    sharePriceRange: {
      value: number;
      min: number;
      max: number;
    };
    sharePricePerformance: number;
    volatility: number;
    dividendAmount: {
      value: number;
      chart: Array<{
        label: string;
        value: number;
      }>;
    };
    NAV: {
      value: number;
      chart: Array<{
        label: string;
        value: number;
      }>;
    };
    dividends: {
      range: {
        value: number;
        min: number;
        max: number;
      };
    };
    priceToBookRatio: {
      range: {
        value: number;
        min: number;
        max: number;
      };
    };
  };
}

const StyledRow = styled(Row)`
  .ant-col {
    border-bottom: 1px solid #e8e8e8;
  }
  .std-trigger {
    cursor: pointer;
    &:hover {
      color: #675bf5;
    }
  }
`;

const { Text } = Typography;

const KeyStatistics: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
}) => {
  let reitStat: IKeyStatisticsDetails = {
    profile: {
      exchange: "",
    },
    keyStatistics: {
      marketCap: 0,
      sharePriceRange: {
        value: 0,
        min: 0,
        max: 0,
      },
      sharePricePerformance: 0,
      volatility: 0,
      dividendAmount: {
        value: 0,
        chart: [],
      },
      NAV: {
        value: 0,
        chart: [],
      },
      dividends: {
        range: {
          value: 0,
          min: 0,
          max: 0,
        },
      },
      priceToBookRatio: {
        range: {
          value: 0,
          min: 0,
          max: 0,
        },
      },
    },
  };

  const { loading, data } = useQuery(REIT_KEY_STATISTICS_INFO, {
    variables: {
      stockCode: match.params.stockCode,
      range: "5y",
    },
  });

  if (data) {
    const { profile, keyStatistics } = data.screenerReit;
    reitStat = Object.assign({}, reitStat, { profile, keyStatistics });
  }
  const { account } = useUserContextValue();
  
  const ExtractAllowedFields: React.FC<{ userLevel: number }> = ({
    userLevel,
  }) => {
    const fieldDefinition = KEY_STATISTICS_FRAME.map((field) => {
      switch (field.name) {
        case "MARKET_CAP":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text className="fs-16">Market Cap</Text>
              <Text>
                {reitStat.keyStatistics.marketCap
                  ? formatCurrency(reitStat.keyStatistics.marketCap)
                  : "-"}
              </Text>
            </Col>
          );
        case "52_WEEK_RANGE":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text className="fs-16">52-week Range</Text>
              <DataSlider
                value={reitStat.keyStatistics.sharePriceRange.value}
                maxValue={reitStat.keyStatistics.sharePriceRange.max}
                exchange={reitStat.profile.exchange}
              />
              <Text className="fs-18">
                {truncateDecimals(
                  reitStat.keyStatistics.sharePriceRange.min,
                  2
                )}{" "}
                -{" "}
                {truncateDecimals(
                  reitStat.keyStatistics.sharePriceRange.max,
                  2
                )}
              </Text>
            </Col>
          );
        case "VOLATILITY":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
              >
                Volatility (Beta)
              </Text>
              <Text>
                {userLevel < field.minimumPlan ? (
                  <span>
                    <LockReitmodal minimumPlan={userLevel} />
                  </span>
                ) : (
                  <>
                    {reitStat.keyStatistics.volatility
                      ? reitStat.keyStatistics.volatility
                      : "-"}
                  </>
                )}
              </Text>
            </Col>
          );
        case "DIVIDEND_AMOUNT":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text className="fs-16">
                Dividend Amount
                <br />
                <Text type="secondary">(TTM Dividend per Share/Unit)</Text>
              </Text>

              <div className="d-flex justify-content-center">
                <span className="std-trigger">
                  <HistoricalChart
                    dataSource={reitStat.keyStatistics.dividendAmount.chart}
                    hasSmallChart={false}
                    type="bar"
                    isNavDPU={true}
                    isDataBoostFeature={false}
                    chartLabel={`Distribution Per Unit (${getCurrency(
                      reitStat.profile.exchange
                    )})`}
                  >
                    <BarChartIcon />
                  </HistoricalChart>
                </span>
                <Text className="ml-2">
                  {reitStat.keyStatistics.dividendAmount.value
                    ? `${truncateDecimals(
                        reitStat.keyStatistics.dividendAmount.value,
                        2
                      )}`
                    : "-"}
                </Text>
              </div>
            </Col>
          );
        case "NAV":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text className="fs-16">
                NAV
                <br />
                <Text type="secondary">
                  (Latest Net Asset Value per Share/Unit)
                </Text>
              </Text>

              <div className="d-flex justify-content-center">
                <span className="std-trigger">
                  <HistoricalChart
                    dataSource={reitStat.keyStatistics.NAV.chart}
                    hasSmallChart={false}
                    type="bar"
                    isNavDPU={true}
                    isDataBoostFeature={false}
                    chartLabel={`Net Asset Value Per Unit (${getCurrency(
                      reitStat.profile.exchange
                    )})`}
                  >
                    <BarChartIcon />
                  </HistoricalChart>
                </span>
                <Text className="ml-2">
                  {reitStat.keyStatistics.NAV.value
                    ? `${truncateDecimals(reitStat.keyStatistics.NAV.value, 2)}`
                    : "-"}
                </Text>
              </div>
            </Col>
          );
        case "DIVIDEND":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-end p-3"
            >
              <Text className="fs-16">
                Dividend <br />
                (TTM Dividend Yield)
              </Text>
              <Col xs={8} className="d-flex justify-content-center border-0">
                <DataSlider
                  value={parseFloat(
                    (
                      reitStat.keyStatistics.dividends.range.value * 100
                    ).toFixed(2)
                  )}
                  maxValue={parseFloat(
                    (reitStat.keyStatistics.dividends.range.max * 100).toFixed(
                      2
                    )
                  )}
                  isDividend={true}
                />
              </Col>
              <div className="d-flex justify-content-center">
                <span className="std-trigger">
                  <HistoricalChart
                    chartStd={true}
                    hasSmallChart={false}
                    chartStdType="dividends"
                  >
                    <ChartIcon />
                  </HistoricalChart>
                </span>
                <Text className="ml-2">
                  {reitStat.keyStatistics.dividends
                    ? `${truncateDecimals(
                        reitStat.keyStatistics.dividends.range.min * 100,
                        2
                      )}% - ${truncateDecimals(
                        reitStat.keyStatistics.dividends.range.max * 100,
                        2
                      )}%`
                    : "-"}
                </Text>
              </div>
            </Col>
          );
        case "PRICE_TO_BOOK":
          return (
            <Col xs={24} className="d-flex align-items-center p-3 border-0">
              <Row className="w-100 align-items-end">
                <Col xs={8} className="border-0">
                  <Text className="fs-16">Price-to-Book Ratio</Text>
                </Col>
                <Col xs={8} className="d-flex justify-content-center border-0">
                  <DataSlider
                    value={reitStat.keyStatistics.priceToBookRatio.range.value}
                    maxValue={reitStat.keyStatistics.priceToBookRatio.range.max}
                  />
                </Col>
                <Col
                  xs={8}
                  className="d-flex justify-content-end align-items-center border-0"
                >
                  <span className="std-trigger">
                    <HistoricalChart
                      chartStd={true}
                      hasSmallChart={false}
                      chartStdType="priceToBookRatio"
                    >
                      <ChartIcon />
                    </HistoricalChart>
                  </span>
                  <Text className="ml-2">
                    {truncateDecimals(
                      reitStat.keyStatistics.priceToBookRatio.range.min,
                      2
                    )}{" "}
                    -{" "}
                    {truncateDecimals(
                      reitStat.keyStatistics.priceToBookRatio.range.max,
                      2
                    )}
                  </Text>
                </Col>
              </Row>
            </Col>
          );
        default:
          return <p>no data available..</p>;
      }
    });
    return (
      <>
        {fieldDefinition.map((element, idx) => {
          return <div key={idx}>{element}</div>;
        })}
      </>
    );
  };

  return (
    <StyledRow>
      <Col xs={24} className="border-0">
        <Text className="card-header d-block w-100 px-3 py-2">
          KEY STATISTICS
        </Text>
        {loading ? (
          <div className="w-100 justify-self-center">
            <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
          </div>
        ) : data ? (
          <>
            <ExtractAllowedFields userLevel={account?.level || 0} />
          </>
        ) : (
          <p className="p-4">no data available</p>
        )}
      </Col>
    </StyledRow>
  );
};
export default withRouter(KeyStatistics);
