import React, { useState, memo } from "react";
import cx from "classnames";
import styled from "styled-components";
import { Collapse, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

// import FitlerRange from 'components/FilterComponents/FilterRange';
import CollapsibleHeader from "./components/CollapsibleHeader";
import AdditionalFilters from "./components/AdditionalFilters";
import SortOrderControl from "./components/SortOrderControl";
import FooterControls from "./components/FooterControls";
import ExpectedResult from "./components/ExpectedResult";
import { setFilters, clearFilters } from "redux/slices/filterStateSlice";
import { TScreenerFilterContent } from "utils/screenerUtils";
import { useUserContextValue } from "contexts/UserContext";
import { CloseCircleIcon } from "utils/iconUtils";
import { setSortOrder } from "redux/slices/filterConfigSlice";
import { setFilterState } from "redux/slices/filterStateSlice";
import { IReduxState } from "redux/reducers";
import { IScreenerData } from "apollo/types/screener";
import { scrollToTop } from "utils/windowUtils";

const { Panel } = Collapse;

type TScreener = {
  count: number;
  rows: IScreenerData[];
};

interface ICollapsibleFilters {
  loadingState: boolean;
  resultCount: number;
  generateReitsData: () => void;
  minMax: {
    [key: string]: {
      min: number;
      max: number;
    };
  };
}

const StyeldCollapse = styled(Collapse)`
  &.ant-collapse {
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 24px 32px !important;
      }
      .ant-collapse-content {
        padding: 20px 32px 32px;
        border-top: 1px solid #f5f5f5;
        .ant-collapse-content-box {
          padding: 0;
        }
      }
      &.ant-collapse-item-disabled {
        .ant-collapse-header {
          padding: 24px 32px !important;
          cursor: default;
          .chevrons {
            display: none;
          }
        }
      }
    }
  }
`;

const StyledFilterList = styled.ul`
  list-style-type: none;
`;

const StyledFilterItem = styled.li`
  padding: 12px 0;
  border-bottom: 1px solid #f5f5f5;
  .filter-name {
    min-width: 20%;
  }
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    min-height: 18px;
    min-width: 18px;
    height: 18px;
    width: 18px;
    border: 0;
  }
`;

const CollapsibleFilters: React.FC<ICollapsibleFilters> = memo(
  ({ generateReitsData, minMax, resultCount, loadingState }) => {
    const dispatch = useDispatch();
    const { filters, filterState } = useSelector(
      (state: IReduxState) => state.filterState
    );
    const sortOrder = useSelector(
      (state: IReduxState) => state.filterConfig.sortOrder
    );
    const { account } = useUserContextValue();

    const [activeKey, setActiveKey] = useState<undefined | string | string[]>(
      "tune-strategy"
    );

    const setFiltersAction = (filters: TScreenerFilterContent[]) => {
      dispatch(setFilters(filters));
    };

    const removeFilter = (index: number) => {
      const matchWithSortField = filters[index].key === sortOrder.sortField;
      const newSetOfFilters = [...filters];
      newSetOfFilters.splice(index, 1);
      // reset sortOrder when selected sorting field filter is removed
      if (matchWithSortField) {
        dispatch(
          setSortOrder({
            sortField: newSetOfFilters[0].key,
            orderBy: "ASC",
          })
        );
      }
      setFiltersAction(newSetOfFilters);
      dispatch(
        setFilterState({
          ...filterState,
          [filters[index].key]: {
            option: [],
            range: { from: 0, to: 0 },
          },
        })
      );
    };

    const clearAllFilters = () => {
      dispatch(clearFilters());
    };

  
    return (
      <StyeldCollapse
        className="bg-white border-0"
        activeKey={activeKey}
        accordion
        onChange={(k) => setActiveKey(k)}
      >
        <Panel
          disabled
          className="p-0"
          key="tune-strategy"
          showArrow={false}
          header={
            <CollapsibleHeader
              resultCount={resultCount}
              activeKey={activeKey}
            />
          }
        >
          <div className="d-flex">
            <StyledFilterList className="p-0 m-0 fs-14 flex-grow-1">
              {filters.map(
                (
                  {
                    title,
                    filterComponent,
                    withModalSelection,
                    key,
                    removable = true,
                    hasRangeFilter = true,
                    valueType,
                  },
                  idx
                ) => {
                  // const isMoney = valueType === 'money';
                  const fitlerId = title.replace(/ /g, "-").toLowerCase();
                  // const showFilterComponent = hasRangeFilter && advancedMode;
                  return (
                    <StyledFilterItem
                      id={fitlerId}
                      key={fitlerId}
                      className={cx({
                        "d-flex": true,
                        "pb-1": withModalSelection,
                      })}
                    >
                      <div className="filter-name">{title}</div>
                      <div className="flex-grow-1">
                        {/* {showFilterComponent && (
                      <FitlerRange
                        key={JSON.stringify(filterState[key].range)}
                        config={{
                          isMoney: Boolean(isMoney),
                          filterKey: key,
                          range: minMax[key],
                        }}
                      />
                    )} */}
                        {filterComponent && filterComponent()}
                      </div>
                      {removable && (
                        <StyledButton
                          className="remove-filter p-0 border-0 float-right"
                          shape="circle"
                          icon={<CloseCircleIcon />}
                          onClick={() => removeFilter(idx)}
                        />
                      )}
                    </StyledFilterItem>
                  );
                }
              )}
            </StyledFilterList>
            <div className="exected-result flex-shrink-0">
              <ExpectedResult />
            </div>
          </div>
          <div className="d-flex py-4 justify-content-between align-items-center">
            <AdditionalFilters
              minMax={minMax}
              userLevel={account?.level || 0}
              filters={filters}
              setFilters={setFiltersAction}
              removeFilter={removeFilter}
            />
            <SortOrderControl filters={filters} />
          </div>
          <FooterControls
            loadingState={loadingState}
            findReits={() => {        
              generateReitsData();
            }}
            clearAllFilters={() => {
              scrollToTop();
              clearAllFilters();
            }}
          />
        </Panel>
      </StyeldCollapse>
    );
  }
);

export default CollapsibleFilters;
