import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { Button, Modal, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { useMessageContextValue } from 'contexts/MessageContext';
import { TSortOrder } from 'redux/slices/filterConfigSlice';
import { DELETE_CUSTOM_STRATEGY } from 'apollo/mutations/screener';
import { CUSTOM_STRATEGIES } from 'apollo/queries/screener';
import { useUserContextValue } from 'contexts/UserContext';

const { confirm } = Modal;
const { Text } = Typography;

interface IPopconfirmAction {
  name: string,
  id: number,
  strategy: string | null,
  setStrategy: (strategy: string) => void,
  setSortOrder: (sortOrder: TSortOrder) => void,
}

const StyledIconButton = styled(Button)`
  &.ant-btn-sm {
    width: 16px;
    height: 16px;
    font-size: 16px;
    min-height: auto;
    span {
      font-size: 16px !important;
    }
  }
`; 

const PopconfirmAction: React.FC<IPopconfirmAction> = ({ id, name, strategy, setStrategy, setSortOrder }) => {
  const { defaultStrategy } = useUserContextValue();
  const { alertError, alertWarning } = useMessageContextValue();
  const [deleteCustomStrategy, { loading: isDeleting }] = useMutation(DELETE_CUSTOM_STRATEGY);
  
  const deleteStrategy = async () => {
    try {
      await deleteCustomStrategy({
        variables: { strategyFilterId: id },
        refetchQueries: [{
          query: CUSTOM_STRATEGIES,
        }],
        awaitRefetchQueries: true,
      });
      alertWarning('Custom strategy deleted!');
      if (strategy && (id === +strategy)) {
        setStrategy(defaultStrategy.name);
        setSortOrder(defaultStrategy.defaultSort);
      }
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
    }  
  }

  const showConfirm = () => {
    confirm({
      content: <Text>Are you sure to delete this strategy <Text strong>"{name}"</Text>?</Text>,
      onOk: deleteStrategy
    });
  }

  return (
    <StyledIconButton
      type="link"
      size="small"
      disabled={isDeleting}
      icon={<DeleteOutlined />}
      onClick={e => {
        e.stopPropagation();
        showConfirm();
      }}
    />
  );
};

export default PopconfirmAction;
