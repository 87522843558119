import React from "react";
import styled from "styled-components";
import { Row, Col, Avatar, Card } from "antd";

import BlogGridDetails from "./components/BlogGridDetails";
import { IWPPost } from "apollo/types/cms";

interface IBlogGrid {
  posts: IWPPost[];
}

const StyledBlogWrapper = styled.div`
  &.styled-blog-wrap {
    height: 100%;
    .ant-avatar {
      width: 100%;
      height: 100%;
    }
    a {
      border-bottom: 1px solid #675bf5;
      font-weight: 600;
    }
  }
`;

const StyledRow = styled(Row)`
  .ant-avatar {
    width: 100%;
    height: 100%;
    border-radius: 4px 0 0 4px;
  }
  a {
    border-bottom: 1px solid #675bf5;
    font-weight: 600;
  }
`;

const StyledCard = styled(Card)`
  &.styled-blog-card {
    border-radius: 4px !important;
    height: 100%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-card-body {
      padding: 0;
      height: 100%;
    }
  }
`;

const BlogGrid: React.FC<IBlogGrid> = ({ posts }) => {
  const [firstBlog, ...otherBlogs] = posts;
  return (
    <>
      <StyledCard className="mb-5 styled-blog-card">
        <StyledRow gutter={24}>
          <Col xs={24} sm={24} md={12} xl={14}>
            {firstBlog.media && <Avatar shape="square" src={firstBlog.media} />}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            xl={10}
            className="d-flex align-items-center"
          >
            <div>
              <BlogGridDetails
                first
                title={firstBlog.title}
                excerpt={firstBlog.excerpt}
                slug={firstBlog.slug}
              />
            </div>
          </Col>
        </StyledRow>
      </StyledCard>
      <Row gutter={[24, 48]}>
        {otherBlogs.map((blog) => {
          const { title, id, media, excerpt, slug } = blog;
          return (
            <Col xs={24} sm={24} md={8} lg={8} key={id}>
              <StyledCard className="styled-blog-card">
                <StyledBlogWrapper className="d-flex flex-column styled-blog-wrap">
                  {media && (
                    <Avatar
                      shape="square"
                      src={media}
                      style={{
                        maxHeight: 220,
                        height: 220,
                        borderRadius: "4px 4px 0 0",
                      }}
                      className="flex-shrink-0"
                    />
                  )}
                  <div
                    className="d-flex flex-column"
                    style={{ padding: "16px 16px 24px", height: "100%" }}
                  >
                    <BlogGridDetails
                      title={title}
                      excerpt={excerpt}
                      slug={slug}
                    />
                  </div>
                </StyledBlogWrapper>
              </StyledCard>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default BlogGrid;
