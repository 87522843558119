import React, { useEffect } from "react";
import { useQuery } from "react-apollo";
import { Row, Col, Typography, Button, Card } from "antd";
import styled from "styled-components";

import { Spinner } from "components/PageSpinner";
import { CATEGORY_POSTS } from "apollo/queries/resources";
import { scrollToTop } from "utils/windowUtils";

interface IEventsData {
  id: string;
  title: string;
  media: string;
  excerpt: string;
  eventDate: string;
  eventStartTime: string;
  eventURL: string;
}

const { Text, Title } = Typography;

const StyleOverride = styled.div`
  margin-top: 80px;
  .ant-card {
    border-radius: 8px !important;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-card-body {
      padding: 0;
    }
  }
  .prod-img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
    max-width: 690px;
  }
  .ant-btn {
    width: 100%;
    max-width: 160px;
  }
  a {
    text-decoration: underline;
  }
  .generated-caption {
    p {
      margin: 0;
    }
  }
  .custom-feature-caption {
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  }
  .featured-container {
    max-width: 690px;
  }
  @media only screen and (max-width: 720px) {
    .featured-row {
      max-width: 382px;
      margin: 0 auto;
    }
  }
`;

const EventsList = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  let eventsData: IEventsData[] = [];
  let featured: IEventsData = {
    id: "",
    title: "",
    media: "",
    excerpt: "",
    eventDate: "",
    eventStartTime: "",
    eventURL: "",
  };

  const { data, loading } = useQuery(CATEGORY_POSTS, {
    variables: {
      categorySlugs: ["events"],
    },
  });

  if (data) {
    featured = data.posts[0].posts[0];
    eventsData = data.posts[0].posts.slice(1);
  }

  const subSequentEvents = (postResult: IEventsData[]) => {
    return (
      <Row className="p-xl-0 mb-md-5 justify-content-center justify-content-md-between">
        {postResult.length > 0 &&
          postResult.map((post, index) => {
            return (
              <Col
                xs={24}
                className="my-4 my-md-0 w-100"
                key={post.id}
                style={{ maxWidth: "384px" }}
              >
                <Card>
                  <Col xs={24}>
                    <img
                      src={
                        post.media
                          ? post.media
                          : "https://via.placeholder.com/690x400"
                      }
                      className="prod-img"
                      alt="reit images"
                    />
                  </Col>

                  <Row className="mt-2 mb-2 px-3">
                    <Col className="mb-2 mb-sm-0" xs={24} md={14}>
                      <Text className="fs-16">
                        Educator:{" "}
                        <Text type="secondary" strong>
                          Tam Ging Wien
                        </Text>
                      </Text>
                    </Col>
                    <Col className="mb-2 mb-sm-0 text-md-right" xs={24} md={10}>
                      <Text type="secondary" className="fs-16">
                        {post.eventDate}
                      </Text>
                    </Col>
                  </Row>

                  <Col
                    xs={24}
                    className="mt-1 mt-md-0 px-3 pb-2 d-flex flex-column justify-content-between "
                    style={{ minHeight: "200px" }}
                  >
                    <div>
                      <Title className="mb-2" level={4}>
                        {post.title}
                      </Title>

                      <div
                        className="fs-16 generated-caption"
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.substring(0, 100) + "...",
                        }}
                      />
                    </div>
                    <a
                      className="d-block my-2"
                      href={post.eventURL}
                      target="_new"
                    >
                      Get Tickets
                    </a>
                  </Col>
                </Card>
              </Col>
            );
          })}
      </Row>
    );
  };

  return (
    <StyleOverride>
      <div className="max-width-1200 mx-auto">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {data ? (
              <>
                <Row className="px-md-3 p-xl-0 mb-md-5 featured-row">
                  <Col xs={24} className="featured-container w-100">
                    <img
                      src={
                        featured.media
                          ? featured.media
                          : "https://via.placeholder.com/690x400"
                      }
                      className="prod-img"
                      alt="reit images"
                    />
                  </Col>
                  <Col
                    xs={24}
                    md={10}
                    className="custom-feature-caption d-flex align-items-center"
                  >
                    <div className="p-4">
                      <Title className="mb-3" level={3}>
                        {featured.title}
                      </Title>
                      <Row className="mb-3">
                        <Col className="fs-16 mb-2 mb-sm-0" xs={24} md={12}>
                          <Text>
                            When:{" "}
                            <Text type="secondary" strong>
                              {featured.eventDate} {featured.eventStartTime}
                            </Text>
                          </Text>
                        </Col>
                        <Col className="fs-16 mb-2 mb-sm-0" xs={24} md={12}>
                          <Text>
                            Educator:{" "}
                            <Text type="secondary" strong>
                              Tam Ging Wien
                            </Text>
                          </Text>
                        </Col>
                      </Row>
                      <div
                        className="fs-18"
                        dangerouslySetInnerHTML={{
                          __html: featured.excerpt.substring(0, 150) + "...",
                        }}
                      />
                      <a href={featured.eventURL} target="_new">
                        <Button type="primary">Get Tickets</Button>
                      </a>
                    </div>
                  </Col>
                </Row>
                {subSequentEvents(eventsData)}
              </>
            ) : (
              <div className="my-5">No events posted yet.</div>
            )}
          </>
        )}
      </div>
    </StyleOverride>
  );
};

export default EventsList;
