import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from 'antd';

import { IWPPost } from 'apollo/types/cms';

const { Text } = Typography;

interface IFooterNavigation {
  postSlug: string,
  categoryPosts: {
    slug: string,
    posts: IWPPost[],
  }[],
}

interface IReducedCategoryPost extends IWPPost {
  categorySlug: string,
}

const StyledBlogsNavigation = styled.div`
  padding: 32px 0;
  min-height: 150px;
  background: #F5F5F5;
  .navigation-wrapper {
    max-width: 384px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 24px;
  line-height: 32px;
  text-decoration: underline !important;
  font-weight: normal;
`;

const FooterNavigation: React.FC<IFooterNavigation> = ({ postSlug, categoryPosts }) => {
  const compressedPosts = categoryPosts.reduce<IReducedCategoryPost[]>((accumulator, currentValue) => {
    const remappedPost = currentValue.posts.map((v) => ({
      ...v,
      categorySlug: currentValue.slug.replace(/_/g, '-').toLowerCase(),
    }));
    return [
      ...accumulator,
      ...remappedPost,
    ];
  }, []);

  const currentPostIndex = compressedPosts.findIndex(({ slug }) => slug === postSlug);

  const prevArticle = (currentPostIndex === 0) ? null : compressedPosts[currentPostIndex - 1];
  const nextArticle = ((currentPostIndex + 1) === compressedPosts.length) ? null : compressedPosts[currentPostIndex + 1];

  return (
    <StyledBlogsNavigation>
      <div className="mx-auto max-width-1200 d-flex justify-content-between">
        <div className="navigation-wrapper">
          {prevArticle && (
            <>
              <Text className="d-block mb-2" type="secondary">Previous article</Text>
              <StyledLink to={`/resources/${prevArticle.categorySlug}/${prevArticle.slug}`}>
                {prevArticle.title}
              </StyledLink>
            </>
          )}
        </div>
        <div className="navigation-wrapper text-right">
          {nextArticle && (
            <>
              <Text className="d-block mb-2" type="secondary">Next article</Text>
              <StyledLink to={`/resources/${nextArticle.categorySlug}/${nextArticle.slug}`}>
                {nextArticle.title}
              </StyledLink>
            </>
          )}
        </div>
      </div>
    </StyledBlogsNavigation>
  );
}

export default FooterNavigation;
