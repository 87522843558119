import React from "react";
import { Row, Col, Typography } from "antd";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

import { REIT_STARTER_INFO } from "apollo/queries/reit";
import { Spinner } from "components/PageSpinner";
import { getCurrency, REIT_EXCEPTIONS } from "utils/reitUtils";
import { truncateDecimals } from "utils/numberUtils";

interface IReitSEO {
  stockCode: string;
  name: string;
  logo: string;
  about: string;
}

interface IStarterInfoDetails {
  name: string;
  stockCode: string;
  exchange: string;
  sector: string;
  logo: string;
  price: number;
  volume: number;
  priceChange: number;
  priceChangePerc: number;
  about?: string;
}

const { Text } = Typography;

const StyleOverride = styled.div`
  .reit-image-container {
    overflow: hidden;
    max-width: 280px;
    max-height: 200px;
  }
`;

const StarterInfo: React.FC<RouteComponentProps<{
  stockCode: string;
}>> = ({ match }) => { 
  let reitInfo: IStarterInfoDetails = {
    name: "",
    stockCode: "",
    exchange: "",
    sector: "",
    logo: "",
    price: 0,
    volume: 0,
    priceChange: 0,
    priceChangePerc: 0,
    about: "",
  };

  const { loading, data } = useQuery(REIT_STARTER_INFO, {
    variables: { stockCode: match.params.stockCode },
  });

  if (data) {
    const { profile } = data.screenerReit;
    reitInfo = Object.assign({}, reitInfo, profile);
  }

  return (
    <StyleOverride>
      <Row className="px-4 py-4">
        {loading ? (
          <div className="w-100 justify-self-center">
            <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
          </div>
        ) : (
          <>
            {data ? (
              <>
                <Col xs={24} md={12}>
                  <div>
                    <h2 className="d-inline m-0">{reitInfo.name}</h2>
                    <Text
                      type="secondary"
                      className="ml-4 font-weight-bold fs-20"
                    >
                      {reitInfo.stockCode}
                    </Text>
                  </div>
                  <div className="d-flex mt-3">
                    <Text type="secondary">
                      <Text
                        type="secondary"
                        className="d-block fs-12 unit-label"
                      >
                        Price
                      </Text>
                      {getCurrency(reitInfo.exchange)}
                      {reitInfo.price.toFixed(2)}
                    </Text>

                    <Text type="secondary" className="ml-5">
                      <Text
                        type="secondary"
                        className="d-block fs-12 unit-label"
                      >
                        Price Change
                      </Text>
                      <Text
                        className={
                          Math.sign(reitInfo.priceChange) < 1
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {Math.sign(reitInfo.priceChange) < 1 ? (
                          <>
                            {`${truncateDecimals(
                              reitInfo.priceChange,
                              2
                            )}(${truncateDecimals(
                              reitInfo.priceChangePerc * 100,
                              2
                            )}%)`}
                            <CaretDownFilled className="ml-2" />
                          </>
                        ) : (
                          <>
                            {`${truncateDecimals(
                              reitInfo.priceChange,
                              2
                            )}(${truncateDecimals(
                              reitInfo.priceChangePerc * 100,
                              2
                            )}%)`}
                            <CaretUpFilled className="ml-2" />
                          </>
                        )}
                      </Text>
                    </Text>
                  </div>
                  <div className="d-flex mt-3">
                    <Text type="secondary">
                      <Text
                        type="secondary"
                        className="d-block fs-12 unit-label"
                      >
                        Country
                      </Text>
                      {reitInfo.exchange}
                    </Text>
                    <Text type="secondary" className="ml-5">
                      <Text
                        type="secondary"
                        className="d-block fs-12 unit-label"
                      >
                        Sector
                      </Text>
                      {reitInfo.sector}
                    </Text>

                    <Text type="secondary" className="ml-5">
                      <Text
                        type="secondary"
                        className="d-block fs-12 unit-label"
                      >
                        Volume
                      </Text>
                      {reitInfo.volume.toLocaleString()}
                    </Text>
                  </div>
                </Col>
                <Col xs={24} md={12} className="d-flex justify-content-end">
                  <div className="reit-image-container w-100 h-100 d-flex justify-content-center align-items-center">
                    <img
                      src={reitInfo.logo}
                      alt={reitInfo.name}
                      className="d-block"
                      style={{
                        width: REIT_EXCEPTIONS.includes(match.params.stockCode)
                          ? "100px"
                          : "100%",
                      }}
                    />
                  </div>
                </Col>
              </>
            ) : (
              <p>no data available</p>
            )}
          </>
        )}
      </Row>
    </StyleOverride>
  );
};
export default withRouter(StarterInfo);
