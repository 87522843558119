import React, { useEffect } from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";

import InformationFrame from "./components/Information";
import KeyStatsFrame from "./components/KeyStatistics";
import PortfolioFrame from "./components/Portfolio";
import FinancialsFrame from "./components/Financials";
import DebtFrame from "./components/Debt";
import GrowthFrame from "./components/Growth";
import ProfileFrame from "./components/Profile";
import TradingView from "./components/TradingView";

import { scrollToTop } from "utils/windowUtils";

const StyleOverride = styled.div`
  .ant-spin {
    min-height: 100px !important;
  }
  .fixed-header {
    position: fixed;
    width: 100%; 
    z-index: 5;
    background-color: #fff;    
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }
  .row-content {
    display: block;
    width: 100%;
    .ant-row {
      &:not(:last-child){
        border-bottom: 1px solid rgba(0,0,0,0.08);
      }
    }
  }
  .frame-subheader {    
    background-color: rgba(154, 145, 255, 0.08);
    text-transform: uppercase;
    display: block;
    width: 100%;
    font-size: 14px;
  }
  .ant-col {
    flex: 1;
    padding: 16px;
    flex-basis: 0; 
    &:nth-of-type(even) {
      background: rgba(154, 145, 255, 0.06);
    }
  }
  .std-trigger {
    cursor: pointer;
    &:hover {
      color: #675bf5;
    }
`;

const ComparisonTable: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  useEffect(() => {
    scrollToTop();
    if (!history.location.state) {
      history.replace("/screener/table");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  return (
    <StyleOverride className="max-width-1200 mx-auto position-relative pb-4">
      {/* /fix for reitlist undefined/ */}
      {history.location.state && (
        <>
          <InformationFrame />
          <KeyStatsFrame />
          <TradingView />
          <PortfolioFrame />
          <FinancialsFrame />
          <DebtFrame />
          <GrowthFrame />
          <ProfileFrame />
        </>
      )}
    </StyleOverride>
  );
};

export default withRouter(ComparisonTable);
