import React, { useState } from "react";
import { AutoComplete, Typography, Spin } from "antd";
import styled from "styled-components";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { withRouter, RouteComponentProps } from "react-router-dom";

import SearchModal from "components/UngregisteredSearch";
import { useUserContextValue } from "contexts/UserContext";
import {
  REGISTERED_USER_INDEX,
  PREMIUM_USER_INDEX,
  ANONYMOUS_USER_INDEX,
} from "utils/appUtils";

import { LockIcon } from "utils/iconUtils";

const { Option } = AutoComplete;
const { Text } = Typography;
const StyleOverride = styled.div`
  .search-icon {
    margin-top: 2px;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: #8c8c8c !important;
  }
`;

interface IReitData {
  id: string;
  value: string;
  prefix: string;
}

interface IExternalprops extends RouteComponentProps {
  listing: Array<IReitData>;
  userLocation: string;
}

const GlobalSearch: React.FC<IExternalprops> = ({
  listing,
  userLocation,
  history,
}) => {
  const [registerModal, setRegisterModal] = useState(false);
  const [isEmpty, setEmpty] = useState(true);
  const [search, setSearch] = useState("");
  const [isTyping, setTyping] = useState(false);
  const { account } = useUserContextValue();
  let typeTimeOut = setTimeout(() => {}, 0);
  const userLevel = account?.level || 0;
  const userRegisteredOnly =
    userLevel > ANONYMOUS_USER_INDEX && userLevel < PREMIUM_USER_INDEX;

  const handleCloseModal = () => {
    setRegisterModal(false);
  };

  const handleSearch = (val: string) => {
    clearTimeout(typeTimeOut);
    setTyping(true);
    if (val.length > 0) {
      typeTimeOut = setTimeout(() => {
        setEmpty(false);
        setTyping(false);
        setSearch(val);
      }, 700);
    } else {
      setEmpty(true);
      setTyping(false);
    }
  };

  const handleSelect = (val: string, option: any) => {
    //modal pop up
    if (option.reitlock === "true") {
      setRegisterModal(true);
    } else {
      setTimeout(() => {
        history.push(`/screener/table/reits/${option.key}`);
      }, 50);
    }
  };

  const TextMarks: React.FC<{ val: string }> = ({ val }) => {
    let rxgp = new RegExp(search, "ig");
    let searchText = val.match(rxgp) || [""];
    let highlight = val.replace(
      rxgp,
      `<mark style={{color: gold}}>${searchText[0]}</mark>`
    );
    return <span dangerouslySetInnerHTML={{ __html: highlight }} />;
  };

  const renderOptions = (listing: Array<IReitData>) => {
    return (
      <>
        {!isEmpty &&
          listing
            .filter((reit) => {
              let rxgp = new RegExp(search, "ig");
              return rxgp.test(reit.value);
            })
            .map((reit) => (
              <Option
                key={reit.id}
                value={reit.value}
                reitlock={
                  userLevel < REGISTERED_USER_INDEX ||
                  (userRegisteredOnly && reit.prefix !== userLocation)
                    ? "true"
                    : "false"
                }
              >
                <div className="d-flex w-100">
                  {userLevel < REGISTERED_USER_INDEX && (
                    <span className="ml-1 mr-2">
                      <LockIcon />
                    </span>
                  )}
                  {userRegisteredOnly && reit.prefix !== userLocation && (
                    <span className="ml-1 mr-2">
                      <LockIcon />
                    </span>
                  )}
                  <Text
                    className={
                      userRegisteredOnly && reit.prefix === userLocation
                        ? "ml-4"
                        : ""
                    }
                  >
                    <TextMarks val={reit.value} />
                    <Text type="secondary" className="fs-12 text-muted" strong>
                      ({reit.id})
                    </Text>
                  </Text>
                </div>
              </Option>
            ))}
      </>
    );
  };

  return (
    <StyleOverride className="position-relative">
      <>
        {registerModal && <SearchModal closeModal={handleCloseModal} />}
        <SearchOutlined className="search-icon" />
        <AutoComplete
          style={{ width: 440 }}
          size="large"
          onKeyDown={(e) => e.stopPropagation()}
          className="mt-1"
          onSearch={handleSearch}
          onSelect={handleSelect}
          placeholder="Search a REIT .."
          getPopupContainer={() =>
            document.getElementById("global-search") || document.body
          }
          notFoundContent={
            isTyping ? (
              <Spin indicator={<LoadingOutlined />} size="small" />
            ) : null
          }
        >
          {renderOptions(listing)}
        </AutoComplete>
      </>
    </StyleOverride>
  );
};

export default withRouter(GlobalSearch);
