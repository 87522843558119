import React, { useState } from "react";
import cx from "classnames";
import { Typography, Button } from "antd";
import { useMutation } from "react-apollo";
import styled from "styled-components";

import { Spinner } from "components/PageSpinner";
import { SEND_MAGIC_LINK } from "apollo/mutations/user";
import { useMessageContextValue } from "contexts/MessageContext";

const MAIL = "/assets/images/mail.svg";
const { Title, Paragraph, Text } = Typography;

const StyledWrapper = styled.div`
  .anticon {
    font-size: 40px !important;
  }
  .link-txt {
    color: #675bf5;
    cursor: pointer;
  }
`;

interface ILoginSuccessProps {
  fromQuiz?: boolean;
  email?: string;
  hasMagicCode?: boolean;
}

interface IResendMailState {
  success: boolean;
  error: string;
}

const redirectEmailSite = (email: ILoginSuccessProps["email"]) => {
  const emailHost = email?.split("@")[1];
  window.open(`https://www.${emailHost}`);
};

const SucessText: React.FC<ILoginSuccessProps> = ({
  email,
  hasMagicCode,
  fromQuiz = false,
}) => {
  const [resending, setResending] = useState<IResendMailState>({
    success: false,
    error: "",
  });
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const { alertError } = useMessageContextValue();
  const handleResendLink = async () => {
    setResending({ success: true, error: "" });
    try {
      await sendMagicLink({
        variables: {
          input: {
            email,
          },
        },
      });
      setResending({ success: false, error: "" });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      if (errorMessage.search(/missing/gi) > -1) {
        errorMessage = "Email address is not registered.";
      }
      alertError(errorMessage);
      setResending({ success: false, error: errorMessage });
    }
  };

  return (
    <StyledWrapper>
      <img src={MAIL} alt="email" className="mb-4" />
      <Title level={2} className="mb-4 text-center px-1">
        {hasMagicCode && "Verifiying Magic link"}
        {resending.success && "Resending Magic link"}
        {!hasMagicCode && !resending.success && "Magic link is in your inbox"}
      </Title>
      {hasMagicCode || resending.success ? (
        <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
      ) : (
        <>
          <Paragraph
            className={cx({
              "px-xs-2": true,
              "fs-20": fromQuiz,
              "fs-16": !fromQuiz,
            })}
            style={{ marginBottom: fromQuiz ? 32 : 40 }}
          >
            We just emailed a magic link to
            <span
              onClick={() => redirectEmailSite(email)}
              className="link-txt pl-1"
            >
              {email}
            </span>
            <br />
            Click the link, and you will be Logged in
          </Paragraph>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="text-white mb-3"
            block
            onClick={() => redirectEmailSite(email)}
          >
            Open mail app
          </Button>
          <Text className="mb-3 d-block text-center fs-14">
            Didn’t received the magic link?{" "}
            <Button
              className="p-0 fs-14"
              type="link"
              onClick={handleResendLink}
              style={{ fontWeight: 100 }}
            >
              Resend
            </Button>
          </Text>
        </>
      )}
    </StyledWrapper>
  );
};

export default SucessText;
