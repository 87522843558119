import React from "react";
import { Typography, Row, Col } from "antd";
import { useQuery } from "react-apollo";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Spinner } from "components/PageSpinner";
import styled from "styled-components";

import { REIT_FINANCIALS_INFO } from "apollo/queries/reit";
import { useUserContextValue } from "contexts/UserContext";
import { KEY_FINANCIALS_FRAME } from "utils/reitUtils";
import LockReitModal from "components/LockReitmodal";
import { HistoricalChart } from "components/Charts";
import { ANONYMOUS_USER_INDEX } from "utils/appUtils";

const { Text } = Typography;

const StyledRow = styled(Row)`
  .ant-col {
    border-bottom: 1px solid #e8e8e8;
  }
`;

interface IFinancialFields {
  value: number;
  chart: Array<{
    label: string;
    value: number;
  }>;
}
interface IFinancialDetails {
  revenue: IFinancialFields;
  NPI: IFinancialFields;
  operatingCashFlow: IFinancialFields;
  freeCashFlow: IFinancialFields;
  FFO: IFinancialFields;
  AFFO: IFinancialFields;
  distributionsPaid: IFinancialFields;
}

const Financials: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
}) => {
  const { account } = useUserContextValue();

  let financialsData: IFinancialDetails = {
    revenue: {
      value: 0,
      chart: [],
    },
    NPI: {
      value: 0,
      chart: [],
    },
    operatingCashFlow: {
      value: 0,
      chart: [],
    },
    freeCashFlow: {
      value: 0,
      chart: [],
    },
    FFO: {
      value: 0,
      chart: [],
    },
    AFFO: {
      value: 0,
      chart: [],
    },
    distributionsPaid: {
      value: 0,
      chart: [],
    },
  };

  const { loading, data } = useQuery(REIT_FINANCIALS_INFO, {
    variables: { stockCode: match.params.stockCode },
  });

  if (data) {
    financialsData = data.screenerReit.financials;
  }

  const ExtractAllowedFields: React.FC<{ userLevel: number }> = ({
    userLevel,
  }) => {
    const fieldDefinition = KEY_FINANCIALS_FRAME.map((field) => {
      switch (field.name) {
        case "REVENUE":
          return (
            <Col
              xs={24}
              className="d-flex align-items-center justify-content-between p-3"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
                style={{ flexBasis: "60%" }}
              >
                Revenue
              </Text>
              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.revenue.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="Revenue"
                        unit="Currency"
                        dataSource={financialsData.revenue.chart}
                        value={financialsData.revenue.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        case "NPI":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
                style={{ flexBasis: "60%" }}
              >
                Net Property Income
                <br />
                (NPI)
              </Text>
              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.NPI.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="NPI"
                        unit="Currency"
                        dataSource={financialsData.NPI.chart}
                        value={financialsData.NPI.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        case "OPERATING_CASH_FLOW":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
              style={{ flexBasis: "60%" }}
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
              >
                Operating Cash Flow
              </Text>
              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.operatingCashFlow.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="Operating Cash Flow"
                        unit="Currency"
                        dataSource={financialsData.operatingCashFlow.chart}
                        value={financialsData.operatingCashFlow.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        case "FREE_CASH_FLOW":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
                style={{ flexBasis: "60%" }}
              >
                Free Cash Flow
              </Text>
              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.freeCashFlow.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="Free Cash Flow"
                        unit="Currency"
                        dataSource={financialsData.freeCashFlow.chart}
                        value={financialsData.freeCashFlow.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        case "FFO":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
                style={{ flexBasis: "60%" }}
              >
                FFO
              </Text>

              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.FFO.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="FFO"
                        unit="Currency"
                        dataSource={financialsData.FFO.chart}
                        value={financialsData.FFO.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        case "AFFO":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
                style={{ flexBasis: "60%" }}
              >
                AFFO
              </Text>

              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.AFFO.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="AFFO"
                        unit="Currency"
                        dataSource={financialsData.AFFO.chart}
                        value={financialsData.AFFO.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        case "DISTRIBUTIONS_PAID":
          return (
            <Col
              xs={24}
              className="d-flex justify-content-between align-items-center p-3 border-0"
            >
              <Text
                className="fs-16 d-flex"
                disabled={userLevel < field.minimumPlan}
                style={{ flexBasis: "60%" }}
              >
                Distributions Paid
              </Text>

              {userLevel < field.minimumPlan ? (
                <span className="ml-2">
                  <LockReitModal
                    isDataBoostFeature={
                      userLevel <= ANONYMOUS_USER_INDEX ? false : true
                    }
                    minimumPlan={userLevel}
                  />
                </span>
              ) : (
                <>
                  {financialsData.distributionsPaid.value ? (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ flexBasis: "34.99%" }}
                    >
                      <HistoricalChart
                        chartLabel="Distributions Paid"
                        unit="Currency"
                        dataSource={financialsData.distributionsPaid.chart}
                        value={financialsData.distributionsPaid.value}
                      />
                    </div>
                  ) : (
                    <Text>-</Text>
                  )}
                </>
              )}
            </Col>
          );
        default:
          return <p>no data available..</p>;
      }
    });

    return (
      <>
        {fieldDefinition.map((elem, idx) => (
          <div key={idx}>{elem}</div>
        ))}
      </>
    );
  };

  return (
    <StyledRow>
      <Col xs={24} className="border-0">
        <Text className="card-header d-block w-100 px-3 py-2">FINANCIALS</Text>
        {loading ? (
          <div className="w-100 justify-self-center">
            <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
          </div>
        ) : (
          <>
            {data ? (
              <ExtractAllowedFields userLevel={account?.level || 0} />
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Col>
    </StyledRow>
  );
};
export default withRouter(Financials);
