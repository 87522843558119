import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Quiz from './Quiz'
import QuizComplete from './pages/QuizComplete';
import QuizAnonymous from './pages/QuizAnonymous';
import FourOhFour from '../FourOhFour';

// https://stackoverflow.com/questions/47369023/react-router-v4-allow-only-certain-parameters-in-url

export default () => (
  <Switch>
    <Route exact path="/quiz/:quiz_num(1|2|3|4|5|6|7|8|9)" component={Quiz} />
    <Route exact path="/quiz/anonymous" component={QuizAnonymous} />
    <Route exact path="/quiz/complete" component={QuizComplete} />
    <Redirect exact from="/quiz" to="/quiz/1"/>
    <Route component={FourOhFour} />
  </Switch>
);
