export const ARTICLE_LIST = [{
  label: 'What are REITs?',
  link: '/resources/reits-101/wp:what-is-a-reit',
}, {
  label: 'Typical structure of a REIT',
  link: '/resources/reits-101/wp:typical-structure-of-a-reit',
}, {
  label: 'Types of REITs',
  link: '/resources/reits-101/wp:types-of-reits',
}];

export const GET_PANEL_STEPS = (props: { quiz: boolean, articles: string[] }) => {
  return [{
    title: 'Take our investment strategy quiz',
    description: 'Discover which strategy works for you.',
    completed: props.quiz,
    list: [{
      label: 'Take the quiz',
      link: '/quiz/1',
      completed: props.quiz,
    }],
  },
  // {
  //   title: 'Watch the video walkthrough',
  //   description: 'Our founder Wien will guide you through how to set up and start using REITscreener 👍',
  //   list: [{
  //     label: 'Watch the video',
  //     link: '/quiz/1',
  //   }],
  // },
  {
    title: 'Complete REITs 101',
    description: 'Read our quick intro and get up to speed.',
    completed: function() {
      const result = ARTICLE_LIST.filter(({ link }) => props.articles.includes(link));
      return result.length === ARTICLE_LIST.length;
    }(),
    list: ARTICLE_LIST.map((v) => ({
      ...v,
      completed: props.articles.includes(v.link),
    })),
  }];
};