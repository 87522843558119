import React, { useState } from "react";
import { useMutation } from "react-apollo";
import styled from "styled-components";
import { Row, Col, Typography, List, Avatar } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";

import SignUpForm from "components/SignUpForm";
import SentMagicLinkModal from "components/SentMagicLinkModal";
import { IRegisterFormValues } from "components/SignUpForm/SignUpForm";
import { SEND_MAGIC_LINK } from "apollo/mutations/user";
import { useMessageContextValue } from "contexts/MessageContext";

const { Text, Title } = Typography;
const StyledCTAWrapper = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2px;
    display: block;
    width: 354px;
    height: 60px;
    opacity: 0.5;
    background-image: url("/assets/images/dots-space.png");
    background-repeat: repeat-x;
    background-position: center 10px;
  }
  padding: 40px 0;
  .max-width-1200 {
    h1 {
      max-width: 80%;
    }
    .form-wrapper {
      margin: 32px 0;
      max-width: 75%;
      .ant-form {
        .ant-form-item {
          .ant-form-item-label > label {
            color: #fff;
          }
          input {
            background: #1f292e;
            border: 1px solid #1f292e;
            color: #fff;
            &::placeholder {
              color: #595959;
            }
          }
          .ant-btn {
            min-height: 48px !important;
            font-size: 16px;
          }
        }
      }
    }
  }

.ant-list-item {
    max-width: 480px !important;
  }
  .ant-list-item-meta {
    align-items: start;
    img {
      width: 16px;
      height: 12px;
    }
  }
  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-list-item-meta-description,
  .ant-list-item-meta-title {
    color: white;
    margin: 0;
    font-size: 16px !important;
  }
  .ant-list-item-meta-description {
    color: #e8e8e8;
  }
  .ant-list-item-meta-avatar {
    margin-right: 5px;
  }
`;
const CHECK_YELLOW = "/assets/images/checkYellow.svg";

const CTAUnregistered: React.FC<RouteComponentProps> = ({ location }) => {
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const [visibility, setVisibility] = useState(false);
  const [email, setEmail] = useState("");
  const { alertError } = useMessageContextValue();
  const data: Array<{
    title: string;
    message: string;
  }> = [
    {
      title: "eBooks",
      message:
        'Receive a complimentary copy of our eBook entitled "8 Myths People Still Believe About REITs"',
    },
    {
      title: "Webinars",
      message: "Unlock access our most popular REIT webinars.",
    },
    {
      title: "eResource",
      message:
        "Gain unrestricted access to our extensive online educational REIT articles and resources",
    },
  ];

  const handleRegisterSubmit = async (
    values: IRegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await sendMagicLink({
        variables: {
          input: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            redirectUrl: location.pathname,
          },
        },
      });
      setSubmitting(false);
      setVisibility(true);
      setEmail(values.email);
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
      setSubmitting(false);
    }
  };

  return (
    <StyledCTAWrapper className="bg-primary-dark position-relative">
      <div className="max-width-1200 mx-auto">
        <Row>
          <Col xs={24} sm={24} md={13}>
            <Text
              className="fs-20 d-block mb-2 text-uppercase"
              style={{ color: "#FEC669" }}
            >
              Get Started
            </Text>
            <Title level={2} className="d-block text-white m-0 w-100 mb-4">
              Everything you need to invest profitably in REITs
            </Title>
            <List
              dataSource={data}
              renderItem={(item) => (
                <List.Item className="py-1 border-0 d-flex w-100">
                  <List.Item.Meta
                    avatar={<Avatar src={CHECK_YELLOW} />}
                    title={item.title}
                    description={item.message}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={11}>
            <div className="form-wrapper mx-auto w-100 my-0">
              <SignUpForm onSubmit={handleRegisterSubmit} />
              <Text className="text-white fs-16 text-center d-block">
                No credit card required · Get started in a minute
              </Text>
            </div>
          </Col>
        </Row>
      </div>
      <SentMagicLinkModal
        visibility={visibility}
        setVisibility={setVisibility}
        email={email}
      />
    </StyledCTAWrapper>
  );
};

export default withRouter(CTAUnregistered);
