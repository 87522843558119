import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Screener from "./Screener";
import FourOhFour from "../FourOhFour";
import FinancialReports from "./pages/Reits/components/FinancialReports";

// https://stackoverflow.com/questions/47369023/react-router-v4-allow-only-certain-parameters-in-url

export default () => (
  <Switch>
    <Route
      exact
      path="/screener/:screener_tab(table|watchlist)"
      component={Screener}
    />
    <Route exact path="/screener/table/comparison" component={Screener} />
    <Route
      exact
      path="/screener/:screener_tab(table|watchlist)/reits/:stockCode"
      component={Screener}
    />
    <Route
      exact
      path="/screener/:screener_tab(table|watchlist)/reits/:stockCode/financialReports"
      component={FinancialReports}
    />
    <Redirect exact from="/screener" to="/screener/table" />
    <Redirect exact from="/reits" to="/screener/table" />
    <Route component={FourOhFour} />
  </Switch>
);
