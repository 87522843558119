import gql from "graphql-tag";

export const CREATE_ACCESS_TOKEN = gql`
  mutation CreateToken($input: CreateTokenInput!) {
    createToken(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_ACCESS_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const SEND_MAGIC_LINK = gql`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input)
  }
`;

export const AUTHENTICATE_MAGIC_LINK = gql`
  mutation AuthenticateMagicLink($code: String!) {
    authenticateMagicLink(code: $code) {
      accessToken
      refreshToken
    }
  }
`;

export const UPDATE_ACCOUNT_SIGNUP = gql`
  mutation UpdateAccountSignUp {
    updateAccountSignUp
  }
`;

export const UPDATE_CARD_INFO = gql`
  mutation UpdateCardInfo($token: String!, $stripeToken: String!) {
    createStripeCard(token: $token, stripeToken: $stripeToken)
  }
`;
export const DELETE_CARD_INFO = gql`
  mutation DeleteCardInfo($token: String!, $cardId: String!) {
    removeStripeCard(token: $token, cardId: $cardId)
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($token: String!, $input: UpdateAccountInput!) {
    updateAccount(token: $token, input: $input) {
      accountId
    }
  }
`;

export const UPDATE_ACCOUNT_CHECKLIST = gql`
  mutation UpdateAccountChecklist($input: UpdateAccountChecklistInput!) {
    updateAccountChecklist(input: $input) {
      articles
    }
  }
`;

export const LOGOUT_ACCOUNT = gql`
  mutation logoutAccount {
    logout
  }
`;
