import React from 'react';
import { Typography } from 'antd';

import { ChevronRight } from 'utils/iconUtils';

const { Text } = Typography;
const REITS_101 = '/assets/images/reits-101.png';
const BOOKS = '/assets/images/books.png';
const EVENTS = '/assets/images/events.png';
const BLOG = '/assets/images/blog.png';

export const MEGA_MENU_ITEMS = [{
  path: '/resources/reits-introduction/wp:the-reitscreener-investment-philosophy',
  rendered: (
    <>
      <img className="mb-2" draggable={false} src={REITS_101} alt="REITs 101" />
      <div className="mb-1 d-flex align-items-center justify-content-between">
        <Text className="link-title" strong>REITs 101</Text>
        <ChevronRight />
      </div>
      <Text className="fs-14" type="secondary">
        Our free online educational resources that help you both understand and take actionable decisions as a REIT investor. If you’ve come to learn about investing in REITs, you’ve come to the right place.
      </Text>
    </>
  ),
}, {
  path: '/resources/books',
  rendered: (
    <>
      <img className="mb-2" draggable={false} src={BOOKS} alt="Books" />
      <div className="mb-1 d-flex align-items-center justify-content-between">
        <Text className="link-title" strong>Books</Text>
        <ChevronRight />
      </div>
      <Text className="fs-14" type="secondary">
        Our free online educational resources that help you both understand and take actionable decisions as a REIT investor. If you’ve come to learn about investing in REITs, you’ve come to the right place.
      </Text>
    </>
  ),
}, {
  path: '/resources/events',
  rendered: (
    <>
      <img className="mb-2" draggable={false} src={EVENTS} alt="Events" />
      <div className="mb-1 d-flex align-items-center justify-content-between">
        <Text className="link-title" strong>Events</Text>
        <ChevronRight />
      </div>
      <Text className="fs-14" type="secondary">
        Get access to our best seller “REITs To Riches” and many others publications that will help you navigate the REIT markets and level up your REITs knowledge.
      </Text>
    </>
  ),
}, {
  path: '/resources/blog',
  rendered: (
    <>
      <img className="mb-2" draggable={false} src={BLOG} alt="Blog" />
      <div className="mb-1 d-flex align-items-center justify-content-between">
        <Text className="link-title" strong>Blog</Text>
        <ChevronRight />
      </div>
      <Text className="fs-14" type="secondary">
        Find out about our thoughts and views about current events in the REIT markets.
      </Text>
    </>
  ),
}];
