export const VIDEO_FIELDS: string = `
      id
      slug
      date
      tag
      title
      videoDate
      videoURL
      videoGroup
      permission
      videoTimestamps {
        title
        time
      }
      media
      content
`;
export const COMMON_POST_FIELDS = `
  id
  title
  media
  excerpt
  eventDate
  eventStartTime
  eventURL
  free
  buyURLs
  slug
  permission
  date
`;
