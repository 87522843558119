import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Typography, Button } from "antd";
import { Link } from "react-router-dom";

import ExclusiveSignUp from "./components/ExclusiveSignUp";
import { ISuccessRegister } from "./components/ExclusiveSignUp/ExclusiveSignUp";
import SuccessText from "components/LoginRegisterCommon/components/SucessText";
import { useUserContextValue } from "contexts/UserContext";
import { ANONYMOUS_USER_INDEX } from "utils/appUtils";
import { LockIconCircle } from "utils/iconUtils";

interface IModalProps {
  visible: boolean;
  needAddOn?: boolean;
  setVisibility: (visibility: boolean) => void;
}

const { Text } = Typography;

const StyledModal = styled(Modal)`
  width: 100% !important;
  &.ant-modal {
    .ant-modal-content {
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      .ant-modal-body {
        width: 100%;
        padding: 0;
      }
    }
  }
`;

const StyleSuccessOverride = styled.div`
  div {
    width: 100% !important;
    max-width: 500px !important;
    img {
      margin-bottom: 24px !important;
    }
    h2.ant-typography {
      font-size: 24px !important;
      font-weight: bold !important;
      margin-bottom: 10px !important;
    }
    .ant-btn {
      max-width: 181px;
      margin-top: 10px !important;
    }
  }
`;

const UnregisteredModal: React.FC<IModalProps> = ({
  visible,
  setVisibility,
  needAddOn,
}) => {
  const { account } = useUserContextValue();
  const userLevel = account?.level || 0;
  const [registerInfo, setRegisterInfo] = useState<ISuccessRegister>({
    isDone: false,
    email: "",
  });

  return (
    <>
      {userLevel <= ANONYMOUS_USER_INDEX && (
        <StyledModal
          visible={visible}
          cancelText={false}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          footer={false}
          style={{ maxWidth: registerInfo.isDone ? "636px" : "996px" }}
          onCancel={() => {
            setVisibility(false);
            setRegisterInfo({ isDone: false, email: "" });
          }}
        >
          {!registerInfo.isDone ? (
            <ExclusiveSignUp setRegisterInfo={setRegisterInfo} />
          ) : (
            <StyleSuccessOverride className="d-flex justify-content-center text-center p-5">
              <SuccessText email={registerInfo.email} />
            </StyleSuccessOverride>
          )}
        </StyledModal>
      )}
      {userLevel > ANONYMOUS_USER_INDEX && (
        <StyledModal
          visible={visible}
          cancelText={false}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          footer={false}
          style={{ maxWidth: "600px" }}
          onCancel={() => setVisibility(false)}
        >
          <div className="p-5 text-center">
            <h3 className="text-center">
              {needAddOn
                ? "You discovered an RTV add on!"
                : "You discovered a video for Premium Users!"}
            </h3>
            <div className="my-3 text-center">
              <LockIconCircle />
            </div>
            <Text className="d-block w-100 mb-3">
              {needAddOn
                ? "Subscribe to REITScreener TV Add On to access (Market Briefs and Specials) videos."
                : "Subscribe to REITScreener Premium to access this video."}
            </Text>
            <Link to="/settings/subscription">
              <Button type="primary">
                {needAddOn ? "Learn More" : "Subscribe to Premium"}
              </Button>
            </Link>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default UnregisteredModal;
