import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography } from "antd";

import DataSlider from "components/DataSlider";
import Spinner from "components/PageSpinner";
import LockReitmodal from "components/LockReitmodal";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_KEYSTAT_FIELDS } from "apollo/fields/reits";
import { getCurrency } from "utils/reitUtils";
import { truncateDecimals, formatCurrency } from "utils/numberUtils";
import { KEY_STATISTICS_FRAME } from "utils/reitUtils";
import { useUserContextValue } from "contexts/UserContext";
import { HistoricalChart } from "components/Charts";
import { ChartIcon, BarChartIcon } from "utils/iconUtils";
import { REGISTERED_USER_INDEX } from "utils/appUtils";

interface IKeyStats {
  stockCode: string;
  exchange: string;
  marketCap: number;
  sharePriceRange: {
    value: number;
    min: number;
    max: number;
  };
  volatility: number;
  dividendAmount: {
    value: number;
    chart: Array<{
      value: number;
      label: string;
    }>;
  };
  NAV: {
    value: number;
    chart: Array<{
      value: number;
      label: string;
    }>;
  };
  dividends: {
    range: {
      value: number;
      min: number;
      max: number;
    };
  };
  priceToBookRatio: {
    range: {
      value: number;
      min: number;
      max: number;
    };
  };
}

const labels: Array<{
  title: string;
  key: string;
  meta?: string;
  metaNL?: boolean;
}> = [
  { title: "Market Cap", key: "marketCap" },
  { title: "52-week Range", key: "sharePriceRange" },
  { title: "Volatility", key: "volatility", meta: "(Beta)", metaNL: false },
  {
    title: "Dividend Amount",
    key: "dividendAmount",
    meta: "(TTM Dividend per Share/Unit)",
    metaNL: true,
  },
  {
    title: "NAV",
    key: "NAV",
    meta: "(Latest Net Asset Value per Share/Unit)",
    metaNL: true,
  },
  {
    title: "Dividend",
    key: "dividends",
    meta: "(TTM Dividend Yield)",
    metaNL: true,
  },
  {
    title: "Price-to-Book Ratio",
    key: "priceToBookRatio",
  },
];

const { Text } = Typography;
const KeyStatistics: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let keyStatData: Array<IKeyStats> = [];
  const { account } = useUserContextValue();
  const KEY_STAT_QUERY = queryCompBuilder(COMP_KEYSTAT_FIELDS, history);
  const userLevel = account?.level || 0;
  let planLevel = 0;

  const { data, loading } = useQuery(KEY_STAT_QUERY);

  if (data) {
    for (let items in data) {
      const { stockCode, exchange } = data[items].profile;
      keyStatData = [
        ...keyStatData,
        {
          ...data[items].keyStatistics,
          stockCode: stockCode,
          exchange: exchange,
        },
      ];
    }
  }

  return (
    <>
      <Text className="frame-subheader p-2 pl-3" strong>
        Key statistics
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : (
          <>
            {data ? (
              <>
                {labels.map((label, idx) => {
                  return (
                    <Row key={idx}>
                      {/* for now check only on volatility */}
                      <Col>
                        {label.key === "volatility" ? (
                          <>
                            <Text
                              className="fs-14"
                              disabled={userLevel < REGISTERED_USER_INDEX}
                            >
                              {label.title}
                              {label.metaNL ? <br /> : " "}
                              {label.meta ? label.meta : ""}
                            </Text>
                          </>
                        ) : (
                          <Text className="fs-14">
                            {label.title}
                            {label.metaNL ? <br /> : " "}
                            {label.meta ? label.meta : ""}
                          </Text>
                        )}
                      </Col>
                      {keyStatData.map((reit, idx) => {
                        //format different fields
                        switch (label.key) {
                          case "marketCap":
                            return (
                              <Col key={idx} className="text-right fs-14">
                                {formatCurrency(Number(reit.marketCap))}
                              </Col>
                            );
                          case "sharePriceRange":
                            const {
                              value: sprVal,
                              min: sprMin,
                              max: sprMax,
                            } = reit.sharePriceRange;
                            return (
                              <Col key={reit.stockCode} className="text-right">
                                <>
                                  <div className="d-flex flex-column pt-4 overflow-hidden">
                                    <DataSlider
                                      value={sprVal}
                                      maxValue={sprMax}
                                      exchange={reit.exchange}
                                    />
                                    <Text className="fs-14 mt-2">
                                      {truncateDecimals(sprMin, 2)} -{" "}
                                      {truncateDecimals(sprMax, 2)}
                                    </Text>
                                  </div>
                                </>
                              </Col>
                            );
                          case "volatility":
                            planLevel =
                              KEY_STATISTICS_FRAME.find(
                                (prod) => prod.name === "VOLATILITY"
                              )?.minimumPlan || 0;
                            return (
                              <Col key={idx} className="text-right fs-14">
                                <Text className="fs-14">
                                  {userLevel < planLevel ? (
                                    <LockReitmodal minimumPlan={userLevel} />
                                  ) : (
                                    reit.volatility
                                  )}
                                </Text>
                              </Col>
                            );
                          case "dividendAmount":
                          case "NAV":
                            const bSource =
                              label.key !== "NAV"
                                ? reit.dividendAmount
                                : reit.NAV;
                            const bLabel =
                              label.key !== "NAV"
                                ? "Distribution Per Unit"
                                : "Net Asset Value Per Unit";
                            const { value: divVal, chart } = bSource;
                            return (
                              <Col key={idx} className="text-right fs-14">
                                <div className="d-flex justify-content-end h-100 align-items-center">
                                  <span className="std-trigger">
                                    <HistoricalChart
                                      dataSource={chart}
                                      hasSmallChart={false}
                                      type="bar"
                                      isNavDPU={true}
                                      isDataBoostFeature={false}
                                      chartLabel={`${bLabel} (${getCurrency(
                                        reit.exchange
                                      )})`}
                                    >
                                      <BarChartIcon />
                                    </HistoricalChart>
                                  </span>
                                  <Text className="ml-2 fs-14">
                                    {divVal
                                      ? `${truncateDecimals(divVal, 2)}`
                                      : "-"}
                                  </Text>
                                </div>
                              </Col>
                            );
                          case "priceToBookRatio":
                          case "dividends":
                            const isDividend =
                              label.key === "dividends" ? true : false;
                            const stdSource =
                              label.key === "dividends"
                                ? reit.dividends
                                : reit.priceToBookRatio;

                            const {
                              range: {
                                min: diviMin,
                                max: diviMax,
                                value: diviValue,
                              },
                            } = stdSource;

                            return (
                              <Col key={idx} className="text-right fs-14">
                                <div className="d-flex flex-column justify-content-end pt-4">
                                  <DataSlider
                                    value={
                                      isDividend
                                        ? parseFloat(
                                            (diviValue * 100).toFixed(2)
                                          )
                                        : diviValue
                                    }
                                    maxValue={
                                      isDividend
                                        ? parseFloat((diviMax * 100).toFixed(2))
                                        : diviMax
                                    }
                                    isDividend={isDividend}
                                  />
                                  <div className="mt-2 d-flex justify-content-end">
                                    <span className="std-trigger">
                                      <HistoricalChart
                                        chartStd={true}
                                        hasSmallChart={false}
                                        chartStdType={
                                          isDividend
                                            ? "dividends"
                                            : "priceToBookRatio"
                                        }
                                        stockCode={reit.stockCode}
                                      >
                                        <ChartIcon />
                                      </HistoricalChart>
                                    </span>
                                    {isDividend ? (
                                      <Text className="ml-2 fs-14">
                                        {diviValue
                                          ? `${truncateDecimals(
                                              diviMin * 100,
                                              2
                                            )}% - ${truncateDecimals(
                                              diviMax * 100,
                                              2
                                            )}%`
                                          : "-"}
                                      </Text>
                                    ) : (
                                      <Text className="ml-2 fs-14">
                                        {diviValue
                                          ? `${truncateDecimals(
                                              diviMin,
                                              2
                                            )} - ${truncateDecimals(
                                              diviMax,
                                              2
                                            )}`
                                          : "-"}
                                      </Text>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            );

                          default:
                            return (
                              <Col key={idx} className="text-right fs-14">
                                test
                              </Col>
                            );
                        }
                      })}
                    </Row>
                  );
                })}
              </>
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(React.memo(KeyStatistics));
