import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, List, Card } from "antd";
import styled from "styled-components";

import { TVideoList } from "pages/Videos/components/Main/Main";

const StyleWrapper = styled.div`
  .prod-img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .ant-list-item {
    border-color: rgba(89, 89, 89, 0.15) !important;
  }
  a.underline {
    border-bottom: 1px solid #675bf5;
  }
`;
const { Text } = Typography;

const FeaturedSection: React.FC<{ videoPosts: TVideoList }> = ({
  videoPosts,
}) => {
  //temporarily set 1 video from latest

  const latestVideos = videoPosts
    .slice(0)
    .sort((a, b) => {
      return new Date(a.date) < new Date(b.date) ? 1 : -1;
    })
    .slice(0);

  const featuredVideo = latestVideos.splice(0, 1)[0];

  const RelatedVideos: React.FC<{ videos: TVideoList }> = ({ videos }) => {
    const vidRelated = videoPosts
      .slice(0)
      .filter((video) => {
        return (
          video.tag === featuredVideo.tag && video.slug !== featuredVideo.slug
        );
      })
      .slice(0, 3);

    return (
      <List
        dataSource={vidRelated}
        bordered={false}
        renderItem={(video) => (
          <List.Item className="pl-0 py-3 justify-content-start align-items-start border-0">
            <Link to={`/reitscreener-tv/${video.slug}`} className="fs-20">
              <div className="video-container overflow-hidden" style={{ borderRadius: "4px" }}>
                <img
                  src={
                    video.media
                      ? video.media
                      : "https://via.placeholder.com/639x399"
                  }
                  style={{ width: "179px", height: "104px" }}
                  alt="market brief"
                />
              </div>
            </Link>
            <Text className="d-block text-wrap ml-4" strong>
              <Link
                to={`/reitscreener-tv/${video.slug}`}
                className="fs-20"
                style={{ textDecoration: "underline" }}
              >
                {video.title}
              </Link>
            </Text>
          </List.Item>
        )}
      />
    );
  };

  return (
    <StyleWrapper className="mt-4 pb-4 px-4">
      <Row className="pt-5 max-width-1200 mx-auto">
        <Col xs={24} md={14}>
          <Text type="secondary" className="d-block w-100 fs-18 mb-3" strong>
            Featured Video
          </Text>
          <Card>
            <Link to={`/reitscreener-tv/${featuredVideo.slug}`}>
              <div className="video-container">
                <img
                  src={
                    featuredVideo.media
                      ? featuredVideo.media
                      : "https://via.placeholder.com/690x400"
                  }
                  className="prod-img"
                  alt="reit videos"
                />
              </div>
            </Link>
            <div className="category-caption py-3 px-3">
              <Col xs={24} md={10} className="d-flex mb-1">
                <Text type="secondary" className="fs-16" strong>
                  {featuredVideo.videoDate}
                </Text>
              </Col>
              <h3 className="d-block w-100 m-0">{featuredVideo.title}</h3>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={10} className="px-md-4 mt-5 mt-md-0">
          <Text type="secondary" className="d-block mb-2 fs-18" strong>
            Related Videos
          </Text>
          <RelatedVideos videos={videoPosts} />
        </Col>
      </Row>
    </StyleWrapper>
  );
};

export default FeaturedSection;
