import React from "react";
import { useQuery } from "react-apollo";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { REIT_GROWTH_INFO } from "apollo/queries/reit";
import { useUserContextValue } from "contexts/UserContext";
import { KEY_GROWTH_FRAME } from "utils/reitUtils";
import { truncateDecimals } from "utils/numberUtils";
// import { PREMIUM_USER_INDEX, REGISTERED_USER_INDEX } from "utils/appUtils";
import LockReitModal from "components/LockReitmodal";
import { Spinner } from "components/PageSpinner";

interface IYearData {
  threeYr: number;
  fiveYr: number;
  sevenYr: number;
}

interface IGrowthData {
  dividends: IYearData;
  NAV: IYearData;
  revenue: IYearData;
  NPI: IYearData;
  operatingCashFlow: IYearData;
  freeCashFlow: IYearData;
  FFO: IYearData;
  AFFO: IYearData;
  distributionsPaid: IYearData;
}

interface IGrowthDetails {
  name: string;
  meta?: string;
  years: IYearData;
  yearPlans: IYearData;
  borderBottom?: boolean;
}

const StyledRow = styled(Row)`
  .ant-col {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const { Text } = Typography;

const Growth: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
}) => {
  let growthData: IGrowthData = {
    dividends: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    NAV: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    revenue: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    NPI: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    operatingCashFlow: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    freeCashFlow: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    FFO: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    AFFO: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
    distributionsPaid: {
      threeYr: 0,
      fiveYr: 0,
      sevenYr: 0,
    },
  };

  const { account } = useUserContextValue();
  // const isAccountPremium = (account?.level || 0) < PREMIUM_USER_INDEX;
  // const isAccountRegistered = (account?.level || 0) < REGISTERED_USER_INDEX;

  const { loading, data } = useQuery(REIT_GROWTH_INFO, {
    variables: {
      stockCode: match.params.stockCode,
    },
  });

  if (data) {
    growthData = data.screenerReit.growth;
  }

  const ExtractAllowedFields: React.FC<{ userLevel: number }> = ({
    userLevel,
  }) => {
    const GrowthDataDetails: React.FC<IGrowthDetails> = ({
      meta,
      years,
      name,
      yearPlans,
      borderBottom,
    }) => (
      <Col
        xs={24}
        className="d-flex justify-content-between align-items-center p-3"
        style={{ borderBottom: borderBottom === false ? "none" : "" }}
      >
        <Col xs={12} className="border-0">
          <Text className="d-block fs-16 col-12">
            {name}
            {meta && (
              <Text type="secondary" className="d-block fs-16">
                {meta}
              </Text>
            )}
          </Text>
        </Col>
        <Col xs={12} className="d-flex justify-content-between border-0">
          <Text type="secondary" className="">
            {userLevel < yearPlans.threeYr ? (
              <span>
                <LockReitModal minimumPlan={account?.level || 0} />
              </span>
            ) : (
              <>
                {years.threeYr
                  ? `${truncateDecimals(years.threeYr * 100)}%`
                  : "-"}
              </>
            )}
          </Text>
          <Text type="secondary" className="">
            {userLevel < yearPlans.fiveYr ? (
              <span>
                <LockReitModal minimumPlan={account?.level || 0} />
              </span>
            ) : (
              <>
                {years.fiveYr
                  ? `${truncateDecimals(years.fiveYr * 100)}%`
                  : "-"}
              </>
            )}
          </Text>
          <Text type="secondary" className="">
            {userLevel < yearPlans.sevenYr ? (
              <span>
                <LockReitModal minimumPlan={account?.level || 0} />
              </span>
            ) : (
              <>
                {years.sevenYr
                  ? `${truncateDecimals(years.sevenYr * 100)}%`
                  : "-"}
              </>
            )}
          </Text>
        </Col>
      </Col>
    );

    const allowedFields = KEY_GROWTH_FRAME.map((field) => {
      switch (field.name) {
        case "DIVIDEND":
          return (
            <GrowthDataDetails
              name="Dividend"
              meta="per Share/Unit"
              years={growthData.dividends}
              yearPlans={field.yearPlans}
            />
          );
        case "NET_ASSET_VALUE":
          return (
            <GrowthDataDetails
              name="Net Asset Value"
              meta="per Share/Unit"
              years={growthData.NAV}
              yearPlans={field.yearPlans}
            />
          );
        case "REVENUE":
          return (
            <GrowthDataDetails
              name="Revenue"
              years={growthData.revenue}
              yearPlans={field.yearPlans}
            />
          );
        case "NET_PROPERTY_INCOME":
          return (
            <GrowthDataDetails
              name="Net Property Income"
              meta="(NPI)"
              years={growthData.NPI}
              yearPlans={field.yearPlans}
            />
          );
        case "OPERATING_CASH_FLOW":
          return (
            <GrowthDataDetails
              name="Operating Cash Flow"
              years={growthData.operatingCashFlow}
              yearPlans={field.yearPlans}
            />
          );
        case "FREE_CASH_FLOW":
          return (
            <GrowthDataDetails
              name="Free Cash Flow"
              years={growthData.freeCashFlow}
              yearPlans={field.yearPlans}
            />
          );
        case "FFO":
          return (
            <GrowthDataDetails
              name="FFO"
              years={growthData.FFO}
              yearPlans={field.yearPlans}
            />
          );
        case "AFFO":
          return (
            <GrowthDataDetails
              name="AFFO"
              years={growthData.AFFO}
              yearPlans={field.yearPlans}
            />
          );
        case "DISTRIBUTIONS_PAID":
          return (
            <GrowthDataDetails
              name="Distributions Paid"
              years={growthData.distributionsPaid}
              yearPlans={field.yearPlans}
              borderBottom={false}
            />
          );
        default:
          return <p>no data available..</p>;
      }
    });

    return (
      <>
        {allowedFields.map((elem, idx) => {
          return (
            <div key={idx} className="w-100">
              {elem}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <StyledRow>
      <Col xs={24} className="border-0">
        <Text className="card-header d-block w-100 px-3 py-2">GROWTH</Text>
      </Col>
      <Col
        xs={24}
        className="d-flex  align-items-center p-3 justify-content-end"
      >
        <div className="d-block w-50 period-caption d-flex justify-content-between">
          <Text type="secondary" className="d-flex">
            3-Year
          </Text>
          <Text type="secondary" className="d-flex">
            5-Year
          </Text>
          <Text type="secondary" className="d-flex">
            7-Year
          </Text>
        </div>
      </Col>
      {loading ? (
        <div className="w-100 justify-self-center">
          <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
        </div>
      ) : (
        <>
          {data ? (
            <ExtractAllowedFields userLevel={account?.level || 0} />
          ) : (
            <p className="p-4">no data available</p>
          )}
        </>
      )}
    </StyledRow>
  );
};
export default withRouter(Growth);
