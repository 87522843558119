import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { Row, Col, Typography } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { HistoricalChart } from "components/Charts";
import { Select } from "antd";

import { AreaChart } from "components/Charts";
import { REIT_52_WEEK_INFO } from "apollo/queries/reit";
import { Spinner } from "components/PageSpinner";
import { truncateDecimals } from "utils/numberUtils";

const { Text } = Typography;
const { Option } = Select;
const StyleOverride = styled.div`
  .anticon svg {
    cursor: pointer;
  }
`;

const ChartFiftyTwo: React.FC<RouteComponentProps<{
  stockCode: string;
}>> = ({ match }) => {
  let chartData = [];
  const [range, setRange] = useState("5y");  
  const { data, loading } = useQuery(REIT_52_WEEK_INFO, {
    variables: {
      range,
      stockCode: match.params.stockCode,
    },
  });

  if (data) {
    chartData = data.screenerReit.chart.sharePrice;
  }

  return (
    <StyleOverride>
      <Text className="card-header d-block w-100 px-3 py-2">SHARE PRICE CHART</Text>
      <Row className="mt-3">
        <Col
          xs={24}
          className="d-flex justify-content-between px-3 py-2"
        >
          <div className="d-flex"> 
            <Select
              className="ml-3"
              defaultValue={range}
              style={{ width: 180 }}
              onChange={(range: string) => setRange(range)}
            >
              <Option value="1y">1 Year</Option>
              <Option value="3y">3 Years</Option>
              <Option value="5y">5 Years</Option>
            </Select>
          </div>

          <HistoricalChart
            isTradingView={true}
            dataSource={chartData}
            hasSmallChart={false}
            isDataBoostFeature={false}
            chartLabel="52-week Price"
            xTickLabelFormatter={(value: string) => {
              return moment(value).format("MMM YYYY");
            }}
          >
            <FullscreenOutlined className="fs-20" />
          </HistoricalChart>
        </Col>
      </Row>

      {loading ? (
        <div className="w-100 justify-self-center">
          <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
        </div>
      ) : (
        <>
          {data ? (
            <>
              <Row className="px-3 mb-3">
                <Col xs={24}>
                  <AreaChart
                    dataSource={chartData}
                    height={293}
                    showLegend={false}
                    yTickLabelFormatter={(value: number) => {
                      return truncateDecimals(value, 2);
                    }}
                    xTickLabelFormatter={(value: string) => {
                      return moment(value).format("MMM YYYY");
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <p className="p-4">no data available</p>
          )}
        </>
      )}
    </StyleOverride>
  );
};
export default withRouter(ChartFiftyTwo);
