import React, { useState } from 'react';
import { Button, Typography, Checkbox, Row, Col } from 'antd';

import LockIconModal from 'components/LockIconModal';
import { COUNTRIES, TCountry, PREMIUM_USER_INDEX } from 'utils/appUtils';

const { Text } = Typography;

interface ICountryModal {
  selectedCountries: TCountry[],
  setSelectedCountries: (countries: TCountry[]) => void,
  userLevel: number,
  userQuizCountry?: TCountry, 
  setModalState: (modalState: boolean) => void,
  defaultCountry: TCountry,
}

const CountryModalContent: React.FC<ICountryModal> = ({ selectedCountries, setSelectedCountries, userLevel, userQuizCountry, setModalState, defaultCountry }) => {
  const [countries, setCountries] = useState(selectedCountries);
  const enabledCountries = COUNTRIES.filter(({ minimumPlan, exchange }) => {
    // if user is a premium subscriber or higher, just take all countries that user has access
    if (userLevel >= PREMIUM_USER_INDEX) {
      return (userLevel >= minimumPlan);
    } else {
      // otherwise if user has answered the quiz already, just take the country in the answer
      if (userQuizCountry) {
        return userQuizCountry.exchange === exchange;
      } else {
        // else just take default country
        return defaultCountry.exchange === exchange;
      }
    }
  });

  const checkboxAction = (country: TCountry, nextCheckboxState: boolean) => {
    if (nextCheckboxState) {
      setCountries([...countries, country])
    } else {
      const newSetOfCountries = [...countries].filter(({ exchange }) => exchange !== country.exchange);
      setCountries(newSetOfCountries);
    }
  }

  const selectAllCheckboxAction = (nextCheckboxState: boolean) => {
    nextCheckboxState ? setCountries(enabledCountries) : setCountries([]);
  }

  return (
    <>
      <div className="mb-3 pb-3 check-all">
        <Checkbox
          checked={countries.length === enabledCountries.length}
          onChange={() => selectAllCheckboxAction(!(countries.length === enabledCountries.length))}
        >
          Select all
        </Checkbox>
      </div>
      <Row className="mb-4" gutter={[24, 8]}>
        {COUNTRIES.map((country) => {
          const { name, exchange, minimumPlan } = country;
          const isSelected = countries.find((c) => c.exchange === exchange);
          const lockFilter = !Boolean(enabledCountries.find((ec) => ec.exchange === exchange));
          return (
            <Col key={exchange} span={12} className="d-flex justify-content-between">
              <Checkbox
                disabled={lockFilter}
                checked={Boolean(isSelected)}
                {...(!lockFilter && {
                  onChange: () => checkboxAction(country, !Boolean(isSelected))
                })}
              >
                {name}
              </Checkbox>
              {lockFilter && (
                <LockIconModal minimumPlan={minimumPlan} />
              )}
            </Col>
          );
        })}
      </Row>
      {!Boolean(countries.length) && (
        <Text className="text-danger mb-4 d-block">You must select at least 1 country</Text>
      )}
      <div className="d-flex justify-content-end">
        <Button size="small" className="mr-3" onClick={() => setModalState(false)}>Cancel</Button>
        <Button
          size="small"
          type="primary"
          disabled={!Boolean(countries.length)}
          onClick={() => {
            setSelectedCountries(countries);
            setModalState(false);
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default CountryModalContent;
