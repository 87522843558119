import React from "react";
import styled from "styled-components";
import { ColumnProps } from "antd/lib/table";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Row, Col, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const StyledButton = styled(Button)`
  min-height: auto;
  padding: 8px 16px;
  font-size: 14px;
  height: auto;
  span {
    margin: 0 !important;
  }
  svg {
    margin: 0 3px 0 -4px;
  }
`;

export const reitsNameColumnFilter = (): Partial<ColumnProps<any>> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div className="p-2">
      <Row className="mb-2">
        <Col span={24}>
          <Input
            placeholder="Search reit"
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) => {
              return (
                setSelectedKeys &&
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              );
            }}
            onPressEnter={() => confirm && confirm()}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <StyledButton
            type="primary"
            onClick={() => confirm && confirm()}
            icon={<SearchOutlined />}
            size="small"
            block
          >
            Search
          </StyledButton>
        </Col>
        <Col span={12}>
          <StyledButton
            onClick={() => clearFilters && clearFilters()}
            size="small"
            block
          >
            Reset
          </StyledButton>
        </Col>
      </Row>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value: any, record: any) => {
    return record.reitName
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase());
  },
});
