import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Typography } from 'antd';

import AppLayout from 'components/AppLayout';
import SignUpForm from 'components/SignUpForm';
import { IRegisterFormValues } from 'components/SignUpForm/SignUpForm';
import { SucessText } from 'components/LoginRegisterCommon';
import { useMessageContextValue } from 'contexts/MessageContext';
import { getAuthToken } from 'utils/userUtils';
import { QUIZ_QUESTIONS } from 'utils/quizUtils';
import { SEND_MAGIC_LINK } from 'apollo/mutations/user';

const { Title, Text } = Typography;

interface IRegisterStatus {
  success: boolean,
  email: string
}

const StyledAnonymousQuizWrapper = styled.div`
  height: calc(100vh - 72px);
  ${(props: { withMaxWidth: number }) => {
    if (props.withMaxWidth) {
      return `max-width: 400px;`;
    } else {
      return `max-width: 500px;`;
    }
  }}
`;

const QuizAnonymous: React.FC<RouteComponentProps> = ({ history }) => {
  const { alertError } = useMessageContextValue();
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const [registerStatus, setRegisterStatus] = useState<IRegisterStatus>({ success: false, email: '' });

  useEffect(() => {
    const quizAnswers = JSON.parse(localStorage.getItem('quizAnswer') || '[]');
    if (getAuthToken() || quizAnswers.length !== QUIZ_QUESTIONS.length) {
      return history.push('/quiz/1');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: IRegisterFormValues, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      await sendMagicLink({
        variables: {
          input: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            redirectUrl: `/quiz/${QUIZ_QUESTIONS.length}?anonymous=true`,
          }
        },
      });
      setSubmitting(false);
      setRegisterStatus({ success: true, email: values.email });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
      setSubmitting(false);
      setRegisterStatus({ success: false, email: '' });
    }
  }

  return (
    <AppLayout>
      <StyledAnonymousQuizWrapper withMaxWidth={registerStatus.success ? 0 : 1} className="mx-auto d-flex align-items-center justify-content-center text-center">
        {registerStatus.success ? (
          <SucessText email={registerStatus.email} />
        ) : (
          <div>
            <Title className="m-0 text-center" level={2}>Great job!</Title>
            <Title className="mb-3 text-center" level={3}>You‘ve completed the quiz</Title>
            <Text type="secondary" className="fs-20 text-center d-block mb-5">Please register for <Text type="secondary" strong>FREE</Text>, and get your personalized investment strategy!</Text>
            <SignUpForm onSubmit={handleSubmit} />
          </div>
        )}
      </StyledAnonymousQuizWrapper>
    </AppLayout>
  );
}

export default withRouter(QuizAnonymous);
