import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Modal, Typography, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import PopoverHelper from 'components/PopoverHelper';
import CollapsibleFilterComponents from './components/CollapsibleFilterComponents';
import { TScreenerFilterContent, ALL_SCREENER_FILTERS } from 'utils/screenerUtils';
import { ModalCloseIcon } from 'utils/iconUtils';
import { FILTER_CATEGORIES, PREMIUM_USER_INDEX } from 'utils/appUtils';
import useDebounce from 'hooks/useDebounce';
import { DEFAULT_FILTER_STATES } from 'utils/screenerUtils';
import { setFilterState } from 'redux/slices/filterStateSlice';
import { IReduxState } from 'redux/reducers';

const { Text } = Typography;
const { Search } = Input;

interface IAdditionalFilters {
  minMax: {
    [key: string]: {
      min: number,
      max: number,
    },
  },
  filters: TScreenerFilterContent[],
  setFilters: (filters: TScreenerFilterContent[]) => void,
  removeFilter: (idx: number) => void,
  userLevel: number,
}

const StyledModal = styled(Modal)`
  &.ant-modal {
    width: 100% !important;
    max-width: 730px;
    .ant-modal-content {
      overflow: hidden;
      .ant-modal-header {
        padding: 16px;
      }
      .ant-modal-body {
        max-height: calc((100vh * 0.75) - 61px);
        overflow: auto;
        padding: 16px;
      }
    }
  }
`;

const StyledUnorderedList = styled.ul`
  list-style-type: none;
`;

const StyledSearch = styled(Search)`
  max-width: 35%;
  .ant-input {
    box-shadow: none;
  }
  .ant-input-suffix {
    .anticon::before {
      border: 0;
    }
  }
`;

const AdditionalFilters: React.FC<IAdditionalFilters> = ({ filters, setFilters, removeFilter, userLevel, minMax }) => {
  const dispatch = useDispatch();
  const strategy = useSelector((state: IReduxState) => state.filterConfig.strategy);
  const filterState = useSelector((state: IReduxState) => state.filterState.filterState);

  const [modalState, setModalState] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredScreenerFilters, setFilteredScreenerFilters] = useState<TScreenerFilterContent[]>([]);

  const debouncedNameValue = useDebounce(searchString, 500);
  const filterSource = Boolean(filteredScreenerFilters.length) ? filteredScreenerFilters : ALL_SCREENER_FILTERS;

  useEffect(() => {
    if (debouncedNameValue) {
      const filteredResult = ALL_SCREENER_FILTERS
        .filter(({ title, subTitle }) => {
          return title.toUpperCase().includes(debouncedNameValue.toUpperCase())
            || (subTitle || '').toUpperCase().includes(debouncedNameValue.toUpperCase())
        });
      setFilteredScreenerFilters(filteredResult);
    } else {
      setFilteredScreenerFilters([]);
    }
  }, [debouncedNameValue]);

  const handleSwitchChange = (value: boolean, filter: TScreenerFilterContent) => {
    if (value) {
      const newSetOfFilterState = {
        ...filterState,
        [filter.key]: {
          // check first if strategy is pre-defined, otherwise empty
          option: DEFAULT_FILTER_STATES[strategy!] ?
            (DEFAULT_FILTER_STATES[strategy!][filter.key] || []) : [],
          range: { // to be replaced
            from: 0,
            to: 0,
          },
        },
      };
      dispatch(setFilterState(newSetOfFilterState));
      setFilters([...filters, filter]);
    } else {
      const filterIndex = filters.findIndex(({ key }) => key === filter.key);
      removeFilter(filterIndex);
    }
  }

  return (
    <div>
      <Button onClick={() => setModalState(true)} className="p-0" size="small" type="link" icon={<PlusOutlined />}>
        Additional filters
      </Button>
      <StyledModal
        destroyOnClose
        visible={modalState}
        onCancel={() => setModalState(false)}
        footer={null}
        closeIcon={<ModalCloseIcon />}
        title={(
          <div className="fs-18">
            <div className="mb-3 d-flex align-items-center">
              <Text className="mr-2">Choose filters to screen REITs</Text>
              <PopoverHelper
                title="Filters"
                content={(
                  <div className="fs-14">
                    <Text className="d-block" type="secondary">
                      Get the best results by tuning your strategies with additional toggles.  
                    </Text>
                  </div>
                )}
              />
            </div>
            <StyledSearch
              allowClear
              onChange={e => setSearchString(e.target.value)}
              placeholder="Find filters"
            />
          </div>
        )}
      >
        <StyledUnorderedList className="p-0 m-0">
          {FILTER_CATEGORIES.map(({ label, value, subCategories }) => {
            const filtersByCategory = filterSource.filter((f) => {
              // if anonymous user, remove premium filters
              return (userLevel === 0) ?
                (f.category === value && f.minimumPlan !== PREMIUM_USER_INDEX) :
                f.category === value;
            });
            // check subcategory filters first
            // to properly check whether main category will be displayed or not
            const subCategoryFiltersCount = filterSource.reduce((accumulator, currentValue) => {
              const subCategoryValues = subCategories?.map((subCat) => subCat.value) || [];
              return subCategoryValues.includes(currentValue.category) ? accumulator += 1 : accumulator;
            }, 0);
            if (!Boolean(filtersByCategory.length) && !Boolean(subCategoryFiltersCount)) return null;
            return (
              <React.Fragment key={value}>
                <li className="filter-category">
                  <Text className="text-capitalize" strong>{label}</Text>
                </li>
                {subCategories ? (
                  <>
                    {subCategories.map((subCat) => {
                      const filtersBySubCategory = filterSource.filter((f) => {
                        // if anonymous user, remove premium filters
                        return (userLevel === 0) ?
                          (f.category === subCat.value && f.minimumPlan !== PREMIUM_USER_INDEX) :
                          f.category === subCat.value;
                      });
                      if (!Boolean(filtersBySubCategory.length)) return null;
                      return (
                        <React.Fragment key={subCat.value}>
                          <li className="filter-sub-category fs-16 mt-2">
                            <Text className="text-capitalize" strong>{subCat.label}</Text>
                          </li>
                          <li>
                            <CollapsibleFilterComponents
                              filters={filtersBySubCategory}
                              currentFilters={filters}
                              userLevel={userLevel}
                              handleSwitchChange={handleSwitchChange}
                            />
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <li>
                    <CollapsibleFilterComponents
                      filters={filtersByCategory}
                      currentFilters={filters}
                      userLevel={userLevel}
                      handleSwitchChange={handleSwitchChange}
                    />
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </StyledUnorderedList>
      </StyledModal>
    </div>
  )
};

export default AdditionalFilters;
