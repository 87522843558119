import React, { useState, memo } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Drawer, Button, Typography } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';

import { useUserContextValue } from 'contexts/UserContext';
import { ModalCloseIcon } from 'utils/iconUtils';
import { GET_PANEL_STEPS } from 'utils/onboardingUtils';
import { truncateDecimals } from 'utils/numberUtils';

const { Text } = Typography;

interface IReducedPanelSteps {
  label: string
  link: string,
  completed: boolean,
}

const StyledDrawerWrapper = styled.div`
  margin-right: 40px;
`;

const StyledRootPanelWrapper = styled.div`
  padding: 32px;
  .header-wrapper {
    border-bottom: 1px solid #E8E8E8;
  }
  .panel-step {
    a {
      text-decoration: underline;
    }
    &.completed {
      .fs-20 {
        text-decoration: line-through;
      }
    }
  }
`;

const UserPanel = memo(() => {
  const { account } = useUserContextValue();
  const [visibility, setVisibility] = useState(false);
  const panelSteps = GET_PANEL_STEPS({
    quiz: Boolean(account?.quiz),
    articles: account?.checklist.articles || [],
  });

  const stepSource = panelSteps.reduce<IReducedPanelSteps[]>((accumulator, currentValue) => {
    return [
      ...accumulator,
      ...currentValue.list,
    ];
  }, []);

  const completedCount = stepSource.reduce<number>((accumulator, currentValue) => {
    return (currentValue.completed) ? (accumulator + 1) : accumulator;
  }, 0);

  const progress = (completedCount / stepSource.length) * 100;

  return (
    <StyledDrawerWrapper>
      <Button className="mr-1 p-0" shape="circle" icon={<PieChartOutlined />} onClick={() => setVisibility(!visibility)} />
        <Text>{truncateDecimals(progress, 1)}%</Text>
      <Drawer
        bodyStyle={{
          padding: 0,
        }}
        className="user-panel"
        placement="right"
        closable={false}
        onClose={() => setVisibility(false)}
        visible={visibility}
      >
        <StyledRootPanelWrapper>
          <div className="header-wrapper pb-3 mb-3 d-flex justify-content-between align-items-center">
            <Text className="subheading" strong>Get started with REITs!</Text>
            <Button
              style={{ height: 16, minHeight: 'auto', width: 16 }}
              className="p-0"
              type="text"
              icon={<ModalCloseIcon />} onClick={() => setVisibility(false)}
            />
          </div>
          {panelSteps.map(({ title, description, list, completed }, idx) => {
            return (
              <div
                key={idx}
                className={cx({
                  "panel-step": true,
                  "mb-4": panelSteps.length !== (idx + 1),
                  "completed": completed,
                })}
              >
                <Text strong className="fs-20 d-block mb-2">{idx + 1}. {title}</Text>
                <div className="pl-4">
                  <Text className="d-block mb-2 fs-14">{description}</Text>
                  {list.map(({ label, link }, iidx) => (
                    <Link className="fs-14 d-block mb-2" key={iidx} to={link}>{label}</Link>
                  ))}
                </div>
              </div>
            );
          })}
        </StyledRootPanelWrapper>
      </Drawer>
    </StyledDrawerWrapper>
  );
});

export default UserPanel;
