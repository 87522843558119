import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Layout } from "antd";

import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import FirstTimeLoginModal from "../FirstTimeLoginModal";
import { isLoggedIn } from "utils/userUtils";
import { useUserContextValue } from "contexts/UserContext";
import MetaTags from "components/MetaTags";

const { Content } = Layout;

interface IAppLayout extends RouteComponentProps {
  brandOnly?: boolean;
  withHeader?: boolean;
  withFooter?: boolean;
}

const AppLayout: React.FC<IAppLayout> = ({
  children,
  withFooter = true,
  withHeader = true,
  brandOnly = false,
  location,
}) => {
  const { account } = useUserContextValue();
  const [scrollState, setScrollState] = useState(false);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (window) {
      const scrollTracker = () => setScrollState(window.scrollY !== 0);
      window.addEventListener("scroll", scrollTracker);
      return () => {
        window.removeEventListener("scroll", scrollTracker);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isQuizRoute = location.pathname.includes("quiz");
    if (isLoggedIn() && account?.newSignUp && !isQuizRoute) {
      setVisibility(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="bg-white">
      <MetaTags />
      {withHeader && (
        <AppHeader scrollState={scrollState} brandOnly={brandOnly} />
      )}
      <Content style={{ marginTop: withHeader ? 72 : 0 }}>{children}</Content>
      {withFooter && <AppFooter />}
      <FirstTimeLoginModal visible={visibility} setVisibility={setVisibility} />
    </Layout>
  );
};

export default withRouter(AppLayout);
