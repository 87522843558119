import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Modal, Typography, Row, Col, List, Avatar, Button } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useMessageContextValue } from "contexts/MessageContext";
import { IRegisterFormValues } from "components/SignUpForm/SignUpForm";
import { useUserContextValue } from "contexts/UserContext";
import { SEND_MAGIC_LINK } from "apollo/mutations/user";
import {
  REGISTERED_USER_INDEX,
  PREMIUM_USER_INDEX,
  ANONYMOUS_USER_INDEX,
} from "utils/appUtils";
import SignUpForm from "components/SignUpForm";
import SuccessText from "components/LoginRegisterCommon/components/SucessText";
import { LockIconCircle } from "utils/iconUtils";

const { Text } = Typography;

interface IModalProps extends RouteComponentProps {
  visible?: boolean;
  closeModal?: () => void;
}

interface ISuccessRegister {
  isDone: boolean;
  email: string;
}

const StyleOverride = styled.div`
  .ant-form {
    width: 100%;
    max-width: 390px;
    .ant-btn {
      min-height: 48px;
    }
  }
  .ant-list-item-meta {
    max-width: 280px;
    img {
      width: 16px;
      height: 12px;
    }
    .ant-list-item-meta-title {
      font-size: 16px !important;
      margin: 0 !important;
    }
    .ant-list-item-meta-description {
      font-size: 14px !important;
    }
    .ant-list-item-meta-avatar {
      margin: 0;
    }
  }
  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gray-panel {
    background-color: #fafafa;
  }
`;
const StyledModal = styled(Modal)`
  width: 100% !important;
  max-width: 996px;
  &.ant-modal {
    .ant-modal-content {
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      .ant-modal-body {
        width: 100%;
        padding: 0;
      }
    }
  }
`;
const StyleSuccessOverride = styled.div`
  div {
    width: 100% !important;
    max-width: 500px !important;
    img {
      margin-bottom: 24px !important;
    }
    h2.ant-typography {
      font-size: 24px !important;
      font-weight: bold !important;
      margin-bottom: 10px !important;
    }
    .ant-btn {
      max-width: 181px;
      margin-top: 10px !important;
    }
  }
`;

const CHECK = "/assets/images/check.svg";
const listData = [
  {
    title: "eBooks",
    description: "8 Myths of REIT Investing People Still Believe",
  },
  {
    title: "Articles",
    description:
      "10 exclusive articles to get your started on your REIT investing journey",
  },
  {
    title: "Webinar",
    description:
      "Complimentary recorded webinar on how to pick REITs that outperform the market",
  },
  {
    title: "Watchlists",
    description:
      "A complimentary assessment to determine your ideal REIT investment strategy along with a watchlist of REITs best suited to your portfolio",
  },
];

const UnregisteredSearch: React.FC<IModalProps> = ({
  location,
  closeModal,
}) => {
  const { account } = useUserContextValue();
  const { alertError } = useMessageContextValue();
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const [registered, setRegistered] = useState<ISuccessRegister>({
    isDone: false,
    email: "",
  });
  const userLevel = account?.level || 0;
  const userAnon = userLevel < REGISTERED_USER_INDEX;
  const userRegisteredOnly =
    userLevel > ANONYMOUS_USER_INDEX && userLevel < PREMIUM_USER_INDEX;

  const handleSubmit = async (
    values: IRegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await sendMagicLink({
        variables: {
          input: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            redirectUrl: location.pathname,
          },
        },
      });
      setSubmitting(false);
      setRegistered({ isDone: true, email: values.email });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
      setSubmitting(false);
    }
  };

  return (
    <>
      {userRegisteredOnly ? (
        <StyledModal
          visible={true}
          cancelText={false}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          footer={false}
          style={{ maxWidth: "500px" }}
          onCancel={closeModal}
          destroyOnClose={true}
        >
          <div className="p-5 text-center">
            <h3 className="text-center">
              You discovered a REIT for Premium Users!
            </h3>
            <div className="my-3 text-center">
              <LockIconCircle />
            </div>
            <Text className="d-block w-100 mb-3 px-3">
              Subscribe to REITScreener Premium to access this reit.
            </Text>
            <Link to="/settings/subscription">
              <Button type="primary">Subscribe to Premium</Button>
            </Link>
          </div>
        </StyledModal>
      ) : (
        <StyledModal
          visible={true}
          onCancel={closeModal}
          footer={false}
          cancelText={false}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          style={{ maxWidth: registered.isDone ? "600px" : "996px" }}
          destroyOnClose={true}
        >
          {registered.isDone ? (
            <StyleSuccessOverride className="d-flex justify-content-center text-center pt-5 pb-3">
              <SuccessText email={registered.email} />
            </StyleSuccessOverride>
          ) : (
            <StyleOverride>
              {userAnon && (
                <Row className="d-flex">
                  <Col
                    xs={{ span: 24, order: 2 }}
                    md={{ span: 10, order: 1 }}
                    className="p-5  gray-panel"
                  >
                    <List
                      size="small"
                      bordered={false}
                      dataSource={listData}
                      renderItem={(each) => (
                        <List.Item className="p-0 mb-3 border-0">
                          <List.Item.Meta
                            description={each.description}
                            title={each.title}
                            avatar={<Avatar src={CHECK} />}
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24, order: 1 }}
                    md={{ span: 14, order: 2 }}
                    className="d-flex flex-column mb-5 mb-md-0 py-5 pl-5 pr-4"
                  >
                    <h4 className="mb-4 font-weight-bold">
                      This REIT is exclusive to registered users!
                    </h4>
                    <Text type="secondary" className="d-block fs-16 mb-4">
                      Register and unlock this content and much much more...
                    </Text>
                    <SignUpForm onSubmit={handleSubmit} />
                  </Col>
                </Row>
              )}
            </StyleOverride>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default withRouter(UnregisteredSearch);
