import React, { useState, memo, useEffect } from "react";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import { Typography } from "antd";
import { useSelector } from "react-redux";

import { useMessageContextValue } from "contexts/MessageContext";
import useDebounce from "hooks/useDebounce";
import { IReduxState } from "redux/reducers";
import { SCREENER } from "apollo/queries/screener";
import { IScreenerData } from "apollo/types/screener";
import { processFilterState } from "utils/screenerUtils";

const { Title, Text } = Typography;

type TScreener = {
  count: number;
  rows: IScreenerData[];
};

interface IScreenerResult {
  screener: TScreener;
}

const StyledDiv = styled.div`
  border-left: 8px solid #e8e8e8;
  margin-left: 48px;
  .fs-16 {
    line-height: 16px;
  }
`;

const StyledDivSticky = styled.div`
  z-index: 2;
  width: 120px;
  transition: transform 0.4s ease;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  user-select: none;
  ${(props: { scrolled: number }) => {
    return props.scrolled > 404
      ? ` 
      top: 0;   
      right: 0;
      transform: translateY(100%);      
         
      border-left: 8px solid #e8e8e8;
      margin-left: 48px;
      .fs-16 {
        line-height: 16px;
      }`
      : `
       transform: translateY(-100%);  
       top: 0;   
       right: 0; 
      `;
  }}
`;

const ExpectedResult = memo(() => {
  const [expectedResult, setExpectedResult] = useState(0);
  const { fixedFilterState, filterState } = useSelector(
    (state: IReduxState) => state.filterState
  );
  const advancedMode = useSelector(
    (state: IReduxState) => state.filterConfig.advancedMode
  );

  const { alertError } = useMessageContextValue();
  const { refetch } = useQuery<undefined | IScreenerResult>(SCREENER, {
    skip: true,
    onError: (error) => alertError(error.message),
  });
  const [scrolled, setScrolled] = useState(0);
  const debouncedFilterState = useDebounce(filterState, 750);
  const debouncedFixedFilterState = useDebounce(fixedFilterState, 750);
  const debouncedAdvancedMode = useDebounce(advancedMode, 750);

  useEffect(() => {
    getExpectedResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterState, debouncedFixedFilterState, debouncedAdvancedMode]);

  //for window scroll listener
  useEffect(() => {
    const scrollListener = () => {
      setScrolled(window.scrollY);
    };
    window.addEventListener("scroll", scrollListener);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  const getExpectedResult = async () => {
    if (!fixedFilterState.countries.length || !fixedFilterState.sectors.length)
      return;
    const processedValues = processFilterState(filterState, advancedMode);
    const inputVariables = {
      ...processedValues,
      exchange: fixedFilterState.countries.map(({ exchange }) => exchange),
      sector: fixedFilterState.sectors.map(({ value }) => value),
    };
    try {
      const { data } = await refetch({
        input: inputVariables,
      });
      if (data) setExpectedResult(data.screener.count);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StyledDiv className="pl-3">
        <Text type="secondary" className="fs-16">
          Result
        </Text>
        <Title className="m-0" level={1}>
          {expectedResult}
        </Title>
      </StyledDiv>
      <StyledDivSticky className="pl-3" scrolled={scrolled || 0}>
        <Text type="secondary" className="fs-16">
          Result
        </Text>
        <Title className="m-0" level={1}>
          {expectedResult}
        </Title>
      </StyledDivSticky>
    </>
  );
});

export default ExpectedResult;
