import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ScreenerFilter from "./components/ScreenerFilter";
import ScreenerTable from "../../components/ScreenerTable";
import { useMessageContextValue } from "contexts/MessageContext";
import { IScreenerData } from "apollo/types/screener";
import { IReduxState } from "redux/reducers";
import { SCREENER } from "apollo/queries/screener";
import { processFilterState } from "utils/screenerUtils";

type TScreener = {
  count: number;
  rows: IScreenerData[];
};

interface IScreenerResult {
  screener: TScreener;
}

const StyledDiv = styled.div`
  ${(props: { maxWidth: number }) => {
    return `max-width: ${props.maxWidth}px;`;
  }}
  &.table-wrapper {
    padding: 0 32px;
  }
`;

const Table = () => {
  const { alertError } = useMessageContextValue();
  const { strategy, sortOrder, advancedMode } = useSelector(
    (state: IReduxState) => state.filterConfig
  );
  const { filterState, fixedFilterState } = useSelector(
    (state: IReduxState) => state.filterState
  );

  const [reitsData, setReitsData] = useState<TScreener>({ rows: [], count: 0 });
  const [paramsChanged, setParamsChanged] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const { refetch } = useQuery<undefined | IScreenerResult>(SCREENER, {
    skip: true,
    onError: (error) => alertError(error.message),
  });

  useEffect(() => {
    setParamsChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, fixedFilterState, advancedMode]);

  const generateReitsData = async () => {
    if (paramsChanged) setParamsChanged(false);
    setLoadingState(true);
    const processedValues = processFilterState(filterState, advancedMode);
    const inputVariables = {
      ...processedValues,
      exchange: fixedFilterState.countries.map(({ exchange }) => exchange),
      sector: fixedFilterState.sectors.map(({ value }) => value),
      order: [sortOrder.sortField, sortOrder.orderBy],
    };
    try {
      const { data } = await refetch({
        input: inputVariables,
      });
      if (data) setReitsData(data.screener);
    } catch (error) {
      console.log(error);
    }
    setLoadingState(false);
  };

  const SeoLinks = () => {
    return (
      <>    
        <Link to="/screener/table/reits/AW9U.SI" />
      </>
    );
  };

  return (
    <div>
      <SeoLinks/>
      <StyledDiv maxWidth={1200} className="mx-auto mb-5">
        <ScreenerFilter
          generateReitsData={generateReitsData}
          resultCount={reitsData.count}
          loadingState={loadingState}
        />
      </StyledDiv>
      {strategy && (
        <StyledDiv maxWidth={1920} className="mx-auto table-wrapper mb-5">
          <ScreenerTable
            reloadTableCallback={generateReitsData}
            paramsChanged={paramsChanged && reitsData.count > 0}
            loading={loadingState}
            data={reitsData}
          />
        </StyledDiv>
      )}
    </div>
  );
};

export default Table;
