import React from 'react';
import { Button } from 'antd';

const FACEBOOK = '/assets/images/social-fb.png';
const TWITTER = '/assets/images/social-twitter.png';
const LINKEDIN = '/assets/images/social-linkedin.png';

const SocialLinks = () => {
  return (
    <>
      <Button
        shape="circle"
        icon={<img src={FACEBOOK} alt="Facebook" />}
        onClick={() => {
          window && window.open('https://www.facebook.com/reitscreener', '_blank');
        }}
      />
      <Button
        className="mx-4"
        shape="circle"
        icon={<img src={TWITTER} alt="Twitter" />}
        onClick={() => {
          window && window.open('https://twitter.com/reitscreener', '_blank');
        }}
      />
      <Button
        shape="circle"
        icon={<img src={LINKEDIN}
        alt="Linkedin" />}
        onClick={() => {
          window && window.open('https://www.linkedin.com/company/reitscreener/', '_blank');
        }}
      />
    </>
  )
}

export default SocialLinks;