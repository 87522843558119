import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography } from "antd";

import Spinner from "components/PageSpinner";
import LockReitmodal from "components/LockReitmodal";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_FINANCIAL_FIELDS } from "apollo/fields/reits";
import { KEY_FINANCIALS_FRAME } from "utils/reitUtils";
import { useUserContextValue } from "contexts/UserContext";
import { HistoricalChart } from "components/Charts";

const labels: Array<{
  title: string;
  key: string;
  meta?: string;
  metaNL?: boolean;
  prodKey: string;
  unit?: "Decimal" | "Percent" | "Currency";
}> = [
  {
    title: "Revenue",
    key: "revenue",
    prodKey: "REVENUE",
    unit: "Currency",
  },
  {
    title: "Net Property Income",
    key: "NPI",
    meta: "(NPI)",
    metaNL: true,
    prodKey: "NPI",
    unit: "Currency",
  },
  {
    title: "Operating Cash Flow",
    key: "operatingCashFlow",
    prodKey: "OPERATING_CASH_FLOW",
    unit: "Currency",
  },
  {
    title: "Free Cash Flow",
    key: "freeCashFlow",
    prodKey: "FREE_CASH_FLOW",
    unit: "Currency",
  },
  {
    title: "FFO",
    key: "FFO",
    prodKey: "FFO",
    unit: "Currency",
  },
  {
    title: "AFFO",
    key: "AFFO",
    prodKey: "AFFO",
    unit: "Currency",
  },
  {
    title: "Distributions Paid",
    key: "distributionsPaid",
    prodKey: "DISTRIBUTIONS_PAID",
    unit: "Currency",
  },
];

interface ICommonFields {
  value: number;
  chart: Array<{
    value: number;
    label: string;
  }>;
}
interface IFinancials {
  revenue: ICommonFields;
  NPI: ICommonFields;
  operatingCashFlow: ICommonFields;
  freeCashFlow: ICommonFields;
  FFO: ICommonFields;
  AFFO: ICommonFields;
  distributionsPaid: ICommonFields;
}
interface IChartDetails {
  planLevel: number;
  value: number;
  chartLabel: string;
  chartData: ICommonFields["chart"];
  unit?: "Decimal" | "Percent" | "Currency";
  meta?: string;
}

const { Text } = Typography;
const Financials: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let financialsData: Array<IFinancials> = [];
  const { account } = useUserContextValue();
  const KEY_FINANCIAL_QUERY = queryCompBuilder(COMP_FINANCIAL_FIELDS, history);
  const userLevel = account?.level || 0;
  let planLevel = 0;

  const getPlanLevel = (
    planFrame: Array<{ name: string; minimumPlan: number }>,
    labelKey: string
  ) => {
    return (
      KEY_FINANCIALS_FRAME.find((prod) => prod.name === labelKey)
        ?.minimumPlan || 0
    );
  };

  const { data, loading } = useQuery(KEY_FINANCIAL_QUERY);

  if (data) {
    for (let items in data) {
      financialsData = [
        ...financialsData,
        {
          ...data[items].financials,
        },
      ];
    }
  }

  const ChartDetails: React.FC<IChartDetails> = ({
    chartData,
    chartLabel,
    meta,
    unit,
    value,
    planLevel,
  }) => {
    return (
      <div className="d-flex align-items-end justify-content-end">
        {userLevel < planLevel ? (
          <LockReitmodal isDataBoostFeature={true} minimumPlan={userLevel} />
        ) : (
          <>
            {chartData.length >= 1 ? (
              <>
                <HistoricalChart
                  dataSource={chartData}
                  unit={unit ? unit : "Decimal"}
                  chartLabel={chartLabel}
                  chartMeta={meta}
                  value={value}
                  valueSmallFont={true}
                />
              </>
            ) : (
              <div className="d-block w-100 h-100 pt-4">
                {value ? `${(value * 100).toFixed(2)}%` : "-"}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Text className="frame-subheader p-2 pl-3" strong>
        Financials
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : (
          <>
            {data ? (
              <>
                {labels.map((label, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        <Text
                          className="fs-14"
                          disabled={
                            userLevel <
                            getPlanLevel(KEY_FINANCIALS_FRAME, label.prodKey)
                          }
                        >
                          {label.title}
                          {label.metaNL ? <br /> : " "}
                          {label.meta ? label.meta : ""}
                        </Text>
                      </Col>
                      {financialsData.map((reit, idx) => {
                        switch (label.key) {
                          case "revenue":
                          case "NPI":
                          case "operatingCashFlow":
                          case "freeCashFlow":
                          case "FFO":
                          case "AFFO":
                          case "distributionsPaid":
                            planLevel =
                              KEY_FINANCIALS_FRAME.find(
                                (prod) => prod.name === label.prodKey
                              )?.minimumPlan || 0;

                            const { value: pVal, chart: pChart } = reit[
                              label.key
                            ];

                            return (
                              <Col key={idx} className="text-right fs-14">
                                <ChartDetails
                                  planLevel={planLevel}
                                  value={pVal}
                                  chartData={pChart}
                                  unit={label.unit}
                                  chartLabel={label.title}
                                  meta={label.meta}
                                />
                              </Col>
                            );
                          default:
                            return (
                              <Col key={idx} className="text-right fs-14">
                                test
                              </Col>
                            );
                        }
                      })}
                    </Row>
                  );
                })}
              </>
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(React.memo(Financials));
