import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import { Tabs, Card, Typography, Button } from 'antd';

import QuizSteps from './components/QuizSteps';
import QuizAnswers from './components/QuizAnswers';
import { IQuiz, TAnswer } from '../../Quiz';
import { CREATE_ACCOUNT_QUIZ } from 'apollo/mutations/quiz';
import { useMessageContextValue } from 'contexts/MessageContext';
import { ACCOUNT } from 'apollo/queries/user';
import { IQuizQuestion, QUIZ_QUESTIONS } from 'utils/quizUtils';
import { getAuthToken } from 'utils/userUtils';

const { TabPane } = Tabs;
const { Text } = Typography;

interface IQuizTabs extends IQuiz {
  answers: TAnswer[],
  setAnswers: (answers: TAnswer[]) => void,
  derivedQuestions: IQuizQuestion[],
  hasQuiz: boolean,
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    display: none !important;
  }
`;

const QuizTabs: React.FC<IQuizTabs> = ({ quizNum, setQuizNum, answers, setAnswers, history, match, derivedQuestions, location, hasQuiz }) => {
  const [createAccountQuizMutation, { loading }] = useMutation(CREATE_ACCOUNT_QUIZ);
  const { alertError } = useMessageContextValue();
  const disableProceedButton = answers[quizNum]?.hasOwnProperty('withSubAnswer') && !Boolean(answers[quizNum].subAnswer);
  const queryParams: { anonymous?: boolean } = queryString.parse(location.search);

  useEffect(() => {
    if (queryParams.anonymous) {
      submitQuizAnswers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectAnswer = (answer: string, multiple?: boolean) => {
    const newAnswers = [...answers];
    newAnswers[quizNum] = {
      order: quizNum,
      answer,
      subAnswer: (answer === newAnswers[quizNum]?.answer) ? newAnswers[quizNum]?.subAnswer : undefined,
      ...(multiple && {
        withSubAnswer: true,
      })
    };
    setAnswers(newAnswers);
  }

  const onSelectSubAnswer = (answer: string) => {
    const newAnswers = [...answers];
    let existingSubAnswer = newAnswers[quizNum].subAnswer?.split(',') || [];
    if (Boolean(existingSubAnswer.length)) {
      if (existingSubAnswer.includes(answer)) {
        existingSubAnswer = existingSubAnswer.filter(v => v !== answer);
      } else {
        existingSubAnswer.push(answer);
      }
    } else {
      existingSubAnswer.push(answer);
    }
    newAnswers[quizNum] = {
      ...newAnswers[quizNum],
      order: quizNum,
      subAnswer: Boolean(existingSubAnswer.length) ? existingSubAnswer.join(',') : undefined,
    };
    setAnswers(newAnswers);
  }

  const onNextQuestion = () => {
    setQuizNum(quizNum + 1);
    history.push(`/quiz/${(+match.params.quiz_num) + 1}`);
  }

  const onPreviousQuestion = () => {
    setQuizNum(quizNum - 1);
    history.push(`/quiz/${(+match.params.quiz_num) - 1}`);
  }

  const submitQuizAnswers = async () => {
    if (!getAuthToken()) return history.push('/quiz/anonymous');
    const derivedAnswers = [...answers].filter((_, idx) => {
      // handle case where user has 9 answers but already took the quiz
      if (hasQuiz) {
        return (idx + 1) < QUIZ_QUESTIONS.length;
      }
      return true;
    });
    try {
      const { data } = await createAccountQuizMutation({
        variables: {
          input: derivedAnswers.map(({ order, withSubAnswer, subAnswer, answer }) => ({
            order,
            answer: withSubAnswer ? subAnswer : answer,
          })),
        },
        refetchQueries: [{
          query: ACCOUNT,
          variables: { token: getAuthToken() }
        }],
        awaitRefetchQueries: true,
      });
      if (data.createAccountQuiz) {
        setAnswers([]);
        history.push('/quiz/complete');
      }
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
    }
  }

  return (
    <Card className="custom-shadow" bodyStyle={{ padding: '32px 40px' }}>
      <QuizSteps
        answerLength={answers.length}
        quizNum={quizNum}
        setQuizNum={setQuizNum}
        derivedQuestions={derivedQuestions}
      />
      <StyledTabs activeKey={quizNum.toString()}>
        {derivedQuestions.map(({ question, answers: answersReference }, idx) => {
          const isLastQuestion = derivedQuestions.length === (idx + 1);
          return (
            <TabPane tab={`Tab ${idx}`} key={idx.toString()}>
              <Text strong className="subheading d-block mb-4">{idx + 1}. {question}</Text>
              <QuizAnswers
                answers={answersReference}
                currentAnswer={answers[quizNum]?.answer}
                subAnswer={answers[quizNum]?.subAnswer}
                onSelectAnswer={onSelectAnswer}
                onSelectSubAnswer={onSelectSubAnswer}
                loadingState={loading}
              />
              <div className="d-flex justify-content-between">
                <div>
                  {+match.params.quiz_num !== 1 && (
                    <Button
                      onClick={onPreviousQuestion}
                      disabled={loading}
                    >
                      Back
                    </Button>
                  )}
                </div>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={!Boolean(answers[quizNum]?.answer) || disableProceedButton}
                  onClick={isLastQuestion ? submitQuizAnswers : onNextQuestion}
                >
                  {isLastQuestion ? 'Show my result' : 'Next'}
                </Button>
              </div>
            </TabPane>
          );
        })}
      </StyledTabs>
    </Card>
  )
}

export default withRouter(QuizTabs);
