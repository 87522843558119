import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Row, Col, Typography } from 'antd';

import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import SocialLinks from 'components/SocialLinks';
const LOGO_LIGHT = '/assets/images/logo-light.png';
const { Footer } = Layout;
const { Text } = Typography;

const StyledFooter = styled(Footer)`
  &.ant-layout-footer {
    background: #FAFAFA;
    padding: 96px 0;

  }
`;

const StyledLeftDiv = styled.div`
  a > .logo {
    height: 36px;
  }
`;

const StyledMiddleDiv = styled.div`
  ul {
    list-style-type: none;
    li {
      line-height: 32px;
      a {
        color: #121324;
        &:hover {
          text-decoration: underline;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
`;

const StyledRightDiv = styled.div`
  .ant-btn {
    border: 0;
    min-height: 40px;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const StyledDiv = styled.div`
  &.legalities-wrapper {
    padding: 9px 16px;
    background: #E6F7FF;
    border-top: 1px solid #91D5FF;
    display: flex;
    justify-content: center;
  }
`;

const AppFooter = () => (
  <>
    <StyledFooter className="footer px-3">
      <div className="mx-auto max-width-1200">
        <Row>
          <Col sm={24} md={8} lg={9} className="d-flex">
            <StyledLeftDiv className="d-flex flex-column">
              <Link to="/">
                <img className="logo d-block mb-4" draggable={false} src={LOGO_LIGHT} alt={process.env.REACT_APP_NAME} />
              </Link>
              <Text type="secondary" className="d-block fs-16">&copy; {(new Date()).getFullYear()} {process.env.REACT_APP_NAME} All Rights Reserved</Text>
            </StyledLeftDiv>        
          </Col>
          <Col sm={24} md={8} lg={9}>
            <StyledMiddleDiv>
              <ul className="m-0">
                <li className="mb-34">
                  <Link to="/screener/table">Screener</Link>
                </li>
                {navigator.userAgent !== 'ReactSnap' && (
                  <li className="mb-3">
                    <Link to="/resources/reits-introduction/wp:the-reitscreener-investment-philosophy">Resources</Link>
                  </li>
                )}
                <li className="mb-3">
                  <Link to="/resources/events">Events</Link>
                </li>
                <li>
                  <a href="https://pro.reitscreener.com" target="_blank" rel="noopener noreferrer">REITScreener PRO</a>
                </li>
              </ul>
            </StyledMiddleDiv>
          </Col>
          <Col sm={24} md={8} lg={6}>
            <Text type="secondary" className="fs-16 d-block mb-4">Follow us on our social media to get latest updates about our product</Text>
            <StyledRightDiv>
              <SocialLinks />
            </StyledRightDiv>
          </Col>
        </Row>
      </div>
    </StyledFooter>
    <StyledDiv className="legalities-wrapper">
      <PrivacyPolicy />
      <TermsOfService />
      {navigator.userAgent === 'ReactSnap' && (
        <Link to="/seo">SEO Trick</Link>
      )}
    </StyledDiv>
  </>
);

export default AppFooter;
