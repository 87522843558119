import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Blog from './Blog';
import SingleBlog from './pages/SingleBlog';
import FourOhFour from 'pages/FourOhFour';

export default () => (
  <Switch>
    <Route exact path="/resources/blog" component={Blog} />
    <Route path="/resources/blog/:post_slug" component={SingleBlog} />
    <Route component={FourOhFour} />
  </Switch>
);
