export const TRADING_VIEW_DEFAULT_CONFIG = {
  autosize: true,
  timezone: "Asia/Singapore",
  library_path: "/assets/trading_chart/charting_library/",
  locale: "en",
  toolbar_bg: "#f1f3f6",
  disabled_features: ["use_localstorage_for_settings"],
  drawings_access: {
    type: "black",
    tools: [
      {
        name: "Regression Trend",
      },
    ],
  },
  time_frames: [
    {
      text: "1000y",
      resolution: "M",
      description: "All",
      title: "All",
    },
    {
      text: "1y",
      resolution: "W",
      description: "1 Year",
      title: "1y",
    },
    {
      text: "3y",
      resolution: "W",
      description: "3 Years",
      title: "3y",
    },
    {
      text: "5y",
      resolution: "W",
      description: "5 Years",
      title: "5y",
    },
  ],
};

export const LAGGING_CHART_COLORS = {
  GREEN: "#9DE900",
  LIGHT_GREEN: "#c1f985",
  AMBER: "#fe9500",
  LIGHT_RED: "#FF7070",
  RED: "#ff1919",
};

export const generateTicks = (values: number[], count = 8, minZero = false) => {
  if (values.length <= 0) return [];
  const deductTotalByTwo = count - 2;
  const minValue = Math.min(...values).toFixed(4);
  const maxValue = Math.max(...values).toFixed(4);
  const totalValue = (Number(maxValue) - Number(minValue)).toFixed(4);
  const minRangeValue = Number(totalValue) / deductTotalByTwo;
  let ticks: number[] = [];
  for (let i = 0; i <= deductTotalByTwo; i++) {
    const tickValue =
      parseFloat((minRangeValue * i).toFixed(4)) + parseFloat(minValue);
    ticks = [...ticks, parseFloat(tickValue.toFixed(4))];
  }
  ticks = [
    minZero ? 0 : parseFloat(minValue) - minRangeValue,
    ...ticks,
    parseFloat(maxValue) + minRangeValue,
  ];
  ticks = [...ticks].map((value) => parseFloat(value.toFixed(4)));
  return ticks;
};

export const generateTicksV2 = (
  values: number[],
  minZero = false,
  defaultSize = 8
) => {
  if (!values.length) {
    return [];
  }
  const min = Math.min(...values);
  const max = Math.max(...values);
  const difference = max - min;
  const minRangeValue = difference / (defaultSize - 2);
  let ticks: number[] = [];
  for (let i = 0; i <= defaultSize - 2; i++) {
    const tickValue = minRangeValue * i + min;
    ticks.push(parseFloat(tickValue.toFixed(4)));
  }
  ticks = [
    minZero ? 0 : min - minRangeValue, // applicable for bar chart types
    ...ticks,
    max + minRangeValue,
  ];
  return ticks;
};
