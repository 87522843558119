import React from "react";
import { useQuery } from "react-apollo";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography, Button } from "antd";
import { Link } from "react-router-dom";

import Spinner from "components/PageSpinner";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_INFO_FIELDS } from "apollo/fields/reits";
import { truncateDecimals } from "utils/numberUtils";
import { getCurrency } from "utils/reitUtils";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface IReitProfile {
  name: string;
  stockCode: string;
  logo: string;
  price: number;
  priceChange: number;
  priceChangePerc: number;
  volume: number;
  exchange: string;
  sector: string;
}

const labels: Array<{ title: string; key: string }> = [
  { title: "Price", key: "price" },
  { title: "Price Change", key: "priceChange" },
  { title: "Volume", key: "volume" },
  { title: "Country", key: "exchange" },
  { title: "Sector", key: "sector" },
];
const { Text } = Typography;

const Information: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let infoData: Array<IReitProfile> = [];

  const INFO_QUERY = queryCompBuilder(COMP_INFO_FIELDS, history);
  const { data, loading } = useQuery(INFO_QUERY);

  if (data) {
    for (let items in data) {
      infoData = [...infoData, data[items].profile];
    }
  }

  return (
    <>
      {/* pos-fixed copy header , dont put loader, let it pop out */}
      <div className="fixed-header max-width-1200">
        <div className="pt-4">
          <Button
            type="link"
            className="fs-14 p-0"
            onClick={history.goBack}
            style={{ fontWeight: 600, minHeight: "1px" }}
          >
            <ArrowLeftOutlined className="mr-2" />
            Back to results
          </Button>
        </div>
        <Row className="pt-5">
          <Col>
            <Text className="fs-18" strong>
              Compare
            </Text>
          </Col>
          {infoData.map((reit, index) => {
            const { name, stockCode, logo } = reit;
            return (
              <Col key={index}>
                <div className="d-flex flex-column align-items-end justify-content-start">
                  <div
                    style={{
                      maxWidth: "80px",
                      minHeight: "50px",
                      maxHeight: "50px",
                    }}
                    className="mb-3 w-100 d-flex align-items-center"
                  >
                    <img
                      src={logo}
                      className="d-block w-100 h-100"
                      alt="logo"
                    />
                  </div>
                  <div>
                    <Link to={`/screener/table/reits/${stockCode}`}>
                      <Text
                        className="d-block w-100 fs-14"
                        style={{ color: "#675BF5" }}
                        strong
                      >
                        {name}
                      </Text>
                    </Link>
                    <Text
                      className="text-right d-block w-100 fs-14"
                      type="secondary"
                      strong
                    >
                      {stockCode}
                    </Text>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="pt-5" />
      <Row className="pt-5">
        <Col>
          <Text className="fs-18" strong>
            Compare
          </Text>
        </Col>
        {infoData.map((reit, index) => {
          const { name, stockCode, logo } = reit;
          return (
            <Col key={index}>
              <div className="d-flex flex-column align-items-end justify-content-start">
                <div
                  style={{
                    maxWidth: "80px",
                    minHeight: "50px",
                    maxHeight: "50px",
                  }}
                  className="mb-3 w-100 d-flex align-items-center"
                >
                  <img src={logo} className="d-block w-100 h-100" alt="logo" />
                </div>
                <div>
                  <Link to={`/screener/table/reits/${stockCode}`}>
                    <Text
                      className="d-block w-100 fs-14"
                      style={{ color: "#675BF5" }}
                      strong
                    >
                      {name}
                    </Text>
                  </Link>
                  <Text
                    className="text-right d-block w-100 fs-14"
                    type="secondary"
                    strong
                  >
                    {stockCode}
                  </Text>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Text className="frame-subheader p-2 pl-3" strong>
        Information
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : data ? (
          <>
            {labels.map((label, idx) => {
              return (
                <Row key={idx}>
                  <Col className="fs-14">{label.title}</Col>
                  {infoData.map((reit, idx) => {
                    //format different fields
                    const value = reit[label.key as keyof typeof reit];
                    switch (label.key) {
                      case "price":
                        return (
                          <Col key={idx} className="text-right fs-14">
                            {getCurrency(reit.exchange)}
                            {value}
                          </Col>
                        );
                      case "priceChange":
                        let isPercNega =
                          Math.sign(Number(value)) < 1 ? true : false;
                        return (
                          <Col key={idx} className="text-right fs-14">
                            <Text
                              className={
                                isPercNega ? "text-danger" : "text-success "
                              }
                            >
                              {truncateDecimals(Number(value), 2)}
                              {`(${truncateDecimals(
                                Number(reit.priceChangePerc * 100),
                                2
                              )})%`}
                              {isPercNega ? (
                                <CaretDownFilled />
                              ) : (
                                <CaretUpFilled />
                              )}
                            </Text>
                          </Col>
                        );
                      case "volume":
                        return (
                          <Col key={idx} className="text-right fs-14">
                            {value.toLocaleString()}
                          </Col>
                        );
                      default:
                        return (
                          <Col key={idx} className="text-right fs-14">
                            {value}
                          </Col>
                        );
                    }
                  })}
                </Row>
              );
            })}
          </>
        ) : (
          <p className="p-4">no data available</p>
        )}
      </Row>
    </>
  );
};

export default withRouter(Information);
