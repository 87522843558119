import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { EyeIcon, TrashIcon } from 'utils/iconUtils';

interface IActionButton {
  watchlist: boolean,
  watchlistAction: () => void,
}

const StyledButton = styled(Button)`
  height: auto;
  min-height: auto;
`;

const ActionButton: React.FC<IActionButton> = ({ watchlistAction, watchlist }) => {
  const IconComponent = watchlist ? TrashIcon : EyeIcon;
  return (
    <StyledButton
      className="p-0"
      type="text"
      title={watchlist ? 'Unwatch this REIT' : 'Add to watchlist'}
      icon={<IconComponent />}
      onClick={watchlistAction}
    />
  );
};

export default ActionButton;
