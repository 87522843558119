import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import { Modal, Carousel, Typography, Button } from 'antd';

import CarouselContent from './components/CarouselContent';
import { UPDATE_ACCOUNT_SIGNUP } from 'apollo/mutations/user';
import { ACCOUNT } from 'apollo/queries/user';
import { getAuthToken, getDisplayName } from 'utils/userUtils';
import { useMessageContextValue } from 'contexts/MessageContext';
import { useUserContextValue } from 'contexts/UserContext';
import { WelcomeIcon, FreeGiftIcon, ReadyIcon } from 'utils/iconUtils';

const { Text } = Typography;

interface IFirstTimeLoginModal {
  visible: boolean,
  setVisibility: (visibility: boolean) => void,
}

const StyledModal = styled(Modal)`
  &.ant-modal {
    top: auto;
    padding: 0;
    width: 640px !important;
    .ant-modal-content {
      border-radius: 4px;
      .ant-modal-body {
        padding: 32px;
      }
      .ant-modal-footer {
        display: none;
      }
    }
  }
`;

const StyledCarousel = styled(Carousel)`
  .slick-dots {
    top: 0 !important;
    bottom: auto !important;
    li {
      margin: 0 1px !important;
      width: 10px !important;
      height: 10px !important;
      button {
        background: #E8E8E8 !important;
        height: 10px !important;
        border: 1px solid #B0B0B0 !important;
        border-radius: 50% !important;
      }
      &.slick-active {
        button {
          background: #675BF5 !important;
        }
      }
      &:not(:last-of-type) {
        margin-right: 16px !important;
      }
    }
  }
  .carousel-content {
    padding-top: 42px;
  }
`;

const StyledButton = styled(Button)`
  min-height: 54px;
  height: auto;
  padding: 12px 30px;
  font-size: 18px;
  line-height: 18px;
`;

const StyledBody = styled.div`
  .fs-20 {
    line-height: 34px;
  }
`;

const FirstTimeLoginModal: React.FC<IFirstTimeLoginModal> = ({ visible = false, setVisibility }) => {
  const { alertError } = useMessageContextValue();
  const { account } = useUserContextValue();
  const [updateAccountSignupMutation, { loading }] = useMutation(UPDATE_ACCOUNT_SIGNUP);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = useRef<Carousel>(null);

  const clickAction = () => {
    carouselRef.current?.next();
  }

  const updateAccountSignupAction = async () => {
    try {
      await updateAccountSignupMutation({
        refetchQueries: [{
          query: ACCOUNT,
          variables: { token: getAuthToken() },
        }],
        awaitRefetchQueries: true,
      });
      setVisibility(false);
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
    }
  }

  const wrapperClassName = cx({
    "d-flex align-items-center": visible,
  });

  return (
    <StyledModal
      wrapClassName={wrapperClassName}
      closable={false}
      maskClosable={false}
      visible={visible}
      onCancel={() => setVisibility(false)}
    >
      <StyledCarousel
        dotPosition="top"
        ref={carouselRef}
        initialSlide={carouselIndex}
        effect="fade"
        afterChange={setCarouselIndex}
        key={carouselIndex}
      >
        <div className="carousel-content">
          {carouselIndex === 0 && (
            <CarouselContent
              icon={<WelcomeIcon />}
              title={`Hi ${getDisplayName(account)}, we are delighted to have you on board!`}
              body={(
                <StyledBody>
                  <Text type="secondary" className="fs-20 d-block mb-3">
                    REITScreener is built by REIT investors for REIT investors. We have created a 3-step REITs discovery journey to help you appreciate REITs better and become a better investor.
                  </Text>
                  <StyledButton onClick={clickAction} type="primary">Let's get started</StyledButton>
                </StyledBody>
              )}
            />
          )}
        </div>
        <div className="carousel-content">
          {carouselIndex === 1 && (
            <CarouselContent
              icon={<FreeGiftIcon />}
              title="Free gift in your inbox!"
              body={(
                <StyledBody>
                  <Text type="secondary" className="fs-20 d-block mb-3">
                    Head over to your email inbox and you’ll receive a welcome mail. Inside is a link to download our popular ebook "8 Myths of REIT Investing that People Still Believe".
                  </Text>
                  <StyledButton onClick={clickAction} type="primary">Next</StyledButton>
                </StyledBody>
              )}
            />
          )}
        </div>
        <div className="carousel-content">
          {carouselIndex === 2 && (
            <CarouselContent
              icon={<ReadyIcon />}
              title="Ready for the journey?"
              body={(
                <StyledBody>
                  <Text type="secondary" className="fs-20 d-block mb-3">
                    We'll be taking you through the 3-step REITs discovery journey which can be completed in any order.
                  </Text>
                  <Text type="secondary" className="fs-20 d-block mb-3">
                    1. We recommend that you start with the <Text type="secondary" strong>quiz</Text> to learn more about your investment profile and strategy.
                  </Text>
                  <Text type="secondary" className="fs-20 d-block mb-3">
                    2. Next, watch our <Text type="secondary" strong>introductory video</Text> to learn more about REITs and the REITScreener advantage. 
                  </Text>
                  <Text type="secondary" className="fs-20 d-block mb-3">
                    3. Finally, we have curated 3 <Text type="secondary" strong>recommended articles</Text> to help you jump-start your knowledge in REITs.
                  </Text>
                  <StyledButton loading={loading} onClick={updateAccountSignupAction} type="primary">I’m ready!</StyledButton>
                </StyledBody>
              )}
            />
          )}
        </div>
      </StyledCarousel>
    </StyledModal>
  );
};

export default FirstTimeLoginModal;
