import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, Row, Col } from 'antd';

import { TailoredIcon, GlobalIcon, ActionableIcon, EaseIcon, CustomisableIcon } from 'utils/iconUtils';

const { Title, Text } = Typography;

const FEATURES = [{
  icon: <TailoredIcon />,
  label: 'Tailored',
  description: (
    <Text type="secondary">
      Trying to filter for REITs using a conventional stock screener? Try filtering for gearing, net property income, occupancy rate or lease expiries. You simply can't because other screeners are not tailored to screen for high quality REITs.
    </Text>
  )
}, {
  icon: <GlobalIcon />,
  label: 'Global',
  description: (
    <Text type="secondary">
      REITScreener covers the major REIT markets of the US, Singapore, Hong Kong and Australia. REITScreener is committed to continue the expansion into new markets in the near future.
    </Text>
  )
}, {
  icon: <ActionableIcon />,
  label: 'Actionable',
  description: (
    <Text type="secondary">
      Discover new and promising REIT investment opportunities. Build your own screening rules once and store them to be used repeatedly. In no time you will be building up a watchlist of REITs best suited to your portfolio to get you started on your investing journey.
    </Text>
  )
}, {
  icon: <EaseIcon />,
  label: 'Ease',
  description: (
    <Text type="secondary">
      Cut out the need to mine through hours of annual reports. Quickly and easily analyse REITs with over 8 years of historical data and built-in valuation models. Our subscribers all time favourite is relative valuation chart which gives you an instant gauge your buy/sell prices.
    </Text>
  )
}, {
  icon: <CustomisableIcon />,
  label: 'Customisable',
  description: (
    <Text type="secondary">
      Whether you are looking for value, growth, low volatility or simply sustainable income we have those strategies "pre-programmed"! This saves your time and flattens your learning curve. <Link to="/quiz">Take our quiz</Link> to find out which strategies best fit you.
    </Text>
  )
}];

const StyledFeaturesWrapper = styled.div`
  &.feature-div  {
    padding: 40px 0 80px;
    h3 {
      max-width: 650px;
    }
    .feature-subheading {
      max-width: 800px;
      margin-bottom: 40px;
    }
  }
`;

const Features = () => {
  return (
    <StyledFeaturesWrapper className="feature-div max-width-1200 mx-auto">
      <Title level={3} className="text-center mx-auto mb-4">Finally...A Complete Toolkit for REIT investors!</Title>
      <div className="feature-subheading mx-auto text-center">
        <Text className="fs-20" type="secondary">
          REITScreener is a complete suite that powers your REIT investing with actionable market insights to improve your odds of outperforming the market.
        </Text>
      </div>
      <Row gutter={[40, 40]} className="mb-0">
        {FEATURES.map(({ label, description, icon }, idx) => (
          <Col key={idx} xs={24} sm={24} md={12} lg={12}>
            <div>
              {icon}
              <Text strong className="d-block mb-2">{label}</Text>
              {description}
            </div>
          </Col>
        ))}
      </Row>
    </StyledFeaturesWrapper>
  );
}

export default Features;
