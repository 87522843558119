import React from "react";
import { Typography, List, Avatar } from "antd";
import styled from "styled-components";

const { Title, Text } = Typography;
const CHECK = "/assets/images/check.svg";

const data: Array<{
  title: string;
  message: string;
}> = [
  {
    title: "eBooks",
    message:
      'Receive a complimentary copy of our eBook entitled "8 Myths People Still Believe About REITs"',
  },
  {
    title: "Webinars",
    message: "Unlock access our most popular REIT webinars.",
  },
  {
    title: "eResource",
    message:
      "Gain unrestricted access to our extensive online educational REIT articles and resources",
  },
  {
    title: "Blogs",
    message:
      "Find out about our thoughts and views about current events in the REIT markets.",
  },
];

const StyledWrapper = styled.div`
  .ant-list-item-meta {
    align-items: center;
    img {
      width: 21px;
      height: 16px;
    }
  }
  .ant-list-item {
    max-width: 480px !important;
  }
  .ant-list-item-meta {
    align-items: start;
    img {
      width: 16px;
      height: 12px;
    }
  }
  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-list-item-meta-description,
  .ant-list-item-meta-title {
    font-size: 16px !important;
    margin: 0;
  }
  .ant-list-item-meta-description {
    color: #595959;
  }
  .ant-list-item-meta-avatar {
    margin-right: 5px;
  }
`;

export default () => {
  return (
    <StyledWrapper>
      <Title level={3} className="mb-4">
        You are one step closer to improving your odds of outperforming the
        market.
      </Title>
      <Text className="text-uppercase fs-18">
        REITScreener gives you actionable market insights!
      </Text>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="mt-2"
        split={false}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={CHECK} />}
              title={item.title}
              description={item.message}
            />
          </List.Item>
        )}
      />
    </StyledWrapper>
  );
};
