import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, Typography } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';

import { Spinner } from 'components/PageSpinner';
import { IWPPost } from 'apollo/types/cms';

const { Title } = Typography;

interface ISidenav extends RouteComponentProps<{ post_slug: string }> {
  category: string,
  title: string | null,
  posts: IWPPost[],
  loading: boolean,
}

const StyledSidenavWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 48px;
  }
  h4 {
    font-size: 18px !important;
    padding: 0 32px;
  }
  .ant-menu {
    width: 100%;
    .ant-menu-item {
      height: auto;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 32px !important;
      overflow: unset;
      text-overflow: unset;
      white-space: inherit;
      color: #121324;
      &.ant-menu-item-selected {
        background: #675bf51a;
      }
      &::after {
        left: 0;
        right: auto;
        border-right: 4px solid #121324;
      }
    }
  }
`;

const Sidenav: React.FC<ISidenav> = ({ category, title, match, history, posts, loading }) => {
  const formattedCategory = category.replace(/_/g, '-').toLowerCase();

  const handleMenuClick = ({ key }: MenuInfo) => {
    history.push(`/resources/${formattedCategory}/${key}`);
  }

  return (
    <StyledSidenavWrapper>
      {title && (
        <Title level={4} className="mb-2">{title}</Title>
      )}
      {loading ? (
        <Spinner style={{ minHeight: 150, background: '#FFF' }} />
      ) : (
        <Menu
          className="border-0"
          mode="inline"
          selectedKeys={[match.params.post_slug]}
          onClick={handleMenuClick}
        >
          {posts.map(({ title, slug }) => (
            <Menu.Item className="m-0" key={slug}>{title}</Menu.Item>
          ))}
        </Menu>   
      )}
    </StyledSidenavWrapper>
  );
}

export default withRouter(Sidenav);
