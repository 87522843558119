import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { Select } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import moment from "moment";

import { useUserContextValue } from "contexts/UserContext";
import {
  REIT_STD_YIELD_SPREAD_INFO,
  REIT_STD_PRICE_TO_BOOK_INFO,
} from "apollo/queries/reit";
import { Spinner } from "components/PageSpinner";
import { AreaChart } from "components/Charts";
import { IRefLineProps } from "components/Charts/AreaChart/AreaChart";
import { truncateDecimals } from "utils/numberUtils";
import { LAGGING_CHART_COLORS } from "utils/chartUtils";

interface IDeviationProps extends RouteComponentProps<{ stockCode: string }> {
  type: string;
  stockCode?: string; //for comparison chart
}

const { Option } = Select;

///need to control states and refectch queries based on year filter

const DeviationChart: React.FC<IDeviationProps> = ({
  match,
  type,
  stockCode,
}) => {
  let chartData = [];
  let chartReflines: IRefLineProps[] = [];
  let deviationValues: number[] = [];

  const [range, setRange] = useState("5y");
  const { token } = useUserContextValue();
  const isYieldSpread = type === "dividends" ? true : false;
  const stdQuery =
    type === "dividends"
      ? REIT_STD_YIELD_SPREAD_INFO
      : REIT_STD_PRICE_TO_BOOK_INFO;
  const { data, loading } = useQuery(stdQuery, {
    variables: {
      stockCode: match.params.stockCode ? match.params.stockCode : stockCode,
      token,
      range,
    },
  });

  const isDividends = type === "dividends";

  if (data) {
    const {
      minusSTDDEV,
      minus2STDDEV,
      average,
      plusSTDDEV,
      plus2STDDEV,
      chart,
    } = data.screenerReit.keyStatistics[type].chart;

    chartData = chart;

    deviationValues = [
      minusSTDDEV,
      minus2STDDEV,
      average,
      plusSTDDEV,
      plus2STDDEV,
    ];

    chartReflines = [
      {
        value: plus2STDDEV,
        dataKey: "plus2STDDEV",
        color: isDividends
          ? LAGGING_CHART_COLORS.GREEN
          : LAGGING_CHART_COLORS.RED,
        strokeColor: isDividends
          ? LAGGING_CHART_COLORS.GREEN
          : LAGGING_CHART_COLORS.RED,
        percentage: isYieldSpread,
        name: isDividends ? "Bargain" : "Very Expensive",
      },
      {
        value: plusSTDDEV,
        dataKey: "plusSTDDEV",
        color: isDividends
          ? LAGGING_CHART_COLORS.LIGHT_GREEN
          : LAGGING_CHART_COLORS.LIGHT_RED,
        strokeColor: isDividends
          ? LAGGING_CHART_COLORS.LIGHT_GREEN
          : LAGGING_CHART_COLORS.LIGHT_RED,
        percentage: isYieldSpread,
        name: isDividends ? "Good Value" : "Expensive",
      },
      {
        value: average,
        dataKey: "average",
        color: LAGGING_CHART_COLORS.AMBER,
        strokeColor: LAGGING_CHART_COLORS.AMBER,
        percentage: isYieldSpread,
        name: "Moderate",
      },
      {
        value: minusSTDDEV,
        dataKey: "minusSTDDEV",
        color: isDividends
          ? LAGGING_CHART_COLORS.LIGHT_RED
          : LAGGING_CHART_COLORS.LIGHT_GREEN,
        strokeColor: isDividends
          ? LAGGING_CHART_COLORS.LIGHT_RED
          : LAGGING_CHART_COLORS.LIGHT_GREEN,
        percentage: isYieldSpread,
        name: isDividends ? "Expensive" : "Good Value",
      },
      {
        value: minus2STDDEV,
        dataKey: "minus2STDDEV",
        color: isDividends
          ? LAGGING_CHART_COLORS.RED
          : LAGGING_CHART_COLORS.GREEN,
        strokeColor: isDividends
          ? LAGGING_CHART_COLORS.RED
          : LAGGING_CHART_COLORS.GREEN,
        percentage: isYieldSpread,
        name: isDividends ? "Very Expensive" : "Bargain",
      },
    ];
  }

  return (
    <>
      <Select
        defaultValue={range}
        style={{ width: 180 }}
        onChange={(range: string) => setRange(range)}
      >
        <Option value="90d">3 Months</Option>
        <Option value="180d">6 Months</Option>
        <Option value="1y">1 Year</Option>
        <Option value="3y">3 Years</Option>
        <Option value="5y">5 Years</Option>
      </Select>
      {loading ? (
        <div className="w-100 justify-self-center">
          <Spinner label="" style={{ height: "100vh", fontSize: 100 }} />
        </div>
      ) : (
        <>
          {data ? (
            <>
              <AreaChart
                deviationValues={deviationValues}
                dataSource={chartData}
                refLines={chartReflines}
                chartLabel={
                  type !== "dividends"
                    ? "Price-to-Book Ratio"
                    : "Yield Spread(%)"
                }
                yTickLabelFormatter={(value: number) => {
                  return type !== "dividends"
                    ? truncateDecimals(value, 2)
                    : `${(value * 100).toFixed(2)}%`;
                }}
                xTickLabelFormatter={(value: string) => {
                  return moment(value).format("MMM YYYY");
                }}
              />
            </>
          ) : (
            <p className="p-4">no data available</p>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(DeviationChart);
