import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import {
  PREMIUM_USER_INDEX,
  REGISTERED_USER_INDEX,
  ANONYMOUS_USER_INDEX,
} from "./appUtils";

export const queryCompBuilder = (
  fields: string,
  reitHistory: any
): DocumentNode => {
  const struc = `
        STOCK_CODE: screenerReit(stockCode: $stockCode) {
         ${fields}
        }
    `;
  const multipleReits = reitHistory.location.state.cmpReitList.map(
    (reit: any, index: any) =>
      struc
        .replace("STOCK_CODE", `REIT${index}`)
        .replace("$stockCode", `"${reit}"`)
        .split("\n")
  );
  const finalQuery = gql`
       query fetchData{
          ${multipleReits.join("\n")}
        }
    `;
  return finalQuery;
};

export const REIT_EXCEPTIONS = ["5121.KL", "NSR.AX"];

export const getCurrency = (exchange: string) => {
  return exchange !== "USA" ? `${exchange[0]}$` : "US$";
};

//plans for each frame
export const KEY_STATISTICS_FRAME = [
  {
    name: "MARKET_CAP",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
  {
    name: "52_WEEK_RANGE",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
  {
    name: "VOLATILITY",
    minimumPlan: REGISTERED_USER_INDEX,
  },
  {
    name: "DIVIDEND_AMOUNT",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
  {
    name: "NAV",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
  {
    name: "DIVIDEND",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
  {
    name: "PRICE_TO_BOOK",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
];

export const KEY_PORTFOLIO_FRAME = [
  {
    name: "PROPERTY_FIELD",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "OCCUPANCY_RATE",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "RENT_GROWTH",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "LEASE_DURATION",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "INCOME_SUPPORT",
    minimumPlan: PREMIUM_USER_INDEX,
  },
];

export const KEY_FINANCIALS_FRAME = [
  {
    name: "REVENUE",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "NPI",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "OPERATING_CASH_FLOW",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "FREE_CASH_FLOW",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "FFO",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "AFFO",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "DISTRIBUTIONS_PAID",
    minimumPlan: PREMIUM_USER_INDEX,
  },
];

export const KEY_DEBT_FRAME = [
  {
    name: "BORROWINGS",
    minimumPlan: ANONYMOUS_USER_INDEX,
  },
  {
    name: "TOTAL_BORROWINGS",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "LOAN_INTEREST_RATES",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "LOAN_DURATION",
    minimumPlan: PREMIUM_USER_INDEX,
  },
  {
    name: "INTEREST_COVER",
    minimumPlan: REGISTERED_USER_INDEX,
  },
];

const KEY_GROWTH_YEAR_PLAN = {
  threeYr: PREMIUM_USER_INDEX,
  fiveYr: REGISTERED_USER_INDEX,
  sevenYr: PREMIUM_USER_INDEX,
};

export const KEY_GROWTH_FRAME = [
  {
    name: "DIVIDEND",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "NET_ASSET_VALUE",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "REVENUE",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "NET_PROPERTY_INCOME",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "OPERATING_CASH_FLOW",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "FREE_CASH_FLOW",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "FFO",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "AFFO",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
  {
    name: "DISTRIBUTIONS_PAID",
    yearPlans: KEY_GROWTH_YEAR_PLAN,
  },
];
