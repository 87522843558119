import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import styled from "styled-components";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { ColumnType } from "antd/lib/table/interface";
import { Table, Typography, Spin, Button, Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { LoadingIcon } from "components/PageSpinner";
import { reitsNameColumnFilter } from "./components/ReitNameFilter/ReitNameFilter";
import ActionButton from "./components/ActionButton";
import { IScreenerData } from "apollo/types/screener";
import { ALL_SCREENER_FILTERS } from "utils/screenerUtils";
import { useUserContextValue } from "contexts/UserContext";
import { useMessageContextValue } from "contexts/MessageContext";
import { truncateDecimals, formatCurrency } from "utils/numberUtils";
import { COUNTRIES } from "utils/appUtils";
import { SCREENER_WATCHLIST } from "apollo/queries/screener";
import {
  ADD_SCREENER_WATCHLIST,
  REMOVE_SCREENER_WATCHLIST,
} from "apollo/mutations/screener";
import { PREMIUM_USER_INDEX, REGISTERED_USER_INDEX } from "utils/appUtils";
import { scrollIntoElement } from "utils/windowUtils";
import { LockIconCircle } from "utils/iconUtils";

const { Text } = Typography;
const MAX_COMPARISON = 4;

interface IScreenerTable extends RouteComponentProps<any> {
  reloadTableCallback?: () => void;
  paramsChanged?: boolean;
  loading: boolean;
  watchlist?: boolean;
  data: {
    count: number;
    rows: IScreenerData[];
  };
}

const StyledTableDiv = styled.div`
  .ant-table {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 8px;
    .ant-table-container {
      border-radius: 4px 4px 0 0;
      &::before,
      &::after {
        box-shadow: none !important;
      }
      table {
        font-size: 14px;
        line-height: 22px;
        .ant-table-thead {
          tr > th {
            user-select: none;
            .ant-table-column-sorters {
              display: flex;
              justify-content: space-between;
              padding: 8px;
              span:first-of-type {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            &.action-column {
              display: none;
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              padding: 8px;
              &.action-column > .ant-btn {
                display: none;
              }
              &::after {
                box-shadow: none;
              }
            }
            &:hover {
              .action-column > .ant-btn {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      font-size: 14px;
    }
    .ant-pagination-options {
      font-size: 14px !important;
    }
  }
`;

const StyledButton = styled(Button)`
  width: auto;
  height: auto;
  position: relative !important;
  top: auto !important;
  left: auto !important;
`;

const ComparisonModal = styled(Modal)`
  &.comparison-modal {
    padding: 20px;
    .ant-modal-close {
      padding: 0 !important;
    }
    .ant-modal-header {
      padding: 24px 32px 0;
      border: 0;
    }
    .ant-modal-body {
      text-align: center;
    }
  }
`;

const ScreenerTable: React.FC<IScreenerTable> = ({
  loading,
  data,
  location,
  watchlist = false,
  paramsChanged = false,
  reloadTableCallback,
  history,
}) => {
  const [watchlistState, setWatchlistState] = useState(false);
  const [addScreenerToWatchlist] = useMutation(ADD_SCREENER_WATCHLIST);
  const [removeScreenerToWatchlist] = useMutation(REMOVE_SCREENER_WATCHLIST);
  const { account } = useUserContextValue();
  const { alertError, alertSuccess, alertWarning } = useMessageContextValue();
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [selectedReits, setSelectedReits] = useState<React.ReactText[]>([]);
  const userLevel = account?.level || 0;
  const [showCompModal, setCompModal] = useState(false);

  useEffect(() => {
    if (!loading && data.count) {
      scrollIntoElement("table-component");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, recordsPerPage]);

  useEffect(() => {
    if (selectedReits.length) {
      setSelectedReits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.rows, recordsPerPage]);

  const handleComparison = () => {
    if (userLevel < REGISTERED_USER_INDEX) return;
    const compareLen = selectedReits.length;
    const registeredLimit =
      userLevel === REGISTERED_USER_INDEX && compareLen > 1 && compareLen < 3;
    const premiumLimit = userLevel >= PREMIUM_USER_INDEX && compareLen > 1;
    const belowPremium = userLevel < PREMIUM_USER_INDEX;

    if (registeredLimit || premiumLimit) {
      history.push({
        pathname: "/screener/table/comparison",
        state: { cmpReitList: selectedReits },
      });
    } else {
      if (belowPremium && compareLen > 2) {
        setCompModal(true);
      }
    }
  };

  const errorHandler = (error: any) => {
    let errorMessage = "Network error";
    if (error.graphQLErrors[0]) {
      errorMessage = error.graphQLErrors[0].message;
    }
    alertError(errorMessage);
  };

  const updateWatchlist = async (stockCode: string, isWatchlist = false) => {
    setWatchlistState(true);
    try {
      const watchlistMutation = isWatchlist
        ? removeScreenerToWatchlist
        : addScreenerToWatchlist;
      await watchlistMutation({
        variables: { stockCode },
        refetchQueries: [{ query: SCREENER_WATCHLIST }],
        awaitRefetchQueries: true,
      });
      if (!isWatchlist) {
        alertSuccess(`${stockCode} is now on your Watchlist!`);
      } else {
        alertWarning(`${stockCode} has been removed from your Watchlist!`);
      }
    } catch (error) {
      errorHandler(error);
    }
    setWatchlistState(false);
  };

  const stringSortFunction = (a: any, b: any) => (key: string) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };

  const fixedColumns: ColumnType<IScreenerData>[] = [
    {
      title: "REIT",
      dataIndex: "reitName",
      key: "reitName",
      width: 225,
      fixed: "left",
      sorter: (a, b) => stringSortFunction(a, b)("reitName"),
      render: (text, record) => (
        <Link to={`${location.pathname}/reits/${record.stockCode}`}>
          {text}
        </Link>
      ),
      ...reitsNameColumnFilter(),
    },
    {
      title: "Stock Code",
      dataIndex: "stockCode",
      key: "stockCode",
      width: 125,
      sorter: (a: any, b: any) => stringSortFunction(a, b)("stockCode"),
    },
  ];

  const actionColumn: ColumnType<IScreenerData> = {
    title: "",
    key: "action",
    width: 50,
    fixed: "right",
    className: "action-column",
    render: (_, record) => (
      <ActionButton
        watchlist={watchlist}
        watchlistAction={() => {
          updateWatchlist(record.stockCode, watchlist);
        }}
      />
    ),
  };

  const tableColumns = ALL_SCREENER_FILTERS.filter(
    ({ minimumPlan }) => (account?.level || 0) >= minimumPlan
  )
    .sort((a, b) => a.columnOrder - b.columnOrder)
    .map(({ title, key, valueType }) => {
      const isMoney = valueType === "money";
      const isPercent = valueType === "percent";
      return {
        title,
        dataIndex: key,
        key,
        render: (value: number, record: any) => {
          if (["sector", "exchange"].includes(key)) return value;

          let suffix = "";
          if (isMoney) {
            suffix = COUNTRIES.find(
              ({ exchange }) => exchange === record.exchange
            )!.currency;
          } else if (isPercent) {
            suffix = "%";
          }

          return isMoney
            ? typeof value === "number"
              ? `${suffix} ${formatCurrency(value)}`
              : "-"
            : typeof value === "number"
            ? `${truncateDecimals(isPercent ? value * 100 : value)} ${suffix}`
            : "-";
        },
        width: 125,
        sorter: (a: any, b: any) => {
          return ["sector", "exchange"].includes(key)
            ? stringSortFunction(a, b)(key)
            : a[key] - b[key];
        },
      };
    });
  return (
    <Spin
      spinning={paramsChanged}
      wrapperClassName="table-blocker"
      indicator={
        <StyledButton
          icon={<ReloadOutlined />}
          onClick={() => reloadTableCallback && reloadTableCallback()}
        >
          Reload table
        </StyledButton>
      }
    >
      <div id="table-component">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <div>
            {Boolean(data.count) && (
              <Text type="secondary" className="fs-16">
                Showing{" "}
                <b style={{ color: "#675BF5", fontWeight: 600 }}>
                  {data.count}
                </b>{" "}
                REITs
              </Text>
            )}
          </div>
          <ComparisonModal
            className="comparison-modal"
            visible={showCompModal}
            onCancel={() => setCompModal(false)}
            footer={false}
          >
            <h4 className="my-3 mb-4">You discovered a premium feature!</h4>
            <LockIconCircle />
            <Text className="my-3 fs-16 d-block w-100 px-4" type="secondary">
              Subscribe to REITScreener Premium to add and compare more REITs
            </Text>
            <Link
              to="/settings/subscription"
              className="ant-btn ant-btn-primary pt-1 mt-1"
            >
              Learn More
            </Link>
          </ComparisonModal>
          <Button onClick={handleComparison}>Compare</Button>
        </div>
        <StyledTableDiv>
          <Table<IScreenerData>
            key={data.count}
            locale={{
              emptyText: "No data",
            }}
            loading={{
              spinning: loading || watchlistState,
              indicator: LoadingIcon,
            }}
            rowKey="stockCode"
            columns={[
              ...fixedColumns,
              ...tableColumns,
              ...(account ? [actionColumn] : []),
            ]}
            dataSource={data.rows}
            scroll={{ x: "max-content", y: "60vh" }}
            showSorterTooltip={false}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"],
              pageSize: recordsPerPage,
              onShowSizeChange: (_, size) => setRecordsPerPage(size),
            }}
            rowSelection={{
              type: "checkbox",
              hideSelectAll: true,
              selectedRowKeys: selectedReits,
              onChange: (selectedRowKeys) => setSelectedReits(selectedRowKeys),
              getCheckboxProps: (record) => {
                const disabledRow =
                  selectedReits.length === MAX_COMPARISON &&
                  !Boolean(
                    selectedReits.find(
                      (stockCode) => stockCode === record.stockCode
                    )
                  );
                return {
                  disabled: disabledRow,
                  name: record.reitName,
                };
              },
            }}
          />
        </StyledTableDiv>
      </div>
    </Spin>
  );
};

export default withRouter(ScreenerTable);
