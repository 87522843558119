import React, { useEffect } from "react";
import { Row, Col, Card, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import StarterInfo from "./components/StarterInfo";
import KeyStatistics from "./components/KeyStatistics";
import ChartFiftyTwo from "./components/ChartFiftyTwo";
import Profile from "./components/Profile";
import Portfolio from "./components/Portfolio";
import Financials from "./components/Financials";
import Growth from "./components/Growth";
import Debt from "./components/Debt";
import { scrollToTop } from "utils/windowUtils";
import MetaTags from "components/MetaTags";
import REIT_DATA from 'seo-data/reits.json';

const StyleOverride = styled.div`
  .custom-card {
    overflow: hidden;
    card-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  }
  .ant-card {
    max-width: 578px;
    min-height: 200px;
  }
  .ant-card-body {
    padding: 0;
  }
  .card-header {
    background: rgba(154, 145, 255, 0.06);
    font-weight: 600;
    font-size: 16px;
  }
  .unit-label {
    color: #bfbfbf;
  }
`;

const ReitDetails: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
  location,
  history,
}) => {
  const reitData = REIT_DATA.find(({ stockCode }) => stockCode === match.params.stockCode);

  useEffect(() => {
    scrollToTop();
    localStorage.setItem("prevPath", location.pathname);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyleOverride className="max-width-1200 mx-auto mt-4 pb-4 px-2">
      {reitData && (
        <MetaTags
          metaTitle={reitData.name}
          metaImg={reitData.logo}
          metaDescription={reitData.description}
        />
      )}
      <Button
        type="link"
        onClick={history.goBack}
        className="fs-14 p-0"
        style={{ fontWeight: 600, minHeight: "1px" }}
      >
        <ArrowLeftOutlined className="mr-2" />
        Back to results
      </Button>
      <Row className="align-items-start justify-content-md-between mt-4">
        <Col xs={24} className="custom-card w-100 mb-4">
          <StarterInfo />
        </Col>
        <Col
          xs={24}
          md={12}
          className="d-flex flex-column align-items-start justify-content-md-between"
        >
          <Card className="custom-card w-100 mb-4">
            <KeyStatistics />
          </Card>
          <Card className="custom-card w-100 mb-4">
            <Portfolio />
          </Card>
          <Card className="custom-card w-100 mb-4">
            <Growth />
          </Card>
        </Col>

        <Col xs={24} md={12} className="d-flex flex-column align-items-end">
          <Card className="custom-card w-100 mb-4">
            <ChartFiftyTwo />
          </Card>
          <Card className="custom-card w-100 mb-4">
            <Profile />
          </Card>
          <Card className="custom-card w-100 mb-4">
            <Financials />
          </Card>
          <Card className="custom-card w-100 mb-4">
            <Debt />
          </Card>
        </Col>
      </Row>
    </StyleOverride>
  );
};

export default withRouter(ReitDetails);
