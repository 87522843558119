import React from 'react';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { TFilterOption } from 'utils/filterUtils';
import { setFilterState } from 'redux/slices/filterStateSlice';
import { IReduxState } from 'redux/reducers';

interface IFilterRadioSelect {
  config: {
    filterStateKey: string,
    options: TFilterOption[],
  }
}

const FilterRadioSelect: React.FC<IFilterRadioSelect> = ({ config }) => {
  const dispatch = useDispatch();
  const filterState = useSelector((state: IReduxState) => state.filterState.filterState);
  const stateSource = filterState[config.filterStateKey];

  return (
    <Radio.Group
      buttonStyle="solid"
      value={stateSource.option[0]?.value}
      onChange={e => {
        const newSetOfFilterState = {
          ...filterState,
          [config.filterStateKey]: {
            ...stateSource,
            option: config.options.filter(({ value }) => value === e.target.value)!,
          },
        };
        dispatch(setFilterState(newSetOfFilterState));
      }}
    >
      {config.options.map((option) => (
        <Radio.Button className="fs-14 font-weight-normal text-capitalize" value={option.value} key={option.value}>
          {option.label.toLowerCase()}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default FilterRadioSelect;
