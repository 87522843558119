import React from 'react';
import { render, hydrate } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import App from './App';
import client from 'config/apolloClient';
import config from 'config/appConfig';
import { isDev } from 'config/env';
import integrations from 'config/integrations';
import store from 'redux/store';

import 'bootstrap-utilities/bootstrap-utilities.css';
import './stylesheets/app.css';
import './stylesheets/heading.css';
import './stylesheets/typography.css';
import './stylesheets/button.css';
import './stylesheets/background.css';
import './stylesheets/message.css';
import './stylesheets/form.css';
import './stylesheets/sliderTooltip.css';

config();
if (navigator.userAgent !== 'ReactSnap') {
  integrations();
}

const rootElement: HTMLElement | null = document.getElementById('root');
const renderMethod = rootElement?.hasChildNodes() ? hydrate : render;
const appRenderer = () => {
  renderMethod(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>,
    rootElement
  );
};

appRenderer();

// added from redux
if (isDev && module.hot) {
  module.hot.accept('./App', appRenderer);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
