import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { List, Row, Col, Typography, Avatar } from "antd";
import { useMutation } from "react-apollo";

import SignUpForm from "components/SignUpForm";
import { IRegisterFormValues } from "components/SignUpForm/SignUpForm";
import { SEND_MAGIC_LINK } from "apollo/mutations/user";
import { useMessageContextValue } from "contexts/MessageContext";

export interface ISuccessRegister {
  isDone: boolean;
  email: string;
}

interface ISignupProps extends RouteComponentProps {
  setRegisterInfo: (status: ISuccessRegister) => void;
}

const CHECK = "/assets/images/check.svg";
const listData = [
  {
    title: "eBooks",
    description: "8 Myths of REIT Investing People Still Believe",
  },
  {
    title: "Articles",
    description:
      "10 exclusive articles to get your started on your REIT investing journey",
  },
  {
    title: "Webinar",
    description:
      "Complimentary recorded webinar on how to pick REITs that outperform the market",
  },
  {
    title: "Watchlists",
    description:
      "A complimentary assessment to determine your ideal REIT investment strategy along with a watchlist of REITs best suited to your portfolio",
  },
];
const { Text } = Typography;
const StyleOverride = styled.div`
  .ant-form {
    width: 100%;
    max-width: 390px;
    .ant-btn {
      min-height: 48px;
    }
  }
  .ant-list-item-meta {
    max-width: 280px;
    img {
      width: 16px;
      height: 12px;
    }
    .ant-list-item-meta-title {
      font-size: 16px !important;
      margin: 0 !important;
    }
    .ant-list-item-meta-description {
      font-size: 14px !important;
    }
    .ant-list-item-meta-avatar {
      margin: 0;
    }
  }
  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gray-panel {
    background-color: #fafafa;
  }
`;

const ExclusiveSignUp: React.FC<ISignupProps> = ({
  location,
  setRegisterInfo,
}) => {
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const { alertError } = useMessageContextValue();

  const handleSubmit = async (
    values: IRegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      await sendMagicLink({
        variables: {
          input: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            redirectUrl: location.pathname,
          },
        },
      });
      setSubmitting(false);
      setRegisterInfo({ isDone: true, email: values.email });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
      setSubmitting(false);
    }
  };

  return (
    <StyleOverride className=" mt-4 mt-md-0">
      <Row className="d-flex">
        <Col
          xs={{ span: 24, order: 2 }}
          md={{ span: 10, order: 1 }}
          className="p-5  gray-panel"
        >
          <List
            size="small"
            bordered={false}
            dataSource={listData}
            renderItem={(each) => (
              <List.Item className="p-0 mb-3 border-0">
                <List.Item.Meta
                  description={each.description}
                  title={each.title}
                  avatar={<Avatar src={CHECK} />}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ span: 14, order: 2 }}
          className="d-flex flex-column mb-5 mb-md-0 py-5 pl-5 pr-4"
        >
          <h4 className="mb-4 font-weight-bold">
            This content is exclusive to registered users!
          </h4>
          <Text type="secondary" className="d-block fs-16 mb-4">
            Register and unlock this content and much much more...
          </Text>
          <SignUpForm onSubmit={handleSubmit} />
        </Col>
      </Row>
    </StyleOverride>
  );
};

export default withRouter(ExclusiveSignUp);
