import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import REITS_DATA from 'seo-data/reits.json';

const { Title } = Typography;

const SEO = () => {
  return (
    <div className="max-width-1200 mx-auto py-5">
      <Title level={3} className="mb-5">SEO Pages</Title>
      <Title level={4}>REITs</Title>
      <ul>
        {REITS_DATA.map((reit) => (
          <li>
            <Link to={`/screener/table/reits/${reit.stockCode}`}>{reit.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SEO;
