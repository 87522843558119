import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import { Row, Col, Typography, Card } from "antd";
import styled from "styled-components";

import { Spinner } from "components/PageSpinner";
import { VIDEOS } from "apollo/queries/resources";
import { IVideoDetails, TVideoList } from "pages/Videos/components/Main/Main";

const StyleWrapper = styled.div`
  &::before {
    content: "";
    top: 0;
    margin-top: 20px;
    left: 50%;
    background-color: #e8e8e8;
    transform: translateX(-50%);
    position: absolute;
    display: block;
    width: 210px;
    height: 2px;
  }
  background: #ffffff;
`;
const { Text } = Typography;

const RelatedVideos: React.FC<{ currentVideo: IVideoDetails }> = ({
  currentVideo,
}) => {
  let vidRelated: TVideoList = [];
  const { loading, data } = useQuery(VIDEOS);

  if (data) {
    vidRelated = data.posts[0].posts;
    vidRelated = vidRelated
      .filter((vid) => {
        return vid.tag === currentVideo.tag && vid.slug !== currentVideo.slug;
      })
      .slice(0, 3);
  }

  return (
    <StyleWrapper className="pt-5 pb-5 mt-5 position-relative">
      <div className="max-width-1200 mx-auto">
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {data ? (
                <div className="pt-4">
                  <h4>Related Videos</h4>
                  <Row className="justify-content-md-between mb-2 mt-4">
                    {vidRelated.map((video) => (
                      <Col
                        key={video.id}
                        xs={24}
                        className="mb-3 w-100 h-100 d-flex"
                        style={{
                          maxWidth: "384px",
                        }}
                      >
                        <Card className="">
                          <Link to={`/reitscreener-tv/${video.slug}`}>
                            <div className="video-container">
                              <img
                                src={
                                  video.media
                                    ? video.media
                                    : "https://via.placeholder.com/639x399"
                                }
                                className="prod-img"
                                alt="reit videos"
                              />
                            </div>
                          </Link>
                          <div className="p-3">
                            <Text type="secondary" className="d-block fs-16 ">
                              {video.videoDate}
                            </Text>
                            <Text className="d-block fs-16" strong>
                              {video.title.length > 34
                                ? video.title.substr(0, 32) + ".."
                                : video.title}
                            </Text>
                          </div>
                        </Card>
                      </Col>
                    ))}
                    {vidRelated.length < 1 && <p>no related videos..</p>}
                    {vidRelated.length > 0 && vidRelated.length < 3 && (
                      <Col xs={24} md={7} className="py-2 mb-3" />
                    )}
                  </Row>
                </div>
              ) : (
                <div className="my-5"> No related videos.</div>
              )}
            </>
          )}
        </>
      </div>
    </StyleWrapper>
  );
};

export default RelatedVideos;
