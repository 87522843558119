import React from "react";
import { AreaChart, Area, YAxis, XAxis } from "recharts";

import { generateTicks } from "utils/chartUtils";

interface IChartProps {
  dataSource: Array<{ value: number; label: string }>;
}

const SmallChart: React.FC<IChartProps> = ({ dataSource }) => {
  const dataPoints: number[] = dataSource.map((data) => data.value);
  const pointValues =
    dataPoints.length > 0
      ? [Math.min(...dataPoints), Math.max(...dataPoints)]
      : [];

  const leftYTicks = generateTicks(pointValues, 7);
  const refinedValues = dataSource
    .map((each) => {
      return {
        value: each.value,
        label: each.label,
      };
    })
    .reverse();

  return (
    <>
      <AreaChart
        data={refinedValues}
        className="d-inline-block"
        width={100}
        height={50}
        style={{ cursor: "pointer" }}
      >
        <YAxis
          type="number"
          domain={["dataMin", "dataMax"]}
          ticks={leftYTicks}
          tickCount={leftYTicks.length}
          axisLine={false}
          hide
        />
        <XAxis
          type="category"
          tickLine={false}
          axisLine={false}
          domain={["dataMin - 100", "dataMax + 100"]}
          hide
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          fill="#E4DDFF"
          strokeWidth={1}
        />
      </AreaChart>
    </>
  );
};

export default SmallChart;
