import React from "react";
import { Typography, Col, Row } from "antd";
import { useQuery } from "react-apollo";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { REIT_KEY_PORTFOLIO_INFO } from "apollo/queries/reit";
import { Spinner } from "components/PageSpinner";
import { HistoricalChart } from "components/Charts";
import { KEY_PORTFOLIO_FRAME } from "utils/reitUtils";
import { useUserContextValue } from "contexts/UserContext";
import { PREMIUM_USER_INDEX, ANONYMOUS_USER_INDEX } from "utils/appUtils";
import LockReitModal from "components/LockReitmodal";

const StyledRow = styled(Row)`
  .ant-col {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const { Text } = Typography;

interface IPortfolioDetails {
  charts: Array<{
    name: string;
    meta?: string;
    content: {
      value: number | boolean | undefined;
      unit?: "Decimal" | "Percent";
      chart: Array<{
        value: number;
        label: string;
      }>;
    };
  }>;
}

interface IPortfolioInfo {
  title?: string;
  meta?: string;
  hasDataBoost: boolean;
  hasBorder?: boolean;
}

const Portfolio: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
}) => {
  let reitChartInfo: IPortfolioDetails = {
    charts: [],
  };

  const { account } = useUserContextValue();

  const { loading, data } = useQuery(REIT_KEY_PORTFOLIO_INFO, {
    variables: {
      stockCode: match.params.stockCode,
    },
  });

  if (data) {
    const { chartsInfo, other } = data.screenerReit;
    //get each chart property
    for (let item in chartsInfo) {
      if (item !== "__typename") {
        let meta = "",
          chartName = "",
          unit = "Decimal";
        switch (item) {
          case "propertyYield":
            chartName = "Property Yield";
            meta = "(Cap Rates) (%)";
            unit = "Percent";
            break;
          case "occupancyRate":
            chartName = "Occupancy Rate";
            meta = "(%)";
            unit = "Percent";
            break;
          case "rentGrowth":
            chartName = "Rent Growth";
            meta = "(Rental Reversions) (%)";
            unit = "Percent";
            break;
          case "leaseDuration":
            chartName = "Lease Duration";
            meta = "(Weighted Average Lease Expiry (WALE)) (Years)";
            break;
          default:
            break;
        }
        reitChartInfo.charts.push({
          name: chartName,
          meta,
          content: Object.assign({}, chartsInfo[item], { unit }),
        });
      }
    }
    reitChartInfo.charts.push({
      name: "Income Support",
      content: {
        value: other.incomeSupport,
        chart: [],
      },
    });
  }

  const ExtractAllowedFields: React.FC<{ userLevel: number }> = ({
    userLevel,
  }) => {
    const userNoAccess = userLevel < PREMIUM_USER_INDEX;

    const ChartDetails: React.FC<{ lookupName: string }> = ({ lookupName }) => {
      const info = reitChartInfo.charts.filter((chart) => {
        return chart.name === lookupName;
      })[0];

      const { name, content, meta } = info;
      const { value, chart, unit } = content;
      return (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ flexBasis: content.chart.length >= 1 ? "34%" : "20%" }}
        >
          {typeof value !== "boolean" ? (
            <>
              {content.chart.length >= 1 ? (
                <HistoricalChart
                  dataSource={chart}
                  unit={unit ? unit : "Decimal"}
                  chartLabel={name}
                  chartMeta={meta}
                  value={value}
                />
              ) : (
                <span className="d-block w-100 text-right">{value ? `${(value * 100).toFixed(2)}%` : "-"}</span>
              )}
            </>
          ) : (
            <Text className="d-block w-100 text-right">
              {value ? "yes" : "no"}
            </Text>
          )}
        </div>
      );
    };

    const PortfolioInfo: React.FC<IPortfolioInfo> = ({
      title,
      meta,
      hasDataBoost,
      hasBorder,
    }) => {
      return (
        <Col
          xs={24}
          className="d-flex align-items-center justify-content-between p-3"
          style={{ borderBottom: hasBorder === false ? "none" : "" }}
        >
          <Text
            className="fs-16 d-flex"
            disabled={userNoAccess}
            style={{ flexBasis: "63.99%" }}
          >
            {title}
            <br />
            {meta}
          </Text>
          {userNoAccess ? (
            <span className="ml-2">
              <LockReitModal
                isDataBoostFeature={
                  userLevel <= ANONYMOUS_USER_INDEX
                    ? false
                    : title !== "Income Support"
                    ? true
                    : false
                }
                minimumPlan={userLevel}
              />
            </span>
          ) : (
            <>{title && <ChartDetails lookupName={title} />}</>
          )}
        </Col>
      );
    };

    const fieldDefinition = KEY_PORTFOLIO_FRAME.map((field) => {
      switch (field.name) {
        case "PROPERTY_FIELD":
          return (
            <PortfolioInfo
              title="Property Yield"
              meta="(Cap Rates) (%)"
              hasDataBoost={true}
            />
          );
        case "OCCUPANCY_RATE":
          return (
            <PortfolioInfo
              title="Occupancy Rate"
              meta="(%)"
              hasDataBoost={true}
            />
          );
        case "RENT_GROWTH":
          return (
            <PortfolioInfo
              title="Rent Growth"
              meta="(Rental Reversions) (%)"
              hasDataBoost={true}
            />
          );
        case "LEASE_DURATION":
          return (
            <PortfolioInfo
              title="Lease Duration"
              meta="(Weighted Average Lease Expiry (WALE)) (Years)"
              hasDataBoost={true}
            />
          );
        case "INCOME_SUPPORT":
          return (
            <PortfolioInfo
              title="Income Support"
              hasDataBoost={false}
              hasBorder={false}
            />
          );
        default:
          return <p>no data available..</p>;
      }
    });

    return (
      <>
        {fieldDefinition.map((elem, idx) => (
          <div key={idx}>{elem}</div>
        ))}
      </>
    );
  };

  return (
    <StyledRow>
      <Col xs={24} className="border-0">
        <Text className="card-header d-block w-100 px-3 py-2">PORTFOLIO</Text>
        {loading ? (
          <div className="w-100 justify-self-center">
            <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
          </div>
        ) : (
          <>
            {data ? (
              <ExtractAllowedFields userLevel={account?.level || 0} />
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Col>
    </StyledRow>
  );
};
export default withRouter(Portfolio);
