import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography } from "antd";

import Spinner from "components/PageSpinner";
import LockReitmodal from "components/LockReitmodal";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_DEBT_FIELDS } from "apollo/fields/reits";
import { KEY_DEBT_FRAME } from "utils/reitUtils";
import { useUserContextValue } from "contexts/UserContext";
import { HistoricalChart } from "components/Charts";
import { truncateDecimals } from "utils/numberUtils";

const labels: Array<{
  title: string;
  key: string;
  meta?: string;
  metaNL?: boolean;
  prodKey: string;
  unit?: "Decimal" | "Percent" | "Currency";
}> = [
  {
    title: "Borrowings",
    key: "borrowings",
    meta: "Gearing (excl.Perpetuals)) (%)",
    metaNL: true,
    prodKey: "BORROWINGS",
    unit: "Percent",
  },
  {
    title: "Total Borrowings",
    key: "totalBorrowings",
    meta: "Gearing (incl.Perpetuals)) (%)",
    metaNL: true,
    prodKey: "TOTAL_BORROWINGS",
    unit: "Percent",
  },
  {
    title: "Loan Interest Rates",
    key: "loanInterestRates",
    meta: "Interest Cost",
    metaNL: true,
    prodKey: "LOAN_INTEREST_RATES",
    unit: "Percent",
  },
  {
    title: "Loan Duration",
    key: "loanDuration",
    meta: "(Weighted Average Debt Maturity (WADM)) (Years)",
    metaNL: true,
    prodKey: "LOAN_DURATION",
    unit: "Decimal",
  },
  {
    title: "Interest Cover",
    key: "NPIInterestCover",
    prodKey: "INTEREST_COVER",
    unit: "Decimal",
  },
];

interface ICommonFields {
  value: number;
  chart: Array<{
    value: number;
    label: string;
  }>;
}
interface IDebt {
  borrowings: ICommonFields;
  totalBorrowings: ICommonFields;
  loanInterestRates: ICommonFields;
  loanDuration: ICommonFields;
  NPIInterestCover: ICommonFields;
}

interface IChartDetails {
  planLevel: number;
  value: number;
  chartLabel: string;
  chartData: ICommonFields["chart"];
  unit?: "Decimal" | "Percent" | "Currency";
  meta?: string;
}

const { Text } = Typography;
const DebtFrame: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let debtData: Array<IDebt> = [];
  const { account } = useUserContextValue();
  const KEY_STAT_QUERY = queryCompBuilder(COMP_DEBT_FIELDS, history);
  const userLevel = account?.level || 0;
  let planLevel = 0;

  const getPlanLevel = (
    planFrame: Array<{ name: string; minimumPlan: number }>,
    labelKey: string
  ) => {
    return (
      KEY_DEBT_FRAME.find((prod) => prod.name === labelKey)?.minimumPlan || 0
    );
  };

  const { data, loading } = useQuery(KEY_STAT_QUERY);

  if (data) {
    for (let items in data) {
      debtData = [
        ...debtData,
        {
          ...data[items].debt,
        },
      ];
    }
  }

  const ChartDetails: React.FC<IChartDetails> = ({
    chartData,
    chartLabel,
    meta,
    unit,
    value,
    planLevel,
  }) => {
    return (
      <div className="d-flex align-items-end justify-content-end">
        {userLevel < planLevel ? (
          <LockReitmodal isDataBoostFeature={true} minimumPlan={userLevel} />
        ) : (
          <>
            {chartData.length >= 1 ? (
              <>
                <HistoricalChart
                  dataSource={chartData}
                  unit={unit ? unit : "Decimal"}
                  chartLabel={chartLabel}
                  chartMeta={meta}
                  value={value}
                  valueSmallFont={true}
                />
              </>
            ) : (
              <>
                <div className="d-block w-100 h-100 pt-4">
                  {unit === "Decimal" ? "" : ""}
                  {value ? (
                    <>
                      {unit === "Decimal" ? (
                        <>{`${truncateDecimals(value, 2)}`}</>
                      ) : (
                        <>{`${(value * 100).toFixed(2)}%`}</>
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Text className="frame-subheader p-2 pl-3" strong>
        Debt
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : (
          <>
            {data ? (
              <>
                {labels.map((label, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        <Text
                          className="fs-14"
                          disabled={
                            userLevel <
                            getPlanLevel(KEY_DEBT_FRAME, label.prodKey)
                          }
                        >
                          {label.title}
                          {label.metaNL ? <br /> : " "}
                          {label.meta ? label.meta : ""}
                        </Text>
                      </Col>
                      {debtData.map((reit, idx) => {
                        switch (label.key) {
                          case "borrowings":
                          case "totalBorrowings":
                          case "loanInterestRates":
                          case "loanDuration":
                          case "NPIInterestCover":
                            planLevel =
                              KEY_DEBT_FRAME.find(
                                (prod) => prod.name === label.prodKey
                              )?.minimumPlan || 0;

                            const { value: pVal, chart: pChart } = reit[
                              label.key
                            ];
                            return (
                              <Col
                                key={idx}
                                className="text-right fs-14 d-flex align-items-center justify-content-end"
                              >
                                <ChartDetails
                                  planLevel={planLevel}
                                  value={pVal}
                                  chartData={pChart}
                                  unit={label.unit}
                                  chartLabel={label.title}
                                  meta={label.meta}
                                />
                              </Col>
                            );
                          default:
                            return (
                              <Col key={idx} className="text-right fs-14">
                                test
                              </Col>
                            );
                        }
                      })}
                    </Row>
                  );
                })}
              </>
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(React.memo(DebtFrame));
