export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const scrollIntoElement = (id: string) => {
  if (document && window) {
    const expectedElement = document.getElementById(id);
    expectedElement && window.scroll({
      top: findElementPosition(expectedElement)! - 90,
      behavior: 'smooth',
    });
  }
}

const findElementPosition = (obj: any) => {
  var curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    // eslint-disable-next-line no-cond-assign
    } while (obj = obj.offsetParent);
    return curtop;
  }
}