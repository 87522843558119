import gql from "graphql-tag";
import { VIDEO_FIELDS } from "apollo/fields/resources";

import { COMMON_POST_FIELDS } from '../fields/resources';

export const CATEGORY_POSTS = gql`
  query CategoryPosts($categorySlugs: [String!]!) {
    posts(categorySlugs: $categorySlugs) {
      slug
      posts {
        ${COMMON_POST_FIELDS}
      }
    }
  }
`;

export const SINGLE_POST = gql`
  query SinglePost($postSlug: String!) {
    post(postSlug: $postSlug) {
      id
      title
      media
      slug
      permission
      blocker
      content
    }
  }
`;

export const VIDEOS = gql`
  query videos {
    posts(categorySlugs: "videos") {
      posts {
        ${VIDEO_FIELDS}
      }
    }
  }
`;

export const VIDEOS_SINGLE = gql`
  query videosSingle($postSlug: String!) {
    post(postSlug: $postSlug) {
      ${VIDEO_FIELDS}
    }
  }
`;
