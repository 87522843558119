import React from "react";
import { Typography, Row, Col, List } from "antd";
import styled from "styled-components";
import { useQuery } from "react-apollo";

import { Spinner } from "components/PageSpinner";
import { ANONYMOUS_USER_INDEX } from "utils/appUtils";
import { PurpleGift } from "utils/iconUtils";
import { useUserContextValue } from "contexts/UserContext";
import { CATEGORY_POSTS } from "apollo/queries/resources";

const { Text, Paragraph } = Typography;

interface IBookPosts {
  posts: Array<{
    id: number;
    title: string;
    media: string;
    excerpt: string;
    free: boolean;
    buyURLs: string[];
  }>;
}

const StyeListOverride = styled.div`
  .book-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 384px;
    min-height: 433px;
    overflow: hidden;
    background: rgba(154, 145, 255, 0.06);
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      background-image: url("/assets/images/ellipses.png");
      width: 186px;
      height: 91px;
      top: 0;
      margin-top: -13px;
      left: 0;
      margin-left: 23px;
    }

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      background-image: url("/assets/images/ellipses.png");
      width: 186px;
      height: 91px;
      right: 0;
      margin-right: -4px;
      margin-bottom: -4px;
      bottom: 0;
    }
  }
  .prod-img {
    display: block;
    width: 100%;
    max-width: 228px;
    height: 100%;
    max-height: 334px;
    z-index: 1;
  }
  .prod-desc {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 78px;
    margin-bottom: 84px;
    width: 100%;
    max-width: 204px;
    height: 100%;
    max-height: 66px;
  }
  .ant-btn {
    background-color: #fec669;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    &:hover {
      color: unset;
    }
  }
  .ant-list {
    .ant-list-items {
      display: flex;
    }
  }
`;

const ResourceList = () => {
  const { account } = useUserContextValue();
  let bookList: IBookPosts = {
    posts: [],
  };
  const { data, loading } = useQuery(CATEGORY_POSTS, {
    variables: {
      categorySlugs: ["books"],
    }
  });

  if (data) {
    bookList = data.posts[0];
  }

  return (
    <div className="max-width-1200 mx-auto">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {data ? (
            <>
              {bookList.posts.map((book, idx) => (
                <StyeListOverride className="mt-5" key={book.id}>
                  <Row className="py-2 justify-content-md-start mb-5">
                    <Col
                      xs={24}
                      md={8}
                      className="d-flex justify-content-center mb-1 mb-md-0"
                    >
                      <div className="book-container d-flex justify-content-center align-items-center">
                        <img
                          src={
                            book.media
                              ? book.media
                              : "https://via.placeholder.com/408x497"
                          }
                          className="prod-img"
                          alt="reit images"
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={15} className="p-md-2">
                      <h2 style={{ fontSize: "38px" }}>{book.title}</h2>
                      {book.free && (
                        <Text className="d-block w-100 mb-4 d-flex align-items-center">
                          <PurpleGift />
                          <div>
                            <Text strong className="ml-2 ">
                              FREE
                            </Text>{" "}
                            {account &&
                            account?.level > ANONYMOUS_USER_INDEX ? (
                              ""
                            ) : (
                              <Text>if you register!</Text>
                            )}
                          </div>
                        </Text>
                      )}
                      <Text type="secondary" className="fs-16 d-block mb-3">
                        By Tam Ging Wien
                      </Text>

                      <Paragraph type="secondary" className="fs-18 pr-md-3">
                        <div
                          className="fs-18 mb-0"
                          dangerouslySetInnerHTML={{
                            __html: book.excerpt,
                          }}
                        />
                      </Paragraph>

                      {book.free ? (
                        <>
                          {account && account.level > ANONYMOUS_USER_INDEX ? (
                            <></>
                          ) : (
                            <>
                              <a
                                href="/signup"
                                className="mr-4"
                                style={{ textDecoration: "underline" }}
                              >
                                <Text strong style={{ color: "#675BF5" }}>
                                  Register and download the eBook
                                </Text>
                              </a>
                            </>
                          )}
                          {account && account.level > ANONYMOUS_USER_INDEX && (
                            <Text
                              className="d-block w-100 font-italic fs-14 mt-4"
                              type="secondary"
                            >
                              *A copy of this eBook has already been sent to
                              your email. Please check your inbox.
                            </Text>
                          )}
                        </>
                      ) : (
                        <List
                          bordered={false}
                          dataSource={book.buyURLs}
                          renderItem={(item) => (
                            <List.Item className="border-0 p-0 pt-1">
                              <div
                                className="fs-18"
                                dangerouslySetInnerHTML={{
                                  __html: item,
                                }}
                              />
                            </List.Item>
                          )}
                        />
                      )}
                    </Col>
                  </Row>
                </StyeListOverride>
              ))}
            </>
          ) : (
            <div className="my-5">No available books yet.</div>
          )}
        </>
      )}
    </div>
  );
};

export default ResourceList;
