/* eslint-disable no-sequences */
const helpScountInit = () => {
  (function(e: any, t: any, n: any) {
    function a() {
      let e = t.getElementsByTagName('script')[0],
        n = t.createElement('script');
      n.type = 'text/javascript';
      n.async = !0;
      n.src = 'https://beacon-v2.helpscout.net';
      e.parentNode.insertBefore(n, e);
    }
    if (e.Beacon = n = function(t: any, n: any, a: any) {
        e.Beacon.readyQueue.push({
          method: t,
          options: n,
          data: a
        })
      }, n.readyQueue = [], 'complete' === t.readyState) return a();
    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)
  })(window, document, (window as any).Beacon || function() {});
  (window as any).Beacon('init', '5632a16f-6413-41be-b98d-7ca21e7fe27b');
}

export default () => {
  helpScountInit();
}

