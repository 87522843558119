import React from "react";
import { Row, Col, Typography, Card } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { TVideoList } from "pages/Videos/components/Main/Main";

interface ICategoryVideos {
  videoPosts: TVideoList;
}

interface IVideoGroups {
  [key: string]: {
    videos: TVideoList;
  };
}

const StyleWrapper = styled.div`
  .prod-img {
    width: 100%;
    max-width: 639px;
    height: 100%;
    max-height: 340px;
    object-fit: cover;
  }
`;
const { Text } = Typography;

const currentCategories: { [key: string]: string } = {
  "REITScreener TV Specials": "specials",
  "Market Briefs": "marketBriefs",
  "Recorded Webinars": "webinars",
  Training: "training",
};

const CategorySection: React.FC<ICategoryVideos> = ({ videoPosts }) => {
  
  const vidGroups: IVideoGroups = {};

  videoPosts.forEach((video) => {
    if (vidGroups.hasOwnProperty(video.tag)) {
      const videoBracket = vidGroups[video.tag].videos;
      videoBracket.push(video);
    } else {
      vidGroups[video.tag] = { videos: [video] };
    }
  });
  return (
    <StyleWrapper className="pb-4 max-width-1200 mx-auto">
      {Object.keys(vidGroups).map((category: string, idx) => (
        <div key={idx}>
          <div className="d-flex justify-content-between">
            <h4>{category}</h4>
            {vidGroups[category].videos.length > 3 && (
              <Link
                to={`/reitscreener-tv/section/${currentCategories[category]}`}
                style={{ textDecoration: "underline" }}
              >
                View all
              </Link>
            )}
          </div>
          <Row className="mb-4 justify-content-md-between">
            {vidGroups[category].videos
              .sort((a, b) => {
                return new Date(a.videoDate) < new Date(b.videoDate) ? 1 : -1;
              })
              .slice(0, 3)
              .map((vid) => (
                <Col
                  xs={24}
                  className="py-3 mb-3 flex-fill w-100"
                  key={vid.id}
                  style={{ maxWidth: "384px" }}
                >
                  <Card>
                    <Link to={`/reitscreener-tv/${vid.slug}`}>
                      <div className="video-container">
                        <img
                          src={
                            vid.media
                              ? vid.media
                              : "https://via.placeholder.com/639x340"
                          }
                          className="prod-img"
                          alt="reit videos"
                        />
                      </div>
                    </Link>
                    <div className="p-3">
                      <Text type="secondary" className="d-block fs-14" strong>
                        {vid.videoDate}
                      </Text>
                      <Text className="d-block fs-20" strong>
                        {vid.title.length > 34
                          ? vid.title.substr(0, 32) + ".."
                          : vid.title}
                      </Text>
                    </div>
                  </Card>
                </Col>
              ))}
            {vidGroups[category].videos.length < 3 && (
              <Col xs={24} md={7} className="py-3 mb-3" />
            )}
          </Row>
        </div>
      ))}
    </StyleWrapper>
  );
};

export default CategorySection;
