import gql from 'graphql-tag';

import { SCREENER_FILTER_FIELDS } from 'apollo/fields/screener';
import { SCREENER_FIELDS } from '../fields/screener';

export const SCREENER = gql`
  query Screener($input: ScreenerInput!) {
    screener(input: $input) {
      count
      rows {
        ${SCREENER_FIELDS}
      }
    }
  }
`;

export const REIT_LISTING = gql`
   {
     screener(
       input: {
         exchange: [SGX, HKEX, MYX, ASX, NYSE]
         sector: [
           HEALTHCARE
           HOSPITALITY
           INDUSTRIAL
           RETAIL
           COMMERCIAL
           DATA_CENTRES
           SPECIALTY
           RESIDENTIAL
           GAMING
           DIVERSIFIED
         ]
       }
     ) {
       count
       rows {
         reitName
         stockCode
         exchange
       }
     }
   }
 `;

export const SCREENER_MIN_MAX = gql`
  query ScreenerMinMax($exchange: [REITExchange]!, $sector: [ReitSector]!) {
    screenerMinMax(exchange: $exchange, sector: $sector) {
      marketCap {
        min
        max
      }
      dividends {
        min
        max
      }
      priceToBookRatio {
        min
        max
      }
      borrowings{
        min
        max
      }
      totalBorrowings {
        min
        max
      }
      volatility{
        min
        max
      }
      NPIInterestCover {
        min
        max
      }
      loanInterestRates {
        min
        max
      }
      propertyYield {
        min
        max
      }
      rentGrowth {
        min
        max
      }
      threeYrDPUGrowthRate {
        min
        max
      }
      fiveYrDPUGrowthRate {
        min
        max
      }
      sevenYrDPUGrowthRate{
        min
        max
      }
      threeYrNAVGrowthRate{
        min
        max
      }
      fiveYrNAVGrowthRate{
        min
        max
      }
      sevenYrNAVGrowthRate{
        min
        max
      }
      leaseDuration {
        min
        max
      }
      loanDuration {
        min
        max
      }
      occupancyRate {
        min
        max
      }
      incomeSupport {
        min
        max
      }
    }
  }
`;

export const SCREENER_FILTER = gql`
  query ScreenerFilter($input: ScreenerInput!) {
    screenerFilter(input: $input) {
      marketCap {
        ${SCREENER_FILTER_FIELDS}
      }
      dividends {
        ${SCREENER_FILTER_FIELDS}
      }
      priceToBookRatio {
        ${SCREENER_FILTER_FIELDS}
      }
      borrowings{
        ${SCREENER_FILTER_FIELDS}
      }
      totalBorrowings {
        ${SCREENER_FILTER_FIELDS}
      }
      volatility{
        ${SCREENER_FILTER_FIELDS}
      }
      NPIInterestCover {
        ${SCREENER_FILTER_FIELDS}
      }
      loanInterestRates {
        ${SCREENER_FILTER_FIELDS}
      }
      propertyYield {
        ${SCREENER_FILTER_FIELDS}
      }
      rentGrowth {
        ${SCREENER_FILTER_FIELDS}
      }
      threeYrDPUGrowthRate {
        ${SCREENER_FILTER_FIELDS}
      }
      fiveYrDPUGrowthRate {
        ${SCREENER_FILTER_FIELDS}
      }
      sevenYrDPUGrowthRate{
        ${SCREENER_FILTER_FIELDS}
      }
      threeYrNAVGrowthRate{
        ${SCREENER_FILTER_FIELDS}
      }
      fiveYrNAVGrowthRate{
        ${SCREENER_FILTER_FIELDS}
      }
      sevenYrNAVGrowthRate{
        ${SCREENER_FILTER_FIELDS}
      }
      leaseDuration {
        ${SCREENER_FILTER_FIELDS}
      }
      loanDuration {
        ${SCREENER_FILTER_FIELDS}
      }
      occupancyRate {
        ${SCREENER_FILTER_FIELDS}
      }
      incomeSupport {
        ${SCREENER_FILTER_FIELDS}
      }
    }
  }
`;

export const CUSTOM_STRATEGIES = gql`
  query StrategyFilter {
    strategyFilter {
      strategyFilterId
      name
      filter
      advanced
      order
    }
  }
`;

export const SCREENER_WATCHLIST = gql`
  query ScreenerWatchlist {
    screenerWatchlist {
      ${SCREENER_FIELDS}
    }
  }
`;