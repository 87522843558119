import { REGISTERED_USER_INDEX, PREMIUM_USER_INDEX, ANONYMOUS_USER_INDEX } from 'utils/appUtils';

export type TFilterOption = {
  label: string,
  value: string,
  minimumPlan: number,
}

export const BORROWINGS = [{
  value: 'VERY_LOW',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'LOW',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'AVERAGE',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'HIGH',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'ULTRA_HIGH',
  minimumPlan: ANONYMOUS_USER_INDEX,
}];

export const SIZE = [{
  value: 'MICRO',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'SMALL',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'MEDIUM',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'LARGE',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'MEGA',
  minimumPlan: ANONYMOUS_USER_INDEX,
}];

export const DIVIDENDS_PROPERTY_YIELD = [{
  value: 'DEPRESSED',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'LOW',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'AVERAGE',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'HIGH',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'ULTRA',
  minimumPlan: ANONYMOUS_USER_INDEX,
}];

export const PRICE_TO_BOOK = [{
  value: 'BARGAIN',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'GOOD_VALUE',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'REASONABLE',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'EXPENSIVE',
  minimumPlan: ANONYMOUS_USER_INDEX,
}, {
  value: 'OVERPRICED',
  minimumPlan: ANONYMOUS_USER_INDEX,
}];

export const VOLATILITY = [{
  value: 'VERY_LOW',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'LOW',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'NORMAL',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'VOLATILE',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'WILD',
  minimumPlan: REGISTERED_USER_INDEX,
}];

export const INTEREST_COVER = [{
  value: 'VERY_RISKY',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'RISKY',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'MODERATE_RISK',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'SAFE',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'VERY_SAFE',
  minimumPlan: REGISTERED_USER_INDEX,
}];

export const LOAN_INTEREST_OCCUPANCY_RATE = [{
  value: 'VERY_LOW',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'LOW',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'AVERAGE',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'HIGH',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'VERY_HIGH',
  minimumPlan: PREMIUM_USER_INDEX,
}];

export const GROWTHS = [{
  value: 'NEGATIVE',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'NEUTRAL',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'SLOW',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'MODERATE',
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  value: 'HIGH',
  minimumPlan: REGISTERED_USER_INDEX,
}];

export const DURATIONS = [{
  value: 'VERY_SHORT',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'SHORT',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'AVERAGE',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'LONG',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'VERY_LONG',
  minimumPlan: PREMIUM_USER_INDEX,
}];

export const INCOME_SUPPORT = [{
  value: 'YES',
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  value: 'NO',
  minimumPlan: PREMIUM_USER_INDEX,
}];

export const getFilterOptions = (options: { value: string, minimumPlan: number }[]) => {
  return options.map<TFilterOption>(({ value, minimumPlan }) => ({
    minimumPlan,
    value,
    label: value.replace(/_/g, ' '),
  }));
};
