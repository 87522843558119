import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import { Row, Col, Card, Typography } from "antd";

import { SucessText, IntroText } from "components/LoginRegisterCommon";
import SignUpForm from "components/SignUpForm";
import { SEND_MAGIC_LINK } from "apollo/mutations/user";
import { useMessageContextValue } from "contexts/MessageContext";
import { IRegisterFormValues } from "components/SignUpForm/SignUpForm";


const { Text, Title } = Typography;
const LOGO = "/assets/images/logo-light.png";

interface IRegisterStatus {
  success: boolean;
  email: string;
}

interface IMagicCodeData {
  redirectUrl?: string;
}

const StyledWrapper = styled.div`
  .max-width-1200 {
    min-height: 100vh;
    .ant-card {
      width: 100%;
      min-width: 580px;
      max-height: 640px;
    }
  }
`;

const StyledFormWrapper = styled.div`
  img {
    height: 36px;
    margin-bottom: 40px;
  }
`;

const SignUp: React.FC<RouteComponentProps> = ({ location }) => {
  const queryParams: IMagicCodeData = queryString.parse(location.search);
  const [reigsterStatus, setLoginStatus] = useState<IRegisterStatus>({
    success: false,
    email: "",
  });
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const { alertError } = useMessageContextValue();

  const handleLoginSubmit = async (
    values: IRegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await sendMagicLink({
        variables: {
          input: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            ...(queryParams.redirectUrl && {
              redirectUrl: queryParams.redirectUrl,
            }),
          },
        },
      });
      setSubmitting(false);
      setLoginStatus({ success: true, email: values.email });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
      setSubmitting(false);
      setLoginStatus({ success: false, email: values.email });
    }
  };

  return (
    <StyledWrapper> 
      <Row
        gutter={120}
        className="max-width-1200 mx-auto d-flex align-items-center"
      >
        <Col xs={0} md={12} className="pl-0">
          <IntroText />
        </Col>
        <Col
          xs={24}
          md={12}
          className="d-flex justify-content-center justify-content-md-end pr-0"
        >
          <Card
            className="d-flex flex-column justify-content-center text-center custom-shadow"
            bodyStyle={{
              padding: "80px 65px",
            }}
          >
            {reigsterStatus.success ? (
              <SucessText email={reigsterStatus.email} />
            ) : (
              <StyledFormWrapper>
                <Link to="/">
                  <img className="mx-auto" src={LOGO} alt="REITs Screener" />
                </Link>
                <Title level={2} className="mb-3 text-center">
                  Create an account
                </Title>
                <Text className="mb-4 d-block text-center fs-14">
                  Already have an account? <Link to="/login">Login</Link>
                </Text>
                <SignUpForm onSubmit={handleLoginSubmit} />
              </StyledFormWrapper>
            )}
          </Card>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default withRouter(SignUp);
