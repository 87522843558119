import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Card } from "antd";
import styled from "styled-components";

import { TVideoList } from "pages/Videos/components/Main/Main";

const StyleWrapper = styled.div`
  .prod-img {
    width: 100%;
    max-width: 639px;
    height: 100%;
    max-height: 340px;
    object-fit: cover;
  }
`;
const { Text } = Typography;

const LatestSection: React.FC<{ videoPosts: TVideoList }> = ({
  videoPosts,
}) => {
  const latestVideos = videoPosts
    .slice(0)
    .sort((a, b) => {
      return new Date(a.videoDate) < new Date(b.videoDate) ? 1 : -1;
    })
    .slice(0, 6);
  return (
    <StyleWrapper className="pt-5 pb-4  max-width-1200 mx-auto">
      <div className="d-flex justify-content-between">
        <h4>Latest Videos</h4>
        <Link
          to="/reitscreener-tv/section/latest"
          style={{ textDecoration: "underline" }}
        >
          View all
        </Link>
      </div>
      <Row className="justify-content-md-between mb-4">
        {latestVideos.map((video) => (
          <Col
            key={video.id}
            xs={24}
            className="mb-3 w-100"
            style={{ maxWidth: "384px" }}
          >
            <Card>
              <Link to={`/reitscreener-tv/${video.slug}`}>
                <div className="video-container">
                  <img
                    src={
                      video.media
                        ? video.media
                        : "https://via.placeholder.com/639x399"
                    }
                    className="d-block prod-img w-100"
                    alt="reit videos"
                  />
                </div>
              </Link>
              <div className="p-3 h-100">
                <Text type="secondary" className="d-block fs-14" strong>
                  {video.videoDate}
                </Text>
                <Text className="d-block fs-20" strong>
                  {video.title.length > 34
                    ? video.title.substr(0, 32) + ".."
                    : video.title}
                </Text>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </StyleWrapper>
  );
};

export default LatestSection;
