import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  top: 0 !important;
  // max-height: calc(100vh - 10%);
  // overflow: hidden;
  .ant-modal-header {
    padding: 32px 40px 40px;
    border: 0;
    text-align: center;
    .ant-modal-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
    }
  }
  .ant-modal-close {
    right: 40px;
    top: 40px;
    .ant-modal-close-x {
      height: 16px;
      width: 16px;
      line-height: 16px;
    }
  }
  .ant-modal-body {
    padding: 0 16px 32px 40px;
    .scrollable-area {
      padding-right: 24px;
      height: 70vh;
      overflow-y: scroll;
      .ant-typography {
        display: block;
      }
    }
  }
`;

export const StyledUL = styled.ul`
  margin-bottom: 32px;
  li {
    margin-bottom: 16px;
  }
`;