import React from "react";
import { Slider, Typography } from "antd";
import styled from "styled-components";

import { getCurrency } from "utils/reitUtils";

interface IDataSliderProps {
  exchange?: string;
  value: number;
  maxValue: number;
  step?: number;
  isDividend?: boolean;
}

// const currencies: { [key: string]: string } = {
//   Singapore: "S$",
//   "Hong Kong": "H$",
//   Malaysia: "M$",
//   Australia: "A$",
//   "United States": "US$",
// };

const StyleSlider = styled(Slider)`
  cursor: unset !important;
  padding: 0 !important;
  width: 120px;
  margin: 0 !important;
  .ant-slider-step,
  .ant-slider-track {
    margin-top: 2px;
    background: #675bf5;
  }
  .ant-slider-handle.ant-tooltip-open {
    margin-top: 2px;
    cursor: unset;
    opacity: 0;   
  }
`;

const { Text } = Typography;

const DataSlider: React.FC<IDataSliderProps> = ({
  exchange,
  value,
  maxValue,
  step,
  isDividend = false,
}) => (

  <StyleSlider
    value={value}
    max={maxValue}
    step={step || 0.01}
    tipFormatter={(value) => (
      
      <Text className="text-white">        
        {exchange && getCurrency(exchange)}
        {isDividend ? `${value}%` : value}
      </Text>
    )}
    tooltipVisible
    className="align-self-end"
    getTooltipPopupContainer={() =>
      document.getElementById("slider-tooltip") || document.body
    }
  />
);

export default DataSlider;
