import React, { useState } from 'react';
import { Button, Checkbox, Row, Col, Typography } from 'antd';

import { SECTORS, TSector } from 'utils/appUtils';

const { Text } = Typography;

interface ISectorModal {
  selectedSectors: TSector[],
  setSelectedSectors: (sectors: TSector[]) => void,
  setModalState: (modalState: boolean) => void,
  selectedMarkets: string[],
}

const SectorModalContent: React.FC<ISectorModal> = ({ selectedSectors, setSelectedSectors, setModalState, selectedMarkets }) => {
  const [sectors, setSectors] = useState(selectedSectors);
  const enabledSectors = SECTORS.filter(({ markets }) => {
    const intersections = markets.filter(m => selectedMarkets.includes(m));
    return Boolean(intersections.length);
  });

  const checkboxAction = (sector: TSector, nextCheckboxState: boolean) => {
    if (nextCheckboxState) {
      setSectors([...sectors, sector])
    } else {
      const newSetOfSectors = [...sectors].filter(({ value }) => value !== sector.value);
      setSectors(newSetOfSectors);
    }
  }

  const selectAllCheckboxAction = (nextCheckboxState: boolean) => {
    nextCheckboxState ? setSectors(enabledSectors) : setSectors([]);
  }

  return (
    <>
      <div className="mb-3 pb-3 check-all">
        <Checkbox
          checked={sectors.length === enabledSectors.length && enabledSectors.length > 0}
          onChange={() => selectAllCheckboxAction(!(sectors.length === enabledSectors.length))}
        >
          Select all
        </Checkbox>
      </div>
      <Row className="mb-4" gutter={[24, 8]}>
        {SECTORS.map((sector) => {
          const { value, markets, label } = sector;
          const isSelected = sectors.find((s) => s.value === value);
          const intersections = markets.filter(m => selectedMarkets.includes(m));
          const lockFilter = !Boolean(intersections.length);
          return (
            <Col key={value} span={12} className="d-flex justify-content-between">
              <Checkbox
                disabled={lockFilter}
                checked={Boolean(isSelected)}
                className="text-capitalize"
                {...(!lockFilter && {
                  onChange: () => checkboxAction(sector, !Boolean(isSelected))
                })}
              >
                {label}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
      {!Boolean(sectors.length) && (
        <Text className="text-danger mb-4 d-block">You must select at least 1 sector</Text>
      )}
      <div className="d-flex justify-content-end">
        <Button size="small" className="mr-3" onClick={() => setModalState(false)}>Cancel</Button>
        <Button
          size="small"
          type="primary"
          disabled={!Boolean(sectors.length)}
          onClick={() => {
            setSelectedSectors(sectors);
            setModalState(false);
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default SectorModalContent;
