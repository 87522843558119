import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-apollo";
import { Typography, Button } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Spinner } from "components/PageSpinner";

import { REIT_PROFILE_INFO } from "apollo/queries/reit";
import { PREMIUM_USER_INDEX } from "utils/appUtils";
import { useUserContextValue } from "contexts/UserContext";
import LockReitModal from "components/LockReitmodal";
import { isDev } from "config/env";

const { Text, Paragraph } = Typography;

const Profile: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
}) => {
  let reitInfo: { name: string; about: string; website: string } = {
    name: "",
    about: "",
    website: "",
  };
  const { data, loading } = useQuery(REIT_PROFILE_INFO, {
    variables: { stockCode: match.params.stockCode },
  });
  const { account } = useUserContextValue();

  if (data) {
    const { profile } = data.screenerReit;
    reitInfo = Object.assign({}, profile);
  }

  return (
    <>
      <Text className="card-header d-block w-100 px-3 py-2">PROFILE</Text>
      {loading ? (
        <div className="w-100 justify-self-center">
          <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
        </div>
      ) : (
        <>
          {data ? (
            <>
              <Paragraph className="px-3 pt-3 my-2">{reitInfo.about}</Paragraph>
              <div className="px-3 pb-2">
                <a target="_new" href={reitInfo.website}>
                  {reitInfo.website}
                </a>
              </div>
              {isDev && (
                <div className="px-3 pb-2">
                  {(account?.level || 0) < PREMIUM_USER_INDEX ? (
                    <LockReitModal
                      wrapChildren={true}
                      minimumPlan={account?.level || 0}
                    >
                      <Button type="link" className="p-0">
                        <FilePdfOutlined />
                        Financial Reports
                      </Button>
                    </LockReitModal>
                  ) : (
                    <Link
                      to={{
                        pathname: `/screener/table/reits/${match.params.stockCode}/financialReports`,
                        state: { reitName: reitInfo.name },
                      }}
                      className="d-block w-100 my-3"
                    >
                      <FilePdfOutlined /> Financial Reports
                    </Link>
                  )}
                </div>
              )}
            </>
          ) : (
            <p className="p-4">no data available</p>
          )}
        </>
      )}
    </>
  );
};
export default withRouter(Profile);
