import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Typography, Card, Button } from 'antd';

const { Title, Text } = Typography;

const StyledCTAWrapper = styled.div`
  &.bg-primary-dark {
    padding: 64px 0 175px 0;
    margin-bottom: 190px;
    .max-width-1200 {
      .ant-card {
        z-index: 1;
        top: 60px;
        right: 0;
        max-width: 650px;
        .ant-card-body {
          padding: 40px 48px;
        }
      }
    }
  }
`;

const CallToAction = () => (
  <StyledCTAWrapper className="bg-primary-dark">
    <div className="max-width-1200 mx-auto">
      <Row>
        <Col xs={24} sm={24} md={10} lg={10}>
          <Title className="text-white" level={2}>How to get started</Title>
          <Text className="subheading text-white">There are some things you should consider to make informed and profitable investment decisions.</Text>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14}>
          <Card className="bg-primary border-0 rounded-0 position-absolute">
            <Title className="text-white mb-4" level={2}>REITScreener gives you actionable market insights!</Title>
            <div className="card-text-wrapper mb-4">
              <Text className="subheading text-white">View a snapshot of the entire market, including trends, in a single interface for investing into strength. Read the latest relevant news to educate your decisions.</Text>
            </div>
            <Link to="/screener/table">
              <Button className="golden-btn custom-shadow" size="large">Start using the Screener</Button>
            </Link>
          </Card>
        </Col>
      </Row>
    </div>
  </StyledCTAWrapper>
);

export default CallToAction;
