export interface IAnswer {
  label: string,
  multiple?: boolean,
  answers?: IAnswer[],
}

export interface IQuizQuestion {
  question: string,
  answers: IAnswer[],
}

export const QUIZ_QUESTIONS: IQuizQuestion[] = [
  {
    question: 'I would describe my knowledge of REITs as...',
    answers: [
      {
        label: 'None – I don’t know what REITs are',
      },
      {
        label: 'Limited – I heard about REITs and want to find out more',
      },
      {
        label: 'Competent – I understand the concept of REITs and own some for dividends',
      },
      {
        label: 'Proficient – I own a diverse portfolio of REITs and paying out good dividends',
      },
      {
        label: 'Expert – I have extensive knowledge and many years of investing profitably in REITs',
      },
    ],
  },
  {
    question: 'What is your primary investment objective?',
    answers: [
      {
        label: 'Preserve my capital, I cannot afford to lose',
      },
      {
        label: 'Receive modest and predictable income, growth is not important',
      },
      {
        label: 'Receive high yielding income at the expense of growth',
      },
      {
        label: 'Receive modest income while growing over the long term',
      },
      {
        label: 'I want fast growth and profits',
      },
    ],
  },
  {
    question: 'What is your age?',
    answers: [
      {
        label: 'Over 55 years old',
      },
      {
        label: 'Between 45 to 55 years old',
      },
      {
        label: 'Between 35 to 44 years old',
      },
      {
        label: 'Between 26 to 35 years old',
      },
      {
        label: '25 years old and below',
      },
    ],
  },
  {
    question: 'How long do you plan to stay invested; how soon would you need your capital?',
    answers: [
      {
        label: '3 to 6 months',
      },
      {
        label: '6 to 12 months',
      },
      {
        label: '1 to 3 years',
      },
      {
        label: '3 to 5 years',
      },
      {
        label: 'More than 5 years',
      },
    ],
  },
  {
    question: 'What is your expected investment returns per year?',
    answers: [
      {
        label: '0.0% to 2.5% per year',
      },
      {
        label: '2.5% to 5.0% per year',
      },
      {
        label: '5.0% to 7.5% per year',
      },
      {
        label: '7.5% to 10.0% per year',
      },
      {
        label: '>= 10.0% per year',
      },
    ],
  },
  {
    question: 'Which best describes your attitudes toward risk?',
    answers: [
      {
        label: 'I cannot afford to lose at all',
      },
      {
        label: 'I can tolerate small losses (3% to 5% a year) if income is stable',
      },
      {
        label: 'I can tolerate medium losses (5% to 10% a year) if I still receive income',
      },
      {
        label: 'I am willing accept large losses (10% to 20% a year) if I can make large gains',
      },
      {
        label: 'I have a high tolerance for risk, volatility and can afford more than >20% losses if I can make outsized returns',
      },
    ],
  },
  {
    question: 'How often do you plan to review your REIT portfolio?',
    answers: [
      {
        label: 'Seldom, I want to focus on other things',
      },
      {
        label: 'Once a year',
      },
      {
        label: 'Twice a year, just to stay up to date',
      },
      {
        label: 'Once a quarter',
      },
      {
        label: 'Weekly or monthly, I want to keep track of my portfolio',
      },
    ],
  },
  {
    question: 'Please share with us the types of investments you already own...',
    answers: [
      {
        label: 'I don’t own any investments yet',
      },
      {
        label: 'I already own some investments (check all that apply):',
        multiple: true,
        answers: [
          {
            label: 'Unit trust and mutual funds',
          },
          {
            label: 'Exchange Trades Funds (ETFs)',
          },
          {
            label: 'Physical Real Estate',
          },
          {
            label: 'Precious Metals (e.g. Gold and Silver)',
          },
          {
            label: 'Bonds',
          },
          {
            label: 'Stocks and Shares',
          },
        ],
      },
    ],
  },
  {
    question: 'Which country are you most interested to invest in REITs...',
    answers: [
      {
        label: 'United States (U.S.)',
      },
      {
        label: 'Singapore',
      },
      {
        label: 'Hong Kong',
      },
      {
        label: 'Malaysia',
      },
      {
        label: 'Australia',
      },
    ],
  },
];
