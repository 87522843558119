import React, { useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Alert, Typography } from 'antd';

import { Spinner } from 'components/PageSpinner';
import ArticleBlocker from 'components/ArticleBlocker';
import { scrollToTop, scrollIntoElement } from 'utils/windowUtils';
import { SINGLE_POST } from 'apollo/queries/resources';
import { useUserContextValue } from 'contexts/UserContext';
import { useFreeResourcesContextValue } from 'contexts/FreeResourcesContext';
import { useMessageContextValue } from 'contexts/MessageContext';
import { IWPPost } from 'apollo/types/cms';
import { isLoggedIn, getAuthToken } from 'utils/userUtils';
import { ARTICLE_LIST } from 'utils/onboardingUtils';
import { UPDATE_ACCOUNT_CHECKLIST } from 'apollo/mutations/user';
import { ACCOUNT } from 'apollo/queries/user';

const { Title } = Typography;

interface ISingePostResult {
  post: IWPPost,
}

const StyledContentWrapper = styled.div`
  .title-footer {
    border: 4px solid #bfbfbf80;
    width: 80px;
    margin-bottom: 32px;
  }
  .content {
    p, ul {
      font-size: 20px;
    }
    .wp-block-image {
      img {
        width: 100%;
      }
    }
    ul {
      margin-bottom: 32px;
      li {
        margin-bottom: 16px;
      }
    }
    iframe {
      display: block;
      margin: auto;
    }
  }
`;

const WPContent: React.FC<RouteComponentProps<{ post_slug: string }>> = ({ match, location }) => {
  const [updateAccountChecklistMutation] = useMutation(UPDATE_ACCOUNT_CHECKLIST);
  const { alertError } = useMessageContextValue();
  const { account } = useUserContextValue();
  const { freeResources, setFreeResources } = useFreeResourcesContextValue();
  const freeReitsArticle = freeResources.find(({ category }) => category === 'reits')!;

  const { data: postData, loading: postLoading } = useQuery<ISingePostResult | undefined>(SINGLE_POST, {
    variables: {
      postSlug: match.params.post_slug,
    }
  });

  useEffect(() => {
    scrollToTop();
    if (!freeReitsArticle.articleSlug && match.params.post_slug) {
      freeReitsArticle.articleSlug = match.params.post_slug;
      setFreeResources(freeResources);
    }
    // onboarding checklist tracking
    if (!account) return;
    const onboardingArticles = ARTICLE_LIST.map(({ link }) => link);
    const trackedArticles = account?.checklist.articles || [];
    if (onboardingArticles.includes(location.pathname) && !trackedArticles.includes(location.pathname)) {
      trackArticle(location.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.post_slug]);

  useEffect(() => {
    if (location.hash) {
      scrollIntoElement(location.hash.replace(/#/g, ''));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData?.post])

  const trackArticle = async (pathname: string) => {
    try {
      await updateAccountChecklistMutation({
        variables: {
          input: {
            articles: [
              ...(account?.checklist.articles || []),
              pathname,
            ]
          },
        },
        refetchQueries: [{
          query: ACCOUNT,
          variables: { token: getAuthToken() },
        }],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
    }
  }

  const post = postData?.post;
  const enableBlocker = !isLoggedIn() &&
    freeReitsArticle.articleSlug &&
    freeReitsArticle.articleSlug !== match.params.post_slug;
  
  return postLoading ? (
    <Spinner />
  ) : (
    <>
      {post ? (
        <StyledContentWrapper>
          <Title className="mb-4" level={1}>{post.title}</Title>
          <div className="title-footer" />
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: function() {
                if (!isLoggedIn() && post.blocker && enableBlocker) {
                  const { content } = { ...post };
                  const arrayContent = content.trim().split('</p>').filter(v => v);
                  arrayContent.splice(post.blocker);
                  return arrayContent.join('</p>');
                }
                return post.content;
              }()
            }}
          />
          {(!isLoggedIn() && enableBlocker) && <ArticleBlocker />}
        </StyledContentWrapper>
      ) : (
        <Alert
          showIcon
          type="warning"
          message="Article not found."
          description="Could not load article contents at the moment."
        />
      )}
    </>
  );
}

export default withRouter(WPContent);
