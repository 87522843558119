import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Blog from './pages/Blog';
import Books from './pages/Books';
import Reits from './pages/Reits';
import Events from './pages/Events';
import FourOhFour from '../FourOhFour';
import { FreeResourcesProvider } from 'contexts/FreeResourcesContext';

export default () => (
  <FreeResourcesProvider>
    <Switch>
      <Route path="/resources/blog" component={Blog} />
      <Route path="/resources/books" component={Books} />
      <Route path="/resources/events" component={Events} />
      <Route
        path="/resources/:category(reits-101|reits-102|other-reit-resources|reits-introduction)/:post_slug?"
        component={Reits}
      />
      <Route component={FourOhFour} />
    </Switch>
  </FreeResourcesProvider>
);
