import React from "react";
import {
  BarChart as BarChartArea,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  TooltipProps,
  LegendPayload,
  ReferenceLine,
  LabelList,
} from "recharts";

import { generateTicks } from "utils/chartUtils";
import { truncateDecimals } from "utils/numberUtils";

export interface IRefLineProps {
  value: number;
  dataKey: string;
  color: string;
  strokeColor: string;
  percentage?: boolean;
  name: string;
}

interface IBarChartProps {
  dataSource: Array<{ value: number; label: string }>;
  deviationValues?: number[];
  refLines?: Array<IRefLineProps>;
  height?: number;
  yTickLabelFormatter?: (value: number) => string;
  xTickLabelFormatter?: (value: string) => string;
  showTooltip?: boolean;
  customFill?: string;
  customStroke?: string;
  chartLabel?: string;
  chartMeta?: string;
  showLegend?: boolean;
  legendPosition?: "top" | "bottom";
  hasYearController?: boolean;
  // legendPayload?: LegendPayload[];
  // legendPayloadAsReferenceLines?: boolean;
  // chartLines?: Array<{ key: string; color: string; yAxisId?: string }>;
}

const BarChart: React.FC<IBarChartProps> = ({
  dataSource,
  height,
  showTooltip = true,
  customFill,
  customStroke,
  chartLabel,
  chartMeta,
  deviationValues,
  showLegend = true,
  yTickLabelFormatter,
  xTickLabelFormatter,
  refLines = [],
}) => {
  let dataPoints: number[] = dataSource.map((data) => data.value);

  let legendPayload: LegendPayload[] = [];

  const CustomToolTip = ({ payload }: TooltipProps) => {
    return (
      <div className="bg-white p-3 shadow">
        {payload?.map((val, idx) => (
          <span key={idx}>
            {val.payload.label}
            <br />
            {val.value}
            <br />
            {val.name}
          </span>
        ))}
      </div>
    );
  };

  if (deviationValues) {
    dataPoints = [...dataPoints, ...deviationValues];
  }

  if (refLines) {
    legendPayload = refLines.map((val) => {
      return {
        value: `${val.name} (${
          val.percentage
            ? `${truncateDecimals(val.value, 2)}%`
            : truncateDecimals(val.value, 2)
        })`,
        id: val.value,
        color: val.color,
        type: "square",
      };
    });
    legendPayload.push({
      value: chartLabel,
      id: 0,
      color: "#675BF5",
      type: "square",
    });
  }

  const pointValues =
    dataPoints.length > 0
      ? [Math.min(...dataPoints), Math.max(...dataPoints)]
      : [];

  const leftYTicks = generateTicks(pointValues);

  //not included
  // const modifyNumerals = (tick: number) => {
  //   let formedValues = "";
  //   let [firstNumeral, secondNumeral] = String(tick).split(".");
  //   if (secondNumeral) {
  //     secondNumeral = secondNumeral.replace(/^0+/, "");
  //     formedValues = `${firstNumeral}.${secondNumeral}`;
  //   }
  //   return Number(formedValues);
  // };
  //not included//not included

  const refinedValues = dataSource
    .map((each) => {
      return {
        value: each.value,
        label: each.label,
      };
    })
    .reverse();

  const defaultAxisLabelStyle = {
    style: {
      textAnchor: "middle",
    },
  };

  // //split long values  min of 4
  const concisedValues = refinedValues.slice(0).map((each) => {
    const splittedVal = String(each.value).split(".");
    const secondNumeral = splittedVal[1] ? splittedVal[1].slice(0, 4) : 0;
    return {
      value: splittedVal[1]
        ? parseFloat(`${splittedVal[0]}.${secondNumeral}`)
        : parseFloat(`${splittedVal[0]}`),
      label: each.label,
    };
  });

  return (
    <ResponsiveContainer width="100%" height={height ? height : "100%"}>
      <BarChartArea
        data={concisedValues}
        margin={{
          top: 40,
          left: 30,
          right: 15,
          bottom: 20,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="label"
          orientation="bottom"
          tickLine={false}
          axisLine={false}
          domain={["dataMin - 100", "dataMax + 100"]}
          tick={({ x, y, payload: { value } }) => {
            return (
              <text x={x - 45} y={y + 20} className="fs-14">
                {xTickLabelFormatter ? xTickLabelFormatter(value) : value}
              </text>
            );
          }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          type="number"
          ticks={leftYTicks}
          tickCount={leftYTicks.length}
          domain={["dataMin", "dataMax"]}
          label={{
            ...defaultAxisLabelStyle,
            offset: 20,
            angle: -90,
            value: chartLabel,
            position: "left",
          }}
          tick={({ x, y, payload: { value } }) => {
            ///different formatter for bar  chart// consider trailing zeros
            return (
              <text x={x - 34} y={y} className="fs-14 px-4">
                {value}
                {/* {value < 1
                  ? truncateDecimals(modifyNumerals(value), 3)
                  : truncateDecimals(value, 2)} */}
              </text>
            );
          }}
        />
        {showTooltip && (
          <Tooltip
            animationDuration={1000}
            cursor={false}
            content={CustomToolTip}
          />
        )}
        <Bar
          name={chartLabel}
          dataKey="value"
          fill={customFill ? customFill : "#E4DDFF"}
          className="w-100"
          fillOpacity={0.8}
        >
          <LabelList
            dataKey="value"
            className="fs-12 mt-5 opacity-0"
            position="top"
            angle={refinedValues.length > 25 ? 310 : 0}
            offset={refinedValues.length > 25 ? 18 : 8}
            // formatter={(value) => {
            //   const modVal = modifyNumerals(Number(value));
            //   return value < 1
            //     ? truncateDecimals(modVal, 3)
            //     : truncateDecimals(Number(value), 2);
            //   return yTickLabelFormatter
            //     ? yTickLabelFormatter(Number(modVal))
            //     : modVal;
            // }}
          />
        </Bar>
        {refLines &&
          refLines.map(({ percentage, strokeColor, value }, idx) => (
            <ReferenceLine
              isFront
              y={value}
              key={idx}
              strokeWidth={2}
              stroke={strokeColor}
            />
          ))}

        {showLegend && refLines && (
          <Legend
            payload={legendPayload.reverse()}
            verticalAlign="bottom"
            align="center"
            iconType="square"
            wrapperStyle={{
              paddingTop: "20px",
            }}
          />
        )}

        {showLegend && refLines.length < 1 && (
          <Legend
            verticalAlign="bottom"
            align="center"
            iconType="square"
            wrapperStyle={{
              paddingTop: "20px",
            }}
          />
        )}
      </BarChartArea>
    </ResponsiveContainer>
  );
};
export default BarChart;
