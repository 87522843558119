import React, { memo } from "react";
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import AppLayout from "components/AppLayout";
import ResourceList from "./components/ResourceList";
import FourOhFour from "pages/FourOhFour";
import CTAUpsell from "components/CTAUpsell";
import CTAUnregistered from "components/CTAUnregistered";
import { isLoggedIn } from "utils/userUtils";

const Books: React.FC<RouteComponentProps> = memo(({ match }) => (
  <AppLayout withFooter={true}>
    <Switch>
      <Route exact path={match.path} component={ResourceList} />
      <Route component={FourOhFour} />
    </Switch>
    {isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}
  </AppLayout>
));

export default withRouter(Books);
