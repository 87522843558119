import React from 'react';
import styled from 'styled-components';
import { Typography, Card, Button } from 'antd';

const { Text } = Typography;
const CHUA_YI_CHENG_ROY = '/assets/images/chua-yi-cheng-roy.png';
const DEAN_GOH = '/assets/images/dean-goh.png';
const JENNIFER_WEE = '/assets/images/jennifer-wee.png';

const FEEDBACKS = [{
  imgSrc: CHUA_YI_CHENG_ROY,
  comment: 'For seasoned investors, much of your data crunching work is removed by the essential metrics being presented to you in organised tables and graphs. New REIT investors will also find this tool a great companion to their learning journey. A newbie like me has never felt so empowered in my investing decisions with REITscreener!',
  name: 'Roy, Chua Yi Cheng',
}, {
  imgSrc: DEAN_GOH,
  comment: 'Its easily customisable view of the software makes investments in REITs a highly personalised experience, suited to your own capital size and risk appetite. It is a fantastic software that easily broadens your perspectives on the various REITs as increased focus and attention can be devoted to its analysis rather than on the extremely cumbersome data entry of the REIT.',
  name: 'Dean Goh',
}, {
  imgSrc: JENNIFER_WEE,
  comment: 'An all-in-one platform to track insights/updates, insider trades, market briefs, quality and valuation scores on REITs. Investing in REITScreener has become one of the best investments and I highly recommend REITScreener to everyone who wants an edge on REITs.',
  name: 'Jennifer Wee',
}];

const StyledFeedbackWrapper = styled.div`
  &.feedback-wrapper {
    background: #F5F5F5;
    padding: 56px 0;
    .feedback-title {
      max-width: 650px;
      margin-bottom: 54px;
    }
  }
`;

const StyledCard = styled(Card)`
  &.feedback-card  {
    flex: 1 1 0px;
    max-width: 340px;
    margin: 0 12px !important;
  }
`;

const Feedbacks = () => (
  <StyledFeedbackWrapper className="feedback-wrapper">
    <div className="feedback-title mx-auto text-center">
      <Text strong className="subheading">Don't take our word for it, listen to what our customers have to say about REITScreener</Text>
    </div>
    <div className="max-width-1200 d-flex mx-auto justify-content-center align-items-start">
      {FEEDBACKS.map(({ imgSrc, comment, name }, idx) => (
        <StyledCard className="feedback-card text-center custom-shadow" key={idx} bodyStyle={{ padding: '32px 24px' }}>
          {/* <img className="mb-4" draggable={false} src={imgSrc} alt={name} /> */}
          <div className="mb-3">
            <Text className="fs-16">{comment}</Text>
          </div>
          <Button className="fs-16" type="link">{name}</Button>
        </StyledCard>
      ))}
    </div>
  </StyledFeedbackWrapper>
)

export default Feedbacks;
