import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid } from 'antd';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

interface IBlogGridDetails {
  first?: boolean
  title: string,
  excerpt: string,
  slug: string,
}

const BlogGridDetails: React.FC<IBlogGridDetails> = ({ first, title, excerpt, slug }) => {
  const { xs, sm, md } = useBreakpoint();
  const TitleElement = ((!xs && first) || (sm && !md)) ? Title : Text 
  return (
    <>
      <div className="flex-grow-1">
        <TitleElement
          level={3}
          strong
        >
          {title}
        </TitleElement>
        <div className="post-excerpt mt-3">
          <Text className="fs-16" type="secondary">
            <div dangerouslySetInnerHTML={{ __html: excerpt }} />
          </Text>
        </div>
      </div>
      <div>
        <Link to={`/resources/blog/${slug}`} className="fs-16 font-weight-normal">Read more</Link>
      </div>
    </>
  );
}

export default BlogGridDetails;
