import React from "react";
import { useQuery } from "react-apollo";
import { Typography, Row, Col } from "antd";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { HistoricalChart } from "components/Charts";
import { REIT_DEBT_INFO } from "apollo/queries/reit";
import { Spinner } from "components/PageSpinner";

import { KEY_DEBT_FRAME } from "utils/reitUtils";
import LockReitModal from "components/LockReitmodal";
import { useUserContextValue } from "contexts/UserContext";
import { ANONYMOUS_USER_INDEX } from "utils/appUtils";
import { truncateDecimals } from "utils/numberUtils";

interface IDataval {
  value: number;
  chart: Array<{
    value: number;
    label: string;
  }>;
}

interface IDebtDetails {
  borrowings: IDataval;
  totalBorrowings: IDataval;
  loanInterestRates: IDataval;
  loanDuration: IDataval;
  NPIInterestCover: IDataval;
}

interface IDebtDisplay {
  chartName: string;
  chartData: IDataval;
  meta?: string;
  minimumPlan?: number;
  unit?: "Percent" | "Decimal";
  borderBottom?: boolean;
}

const StyleOverride = styled.div`
  .chart-click {
    cursor: pointer;
    width: 80px;
    height: 24px;
  }
`;

const StyledRow = styled(Row)`
  .ant-col {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const { Text } = Typography;

const Debt: React.FC<RouteComponentProps<{ stockCode: string }>> = ({
  match,
}) => {
  let debtDetails: IDebtDetails = {
    borrowings: { value: 0, chart: [] },
    totalBorrowings: { value: 0, chart: [] },
    loanInterestRates: { value: 0, chart: [] },
    loanDuration: { value: 0, chart: [] },
    NPIInterestCover: { value: 0, chart: [] },
  };

  const { loading, data } = useQuery(REIT_DEBT_INFO, {
    variables: { stockCode: match.params.stockCode },
  });
  const { account } = useUserContextValue();

  if (data) {
    debtDetails = data.screenerReit.debt;
  }

  const ExtractAllowedFields: React.FC<{ userLevel: number }> = ({
    userLevel,
  }) => {
    const {
      borrowings,
      totalBorrowings,
      loanDuration,
      loanInterestRates,
      NPIInterestCover,
    } = debtDetails;

    const DebtDataInfo: React.FC<IDebtDisplay> = ({
      chartName,
      chartData,
      minimumPlan = 0,
      meta,
      unit,
      borderBottom,
    }) => {
      const isAllowed = userLevel < minimumPlan;
      return (
        <Col
          xs={24}
          className="d-flex justify-content-between align-items-center p-3"
          style={{ borderBottom: borderBottom === false ? "none" : "" }}
        >
          <Text className="fs-16" style={{ flexBasis: "66.99%" }}>
            <Text disabled={isAllowed} className="d-flex">
              {chartName}
            </Text>
            <Text type="secondary" disabled={isAllowed}>
              {meta}
            </Text>
          </Text>

          {isAllowed ? (
            <span className="ml-2">
              <LockReitModal
                isDataBoostFeature={
                  userLevel <= ANONYMOUS_USER_INDEX ? false : true
                }
                minimumPlan={userLevel}
              />
            </span>
          ) : (
            <>
              {chartData.value ? (
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ flexBasis: "31.99%" }}
                >
                  {chartData.chart.length >= 1 ? (
                    <HistoricalChart
                      dataSource={chartData.chart}
                      unit={unit}
                      chartLabel={chartName}
                      chartMeta={meta}
                      value={chartData.value}
                    />
                  ) : (
                    <span className="d-block w-100 text-right">
                      {truncateDecimals(chartData.value, 2)}
                    </span>
                  )}
                </div>
              ) : (
                "-"
              )}
            </>
          )}
        </Col>
      );
    };

    const allowedFields = KEY_DEBT_FRAME.map((field) => {
      switch (field.name) {
        case "BORROWINGS":
          return (
            <DebtDataInfo
              chartData={borrowings}
              chartName="Borrowings"
              unit="Percent"
              meta="Gearing (excl. Perpetuals)) (%)"
              minimumPlan={field.minimumPlan}
            />
          );
        case "TOTAL_BORROWINGS":
          return (
            <DebtDataInfo
              chartData={totalBorrowings}
              chartName="Total Borrowings"
              unit="Percent"
              meta="(Gearing (incl. Perpetuals)) (%)"
              minimumPlan={field.minimumPlan}
            />
          );
        case "LOAN_INTEREST_RATES":
          return (
            <DebtDataInfo
              chartData={loanInterestRates}
              chartName="Loan Interest Rates"
              unit="Percent"
              meta="Interest Cost"
              minimumPlan={field.minimumPlan}
            />
          );
        case "LOAN_DURATION":
          return (
            <DebtDataInfo
              chartData={loanDuration}
              chartName="Loan Duration"
              unit="Decimal"
              meta="  (Weighted Average Debt Maturity (WADM)) (Years)"
              minimumPlan={field.minimumPlan}
            />
          );
        case "INTEREST_COVER":
          return (
            <DebtDataInfo
              chartData={NPIInterestCover}
              chartName="Interest Cover"
              unit="Decimal"
              minimumPlan={field.minimumPlan}
              borderBottom={false}
            />
          );
        default:
          return <p>no data available..</p>;
      }
    });
    return (
      <>
        {allowedFields.map((element, idx) => {
          return <div key={idx}>{element}</div>;
        })}
      </>
    );
  };

  return (
    <StyleOverride>
      <StyledRow>
        <Col xs={24} className="border-0">
          <Text className="card-header d-block w-100 px-3 py-2">DEBT</Text>
          {loading ? (
            <div className="w-100 justify-self-center">
              <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
            </div>
          ) : (
            <>
              {data ? (
                <ExtractAllowedFields userLevel={account?.level || 0} />
              ) : (
                <p className="p-4">no data available</p>
              )}
            </>
          )}
        </Col>
      </StyledRow>
    </StyleOverride>
  );
};
export default withRouter(Debt);
