import React from 'react';
import { withFormik, FormikProps } from 'formik';
import { Form, Input, Button } from 'antd'

import { registerFormSchema } from './validation'

export interface IRegisterFormValues {
  firstName: string,
  lastName: string,
  email: string
}

interface IExternalProps {
  onSubmit: (
    values: IRegisterFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void,
}

const SignUpForm: React.FC<FormikProps<IRegisterFormValues>> = ({
  values, isSubmitting, handleChange, handleBlur, handleSubmit, errors, touched, isValid,
}) => {
  return (
    <Form colon={false} onSubmitCapture={handleSubmit}>
      <Form.Item
        className="mb-2 d-block text-left"
        label="First name"
        {...(touched.firstName &&
          errors.firstName && {
            validateStatus: "error",
            help: errors.firstName,
          })}
      >
        <Input
          size="large"
          placeholder="Maria"
          onKeyDown={(e) => e.stopPropagation()}
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          className="py-2"
        />
      </Form.Item>
      <Form.Item
        className="mb-2 d-block text-left"
        label="Last name"
        {...(touched.lastName &&
          errors.lastName && {
            validateStatus: "error",
            help: errors.lastName,
          })}
      >
        <Input
          size="large"
          placeholder="Hawkins"
          name="lastName"
          onKeyDown={(e) => e.stopPropagation()}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          className="py-2"
        />
      </Form.Item>
      <Form.Item
        className="mb-2 d-block text-left"
        label="Email"
        {...(touched.email &&
          errors.email && {
            validateStatus: "error",
            help: errors.email,
          })}
      >
        <Input
          size="large"
          placeholder="example@domain.com"
          name="email"
          value={values.email}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          className="py-2"
        />
      </Form.Item>
      <Form.Item className="mb-0">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          block
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
          className="mb-2 mt-2"
        >
          Create your account
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withFormik<IExternalProps, IRegisterFormValues>({
  validationSchema: registerFormSchema,
  validateOnMount: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values, formikBag.setSubmitting);
  },
  displayName: 'LoginForm',
})(SignUpForm);
