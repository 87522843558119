import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Typography, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// import PopoverHelper from 'components/PopoverHelper';
import SectorModalContent from './components/SectorModalContent';
import { ModalCloseIcon } from 'utils/iconUtils';
import { setFixedFilterState, TFixedFilterState } from 'redux/slices/filterStateSlice';
import { IReduxState } from 'redux/reducers';

const { Text } = Typography;

const StyledFilterWrapper = styled.div`
  .ant-btn {
    padding: 5px 8px;
    min-height: 32px;
    font-size: 14px;
    color: #595959;
    font-weight: normal;
  }
  .ant-tag {
    font-size: 14px;
    padding: 5px 8px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 362px;
    margin: auto;
    .ant-modal-close {
      padding: 24px 16px;
      .ant-modal-close-x {
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
    }
    .ant-modal-header {
      padding: 16px 16px 0;
      border: 0;
    }
    .ant-modal-body {
      span {
        font-size: 14px;
      }
      .check-all {
        border-bottom: 1px solid #E8E8E8;
      }
      .ant-btn {
        min-height: 34px;
        padding: 6px 14px;
      }
    }
  }
`;

const FilterSector = () => {
  const dispatch = useDispatch();
  const fixedFilterState = useSelector((state: IReduxState) => state.filterState.fixedFilterState);
  const { sectors, countries } = fixedFilterState;

  const [modalState, setModalState] = useState(false);

  const setFixedFilterStateAction = (newFixedFilterState: TFixedFilterState) => {
    dispatch(setFixedFilterState(newFixedFilterState));
  }

  return (
    <StyledFilterWrapper className="d-flex flex-wrap">
      {sectors.map(({ value, label }) => {
        return (
          <Tag
            key={value}
            color="#675BF5"
            className="mb-2 text-capitalize"
            closable={sectors.length > 1}
            onClose={() => {
              setFixedFilterStateAction({
                ...fixedFilterState,
                sectors: sectors.filter((s) => s.value !== value),
              });
            }}
          >
            {label}
          </Tag>
        );
      })}
      <div>
        <Button className="mb-2" onClick={() => setModalState(true)} size="small" type="dashed" icon={<PlusOutlined />}>
          Sector
        </Button>
        <StyledModal
          maskClosable={false}
          visible={modalState}
          onCancel={() => setModalState(false)}
          bodyStyle={{ padding: 16 }}
          closeIcon={<ModalCloseIcon />}
          destroyOnClose
          footer={null}
          title={(
            <div className="fs-18 d-flex align-items-center">
              <Text className="mr-2">Sector</Text>
              {/* <PopoverHelper
                title="Sector"
                content={(
                  <div className="fs-14">
                    <Text className="d-block" type="secondary">
                      Description here
                    </Text>
                  </div>
                )}
              /> */}
            </div>
          )}
        >
          <SectorModalContent
            selectedSectors={sectors}
            setSelectedSectors={(newSetOfSectors) => {
              setFixedFilterStateAction({
                ...fixedFilterState,
                sectors: newSetOfSectors,
              });
            }}
            setModalState={setModalState}
            selectedMarkets={countries.map(({ exchange }) => exchange)}
          />
        </StyledModal>
      </div>
    </StyledFilterWrapper>
  );
}

export default FilterSector;
