import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Typography, Button } from "antd";

import AppLayout from "components/AppLayout";
import Features from "./components/Features";
import Feedbacks from "./components/Feedbacks";
import CallToAction from "components/CallToAction";

const { Title, Text } = Typography;
const ROOT_HERO_IMAGE = "/assets/images/root-hero-image.png";

const StyledHeroWrapper = styled.div`
  &.mx-auto {
    padding: 46px 0 40px;
  }
`;

const StyledImg = styled.img`
  &.float-right {
    width: 100%;
    height: 100%;
    max-width: 488px;
    max-height: 366px;
  }
`;

const Root = () => (
  <AppLayout>
    <StyledHeroWrapper className="max-width-1200 mx-auto">
      <Row gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className="d-flex align-items-center"
        >
          <div>
            <Title className="mb-4" level={1}>
              Everything you need to invest profitably in REITs
            </Title>
            <Text className="subheading d-block">
              Shortlist REITs Best Suited For Your Portfolio.
              <br />
              Ready to give it a go?
            </Text>
            <Link to="/quiz">
              <Button className="mt-4 golden-btn custom-shadow" size="large">
                Let's get started!
              </Button>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledImg
            draggable="false"
            className="float-right"
            src={ROOT_HERO_IMAGE}
            alt="Hero image"
          />
        </Col>
      </Row>
    </StyledHeroWrapper>
    <Features />
    <Feedbacks />
    <CallToAction />
  </AppLayout>
);

export default Root;
