import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Typography, Row, Col } from "antd";

import AppLayout from "components/AppLayout";
import QuizTabs from "./components/QuizTabs";
import { useUserContextValue } from "contexts/UserContext";
import { QUIZ_QUESTIONS } from "utils/quizUtils";
import { QuizIcon } from "utils/iconUtils";
import { scrollToTop } from "utils/windowUtils";

const { Title, Text } = Typography;

export interface IQuiz extends RouteComponentProps<{ quiz_num: string }> {
  quizNum: number;
  setQuizNum: (quizNum: number) => void;
}

export type TAnswer = {
  order: number;
  answer: string | undefined;
  subAnswer?: string | undefined;
  withSubAnswer?: boolean;
};

const StyledQuizWrapper = styled.div`
  padding: 46px 0;
  .description-wrapper {
    max-width: 80%;
    margin-bottom: 56px;
  }
`;

const StyledStepsWrapper = styled.div`
  max-width: 792px;
`;

const Quiz: React.FC<RouteComponentProps<{ quiz_num: string }>> = ({
  match,
  history,
}) => {
  const quizAnswers: TAnswer[] = JSON.parse(localStorage.getItem("quizAnswer") || '[]');
  const [quizNum, setQuizNum] = useState(+match.params.quiz_num - 1);
  const [answers, setAnswers] = useState<TAnswer[]>(quizAnswers);
  const { account } = useUserContextValue();

  const setQuizAnswers = (answers: TAnswer[]) => {
    localStorage.setItem('quizAnswer', JSON.stringify(answers));
    setAnswers(answers);
  }

  const DERIVED_QUIZ_QUESTIONS = [...QUIZ_QUESTIONS];
  if (Boolean(account?.quiz)) {
    DERIVED_QUIZ_QUESTIONS.pop();
  } // removes last question

  useEffect(() => {
    const diff = +match.params.quiz_num - answers.length;
    if (diff > 1) {
      history.replace(`/quiz/${answers.length + 1}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToTop();
    if (quizNum !== +match.params.quiz_num - 1) {
      setQuizNum(+match.params.quiz_num - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.quiz_num]);

  return (
    <AppLayout>
      <StyledQuizWrapper className="max-width-1200 mx-auto">
        <Row>
          <Col xs={24} sm={24} md={10} lg={10}>
            <div style={{ marginTop: 90 }}>
              <QuizIcon />
              <Title className="mb-3" level={2}>
                Investor profiling questionnaire
              </Title>
              <div className="description-wrapper">
                <Text type="secondary">
                  Complete the the quiz and we will setup your personalized
                  investment strategy. Screener will be tuned for you to your
                  new strategy so you can make better investment decisions.
                </Text>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14}>
            <StyledStepsWrapper className="mx-auto">
              <QuizTabs
                hasQuiz={Boolean(account?.quiz)}
                quizNum={quizNum}
                setQuizNum={setQuizNum}
                answers={answers}
                setAnswers={setQuizAnswers}
                derivedQuestions={DERIVED_QUIZ_QUESTIONS}
              />
            </StyledStepsWrapper>
          </Col>
        </Row>
      </StyledQuizWrapper>
    </AppLayout>
  );
};

export default withRouter(Quiz);
