export const GROWTH_FIELDS: string = `
   threeYr
   fiveYr
   sevenYr
`;

export const COMMON_CHART_FIELDS: string = `
   value
   chart {
     label
     value
   }
`;

export const STD_CHART_FIELDS: string = `
   plus2STDDEV
   plusSTDDEV
   average
   minusSTDDEV
   minus2STDDEV
   chart {
     label
     value
   }
`;

export const COMP_INFO_FIELDS: string = `
  profile {
    name
    stockCode
    logo
    price
    priceChange
    priceChangePerc
    volume
    exchange
    sector
  } 
`;

export const COMP_KEYSTAT_FIELDS: string = `
   profile {
     stockCode
     exchange
   }
    keyStatistics{
       marketCap
       sharePriceRange{
         value
         min
         max
      }
     volatility
     dividendAmount{
         value
         chart{
          value
          label
       }
     }
     NAV{
       value
       chart {
         value
         label
       }
     }
     dividends{
       range{
         value
         min
         max  
       }
     }
     priceToBookRatio{
       range{
         value
         min
         max
       }
     } 
   } 
`;

export const COMP_PORTFOLIO_FIELDS: string = `
 portfolio {
    propertyYield {
      ${COMMON_CHART_FIELDS}
    }
    occupancyRate {
      ${COMMON_CHART_FIELDS}
    }
    rentGrowth {
      ${COMMON_CHART_FIELDS}
    }
    leaseDuration {
      ${COMMON_CHART_FIELDS}
    }
    incomeSupport
 }   
`;

export const COMP_FINANCIAL_FIELDS: string = `
 financials {
    revenue {
      ${COMMON_CHART_FIELDS}
    }
    NPI {
      ${COMMON_CHART_FIELDS}
    }
    operatingCashFlow {
      ${COMMON_CHART_FIELDS}
    }
    freeCashFlow {
      ${COMMON_CHART_FIELDS}
    }
    FFO {
      ${COMMON_CHART_FIELDS}
    }
    AFFO {
      ${COMMON_CHART_FIELDS}
    }
    distributionsPaid {
      ${COMMON_CHART_FIELDS}
    }
 }   
`;

export const COMP_DEBT_FIELDS: string = `
 debt {
    borrowings {
      ${COMMON_CHART_FIELDS}
    }
    totalBorrowings {
      ${COMMON_CHART_FIELDS}
    }
    loanInterestRates {
      ${COMMON_CHART_FIELDS}
    }
    loanDuration {
      ${COMMON_CHART_FIELDS}
    }
    NPIInterestCover {
      ${COMMON_CHART_FIELDS}
    }
 }   
`;

export const COMP_GROWTH_FIELDS: string = `
 growth {
      dividends{
        ${GROWTH_FIELDS}
      }   
      NAV{
        ${GROWTH_FIELDS}
      }   
      revenue{
        ${GROWTH_FIELDS}
      }   
      NPI{
        ${GROWTH_FIELDS}
      }   
      operatingCashFlow{
        ${GROWTH_FIELDS}
      }   
      freeCashFlow{
        ${GROWTH_FIELDS}
      }   
      FFO{
        ${GROWTH_FIELDS}
      }   
      AFFO{
        ${GROWTH_FIELDS}
      }   
      distributionsPaid{
        ${GROWTH_FIELDS}
      }   
 }   
`;

export const COMP_PROFILE_FIELDS: string = `
    profile {
      name
      stockCode
      about
      website
    }
`;
export const COMP_TRADINGVIEW_FIELDS: string = `
   profile {
      stockCode
   }
   chart{
      sharePrice{
        value
        label
      }
    }
`;
