const SUBSCRIPTION_INFO = `
  active
  validUntil
  renewal
`;

export default `
  accountId
  username
  firstname
  lastname
  avatar
  newSignUp
  level
  trial
  plan {
    plan  
  }
  subscription{
    premium {
      ${SUBSCRIPTION_INFO}
    }
    rtvAnnual {
      ${SUBSCRIPTION_INFO}
    }
    rtvMonthly {
      ${SUBSCRIPTION_INFO}
    }
    dataBoost {
      ${SUBSCRIPTION_INFO}
    }
    pro {
      ${SUBSCRIPTION_INFO}
    }
    bundle {
      ${SUBSCRIPTION_INFO}
    }
    bundleFullyPaid
  }
  cards{
    cardId
    last4
    brand
    expYear
    expMonth
  }
  quiz {
    strategy
    country
    persona
    profile
  }
  invoices {
    invoiceId
    invoice
    amountDue
    amountPaid
    created
    discount
    currency
    items {
      name
      description
      quantity
      amount
    }
  }
  checklist {
    articles
  }
`;
