import React, { useEffect, useState } from "react";
import { StaticContext } from "react-router";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { useQuery } from "react-apollo";
import { Table } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { scrollToTop } from "utils/windowUtils";
import AppLayout from "components/AppLayout";
import { Select, Row, Col, Typography, Card, Button } from "antd";
import styled from "styled-components";
import { REIT_FINANCIAL_REPORT } from "apollo/queries/reit";
import { useUserContextValue } from "contexts/UserContext";
import Spinner from "components/PageSpinner";
import { PREMIUM_USER_INDEX } from "utils/appUtils";

const { Option } = Select;
const { Text } = Typography;

const StyleOverride = styled.div`
  .ant-card {
    height: 100% !important;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-card-body {
      padding: 0;
    }
  }

  .ant-table-cell {
    padding-left: 24px;
  }

  .ant-table-thead {
    .ant-table-cell {
      background: rgba(154, 145, 255, 0.06);
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      &:last-of-type {
        text-align: center;
      }
    }
  }
  .ant-pagination {
    margin-right: 28px !important;
  }
`;

interface IFinancialReport {
  count: number;
  reports: Array<{
    type: string;
    announcementDate: string;
    yearEnded: string;
    reportPdfLink: string;
  }>;
}

const FinancialReports: React.FC<RouteComponentProps<
  { stockCode: string },
  StaticContext,
  { reitName: string }
>> = ({ match, history }) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  let reportData: IFinancialReport = {
    count: 0,
    reports: [],
  };

  const { account, token } = useUserContextValue();
  const [report, setReport] = useState("quarterly");
  const { data, loading } = useQuery(REIT_FINANCIAL_REPORT, {
    variables: {
      token,
      stockCode: match.params.stockCode,
      type: report.toUpperCase(),
    },
  });

  if (data) {
    reportData = data.financialReports;
    reportData = Object.assign({}, reportData, {
      reports: reportData.reports
        .slice(0)
        .sort((a, b) => {
          return a.announcementDate < b.announcementDate ? 1 : -1;
        })
        .map((each, idx) => {
          return { ...each, key: idx };
        }),
    });
  }

  const columns = [
    {
      title: "Report type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Announcement date",
      dataIndex: "announcementDate",
      key: "announcementDate",
      render: (date: string) => {
        return moment(date).format("MMM YYYY");
      },
    },
    {
      title: "Financial year ended",
      dataIndex: "yearEnded",
      key: "yearEnded",
    },
    {
      title: "View Report",
      dataIndex: "reportPdfLink",
      key: "reportPdfLink",
      render: (link: string) => {
        return link ? (
          <a
            target="_new"
            href={link}
            className="d-flex justify-content-center"
          >
            <FilePdfOutlined />
          </a>
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <AppLayout>
      {(account?.level || 0) < PREMIUM_USER_INDEX ? (
        <Redirect to="/" />
      ) : (
        <StyleOverride className="mx-auto max-width-1200 h-100 py-4">
          <div className="d-flex justify-content-between">
            <Button
              type="link"
              onClick={history.goBack}
              className="fs-14 p-0"
              style={{ fontWeight: 600, minHeight: "1px" }}
            >
              <ArrowLeftOutlined className="mr-2" />
              Back to reit page
            </Button>
            <Select
              defaultValue={report}
              style={{ width: 180 }}
              onChange={(type: string) => setReport(type)}
            >
              <Option value="quarterly">Quarterly Reports</Option>
              <Option value="annual">Annual Reports</Option>
            </Select>
          </div>
          <Card className="d-block mt-4">
            <Row>
              <Col xs={24} className="p-4">
                <h2>{history.location.state.reitName}</h2>
                <Text className="fs-20 text-capitalize">
                  Financial Report - {report}
                </Text>
              </Col>
              <Col xs={24}>
                {loading ? (
                  <div className="w-100 justify-self-center">
                    <Spinner
                      label=""
                      style={{ minHeight: 150, fontSize: 100 }}
                    />
                  </div>
                ) : data ? (
                  <Table
                    dataSource={reportData.reports}
                    columns={columns}
                    pagination={{
                      pageSize: 6,
                      total: reportData.count,
                    }}
                  />
                ) : (
                  <p className="p-4">no data available</p>
                )}
              </Col>
            </Row>
          </Card>
        </StyleOverride>
      )}
    </AppLayout>
  );
};

export default withRouter(FinancialReports);
