import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import PageSpinner from 'components/PageSpinner';
import ErrorPage from 'pages/ErrorPage';
import { isLoggedIn, getAuthToken, setAuthToken } from 'utils/userUtils';
import { ACCOUNT, USER_DEFAULTS } from 'apollo/queries/user';
import { IAccount } from 'apollo/types/user';
import { UserProvider } from 'contexts/UserContext';
import { COUNTRIES } from 'utils/appUtils';

const redirectToRootPaths = ['/login', '/signup/intro'];

interface IUserDefaultsResult {
  userDefaults: {
    location: string;
  }
}

interface IQueryVariables {
  token: string;
}

interface IQueryData {
  account: IAccount;
}

export default <P extends object>(
  ComposedComponent: React.ComponentType<P>,
  requireAuth = true
) => {
  const RequireAuth = (props: RouteComponentProps) => {
    const token = getAuthToken();
    
    const { data: locationData, loading: locationLoading, error: locationError } = useQuery<IUserDefaultsResult>(USER_DEFAULTS, {
      skip: navigator.userAgent === 'ReactSnap',
    });
    const { data, loading, error } = useQuery<IQueryData, IQueryVariables>(ACCOUNT, {
      variables: { token },
      skip: !isLoggedIn(),
    });
    const errorSource = error || locationError;

    useEffect(() => {
      const redirectToHome = () => {
        if (isLoggedIn()) {
          const { history } = props;
          const { pathname } = window.location;
          if (redirectToRootPaths.includes(pathname)) {
            history.replace('/');
          }
        }
      };
      const redirectToLogin = () => {
        if (!isLoggedIn()) {
          const { history } = props;
          history.replace('/login');
        }
      };
      requireAuth ? redirectToLogin() : redirectToHome();
    }, [props]);

    if (errorSource) {
      localStorage.clear();
      setAuthToken('');
      return <ErrorPage errorMessage={errorSource.graphQLErrors[0].message} />;
    }

    return (loading || locationLoading) ? (
      <PageSpinner />
    ) : (
      <UserProvider
        value={{
          token,
          account: data?.account,
          defaultCountry: COUNTRIES.find(({ exchange }) => exchange === locationData?.userDefaults.location),
        }}
      >
        <ComposedComponent {...(props as P)} />
      </UserProvider>
    );
  };
  return RequireAuth;
};
