import React, { useState } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Switch, Typography, Collapse, Button } from 'antd';

import LockIconModal from 'components/LockIconModal';
import { TScreenerFilterContent } from 'utils/screenerUtils';
import { ChevronBottom, ChevronTop } from 'utils/iconUtils';

const { Text } = Typography;
const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  border-bottom: 1px solid #F5F5F5 !important;
  .ant-collapse-header {
    display: flex;
    padding: 16px 0 !important;
    .lockicon-modal-wrapper {
      display: flex;
    }
    .ant-collapse-extra {
      flex-shrink: 0;
      .ant-btn {
        font-size: 14px;
        font-weight: normal;
        height: auto;
        min-height: auto;
        svg {
          width: 14px;
          height: 10px;
          path {
            fill: #675BF5;
          }
        }
      }
    }
    &::before, &::after, .ant-collapse-arrow {
      display: none !important;
    }
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.last-item {
    border-bottom: 0 !important;
  }
  &.ant-collapse-item-active {
    .ant-collapse-header {
      padding-bottom: 8px !important;
    }
  }
`;

interface ICollapsibleFilterComponents {
  userLevel: number,
  handleSwitchChange: (value: boolean, filter: TScreenerFilterContent) => void,
  filters: TScreenerFilterContent[],
  currentFilters: TScreenerFilterContent[],
}

const CollapsibleFilterComponents: React.FC<ICollapsibleFilterComponents> = ({ filters, userLevel, handleSwitchChange, currentFilters }) => {
  const [collapsibleState, setCollapsibleState] = useState<string | string[] | undefined>([]);
  return (
    <Collapse accordion bordered={false} className="bg-transparent" onChange={setCollapsibleState}>
      {filters.map((screenerFilter, idx) => {
        const { title, subTitle, key, removable = true, minimumPlan, description } = screenerFilter;
        const defaultChecked = Boolean(currentFilters.find((filter) => filter.key === key));
        const lockFilter = (userLevel < minimumPlan);
        const isPanelActive = collapsibleState?.includes(key);
        const lastListItemClass = cx({
          "last-item mb-2": idx === (filters.length - 1),
        });
        return (
          <StyledPanel
            key={key}
            className={lastListItemClass}
            header={(
              <div className="fs-14 d-flex align-items-center flex-grow-1">
                <Switch
                  checked={defaultChecked}
                  disabled={!removable || lockFilter}
                  className="mr-2"
                  {...(!lockFilter && {
                    onChange: (v, e) => {
                      e.stopPropagation();
                      handleSwitchChange(v, screenerFilter);
                    },
                  })}
                />
                <div>
                  <Text strong>{title}</Text>
                  {subTitle && (
                    <Text className="ml-2" type="secondary">{subTitle}</Text>
                  )}
                </div>
                {lockFilter && (
                  <div className="ml-2">
                    <LockIconModal minimumPlan={minimumPlan} />
                  </div>
                )}
              </div>
            )}
            extra={(
              <Button className="p-0" size="small" type="link">
                <span className="mr-2">More info</span>
                {isPanelActive ? <ChevronTop /> : <ChevronBottom />}
              </Button>
            )}
          >
            <div className="filter-description w-75" style={{ paddingLeft: 54 }}>
              <Text className="fs-14" type="secondary">{description}</Text>
            </div>
          </StyledPanel>
        );
      })}
    </Collapse>
  );
};

export default CollapsibleFilterComponents;
