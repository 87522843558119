import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import { Row, Col, Card, Typography, Spin } from "antd";

import { LoadingIcon } from "components/PageSpinner";
import { SucessText, IntroText } from "components/LoginRegisterCommon";
import LoginForm from "./components/LoginForm";
import {
  SEND_MAGIC_LINK,
  AUTHENTICATE_MAGIC_LINK,
} from "apollo/mutations/user";
import { useMessageContextValue } from "contexts/MessageContext";
import { ILoginFormValues } from "./components/LoginForm/LoginForm";
import { setAuthToken, setRefreshToken } from "utils/userUtils";

const LOGO = "/assets/images/logo-light.png";
const { Text, Title } = Typography;

interface ILoginStatus {
  success: boolean;
  email?: string;
  hasMagicCode?: boolean;
}

interface IMagicCodeData {
  code?: string;
  redirectUrl?: string;
  error?: string;
}

const StyledWrapper = styled.div`
  .max-width-1200 {
    min-height: 100vh;
    .ant-card {
      width: 100%;
      min-width: 580px;
      max-height: 640px;
      min-height: 562px;
    }
  }
`;

const StyledFormWrapper = styled.div`
  img {
    height: 36px;
    margin-bottom: 40px;
  }
`;

const LogIn: React.FC<RouteComponentProps> = ({ history, location, match }) => {
  const queryParams: IMagicCodeData = queryString.parse(location.search);
  const [verificationState, setVerificationState] = useState(false);
  const [loginStatus, setLoginStatus] = useState<ILoginStatus>({
    success: false,
    email: "",
    hasMagicCode: false,
  });
  const { alertError } = useMessageContextValue();
  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);
  const [authenticateMagicLink] = useMutation(AUTHENTICATE_MAGIC_LINK);

  const verifyCredentials = async () => {
    setVerificationState(true);
    try {
      const { data } = await authenticateMagicLink({
        variables: { code: queryParams.code },
      });
      setAuthToken(data.authenticateMagicLink.accessToken);
      setRefreshToken(data.authenticateMagicLink.refreshToken);
      history.push(
        queryParams.redirectUrl ||
          "/resources/reits-introduction/wp:the-reitscreener-investment-philosophy"
      );
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      setVerificationState(false);
      alertError(errorMessage);
    }
  };

  useEffect(() => {
    if (queryParams.code) verifyCredentials();
    if (queryParams.error && queryParams.error === "INVALID_TOKEN")
      alertError("Token has been invalidated.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginSubmit = async (
    values: ILoginFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await sendMagicLink({
        variables: {
          input: {
            ...values,
            ...(queryParams.redirectUrl && {
              redirectUrl: queryParams.redirectUrl,
            }),
          },
        },
      });
      setSubmitting(false);
      setLoginStatus({ success: true, email: values.email });
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      if (errorMessage.search(/missing/gi) > -1) {
        errorMessage = "Email address is not registered.";
      }
      alertError(errorMessage);
      setSubmitting(false);
      setLoginStatus({ success: false, email: values.email });
    }
  };

  return (
    <StyledWrapper>
      <Row
        gutter={120}
        className="max-width-1200 mx-auto d-flex align-items-center"
      >
        <Col xs={0} md={12} className="pl-0">
          <IntroText />
        </Col>
        <Col
          xs={24}
          md={12}
          className="d-flex justify-content-center justify-content-md-end pr-0"
        >
          <Spin spinning={verificationState} indicator={LoadingIcon}>
            <Card
              className="d-flex flex-column justify-content-center text-center custom-shadow"
              bodyStyle={{
                padding: "80px 65px",
              }}
            >
              {loginStatus.success ? (
                <SucessText
                  email={loginStatus.email}
                  hasMagicCode={loginStatus.hasMagicCode}
                />
              ) : (
                <StyledFormWrapper>
                  <Link to="/">
                    <img className="mx-auto" src={LOGO} alt="REITs Screener" />
                  </Link>
                  <Title level={2} className="mb-3 text-center">
                    Login with email
                  </Title>
                  <Text className="mb-4 d-block text-center fs-14">
                    Don't have an account?{" "}
                    <Link to="/signup">Create a free account</Link>
                  </Text>
                  <LoginForm onSubmit={handleLoginSubmit} />
                </StyledFormWrapper>
              )}
            </Card>
          </Spin>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default withRouter(LogIn);
