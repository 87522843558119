import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Tabs } from "antd";
import { useQuery } from "react-apollo";

import AppLayout from "components/AppLayout";
import Table from "./pages/Table";
import Watchlist from "./pages/Watchlist";
import Reits from "./pages/Reits";
import CTAUpsell from "components/CTAUpsell";
import CTAUnregistered from "components/CTAUnregistered";
import ComparisonTable from "./pages/Comparison";
import { useUserContextValue } from "contexts/UserContext";
import { ScreenerIcon, WatchlistIcon } from "utils/iconUtils";
import { REGISTERED_USER_INDEX, ANONYMOUS_USER_INDEX } from "utils/appUtils";
import { isLoggedIn } from "utils/userUtils";
import { scrollToTop } from "utils/windowUtils";
import GlobalSearch from "./pages/GlobalSearch";
import { REIT_LISTING } from "apollo/queries/screener";
import { USER_DEFAULTS } from "apollo/queries/user";
import { isDev } from "config/env";

interface IReitData {
  id: string;
  value: string;
  prefix: string;
}

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 32px auto !important;
    max-width: 1200px;
    display: flex !important;
    width: 100%;
    .ant-tabs-nav-list {
      flex: 1;
      ${(props: { tabcount: number }) => {
        return (
          props.tabcount < 2 &&
          `
           justify-content: space-between; 
          `
        );
      }}
      font-size: 16px;
      .ant-tabs-tab {
        padding: 16px 0;
        ${(props: { tabcount: number }) => {
          return (
            props.tabcount > 1 &&
            `
            &:nth-child(3){ 
              margin-left: auto;
            }
          `
          );
        }}
        &.ant-tabs-tab-active {
          svg > path {
            fill: #675bf5;
          }
        }
      }
    }
  }
  .ant-tabs-content {
    .ant-tabs-tabpane {
      padding: 4px;
    }
  }
`;

const StyledParentTabs = styled(Tabs)`
  &.parent-tab > .ant-tabs-nav {
    display: none;
  }
`;

const Screener: React.FC<RouteComponentProps<{
  screener_tab: string;
  stockCode: string;
}>> = ({ history, match }) => {
  const { account } = useUserContextValue();
  const { data } = useQuery(REIT_LISTING);
  const { data: dataLocation } = useQuery(USER_DEFAULTS);
  let reitListing: Array<IReitData> = [];
  if (data) {
    reitListing = data.screener.rows.map((reit: any) => {
      return {
        id: reit.stockCode,
        value: reit.reitName,
        prefix: reit.exchange,
      };
    });
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const SCREENER_TABS = [
    {
      path: "table",
      title: "Screener",
      icon: <ScreenerIcon />,
      contentBody: <Table />,
      minimumPlan: ANONYMOUS_USER_INDEX,
    },
    {
      path: "watchlist",
      title: "Watchlist",
      icon: <WatchlistIcon />,
      contentBody: <Watchlist />,
      minimumPlan: REGISTERED_USER_INDEX,
    },
  ];

  const setActiveTab = (key: string) => {
    if (key === "search") return;
    history.push(`/screener/${key}`);
  };

  const isComparisonView = history.location.pathname.includes("comparison");
  const tabcount = SCREENER_TABS.filter(
    ({ minimumPlan }) => (account?.level || 0) >= minimumPlan
  ).length;

  return (
    <AppLayout
      withFooter={isComparisonView ? false : true}
      withHeader={isComparisonView ? false : true}
    >
      <StyledParentTabs
        className="parent-tab"
        activeKey={
          match.params.stockCode
            ? "reits"
            : isComparisonView
            ? "comparison"
            : "screener"
        }
      >
        <TabPane tab="Screener" key="screener">
          <StyledTabs
            tabcount={tabcount || 0}
            activeKey={match.params.screener_tab}
            onChange={setActiveTab}
          >
            {SCREENER_TABS.filter(
              ({ minimumPlan }) => (account?.level || 0) >= minimumPlan
            ).map(({ title, icon, contentBody, path, minimumPlan }) => (
              <TabPane
                key={path}
                tab={
                  <div className="d-flex">
                    <div className="mr-2">{icon}</div>
                    {title}
                  </div>
                }
              >
                {contentBody}
              </TabPane>
            ))}

            {isDev && data && dataLocation && (
              <TabPane
                key="search"
                tab={
                  <GlobalSearch
                    listing={reitListing}
                    userLocation={
                      account?.quiz?.country ||
                      dataLocation.userDefaults.location
                    }
                  />
                }
              />
            )}
          </StyledTabs>
        </TabPane>
        <TabPane tab="Reits" key="reits">
          {match.params.stockCode && <Reits />}
        </TabPane>
        <TabPane tab="Comparison" key="comparison">
          {isComparisonView && <ComparisonTable />}
        </TabPane>
      </StyledParentTabs>
      {!isComparisonView && (
        <>{isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}</>
      )}
    </AppLayout>
  );
};

export default withRouter(Screener);
