import React from "react";
import RequireAuth from "HOC/RequireAuth";

const Root = React.lazy(() => import("pages/Root"));
const SignIn = React.lazy(() => import("pages/LogIn"));
const Signup = React.lazy(() => import("pages/Signup"));
const Resources = React.lazy(() => import("pages/Resources"));
const Videos = React.lazy(() => import("pages/Videos"));
const Screener = React.lazy(() => import("pages/Screener"));
const Quiz = React.lazy(() => import('pages/Quiz'));
const Subscription = React.lazy(() => import("pages/Subscription"));
const Settings = React.lazy(() => import("pages/Settings"));

const routes = [
  {
    exact: true,
    component: RequireAuth(Root, false),
    path: "/",
  },
  {
    exact: true,
    component: RequireAuth(SignIn, false),
    path: "/login",
  },
  {
    component: RequireAuth(Signup, false),
    path: "/signup",
  },
  {
    component: RequireAuth(Resources, false),
    path: "/resources",
  },
  {
    component: RequireAuth(Videos, false),
    path: "/reitscreener-tv",
  },
  {
    component: RequireAuth(Screener, false),
    path: "/screener",
  },
  {
    component: RequireAuth(Quiz, false),
    path: "/quiz",
  },
  {
    component: RequireAuth(Subscription),
    path: "/subscription",
  },
  {
    component: RequireAuth(Settings),
    path: "/settings",
  },
];

export default routes;
