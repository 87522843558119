import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

interface ICarouselContent {
  icon: React.ReactNode,
  title: string,
  body: React.ReactNode,
}

const StyledCarouselContent = styled.div`
`;

const CarouselContent: React.FC<ICarouselContent> = ({ icon, title, body }) => {
  return (
    <StyledCarouselContent className="text-center">
      <div className="mb-4">{icon}</div>
      <Title className="mb-3 mt-0" level={3}>{title}</Title>
      {body}
    </StyledCarouselContent>
  );
}

export default CarouselContent;
