import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, Button, Typography } from 'antd';

import { REGISTERED_USER_INDEX } from 'utils/appUtils';
import { LockIcon, LockIconCircle, ModalCloseIcon } from 'utils/iconUtils';

const { Text } = Typography;

interface ILockIconModal {
  minimumPlan: number,
}

const StyledButton = styled(Button)`
  &.lock-btn {
    min-height: 16px !important;
    min-width: 16px;
    height: 16px;
    width: 16px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close {
    padding: 32px 16px;
    .ant-modal-close-x {
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
  }
  .ant-modal-header {
    padding: 24px 32px 0;
    border: 0;
  }
  .ant-modal-body {
    padding-top: 0 !important;
  }
`;

const LockIconModal: React.FC<ILockIconModal> = ({ minimumPlan }) => {
  const [modalState, setModalState] = useState(false);
  const isAnonymous = (minimumPlan === REGISTERED_USER_INDEX);
  return (
    <div className="lockicon-modal-wrapper">
      <StyledButton
        onClick={(e) => {
          e.stopPropagation();
          setModalState(true);
        }}
        className="p-0 border-0 lock-btn"
        shape="circle"
        icon={<LockIcon />}
      />
      <StyledModal
        bodyStyle={{ padding: '24px 32px' }}
        visible={modalState}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          setModalState(false);
        }}
        getContainer={document.body}
        title={(
          <Text strong className="subheading d-block text-center mb-4">
            {isAnonymous ? 'You discovered a feature for registered users'  : 'You discovered a premium feature!'}
          </Text>
        )}
        closeIcon={<ModalCloseIcon />}
      >
        <div className="text-center">
          <div className="mb-3">
            <LockIconCircle />
          </div>
          <Text className="fs-16 d-block mb-4" type="secondary">
            {isAnonymous ?
              'Please register to REITScreener to unlock this feature, and improve your odds of outperforming the market.' :
              'Subscribe to REITScreener Premium to improve your odds of outperforming the market.'
            }
          </Text>
          <Link to={isAnonymous ? "/signup" : "/subscription/premium"}>
            <Button type="primary">
              {isAnonymous ? 'Register now' : 'Learn more'}
            </Button>
          </Link>
        </div>
      </StyledModal>
    </div>
  );
}

export default LockIconModal;
