import React, { useRef, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Row, Col, Typography, List } from "antd";
import { useQuery } from "react-apollo";
import styled from "styled-components";
import ReactPlayer from "react-player";

import RelatedVideos from "./components/RelatedVideos";
import UnregisteredModal from "./components/UnregisteredModal";

import { scrollToTop } from "utils/windowUtils";
import { IVideoDetails } from "pages/Videos/components/Main/Main";
import { VIDEOS_SINGLE } from "apollo/queries/resources";
import { PREMIUM_USER_INDEX, ANONYMOUS_USER_INDEX } from "utils/appUtils";
import { Spinner } from "components/PageSpinner";
import { useUserContextValue } from "contexts/UserContext";

const StyleWrapper = styled.div`
  .ant-card {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-card-body {
      padding: 0;
    }
  }
  .prod-img {
    width: 100%;
    max-width: 832px;
    height: 100%;
    max-height: 431px;
    object-fit: cover;
  }
  .video-container {
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      background-image: url("/assets/images/playButton.png");
      position: absolute;
      width: 68px;
      height: 48px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.15);
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .video-timestamp-parent {
    width: 100%;
    max-width: 430px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  }

  .ant-list-item {
    border-color: rgba(89, 89, 89, 0.15) !important;
  }
  .link-return {
    color: #232735;
    &::before {
      content: "🡠";
      margin-right: 10px;
    }
  }
  .video-parent {
    border-radius: 4px !important;
    overflow: hidden;
    min-height: 400px;
  }
  .trigger-time-stamp {
    cursor: pointer;
    .ant-typography {
      color: #fff;
    }
    &:hover {
      .ant-typography {
        color: #675bf5;
      }
    }
  }
  .description-box {
    height: 100%;
    max-height: 250px;
    overflow-y: auto;
  }
  .spinner-custom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const { Text } = Typography;

// const convertSeconds = (time: string) => {
//   const timeSplit: string[] = time.split(":");
//   var seconds =
//     timeSplit.length > 2
//       ? +timeSplit[0] * 60 * 60 + +timeSplit[1] * 60 + +timeSplit[2]
//       : +timeSplit[0] * 60 + +timeSplit[1];
//   return seconds;
// };

const SingleView: React.FC<RouteComponentProps<{ videoSlug: string }>> = ({
  match,
}) => {
  let vidDetails: IVideoDetails = {
    id: "",
    slug: "",
    date: "",
    tag: "",
    title: "",
    videoDate: "",
    videoURL: "",
    permission: [],
    videoTimestamps: [],
    media: "",
    content: "",
  };

  const vidPlayer = useRef<ReactPlayer>(null);
  const { account } = useUserContextValue();
  const { loading, data } = useQuery(VIDEOS_SINGLE, {
    variables: {
      postSlug: match.params.videoSlug,
    },
  });
  const [modalRegister, setModalRegister] = useState(false);
  const [liveReady, setLiveReady] = useState(false);

  if (data) {
    vidDetails = data.post;
  }

  useEffect(() => {
    scrollToTop();
  }, [match.params.videoSlug]);

  const isUserNotAllowed = (): boolean => {
    let response = true;
    const userLevel = account?.level || 0;
    const underAddOn = ["REITScreener TV Specials", "Market Briefs"].includes(
      vidDetails.tag
    );

    //check if video chosen is under Add On
    const hasRtv =
      account?.subscription.rtvMonthly.active ||
      account?.subscription.rtvAnnual.active;

    if (userLevel === PREMIUM_USER_INDEX || hasRtv) {
      //check if needs video needs add on
      if (underAddOn) {
        response = hasRtv ? false : true;
      } else {
        //let premium user watch
        response = false;
      }
    } else {
      response = true;
    }

    //check video permission
    const checkVideoPermission = (): boolean => {
      let allowedViewing = false;
      if (vidDetails.permission) {
        switch (vidDetails.permission[0]) {
          case "Registered":
            allowedViewing = userLevel > ANONYMOUS_USER_INDEX ? true : false;
            break;
          default:
            allowedViewing = false;
            break;
        }
      }
      return allowedViewing;
    };

    //SPECIAL EXCEPTIONS  && PRO USER

    if (
      userLevel > PREMIUM_USER_INDEX ||
      checkVideoPermission() ||
      account?.subscription.pro.active
    ) {
      response = false;
    }
    return response;
  };

  const convertSeconds = (time: string) => {
    const timeSplit: string[] = time.split(":");
    var seconds =
      timeSplit.length > 2
        ? +timeSplit[0] * 60 * 60 + +timeSplit[1] * 60 + +timeSplit[2]
        : +timeSplit[0] * 60 + +timeSplit[1];
    return seconds;
  };

  const jumpTimeStamp = (e: React.FormEvent<HTMLSpanElement>) => {
    const targetTime: string =
      e.currentTarget.getAttribute("data-time") || "00:00:00";
    //remove parenthesis

    let timeTrimmed = targetTime.replace(/(\(|\))/gi, "");

    vidPlayer.current?.seekTo(convertSeconds(timeTrimmed));
  };

  const VideoTimeStamps: React.FC<{
    timeStamps: Array<{ title: string; time: string }>;
  }> = ({ timeStamps }) => {
    return (
      <div
        className="h-100"
        style={{
          maxHeight: "300px",
          overflowY: timeStamps.length >= 4 ? "scroll" : "hidden",
        }}
      >
        <List
          dataSource={timeStamps}
          renderItem={(item) => (
            <List.Item className="py-0 mb-2">
              <span
                className="trigger-time-stamp py-2"
                onClick={
                  isUserNotAllowed()
                    ? () => setModalRegister(true)
                    : jumpTimeStamp
                }
                data-time={item.time}
              >
                <Text className="d-block fs-16">
                  {item.title} - {item.time}
                </Text>
              </span>
            </List.Item>
          )}
        />
      </div>
    );
  };

  const VideoDescription: React.FC<{ description: string }> = ({
    description,
  }) => {
    return (
      <>
        <h6 className="mt-4">Description</h6>
        <div
          className="fs-18 mt-2 description-box"
          dangerouslySetInnerHTML={{
            __html: description ? description : "N/A",
          }}
        />
      </>
    );
  };
  return (
    <StyleWrapper>
      {loading ? (
        <Spinner label="" />
      ) : (
        <>
          {data ? (
            <>
              <UnregisteredModal
                visible={modalRegister}
                needAddOn={[
                  "REITScreener TV Specials",
                  "Market Briefs",
                ].includes(vidDetails.tag)}
                setVisibility={setModalRegister}
              />
              <Row className="mb-2 justify-content-md-between">
                <Col xs={24} className="bg-primary-dark py-4">
                  <div className="max-width-1200 mx-auto">
                    <Text
                      type="secondary"
                      className="d-block fs-16 text-white my-4"
                    >
                      <span style={{ color: "#675BF5" }}>{vidDetails.tag}</span>
                    </Text>
                    <Row>
                      <Col
                        xs={24}
                        md={15}
                        className="mb-3 video-parent position-relative"
                      >
                        {isUserNotAllowed() === false ? (
                          <>
                            {vidDetails.videoURL ? (
                              <span
                                className={
                                  liveReady ? "d-none" : "spinner-custom"
                                }
                              >
                                <Spinner />
                              </span>
                            ) : (
                              <p className="text-white">
                                The video cannot be played at this moment.
                              </p>
                            )}

                            <ReactPlayer
                              playing={true}
                              onReady={() => setLiveReady(true)}
                              ref={vidPlayer}
                              url={vidDetails.videoURL}
                              width="100%"
                              height="100%"
                              controls={true}
                            />
                          </>
                        ) : (
                          <div
                            style={{ borderRadius: "4px" }}
                            className="video-container"
                            onClick={() => setModalRegister(true)}
                          >
                            <img
                              src={
                                vidDetails.media
                                  ? vidDetails.media
                                  : "https://via.placeholder.com/639x399"
                              }
                              className="prod-img"
                              alt="reit videos"
                            />
                          </div>
                        )}
                      </Col>
                      <Col className="d-none d-md-block video-timestamp-parent pl-5">
                        <Text
                          type="secondary"
                          className="d-block fs-18 mb-3 text-white"
                          strong
                        >
                          Timestamps
                        </Text>
                        <VideoTimeStamps
                          timeStamps={vidDetails.videoTimestamps}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={24} className="mb-3">
                  <div className="max-width-1200 mx-auto">
                    <VideoDescription description={vidDetails.content} />
                  </div>
                </Col>
              </Row>
              <RelatedVideos currentVideo={vidDetails} />
            </>
          ) : (
            <div className="my-5">Video does not exist.</div>
          )}
        </>
      )}
    </StyleWrapper>
  );
};

export default withRouter(SingleView);
