import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { IScreenerData } from 'apollo/types/screener';

import ScreenerTable from '../../components/ScreenerTable';
import { SCREENER_WATCHLIST } from 'apollo/queries/screener';

interface IScreenerWatchlistResult {
  screenerWatchlist: IScreenerData[],
}

const StyledDiv = styled.div`
  ${(props: { maxWidth: number }) => {
    return `max-width: ${props.maxWidth}px;`;
  }}
  &.table-wrapper {
    padding: 0 32px;
  }
`;

const Watchlist = () => {
  const { data, loading } = useQuery<IScreenerWatchlistResult>(SCREENER_WATCHLIST);

  return (
    <StyledDiv maxWidth={1920} className="mx-auto table-wrapper mb-5">
      <ScreenerTable watchlist loading={loading} data={{ count: 0, rows: data?.screenerWatchlist || [] }} />
    </StyledDiv>
  );
}

export default Watchlist;
