import React, { ReactNode } from 'react';

import FilterCountry from 'components/FilterComponents/FilterCountry';
import FilterSector from 'components/FilterComponents/FilterSector';
import FilterMultipleSelect from 'components/FilterComponents/FilterMultipleSelect';
import FilterRadioSelect from 'components/FilterComponents/FilterRadioSelect';
import { TFilterState } from 'redux/slices/filterStateSlice';
import {
  ANONYMOUS_USER_INDEX, REGISTERED_USER_INDEX, PREMIUM_USER_INDEX, INVESTMENT_STRATEGIES,
  BASIC_FILTERS, KEY_STATISTICS_FILTERS, PORTFOLIO_FILTERS, DIVIDEND_GROWTH_FILTERS, NAV_GROWTH_FILTERS, DEBT_FILTERS,
} from './appUtils';
import {
  getFilterOptions,
  SIZE, DIVIDENDS_PROPERTY_YIELD, PRICE_TO_BOOK, BORROWINGS, VOLATILITY,
  GROWTHS, INTEREST_COVER, LOAN_INTEREST_OCCUPANCY_RATE,
  DURATIONS, INCOME_SUPPORT, TFilterOption,
} from './filterUtils';

export type TScreenerFilterContent = {
  key: string,
  title: string,
  subTitle?: string,
  removable?: boolean, // should default to true
  withModalSelection?: boolean,
  minimumPlan: number,
  strategies?: string[],
  filterComponent?: () => ReactNode,
  category: string,
  description: string,
  hasRangeFilter?: boolean, // should default to true,
  columnOrder: number,
  valueType?: 'percent' | 'money',
  optionSource?: { label: string, value: string, minimumPlan: number }[],
}

// INVESTMENT STRATEGIES
const LOW_BETA = INVESTMENT_STRATEGIES.find(({ name }) => name === 'LOW_BETA')!.name;
const DIVIDEND_MACHINES = INVESTMENT_STRATEGIES.find(({ name }) => name === 'DIVIDEND_MACHINES')!.name;
const YIELD_CHASER = INVESTMENT_STRATEGIES.find(({ name }) => name === 'YIELD_CHASER')!.name;
const VALUE_SEEKER = INVESTMENT_STRATEGIES.find(({ name }) => name === 'VALUE_SEEKER')!.name;
const VALUE_GROWTH = INVESTMENT_STRATEGIES.find(({ name }) => name === 'VALUE_GROWTH')!.name;
const GROWTH_ENGINE = INVESTMENT_STRATEGIES.find(({ name }) => name === 'GROWTH_ENGINE')!.name;

// FILTER COMPONENTS DATA STRUCTURE
export const ALL_SCREENER_FILTERS: TScreenerFilterContent[] = [{
  key: 'exchange',
  title: 'Country',
  removable: false,
  withModalSelection: true,
  minimumPlan: ANONYMOUS_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_SEEKER, VALUE_GROWTH, GROWTH_ENGINE],
  filterComponent: () => <FilterCountry />,
  category: BASIC_FILTERS,
  description: "This is the country (e.g. Singapore, US, Australia…etc) that the REIT is listed and traded in. For example, if a stock trades on the Singapore Stock Exchange, then the stock's country would be the Singapore.",
  hasRangeFilter: false,
  columnOrder: 1,
}, {
  key: 'sector',
  title: 'Sector',
  removable: false,
  withModalSelection: true,
  minimumPlan: ANONYMOUS_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_SEEKER, VALUE_GROWTH, GROWTH_ENGINE],
  filterComponent: () => <FilterSector />,
  category: BASIC_FILTERS,
  description: "This is the Sector that the REITs is classified. Every sector has its unique characteristics and risk.",
  hasRangeFilter: false,
  columnOrder: 2,
}, {
  key: 'marketCap',
  title: 'Size',
  subTitle: 'Market Cap ($mil)',
  minimumPlan: ANONYMOUS_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_SEEKER, VALUE_GROWTH, GROWTH_ENGINE],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(SIZE), filterStateKey: 'marketCap' }}
    />
  ),
  category: KEY_STATISTICS_FILTERS,
  description: "The size of a REIT is usually measured by its Market Cap calculated by multiplying its current Share Price by the current number of Units or Shares.",
  columnOrder: 3,
  valueType: 'money',
  optionSource: getFilterOptions(SIZE),
}, {
  key: 'volatility',
  title: 'Volatility',
  subTitle: 'Beta',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [LOW_BETA],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(VOLATILITY), filterStateKey: 'volatility' }}
    />
  ),
  category: KEY_STATISTICS_FILTERS,
  description: "The Beta is a measure of a REIT’s price volatility relative to the broader stock market and is one of the frequently quoted measurements of risk.",
  columnOrder: 8,
  optionSource: getFilterOptions(VOLATILITY),
}, {
  key: 'dividends',
  title: 'Dividends',
  subTitle: 'DPU Yield (%)',
  minimumPlan: ANONYMOUS_USER_INDEX,
  strategies: [DIVIDEND_MACHINES, YIELD_CHASER, VALUE_SEEKER],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(DIVIDENDS_PROPERTY_YIELD), filterStateKey: 'dividends' }}
    />
  ),
  category: KEY_STATISTICS_FILTERS,
  description: "The Dividend Yield is calculated on a Trailing Twelfth Month (TTM) basis and gives a measurement of how quickly an investor will recoup their capital just by collecting dividends. For example, a dividend yield of 5% would mean that an investor would fully recoup their capital in 20 years just from dividends alone.",
  columnOrder: 4,
  valueType: 'percent',
  optionSource: getFilterOptions(DIVIDENDS_PROPERTY_YIELD),
}, {
  key: 'priceToBookRatio',
  title: 'Price-to-Book',
  subTitle: 'P/B Ratio (x)',
  minimumPlan: ANONYMOUS_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_SEEKER, VALUE_GROWTH],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(PRICE_TO_BOOK), filterStateKey: 'priceToBookRatio' }}
    />
  ),
  category: KEY_STATISTICS_FILTERS,
  description: "The Price-to-Book Ratio, or P/B Ratio is used as a relative measure of a REIT’s Unit Price to its Book Value (or Net Asset Value). Looking at the historical trading range of the P/B Ratio would give an indication of how expensive or good value the current Share Price.",
  columnOrder: 5,
  optionSource: getFilterOptions(PRICE_TO_BOOK),
}, {
  key: 'propertyYield',
  title: 'Property Yield',
  subTitle: 'Cap Rates (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(DIVIDENDS_PROPERTY_YIELD), filterStateKey: 'propertyYield' }}
    />
  ),
  category: PORTFOLIO_FILTERS,
  description: "The Capitalisation Rate (or Cap Rate) is the real estate industry standard in the measurement of the gross returns of the commercial property or portfolio relative to its valuation. It can be thought off as the gross percentage return an investor would receive by purchasing the real estate portfolio in cash.",
  columnOrder: 17,
  valueType: 'percent',
  optionSource: getFilterOptions(DIVIDENDS_PROPERTY_YIELD),
}, {
  key: 'occupancyRate',
  title: 'Occupancy Rate (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(LOAN_INTEREST_OCCUPANCY_RATE), filterStateKey: 'occupancyRate' }}
    />
  ),
  category: PORTFOLIO_FILTERS,
  description: "The Occupancy Rate is the committed tenancies relative to the total leasable area of the REIT’s portfolio. The higher the occupancy rate, the more likely that the portfolio of properties are heavily in demand.",
  columnOrder: 21,
  valueType: 'percent',
  optionSource: getFilterOptions(LOAN_INTEREST_OCCUPANCY_RATE),
}, {
  key: 'rentGrowth',
  title: 'Rental Growth',
  subTitle: 'Rental Reversions (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_GROWTH, GROWTH_ENGINE],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'rentGrowth' }}
    />
  ),
  category: PORTFOLIO_FILTERS,
  description: "The Rental Reversion is the average growth in rentals measured based on the rental contracts which are renewed. A positive rental reversion would indicate that the REIT managed to renew its expiring leases on average higher while a negative rental reversion would mean that the renewed leases are on average lower.",
  columnOrder: 18,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'leaseDuration',
  title: 'Lease Duration',
  subTitle: 'Weighted Average Lease Expiry (WALE) (Years)',
  minimumPlan: PREMIUM_USER_INDEX,
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(DURATIONS), filterStateKey: 'leaseDuration' }}
    />
  ),
  category: PORTFOLIO_FILTERS,
  description: "The Weighted Average Lease Expiry (WALE) is the blended average duration of the remaining lease of the REIT’s entire portfolio tenancy. The longer the WALE, the more likely it is to indicate that the REIT have tenants that loyal and are longer staying.",
  columnOrder: 19,
  optionSource: getFilterOptions(DURATIONS),
}, {
  key: 'incomeSupport',
  title: 'Income Support (Yes/No)',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_GROWTH, GROWTH_ENGINE],
  filterComponent: () => (
    <FilterRadioSelect
      config={{ options: getFilterOptions(INCOME_SUPPORT), filterStateKey: 'incomeSupport' }}
    />
  ),
  category: PORTFOLIO_FILTERS,
  description: "Some REITs practice providing income support to its properties in order to boost the income and therefore the dividends to shareholders. Income support is generally viewed as temporary and after it has lapse, there is a risk that the dividends of the REIT may fall.",
  columnOrder: 22,
  valueType: 'money',
  optionSource: getFilterOptions(INCOME_SUPPORT),
}, {
  key: 'threeYrDPUGrowthRate',
  title: 'DPU 3-Year',
  subTitle: 'DPU Growth Rate (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [GROWTH_ENGINE, YIELD_CHASER],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'threeYrDPUGrowthRate' }}
    />
  ),
  category: DIVIDEND_GROWTH_FILTERS,
  description: "The Dividend Growth measures the year-on-year growth of dividend payments by the REIT on a per Share basis using the Trailing Twelve Month (TTM) method. By measuring on a per Share basis, investors are able to better assess the gauge the growth trajectory of a REIT. This can be viewed as the equivalent of the growth of rental for a physical real estate.",
  columnOrder: 9,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'fiveYrDPUGrowthRate',
  title: 'DPU 5-Year',
  subTitle: 'DPU Growth Rate (%)',
  minimumPlan: REGISTERED_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, VALUE_SEEKER, VALUE_GROWTH],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'fiveYrDPUGrowthRate' }}
    />
  ),
  category: DIVIDEND_GROWTH_FILTERS,
  description: "The Dividend Growth measures the year-on-year growth of dividend payments by the REIT on a per Share basis using the Trailing Twelve Month (TTM) method. By measuring on a per Share basis, investors are able to better assess the gauge the growth trajectory of a REIT. This can be viewed as the equivalent of the growth of rental for a physical real estate.",
  columnOrder: 10,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'sevenYrDPUGrowthRate',
  title: 'DPU 7-Year',
  subTitle: 'DPU Growth Rate (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'sevenYrDPUGrowthRate' }}
    />
  ),
  category: DIVIDEND_GROWTH_FILTERS,
  description: "The Dividend Growth measures the year-on-year growth of dividend payments by the REIT on a per Share basis using the Trailing Twelve Month (TTM) method. By measuring on a per Share basis, investors are able to better assess the gauge the growth trajectory of a REIT. This can be viewed as the equivalent of the growth of rental for a physical real estate.",
  columnOrder: 11,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'threeYrNAVGrowthRate',
  title: 'NAV 3-Year',
  subTitle: 'NAV per Unit Growth Rate (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [GROWTH_ENGINE],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'threeYrNAVGrowthRate' }}
    />
  ),
  category: NAV_GROWTH_FILTERS,
  description: "The NAV Growth measures the year-on-year growth of Net Asset Value or Book Value of the REIT on a per Share basis. By measuring on a per Share basis, investors are able to better assess the gauge the growth trajectory of a REIT. This can be viewed as the equivalent of the capital appreciation for a physical real estate.",
  columnOrder: 12,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'fiveYrNAVGrowthRate',
  title: 'NAV 5-Year',
  subTitle: 'NAV per Unit Growth Rate (%)',
  minimumPlan: REGISTERED_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, VALUE_SEEKER, VALUE_GROWTH],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'fiveYrNAVGrowthRate' }}
    />
  ),
  category: NAV_GROWTH_FILTERS,
  description: "The NAV Growth measures the year-on-year growth of Net Asset Value or Book Value of the REIT on a per Share basis. By measuring on a per Share basis, investors are able to better assess the gauge the growth trajectory of a REIT. This can be viewed as the equivalent of the capital appreciation for a physical real estate.",
  columnOrder: 13,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'sevenYrNAVGrowthRate',
  title: 'NAV 7-Year',
  subTitle: 'NAV per Unit Growth Rate (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(GROWTHS), filterStateKey: 'sevenYrNAVGrowthRate' }}
    />
  ),
  category: NAV_GROWTH_FILTERS,
  description: "The NAV Growth measures the year-on-year growth of Net Asset Value or Book Value of the REIT on a per Share basis. By measuring on a per Share basis, investors are able to better assess the gauge the growth trajectory of a REIT. This can be viewed as the equivalent of the capital appreciation for a physical real estate.",
  columnOrder: 14,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'borrowings',
  title: 'Borrowings',
  subTitle: 'Gearing (excl. perpetuals) (%)',
  minimumPlan: ANONYMOUS_USER_INDEX,
  strategies: [DIVIDEND_MACHINES, VALUE_SEEKER],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(BORROWINGS), filterStateKey: 'borrowings' }}
    />
  ),
  category: DEBT_FILTERS,
  description: "The Gearing is a measure of the financial leverage of the REIT and is calculated as the Total Debt divided by Total Assets. However, this standard calculation excludes Perpetual Securities that are frequently used by REITs as an alternative means of borrowing.",
  columnOrder: 6,
  valueType: 'percent',
  optionSource: getFilterOptions(GROWTHS),
}, {
  key: 'totalBorrowings',
  title: 'Total Borrowings ',
  subTitle: 'Gearing (incl. perpetuals) (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  strategies: [LOW_BETA, DIVIDEND_MACHINES, YIELD_CHASER, VALUE_GROWTH],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(BORROWINGS), filterStateKey: 'totalBorrowings' }}
    />
  ),
  category: DEBT_FILTERS,
  description: "The Gearing including Perpetual Securities is a more representative measure of the financial leverage of the REIT and is calculated as the Total Debt + Total Perpetual Securities divided by Total Assets. This means of measurement ensures that the leverage risk of a REIT is better represented.",
  columnOrder: 7,
  valueType: 'percent',
  optionSource: getFilterOptions(BORROWINGS),
}, {
  key: 'NPIInterestCover',
  title: 'Interest Cover (x)',
  minimumPlan: REGISTERED_USER_INDEX,
  strategies: [DIVIDEND_MACHINES, YIELD_CHASER, VALUE_SEEKER, VALUE_GROWTH, GROWTH_ENGINE],
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(INTEREST_COVER), filterStateKey: 'NPIInterestCover' }}
    />
  ),
  category: DEBT_FILTERS,
  description: "The Interest Cover is a measure of how many times a REIT can pay its Interest Expenses from its Net Property Income (NPI). The NPI of a REIT is its gross rental after deduction of all real estate expenses. As interest expenses are the bulk of a REIT’s annual expenditure, the higher the interest cover, the more income there is to pay dividends to the investors.",
  columnOrder: 15,
  optionSource: getFilterOptions(INTEREST_COVER),
}, {
  key: 'loanInterestRates',
  title: 'Loan Interest Rates',
  subTitle: 'Interest Cost (%)',
  minimumPlan: PREMIUM_USER_INDEX,
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(LOAN_INTEREST_OCCUPANCY_RATE), filterStateKey: 'loanInterestRates' }}
    />
  ),
  category: DEBT_FILTERS,
  description: "The interest expenses are usually the highest expense that a REIT incurs annually. Hence, by monitoring the Interest Cost of the REIT, an investor can compare if the REIT is managing its highest expense well.",
  columnOrder: 16,
  valueType: 'percent',
  optionSource: getFilterOptions(LOAN_INTEREST_OCCUPANCY_RATE),
}, {
  key: 'loanDuration',
  title: 'Loan Duration',
  subTitle: 'Weighted Average Debt Maturity (WADM) (Years)',
  minimumPlan: PREMIUM_USER_INDEX,
  filterComponent: () => (
    <FilterMultipleSelect
      config={{ options: getFilterOptions(DURATIONS), filterStateKey: 'loanDuration' }}
    />
  ),
  category: DEBT_FILTERS,
  description: "The Weighted Average Debt Maturity (WADM) is the blended average duration of the remaining loan durations of all the REIT’s loans. The longer the WADM, the less the investor has to be concerned with the REIT refinancing loans that are coming due.",
  columnOrder: 20,
  optionSource: getFilterOptions(DURATIONS),
}];

export const getFilters = (strategy: string, userLevel: number, ignoreStrategy = false) => {
  return ALL_SCREENER_FILTERS.filter(({ minimumPlan, strategies }) => {
    return (userLevel >= minimumPlan && strategies?.includes(strategy)) || 
      (userLevel >= minimumPlan && ignoreStrategy);
  });
};

// FILER'S DEFAULT VALUES BY INVESTMENT STRATEGY
export const DEFAULT_FILTER_STATES: { [key: string]: { [key: string]: TFilterOption[] } } = {
  LOW_BETA: {
    marketCap: function() {
      const DEFAULTS = SIZE.filter((v) => ['MEDIUM', 'LARGE', 'MEGA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    priceToBookRatio: function() {
      const DEFAULTS = PRICE_TO_BOOK.filter((v) => ['BARGAIN', 'GOOD_VALUE', 'REASONABLE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    borrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['VERY_LOW', 'LOW', 'AVERAGE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    totalBorrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['VERY_LOW', 'LOW', 'AVERAGE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    volatility: function() {
      const DEFAULTS = VOLATILITY.filter((v) => ['VERY_LOW', 'LOW'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrDPUGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrNAVGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    NPIInterestCover: function() {
      const DEFAULTS = INTEREST_COVER.filter((v) => ['SAFE', 'VERY_SAFE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    rentGrowth: function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    occupancyRate: function() {
      const DEFAULTS = LOAN_INTEREST_OCCUPANCY_RATE.filter((v) => ['AVERAGE', 'HIGH', 'VERY_HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    incomeSupport: function() {
      const DEFAULTS = INCOME_SUPPORT.filter((v) => ['NO'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
  },
  DIVIDEND_MACHINES: {
    marketCap: function() {
      const DEFAULTS = SIZE.filter((v) => ['MEDIUM', 'LARGE', 'MEGA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    dividends: function() {
      const DEFAULTS = DIVIDENDS_PROPERTY_YIELD.filter((v) => ['AVERAGE', 'HIGH', 'ULTRA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    priceToBookRatio: function() {
      const DEFAULTS = PRICE_TO_BOOK.filter((v) => ['REASONABLE', 'BARGAIN', 'GOOD_VALUE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    borrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['AVERAGE', 'VERY_LOW', 'LOW'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    totalBorrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['AVERAGE', 'VERY_LOW', 'LOW'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrDPUGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrNAVGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    NPIInterestCover: function() {
      const DEFAULTS = INTEREST_COVER.filter((v) => ['MODERATE_RISK', 'SAFE', 'VERY_SAFE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    rentGrowth: function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    occupancyRate: function() {
      const DEFAULTS = LOAN_INTEREST_OCCUPANCY_RATE.filter((v) => ['AVERAGE', 'HIGH', 'VERY_HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    incomeSupport: function() {
      const DEFAULTS = INCOME_SUPPORT.filter((v) => ['NO'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
  },
  YIELD_CHASER: {
    marketCap: function() {
      const DEFAULTS = SIZE.filter((v) => ['MICRO', 'SMALL', 'MEDIUM', 'LARGE', 'MEGA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    dividends: function() {
      const DEFAULTS = DIVIDENDS_PROPERTY_YIELD.filter((v) => ['HIGH', 'ULTRA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    priceToBookRatio: function() {
      const DEFAULTS = PRICE_TO_BOOK.filter((v) => ['REASONABLE', 'BARGAIN', 'GOOD_VALUE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    totalBorrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['AVERAGE', 'VERY_LOW', 'LOW', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'threeYrDPUGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    NPIInterestCover: function() {
      const DEFAULTS = INTEREST_COVER.filter((v) => ['RISKY', 'MODERATE_RISK', 'SAFE', 'VERY_SAFE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    rentGrowth: function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    incomeSupport: function() {
      const DEFAULTS = INCOME_SUPPORT.filter((v) => ['NO'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
  },
  VALUE_SEEKER: {
    marketCap: function() {
      const DEFAULTS = SIZE.filter((v) => ['MICRO', 'SMALL', 'MEDIUM', 'LARGE', 'MEGA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    dividends: function() {
      const DEFAULTS = DIVIDENDS_PROPERTY_YIELD.filter((v) => ['AVERAGE', 'HIGH', 'ULTRA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    priceToBookRatio: function() {
      const DEFAULTS = PRICE_TO_BOOK.filter((v) => ['BARGAIN', 'GOOD_VALUE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    borrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['AVERAGE', 'VERY_LOW', 'LOW', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    totalBorrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['AVERAGE', 'VERY_LOW', 'LOW', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrDPUGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrNAVGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    NPIInterestCover: function() {
      const DEFAULTS = INTEREST_COVER.filter((v) => ['RISKY', 'MODERATE_RISK', 'SAFE', 'VERY_SAFE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
  },
  VALUE_GROWTH: {
    marketCap: function() {
      const DEFAULTS = SIZE.filter((v) => ['SMALL', 'MEDIUM', 'LARGE', 'MEGA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    priceToBookRatio: function() {
      const DEFAULTS = PRICE_TO_BOOK.filter((v) => ['BARGAIN', 'GOOD_VALUE', 'EXPENSIVE', 'REASONABLE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    totalBorrowings: function() {
      const DEFAULTS = BORROWINGS.filter((v) => ['AVERAGE', 'VERY_LOW', 'LOW', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrDPUGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'fiveYrNAVGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    NPIInterestCover: function() {
      const DEFAULTS = INTEREST_COVER.filter((v) => ['RISKY', 'MODERATE_RISK', 'SAFE', 'VERY_SAFE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    rentGrowth: function() {
      const DEFAULTS = GROWTHS.filter((v) => ['SLOW', 'NEUTRAL', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    incomeSupport: function() {
      const DEFAULTS = INCOME_SUPPORT.filter((v) => ['NO'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
  },
  GROWTH_ENGINE: {
    marketCap: function() {
      const DEFAULTS = SIZE.filter((v) => ['MICRO', 'SMALL', 'MEDIUM', 'LARGE', 'MEGA'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'threeYrDPUGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    'threeYrNAVGrowthRate': function() {
      const DEFAULTS = GROWTHS.filter((v) => ['SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    NPIInterestCover: function() {
      const DEFAULTS = INTEREST_COVER.filter((v) => ['MODERATE_RISK', 'SAFE', 'VERY_SAFE'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    rentGrowth: function() {
      const DEFAULTS = GROWTHS.filter((v) => ['NEUTRAL', 'SLOW', 'MODERATE', 'HIGH'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
    incomeSupport: function() {
      const DEFAULTS = INCOME_SUPPORT.filter((v) => ['NO'].includes(v.value));
      return getFilterOptions(DEFAULTS);
    }(),
  },
};

// process filterState to remove unnecessary data
export const processFilterState = (filterState: TFilterState, advancedMode: boolean, returnAll = false) => {
  const filteredValues = Object.keys(filterState)
    .filter(v => {
      const { option, range } = filterState[v];
      // if (
      //   v === 'incomeSupport' &&
      //   option.length &&
      //   option[0].value === 'NO' &&
      //   (!advancedMode && !returnAll) // make sure income support can still be considered when on advanced mode
      // ) return false;
      const isValidRangeValue = !(range.from === 0 && range.to === 0) &&
        (typeof range.from === 'number' && typeof range.to === 'number');
      return (advancedMode) ? isValidRangeValue : Boolean(option.length);
    })
    .reduce((accumulator, currentValue) => {
      const optionValue = returnAll ?
        filterState[currentValue].option :
        filterState[currentValue].option.map(({ value }) => value);
      return {
        ...accumulator,
        [currentValue]: {
          // only return either of the key based on filter view state
          ...(advancedMode ? {
            range: filterState[currentValue].range,
          } : {
            option: optionValue,
          }),
        },
      };
    }, {});
  return filteredValues;
}