import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Avatar, Typography, Row, Col, Card } from 'antd';

import FooterNavigation from 'pages/Resources/components/FooterNavigation';
import AppLayout from 'components/AppLayout';
import { Spinner } from 'components/PageSpinner';
import ArticleBlocker from 'components/ArticleBlocker';
import CTAUnregistered from 'components/CTAUnregistered';
import CTAUpsell from 'components/CTAUpsell';
import { scrollToTop } from 'utils/windowUtils';
import { useFreeResourcesContextValue } from 'contexts/FreeResourcesContext';
import { SINGLE_POST, CATEGORY_POSTS } from 'apollo/queries/resources';
import { IWPPost } from 'apollo/types/cms';
import { isLoggedIn } from 'utils/userUtils';

const { Title, Text } = Typography;
const AUTHOR_AVATAR = '/assets/images/author.png';

interface IBlogQueryResult {
  posts: {
    slug: string,
    posts: IWPPost[],
  }[],
}

interface ISingeBlogResult {
  post: IWPPost,
}

const StyledSingleBlogWrapper = styled.div`
  padding-bottom: 100px;
  .featured-image {
    margin: 40px 0;
    width: 100%;
    height: 100%;
    max-height: 450px;
  }
`;

const StyledBlogContent = styled.div`
  max-width: 90%;
  p {
    font-size: 20px;
    img {
      width: 100%;
    }
  }
  .wp-block-image {
    img {
      width: 100%;
    }
  }
  iframe {
    display: block;
    margin: auto;
  }
`;

const StyledCard = styled(Card)`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

const SingleBlog: React.FC<RouteComponentProps<{ post_slug: string }>> = ({ match }) => {
  const { freeResources, setFreeResources } = useFreeResourcesContextValue();
  const freeBlogArticle = freeResources.find(({ category }) => category === 'blog')!;

  const { data: postsData } = useQuery<undefined | IBlogQueryResult>(CATEGORY_POSTS, {
    variables: {
      categorySlugs: ["blog"],
    }
  });
  const { data: postData, loading: postLoading } = useQuery<ISingeBlogResult | undefined>(SINGLE_POST, {
    variables: {
      postSlug: match.params.post_slug,
    }
  });

  useEffect(() => {
    scrollToTop();
    if (!freeBlogArticle.articleSlug && match.params.post_slug) {
      freeBlogArticle.articleSlug = match.params.post_slug;
      setFreeResources(freeResources);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.post_slug]);

  const post = postData?.post;
  const categoryPosts = postsData?.posts || [];
  const enableBlocker = !isLoggedIn() &&
    freeBlogArticle.articleSlug &&
    freeBlogArticle.articleSlug !== match.params.post_slug;
  
  return (
    <AppLayout>
      {postLoading ? (
        <Spinner />
      ) : (
        <>
          {post ? (
            <>
              <StyledSingleBlogWrapper className="max-width-1200 mx-auto">
                <Row className="mt-5" gutter={24}>
                  <Col span={18}>
                    <Title level={2} className="m-0 text-center">{post.title}</Title>
                    {post.media && (
                      <Avatar className="featured-image" shape="square" src={post.media} />
                    )}
                    <StyledBlogContent
                      className="mx-auto"
                      dangerouslySetInnerHTML={{
                        __html: function() {
                          if (!isLoggedIn() && post.blocker && enableBlocker) {
                            const { content } = { ...post };
                            const arrayContent = content.trim().split('</p>').filter(v => v);
                            arrayContent.splice(post.blocker);
                            return arrayContent.join('</p>');
                          }
                          return post.content;
                        }()
                      }}
                    />                
                    {(!isLoggedIn() && enableBlocker) && <ArticleBlocker />}
                  </Col>
                  <Col span={6}>
                    <StyledCard bodyStyle={{ padding: '16px 24px' }}>
                      <Text className="d-block mb-3" strong>Co-Founder and Author</Text>
                      <div className="d-flex mb-3 align-items-center">
                        <Avatar style={{ width: 48, height: 48 }} src={AUTHOR_AVATAR} className="mr-3" />
                        <Text>Tam Ging Wien</Text>
                      </div>
                      <Text className="fs-16 d-block">
                        Tam Ging Wien has been an avid equities and real estate investor for over 10 years. He is the co-founder of REITScreener.com and ProButterfly.com, an independent investment site that helps investors level-up their investments in REITs, real estate and equities.
                      </Text>
                    </StyledCard>
                  </Col>
                </Row>
              </StyledSingleBlogWrapper>
              <FooterNavigation
                postSlug={match.params.post_slug}
                categoryPosts={categoryPosts}
              />
            </>
          ) : (
            <div className="max-width-1200 mx-auto my-5">
              <Title level={3}>Content you are looking for is not available.</Title>
            </div>
          )}
        </>
      )}
      {isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}
    </AppLayout>
  );
}

export default withRouter(SingleBlog);
