import React from 'react';
import styled from 'styled-components';
import { Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import LockIconModal from 'components/LockIconModal';
import { useUserContextValue } from 'contexts/UserContext';
import { TFilterOption } from 'utils/filterUtils';
import { setFilterState } from 'redux/slices/filterStateSlice';
import { IReduxState } from 'redux/reducers';

interface IFilterMultipleSelect {
  config: {
    filterStateKey: string,
    options: TFilterOption[],
  }
}

const StyledInputGroup = styled(Input.Group)`
  .ant-btn {
    font-size: 14px;
    padding: 5px 16px;
    min-height: auto;
    border-radius: 0;
    &:first-of-type {
      border-radius: 2px 0 0 2px;
    }
    &:last-of-type {
      border-radius: 0 2px 2px 0;
    }
    &.ant-btn-primary:focus {
      background-color: #675BF5;
      border-color: #675BF5;
    }
  }
`;

const FilterMultipleSelect: React.FC<IFilterMultipleSelect> = ({ config }) => {
  const dispatch = useDispatch();
  const filterState = useSelector((state: IReduxState) => state.filterState.filterState);

  const { account } = useUserContextValue();
  const stateSource = filterState[config.filterStateKey];
  const userLevel = account?.level || 0;

  const selectOptionAction = (option: TFilterOption, nextButtonState: boolean) => {
    const newSetOfFilterState = nextButtonState ? {
      ...filterState,
      [config.filterStateKey]: {
        ...stateSource,
        option: [...stateSource.option, option],
      },
    } : {
      ...filterState,
      [config.filterStateKey]: {
        ...stateSource,
        option: stateSource.option.filter((b) => b.value !== option.value),
      },
    };
    dispatch(setFilterState(newSetOfFilterState));
  }

  return (
    <StyledInputGroup className="d-flex">
      {config.options.map((option) => {
        const { label, value, minimumPlan } = option;
        const isSelected = stateSource.option.find((b) => b.value === value);
        const lockFilter = (userLevel < minimumPlan);
        return (
          <Button
            className="font-weight-normal text-capitalize d-flex justify-content-between"
            type={isSelected ? 'primary' : 'default'}
            disabled={lockFilter}
            key={value}
            onClick={() => selectOptionAction(option, !isSelected)}
          >
            {label.toLowerCase()}
            {lockFilter && (
              <LockIconModal minimumPlan={minimumPlan} />
            )}
          </Button>
        );
      })}
    </StyledInputGroup>
  );
};

export default FilterMultipleSelect;
