import React from "react";
import { Switch, Route } from "react-router-dom";

import AppLayout from "components/AppLayout";
import CTAUpsell from "components/CTAUpsell";
import CTAUnregistered from "components/CTAUnregistered";
import MainView from "./components/Main";
import SingleView from "./components/Single";
import Section from "./components/Section";
import FourOhFour from "pages/FourOhFour";
import { isLoggedIn } from "utils/userUtils";

const Videos = () => (
  <AppLayout withFooter={true}>
    <Switch>
      <Route exact path="/reitscreener-tv" component={MainView} />
      <Route exact path="/reitscreener-tv/:videoSlug" component={SingleView} />
      <Route exact path="/reitscreener-tv/section/:section" component={Section} />
      <Route component={FourOhFour} />
    </Switch>
    {isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}
  </AppLayout>
);

export default Videos;
