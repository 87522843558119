import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import SuccessText from 'components/LoginRegisterCommon/components/SucessText';

interface IRegisterStatus {
  visibility: boolean,
  setVisibility: (visibility: boolean) => void,
  email: string
}

const StyledModal = styled(Modal)`
  width: 100% !important;
  &.ant-modal {
    .ant-modal-content {
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      display: flex;
      justify-content: center;
     .ant-modal-body {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .ant-btn {
          width: 100%;
          max-width: 450px;        
        }
      }
    }
  }
`;

const SentMagicLinkModal: React.FC<IRegisterStatus> = ({ visibility, setVisibility, email }) => {
  return (
    <StyledModal
      visible={visibility}
      footer={null}
      onCancel={() => setVisibility(false)}
      bodyStyle={{ padding: '60px 20px' }}
    >
      <SuccessText email={email} />
    </StyledModal>
  );
}

export default SentMagicLinkModal;
