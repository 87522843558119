import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Steps } from 'antd';

import { IQuiz } from 'pages/Quiz/Quiz';
import { IQuizQuestion } from 'utils/quizUtils';

const { Step } = Steps;

interface IQuizSteps extends IQuiz {
  answerLength: number,
  derivedQuestions: IQuizQuestion[],
}

const StyledSteps = styled(Steps)`
  max-width: 80%;
  .ant-steps-item {
    padding: 8px;
    margin-left: -8px;
    margin-right: 0 !important;
    &.ant-steps-item-finish {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: #52C41A;
          border: 1px solid #B7EB8F;
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            &::after {
              background: #B7EB8F;
            }
          }
        }
      }
    }
    &.ant-steps-item-active {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: #FFF;
          border: 1px solid #52C41A;
          box-shadow: 0px 0px 8px rgba(82, 196, 26, 0.5);
        }
      }
    }
    &.has-answer {
      cursor: pointer;
    }
    .ant-steps-item-container {
      .ant-steps-item-icon {
        border: 1px solid rgba(18, 19, 36, 0.1);
        width: 14px !important;
        height: 14px !important;
        margin: 0;
        .ant-steps-icon {
          display: none;
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          &::after {
            background: rgba(18, 19, 36, 0.1);
            height: 2px;
            left: 0;
            top: 6px;
          }
        }
      }
    }
  }
`;

const QuizSteps: React.FC<IQuizSteps> = ({ quizNum, setQuizNum, history, answerLength, derivedQuestions }) => {
  const moveToQuestion = (idx: number) => {
    setQuizNum(idx);
    history.push(`/quiz/${idx + 1}`);
  }

  return (
    <StyledSteps current={quizNum} className="mb-4">
      {derivedQuestions.map((_, idx) => (
        <Step
          {...(idx <= answerLength) && {
            onClick: () => moveToQuestion(idx ),
          }}
          className={cx({
            'has-answer': idx <= answerLength,
          })}
          key={idx}
        />
      ))}
    </StyledSteps>
  );
}

export default withRouter(QuizSteps);
