import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import styled from 'styled-components';
import { Modal, Form, Input, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { CUSTOM_STRATEGIES } from 'apollo/queries/screener';
import { setStrategy } from 'redux/slices/filterConfigSlice';
import { IReduxState } from 'redux/reducers';
import { processFilterState } from 'utils/screenerUtils';
import { useMessageContextValue } from 'contexts/MessageContext';
import { UPSERT_CUSTOM_STRATEGY } from 'apollo/mutations/screener';

const { Option } = Select;

type TStrategyActionState = {
  update: boolean,
  modalState: boolean,
};

interface IStrategyModal {
  customStrategyAction: TStrategyActionState,
  setCustomStrategyAction: (strategyAction: TStrategyActionState) => void,
}

interface ICustomStrategyResult {
  strategyFilter: {
    strategyFilterId: number,
    name: string,
    filter: string,
  }[],
}

const StyledModal = styled(Modal)`
  max-width: 336px;
  .ant-modal-content {
    .ant-modal-header {
      padding: 16px 16px 0;
      border: 0;
      .ant-modal-title {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      .custom-footer {
        .ant-btn {
          padding: 6px 14px;
          min-height: 34px;
        }
      }
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-input {
    height: 40px;
  }
`;

const StrategyModal: React.FC<IStrategyModal> = ({ customStrategyAction, setCustomStrategyAction }) => {
  const dispatch = useDispatch();
  const { alertError, alertSuccess } = useMessageContextValue();
  const { advancedMode, sortOrder, strategy } = useSelector((state: IReduxState) => state.filterConfig);
  const { filterState, fixedFilterState } = useSelector((state: IReduxState) => state.filterState);

  const [upsertCustomStrategy] = useMutation(UPSERT_CUSTOM_STRATEGY);
  const [loadingState, setLoadingState] = useState(false);
  const [formValues, setFormValues] = useState<{ name: string, strategyFilterId: undefined | string }>({
    name: '',
    strategyFilterId: undefined,
  });

  const { modalState, update } = customStrategyAction;
  const modalTitle = update ? 'Update strategy' : 'Save new strategy';

  const { data: strategyData, loading: strategyLoading } = useQuery<undefined | ICustomStrategyResult>(CUSTOM_STRATEGIES, {
    skip: !update,
  });

  const closeAction = () => {
    setFormValues({
      name: '',
      strategyFilterId: undefined,
    });
    setCustomStrategyAction({
      ...customStrategyAction,
      modalState: false,
    });
  }

  const upsertInvestmentStrategy = async () => {
    setLoadingState(true);
    const processedValues = processFilterState(filterState, advancedMode, true);
    try {
      const variableInputs = {
        ...(update ? {
          strategyFilterId: formValues.strategyFilterId,
        } : {
          name: formValues.name,
        }),
        filter: {
          filter: JSON.stringify({
            fixedFilterState,
            filterState: processedValues,
          }),
          advanced: advancedMode,
          order: JSON.stringify([sortOrder.sortField, sortOrder.orderBy]),
        },
      };

      const { data } = await upsertCustomStrategy({
        variables: {
          input: variableInputs,
        },
        refetchQueries: [{
          query: CUSTOM_STRATEGIES,
        }],
        awaitRefetchQueries: true,
      });
      alertSuccess('Custom strategy saved!');
      // set selected strategy after create / update
      if (data && strategy !== data.upsertStrategyFilter.strategyFilterId.toString()) {
        const newStrategy = data.upsertStrategyFilter.strategyFilterId.toString();
        dispatch(setStrategy(newStrategy));
      }
      closeAction();
    } catch (error) {
      let errorMessage = "Network error";
      if (error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message;
      }
      alertError(errorMessage);
    }
    setLoadingState(false);
  }

  return (
    <StyledModal
      visible={modalState}
      closable={false}
      maskClosable={false}
      title={modalTitle}
      bodyStyle={{ padding: 16 }}
      footer={false}
    >
      <Form colon={false} layout="vertical">
        {!update ? (
          <StyledFormItem label="Set name" className="mb-4">
            <Input
              size="large"
              placeholder="Strategy name"
              type="text"
              value={formValues.name}
              disabled={loadingState}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                });
              }}
            />
          </StyledFormItem>
        ) : (
          <StyledFormItem label="Choose strategy" className="mb-4">
            <Select<string>
              size="large"
              disabled={loadingState || strategyLoading}
              placeholder="Select strategy to update"
              value={formValues.strategyFilterId}
              onChange={v => {
                setFormValues({
                  ...formValues,
                  strategyFilterId: v,
                });
              }}
            >
              {strategyData?.strategyFilter.map(({ name, strategyFilterId }) => (
                <Option key={strategyFilterId} value={strategyFilterId}>{name}</Option>
              ))}
            </Select>
          </StyledFormItem>
        )}
        <div className="custom-footer d-flex justify-content-end mt-1">
          <div>
            <Button disabled={loadingState} onClick={closeAction} size="small" className="mr-2">
              Cancel
            </Button>
            <Button
              loading={loadingState}
              onClick={upsertInvestmentStrategy}
              size="small"
              type="primary"
              disabled={(update && !formValues.strategyFilterId) || (!update && !formValues.name)}
            >
              {update ? 'Update' : 'Save'}
            </Button>
          </div>
        </div>
      </Form>
    </StyledModal>
  );
};

export default StrategyModal;
