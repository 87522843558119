import React, { useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Modal, Button, Typography } from "antd";

import {
  ANONYMOUS_USER_INDEX,
  PREMIUM_USER_INDEX,
  REGISTERED_USER_INDEX,
} from "utils/appUtils";
import { LockIcon, LockIconCircle, ModalCloseIcon } from "utils/iconUtils";

const { Text } = Typography;

interface ILockIconModal extends RouteComponentProps {
  minimumPlan: number;
  wrapChildren?: boolean;
  isDataBoostFeature?: boolean;
}

const StyledButton = styled(Button)`
  &.lock-btn {
    min-height: 16px !important;
    min-width: 16px;
    height: 16px;
    width: 16px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-close {
    padding: 32px 16px;
    .ant-modal-close-x {
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
  }
  .ant-modal-header {
    padding: 24px 32px 0;
    border: 0;
  }
  .ant-modal-body {
    padding-top: 0 !important;
  }
`;

const StyledDataboostModal = styled(Modal)`
  width: 100% !important;
  margin: 0 auto;

  .databoost-caption {
    max-width: 470px;
    margin: 0 auto;
    div {
      svg {     
        width: 80px;
        height: 80px;
      }
    }
  }

  &.ant-modal {
    .ant-modal-content {
      padding: 20px;
      max-width: 860px;
      margin: 0 auto;
      .ant-modal-body {
        background-image: url("/assets/images/dummyChart.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
        min-height: 360px;
      }
    }
  }
`;

const LockReitModal: React.FC<ILockIconModal> = ({
  minimumPlan = 0,
  wrapChildren = false,
  isDataBoostFeature = false,
  children,
  location,
}) => {
  const [modalState, setModalState] = useState(false);
  const isAnonymous = minimumPlan === ANONYMOUS_USER_INDEX;
  const isRegistered = minimumPlan === REGISTERED_USER_INDEX;
  const aboveRegistered = minimumPlan >= PREMIUM_USER_INDEX;
  localStorage.setItem("prevPath", location.pathname);

  const DataboostNextPath = () => {
    return (
      <Link to="/settings/subscription?upsell=dataBoost">
        <Button size="small" type="primary">
          Learn more
        </Button>
      </Link>
    );
  };

  return (
    <div className="lockicon-modal-wrapper">
      <StyledButton
        onClick={(e) => {
          e.stopPropagation();
          setModalState(true);
        }}
        className={wrapChildren ? "d-none" : "p-0 border-0 lock-btn"}
        shape="circle"
        icon={<LockIcon />}
      />

      {wrapChildren && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setModalState(true);
          }}
        >
          {children}
        </div>
      )}

      {isDataBoostFeature ? (
        <StyledDataboostModal
          visible={modalState}
          footer={null}
          onCancel={(e) => {
            e.stopPropagation();
            setModalState(false);
          }}
          getContainer={document.body}
        >
          <div className="text-center databoost-caption">
            <div className="mb-3">
              <LockIconCircle />
              <Text strong className="subheading d-block text-center mb-4">
                {/* {isAnonymous && "You discovered a feature for registered users"} */}
                {(isAnonymous || isRegistered || aboveRegistered) &&
                  "This is a REITScreener Data Boost addon!"}
              </Text>
            </div>
            <Text className="fs-16 d-block mb-4 px-4" type="secondary">
              {(isAnonymous || isRegistered || aboveRegistered) && (
                <Text type="secondary">
                  You need to Subscribe to <b>REITScreener Premium</b> and
                  <b> REITScreener Data Boost</b> to get acces to advanced
                  charts.
                </Text>
              )}
            </Text>
            <DataboostNextPath />
          </div>
        </StyledDataboostModal>
      ) : (
        <StyledModal
          bodyStyle={{ padding: "24px 32px" }}
          visible={modalState}
          footer={null}
          onCancel={(e) => {
            e.stopPropagation();
            setModalState(false);
          }}
          getContainer={document.body}
          title={
            <Text
              strong
              className="pt-4 px-3 subheading d-block text-center mb-4"
            >
              {isAnonymous
                ? "You discovered a feature for registered users"
                : "You discovered a premium feature!"}
            </Text>
          }
          closeIcon={<ModalCloseIcon />}
        >
          <div className="text-center">
            <div className="mb-3">
              <LockIconCircle />
            </div>
            <Text className="fs-16 d-block mb-4 px-4" type="secondary">
              {isAnonymous
                ? "Please register to REITScreener to unlock this feature, and improve your odds of outperforming the market."
                : "Subscribe to REITScreener Premium to improve your odds of outperforming the market."}
            </Text>
            <Link to={isAnonymous ? "/signup" : "/subscription/premium"}>
              <Button type="primary">
                {isAnonymous ? "Register now" : "Learn more"}
              </Button>
            </Link>
          </div>
        </StyledModal>
      )}
    </div>
  );
};

export default withRouter(LockReitModal);