import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Popover, Typography, Button } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip/index';

import { QuestionMarkIcon } from 'utils/iconUtils';

const { Text } = Typography;

interface IPopoverHelper {
  title: string,
  content: ReactNode,
  placement?: TooltipPlacement,
}

const StyledButton = styled(Button)`
  &.ant-btn {
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    width: 24px;
  }
`;

const PopoverHelper: React.FC<IPopoverHelper> = ({ title, content, placement = 'rightTop' }) => (
  <Popover
    placement={placement}
    overlayClassName="overlay-helper"
    title={<Text className="fs-14" strong>{title}</Text>}
    content={content}
  >
    <StyledButton className="p-0 border-0" shape="circle" icon={<QuestionMarkIcon />} />
  </Popover>
);

export default PopoverHelper;
