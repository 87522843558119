import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Select, Typography, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import LockIconModal from 'components/LockIconModal';
import PopoverHelper from 'components/PopoverHelper';
import { LoadingIcon } from 'components/PageSpinner';
import PopconfirmAction from './components/PopconfirmAction';
import CollapsibleFilters from './components/CollapsibleFilters';
import { INVESTMENT_STRATEGIES } from 'utils/appUtils';
import { useUserContextValue } from 'contexts/UserContext';
import { DEFAULT_FILTER_STATES, getFilters } from 'utils/screenerUtils';
import { setStrategy, setFilterView, setSortOrder } from 'redux/slices/filterConfigSlice';
import { setFilterState, setFilters, setFixedFilterState, TFilterState, TFixedFilterState } from 'redux/slices/filterStateSlice';
import { IReduxState } from 'redux/reducers';
import { TStrategy } from 'apollo/types/screener';
import { CUSTOM_STRATEGIES } from 'apollo/queries/screener';
import { TCountry, TSector, PREMIUM_USER_INDEX, COUNTRIES, SECTORS } from 'utils/appUtils';

const { Option, OptGroup } = Select;
const { Text } = Typography;

interface IScreenerFilter {
  generateReitsData: () => void,
  resultCount: number,
  loadingState: boolean,
}

type TCustomUserStrategy = {
  filterState: TFilterState,
  fixedFilterState: {
    countries: TCountry[],
    sectors: TSector[],
  },
};

export interface ICustomStrategyResult {
  strategyFilter: TStrategy[],
}

const StyledSelectWrapper  = styled.div`
  .ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        .ant-btn {
          display: none;
        }
      }
    }
  }
`;

const ScreenerFilter: React.FC<IScreenerFilter> = ({ resultCount, generateReitsData, loadingState }) => {
  const { account, defaultStrategy, defaultCountry: DEFAULT_COUNTRY } = useUserContextValue();
  const dispatch = useDispatch();
  const filterConfig = useSelector((state: IReduxState) => state.filterConfig);
  const filterState = useSelector((state: IReduxState) => state.filterState);

  const { data: strategyData, loading: strategyLoading } = useQuery<undefined | ICustomStrategyResult>(CUSTOM_STRATEGIES, {
    skip: !Boolean(account)
  });

  const customStrategies = strategyData?.strategyFilter || [];

  const setStrategyAction = (strategy: string) => {
    dispatch(setStrategy(strategy));
    const { defaultFilterState, defaultFilters, defaultSortOrder, defaulterAdvancedMode, defaultFixedFilterState } = getFiltersObjectByStrategy(strategy);
    setFixedFilterStateAction(defaultFixedFilterState);
    dispatch(setFilterState(defaultFilterState));
    dispatch(setFilters(defaultFilters));
    dispatch(setSortOrder(defaultSortOrder));
    if (filterConfig.advancedMode !== defaulterAdvancedMode) {
      dispatch(setFilterView(defaulterAdvancedMode));
    }
  }

  // const setFilterViewAction = (isAdvancedMode: boolean) => {
  //   dispatch(setFilterView(isAdvancedMode));
  // }

  const getFiltersObjectByStrategy = (strategy: string) => {
    const DEFAULT_MIN_MAX = {
      from: 0,
      to: 0,
    }; // to be replaced when min max is available

    const selectedPredefinedStrategy = INVESTMENT_STRATEGIES.find(({ name }) => name === strategy);
    const derivedFilters = getFilters(strategy, account?.level || 0, !Boolean(selectedPredefinedStrategy));
    const enabledStateKeys = derivedFilters.map(({ key }) => key);
    
    let filterSource = [...derivedFilters];
    let isAdvancedMode = filterConfig.advancedMode;
    let defaultFilterValuesForEnabledStates: TFilterState = {};
    let sortOrder = selectedPredefinedStrategy?.defaultSort || filterConfig.sortOrder;
    let fixedFilterState = filterState.fixedFilterState;

    if (selectedPredefinedStrategy) {
      // if selected strategy is pre-defined
      defaultFilterValuesForEnabledStates = Object.keys(DEFAULT_FILTER_STATES[strategy])
        .filter((key) => enabledStateKeys.includes(key))
        .reduce<TFilterState>((accumulator, currentValue) => {
          return {
            ...accumulator,
            [currentValue]: {
              option: DEFAULT_FILTER_STATES[strategy][currentValue],
              range: DEFAULT_MIN_MAX,
            },
          };
        }, {});
      sortOrder = selectedPredefinedStrategy.defaultSort;
    } else {
      // if selected strategy is custom made
      const { advanced, filter, order } = customStrategies.find(({ strategyFilterId }) => strategyFilterId === +strategy)!;
      const parsedFilter: TCustomUserStrategy = JSON.parse(filter);
      const parsedOrder: string[] = JSON.parse(order);

      filterSource = derivedFilters.filter(({ key }) => {
        return ['exchange', 'sector'].includes(key) ||
          Object.keys(parsedFilter.filterState).includes(key);
      });

      defaultFilterValuesForEnabledStates = Object.keys(parsedFilter.filterState)
        .filter((key) => enabledStateKeys.includes(key))
        .reduce<TFilterState>((accumulator, currentValue) => {
          const { option, range } = parsedFilter.filterState[currentValue];
          const rangeValue = advanced ? range : DEFAULT_MIN_MAX;
          const optionValue = advanced ? [] : option;
          return {
            ...accumulator,
            [currentValue]: {
              option: optionValue,
              range: rangeValue,
            },
          };
        }, {});
      fixedFilterState = parsedFilter.fixedFilterState;
      isAdvancedMode = advanced;
      sortOrder = {
        sortField: parsedOrder[0],
        orderBy: parsedOrder[1],
      };
    }
    return {
      defaultFilters: filterSource,
      defaulterAdvancedMode: isAdvancedMode,
      defaultFilterState: defaultFilterValuesForEnabledStates,
      defaultSortOrder: sortOrder,
      defaultFixedFilterState: fixedFilterState,
    }
  }

  const setFixedFilterStateAction = (fixedFilterState: TFixedFilterState) => {
    dispatch(setFixedFilterState(fixedFilterState));
  }

  useEffect(() => {
    const { defaultFilterState, defaultFilters, defaultSortOrder, defaulterAdvancedMode } = getFiltersObjectByStrategy(defaultStrategy.name);
    const isPremiumSubscriber = account && (account.level >= PREMIUM_USER_INDEX);
    const defaultCountry = account?.quiz ?
      COUNTRIES.find(({ exchange }) => exchange === account?.quiz?.country) || DEFAULT_COUNTRY
      : DEFAULT_COUNTRY;
    const defaultSectors = SECTORS.filter(({ markets }) => markets.includes(defaultCountry.exchange));
    setFixedFilterStateAction({
      countries: isPremiumSubscriber ? COUNTRIES : [defaultCountry],
      sectors: isPremiumSubscriber ? SECTORS : defaultSectors,
    });
    dispatch(setStrategy(defaultStrategy.name));
    dispatch(setFilterState(defaultFilterState));
    dispatch(setFilters(defaultFilters));
    dispatch(setSortOrder(defaultSortOrder));
    if (filterConfig.advancedMode !== defaulterAdvancedMode) {
      dispatch(setFilterView(defaulterAdvancedMode));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Spin spinning={false} indicator={LoadingIcon}>
      <div className="d-flex justify-content-between align-items-end mb-4">
        <div>
          <Text className="fs-14 d-block mb-2" type="secondary">Investment Strategy</Text>
          <StyledSelectWrapper className="d-flex align-items-center">
            <Select
              size="large"
              style={{ width: 256 }}
              placeholder="Select investment strategy"
              className="mr-2 text-capitalize"
              value={filterConfig.strategy || undefined}
              onChange={setStrategyAction}
              loading={strategyLoading}
            >
              <OptGroup label="Predefined strategies">
                {INVESTMENT_STRATEGIES.map(({ name, minimumPlan }) => {
                  const isLockStrategy = (account?.level || 0) < minimumPlan;
                  return (
                    <Option disabled={isLockStrategy} key={name} className="text-capitalize" value={name}>
                      <div className="d-flex justify-content-between">
                        {name.replace(/_/g, ' ').toLocaleLowerCase()}
                        {isLockStrategy && <LockIconModal minimumPlan={minimumPlan} />}
                      </div>
                    </Option>
                  );
                })}
              </OptGroup>
              {Boolean(customStrategies.length) && (
                <OptGroup label="Custom strategies">
                  {customStrategies.map(({ name, strategyFilterId }) => {
                    return (
                      <Option key={strategyFilterId} value={strategyFilterId.toString()}>
                        <div className="d-flex justify-content-between" style={{ whiteSpace: 'break-spaces' }}>
                          {name}
                          <PopconfirmAction
                            name={name}
                            id={strategyFilterId}
                            strategy={filterConfig.strategy}
                            setStrategy={setStrategyAction}
                            setSortOrder={(newSortOrder) => dispatch(setSortOrder(newSortOrder))}
                          />
                        </div>
                      </Option>
                    );
                  })}
                </OptGroup>
              )}
            </Select>
            <PopoverHelper
              title="Investment strategies"
              content={(
                <div className="fs-14">
                  <Text className="d-block mb-3" type="secondary">
                    Get started quickly with REITScreener by picking one of our predefined REIT screening strategies that suits your portfolio. Not sure which strategy to use? Take our quiz to help you identify a strategy to shortlist REITs best suited for your portfolio now. Let's get started!
                  </Text>
                  <Link to="/quiz" style={{ textDecoration: 'underline' }}>Take a quiz</Link>
                </div>
              )}
            />
          </StyledSelectWrapper>
        </div>
        {/* <div className="d-flex align-items-center">
          <Switch
            className="mr-2"
            checked={filterConfig.advancedMode}
            onChange={setFilterViewAction}
          />
          <Text className="fs-14 mr-1">Advanced view</Text>
          <PopoverHelper
            placement="leftTop"
            title="Advanced view"
            content={(
              <div className="fs-14">
                <Text className="d-block" type="secondary">
                  If you’re an advanced REIT investor and are able to comprehend the meaning behind each numeric figure, toggle ON to define specific ranges for each filter to screen for REITs that meet your exact criteria. Otherwise, toggle OFF and leave REITScreener to help you interpret those figures!
                </Text>
              </div>
            )}
          />
        </div> */}
      </div>
      <CollapsibleFilters
        generateReitsData={generateReitsData}
        minMax={{}}
        resultCount={resultCount}
        loadingState={loadingState}
      />
    </Spin>
  );
};

export default ScreenerFilter;
