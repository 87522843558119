import React, { useEffect } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Card, Typography } from "antd";
import { useQuery } from "react-apollo";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { VIDEOS } from "apollo/queries/resources";
import { scrollToTop } from "utils/windowUtils";
import { Spinner } from "components/PageSpinner";
import { TVideoList } from "../Main/Main";

const StyleOverride = styled.div`
  .ant-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-card-body {
      padding: 0;
    }
  }
  .video-container {
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      background-image: url("/assets/images/playButton.png");
      position: absolute;
      width: 68px;
      height: 48px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.15);
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .ant-card-body {
      padding: 0;
    }
  }
`;

const StyledRow = styled(Row)`
  .ant-col {
    flex: 0 0 32%;
    max-width: 33.333333%;
    &:nth-child(3n + 2) {
      margin: 0 2%;
    }
  }
`;

const { Text } = Typography;

const Section: React.FC<RouteComponentProps<{ section: string }>> = ({
  match,
}) => {
  let videos: TVideoList = [];
  let specialVideoGroups: { [key: string]: TVideoList } = {};

  let tag = "";
  const { data, loading } = useQuery(VIDEOS);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (data) {
    videos = data.posts[0].posts;
    switch (match.params.section) {
      case "specials":
        tag = "REITScreener TV Specials";
        break;
      case "marketBriefs":
        tag = "Market Briefs";
        break;
      case "webinars":
        tag = "Recorded Webinars";
        break;
      case "training":
        tag = "Training";
        break;
      case "latest":
        tag = "Latest Videos";
        break;
      default:
        break;
    }
  }

  const VidListing: React.FC<{ videoList: TVideoList }> = ({ videoList }) => {
    return (
      <StyledRow>
        {videoList.map((video) => (
          <Col key={video.id} className="w-100 mb-4">
            <Card className="h-100">
              <Link to={`/reitscreener-tv/${video.slug}`}>
                <div className="video-container">
                  <img
                    src={
                      video.media
                        ? video.media
                        : "https://via.placeholder.com/639x399"
                    }
                    className="d-block prod-img w-100"
                    alt="reit videos"
                  />
                </div>
              </Link>
              <div className="p-3 h-100">
                <Text type="secondary" className="d-block fs-14" strong>
                  {video.videoDate}
                </Text>
                <Text className="d-block fs-20" strong>
                  {video.title.length > 34
                    ? video.title.substr(0, 32) + ".."
                    : video.title}
                </Text>
              </div>
            </Card>
          </Col>
        ))}
      </StyledRow>
    );
  };

  const DisplayVideoListing: React.FC<{ videos: TVideoList }> = ({
    videos,
  }) => {
    //latest videos
    const latestVideos = videos
      .slice(0)
      .sort((a, b) => {
        return new Date(a.videoDate) < new Date(b.videoDate) ? 1 : -1;
      })
      .slice(0, 6);

    //regulars sort
    const videoList =
      tag !== "Latest Videos"
        ? videos
            .filter((video) => video.tag === tag)
            .sort((a, b) => {
              return tag !== "Market Briefs"
                ? new Date(a.videoDate) < new Date(b.videoDate)
                  ? 1
                  : -1
                : new Date(a.title) < new Date(b.title)
                ? 1
                : -1;
            })
        : latestVideos;

    //special videos with sub-grouping
    switch (tag) {
      case "Market Briefs":
        videos
          .slice(0)
          .filter((video) => video.tag === tag)
          .sort((a, b) => {
            return new Date(a.title) < new Date(b.title) ? 1 : -1;
          })
          .forEach((video) => {
            const group = video.videoGroup || "test";
            if (specialVideoGroups.hasOwnProperty(group)) {
              specialVideoGroups[group].push(video);
            } else {
              specialVideoGroups[group] = [video];
            }
          });
        break;
      case "Training":
        videos
          .slice(0)
          .filter((video) => video.tag === tag)
          .forEach((video) => {
            const group = video.videoGroup || "test";
            if (specialVideoGroups.hasOwnProperty(group)) {
              specialVideoGroups[group].push(video);
            } else {
              specialVideoGroups[group] = [video];
            }
          });
        break;
      default:
        break;
    }

    return (
      <>
        {tag === "Market Briefs" || tag === "Training" ? (
          <>
            {Object.keys(specialVideoGroups).map((group, idx) => {
              return (
                <div key={idx} className="mb-3">
                  <Col xs={24}>
                    <h4 className="pb-2">{group}</h4>
                  </Col>
                  <VidListing videoList={specialVideoGroups[group]} />
                </div>
              );
            })}
          </>
        ) : (
          <VidListing videoList={videoList} />
        )}
      </>
    );
  };

  return (
    <StyleOverride className="max-width-1200 mx-auto">
      <Row className="py-5 justify-content-between">
        {loading ? (
          <div className="mx-auto">
            <Spinner label="" style={{ minHeight: 150, fontSize: 100 }} />
          </div>
        ) : (
          <>
            {data ? (
              <>
                <Link
                  to="/reitscreener-tv"
                  className="fs-14"
                  style={{ fontWeight: 600 }}
                >
                  <ArrowLeftOutlined className="mr-2" />
                  Back to Main Page
                </Link>
                <Col xs={24} className="my-4">
                  <h4 className="text-center">{tag}</h4>
                </Col>
                <DisplayVideoListing videos={videos} />
              </>
            ) : (
              <>
                <p className="p-4">no data available</p>
              </>
            )}
          </>
        )}
      </Row>
    </StyleOverride>
  );
};

export default withRouter(Section);
