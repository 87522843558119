import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Layout, Menu, Button, Typography } from 'antd';

import UserMenu from './components/UserMenu';
import { isLoggedIn } from 'utils/userUtils';
import { MEGA_MENU_ITEMS } from 'utils/menuUtils';
import { ChevronBottonMenu } from 'utils/iconUtils';

const { Header } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;
const LOGO_LIGHT = '/assets/images/logo-light.png';

interface IAppHeader extends RouteComponentProps {
  brandOnly: boolean,
  scrollState: boolean,
}

const StyledHeader = styled(Header)`
  &.ant-layout-header {
    ${(props: { scrolled: number }) => {
      return (props.scrolled) ? `
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
        transition: box-shadow 0.5s ease 0s, top 0.5s ease 0s;
      ` : `
        box-shadow: none;
      `;
    }}
    border-bottom: 0.5px solid #E8E8E8;
    transition: box-shadow 0.5s ease 0s, top 0.5s ease 0s;
    z-index: 6;
    height: 72px;
    line-height: 72px;
    .inner-menu-wrapper {
      a > .logo {
        height: 36px;
      }
      .ant-menu {
        line-height: 24px;
        .ant-menu-item, .ant-menu-submenu {
          font-size: 16px;
          margin-top: 0;
          padding: 0 0 2px;
          margin: 0 16px;
          color: #121324;
          .ant-menu-submenu-title {
            padding: 0;
          }
        }
      }
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  flex: 0 50%;
  &.ant-menu-item {
    height: auto !important;
    line-height: normal !important;
    margin: 0 !important;
    white-space: normal;
  }
  &:nth-child(odd) {
    border-right: 1px solid #F5F5F5 !important;
  }
  &:nth-child(1), &:nth-child(2) {
    border-bottom: 1px solid #F5F5F5 !important;
  }
  .link-title {
    line-height: 33px;
  }
  &:hover {
    .link-title {
      color: #8C8C8C !important;
    }
  }
`;

const AppHeader: React.FC<IAppHeader> = ({ history, location, brandOnly, scrollState }) => {
  const menuRouter = (clickParams: MenuInfo) => {
    if ((clickParams.key as string).includes('https')) {
      window.open(clickParams.key as string, '_blank');
    } else {
      history.push(clickParams.key as string);
    }
  };

  return (
    <StyledHeader scrolled={scrollState ? 1 : 0} className="header bg-white px-3 position-fixed w-100">
      <div className="inner-menu-wrapper d-flex justify-content-between mx-auto max-width-1200 align-items-center">
        <Link to="/">
          <img className="logo" draggable={false} src={LOGO_LIGHT} alt={process.env.REACT_APP_NAME} />
        </Link>
        {!brandOnly && (
          <>
            <Menu selectedKeys={[location.pathname]} onClick={menuRouter} className="border-0 d-none d-md-block" mode="horizontal">
              <Menu.Item key="/screener/table">Screener</Menu.Item>
              <SubMenu
                popupClassName="mega-menu rounded max-width-1200 w-100 mx-auto position-fixed custom-shadow"
                title={(
                  <div className="d-flex align-items-center">
                    <Text className="mr-2">Resources</Text>
                    <ChevronBottonMenu />
                  </div>
                )}
              >
                {MEGA_MENU_ITEMS.map(({ rendered, path }) => (
                  <StyledMenuItem key={path} className="p-4">{rendered}</StyledMenuItem>
                ))}
              </SubMenu>
              <Menu.Item key="/reitscreener-tv">REITScreener TV</Menu.Item>
              <Menu.Item key="https://pro.reitscreener.com/">REITScreener Pro</Menu.Item>
            </Menu>
            {isLoggedIn() ? (
              <UserMenu />
            ) : (
              <div className="nav-secondary d-none d-md-block">
                <Link to={`/login?redirectUrl=${location.pathname}`}>
                  <Button size="middle" type="link">Sign in</Button>
                </Link>
                <Link to={`/signup?redirectUrl=${location.pathname}`}>
                <Button size="middle" type="primary">Register</Button>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </StyledHeader>
  );
}

export default withRouter(React.memo<IAppHeader>(AppHeader));
