import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography } from "antd";

import Spinner from "components/PageSpinner";
import LockReitmodal from "components/LockReitmodal";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_GROWTH_FIELDS } from "apollo/fields/reits";
import { KEY_GROWTH_FRAME } from "utils/reitUtils";
import { useUserContextValue } from "contexts/UserContext";
import { truncateDecimals } from "utils/numberUtils";

const labels: Array<{
  title: string;
  key: string;
  meta?: string;
  metaNL?: boolean;
  prodKey: string;
  unit?: "Decimal" | "Percent" | "Currency";
}> = [
  {
    title: "Dividends",
    key: "dividends",
    meta: "per Share/Unit",
    metaNL: true,
    prodKey: "DIVIDEND",
    unit: "Percent",
  },
  {
    title: "NAV",
    key: "NAV",
    meta: "per Share/Unit",
    metaNL: true,
    prodKey: "NET_ASSET_VALUE",
    unit: "Percent",
  },
  {
    title: "Revenue",
    key: "revenue",
    prodKey: "REVENUE",
    unit: "Percent",
  },
  {
    title: "Net Property Income",
    key: "NPI",
    meta: "(NPI)",
    metaNL: true,
    prodKey: "NET_PROPERTY_INCOME",
    unit: "Percent",
  },
  {
    title: "Operating Cash Flow",
    key: "operatingCashFlow",
    prodKey: "OPERATING_CASH_FLOW",
    unit: "Percent",
  },
  {
    title: "Free Cash Flow",
    key: "freeCashFlow",
    prodKey: "FREE_CASH_FLOW",
    unit: "Percent",
  },
  {
    title: "FFO",
    key: "FFO",
    prodKey: "FFO",
    unit: "Percent",
  },
  {
    title: "AFFO",
    key: "AFFO",
    prodKey: "AFFO",
    unit: "Percent",
  },
  {
    title: "Distributions Paid",
    key: "distributionsPaid",
    prodKey: "DISTRIBUTIONS_PAID",
    unit: "Percent",
  },
];

interface ICommonFields {
  threeYr: number;
  fiveYr: number;
  sevenYr: number;
}
interface IGrowth {
  dividends: ICommonFields;
  NAV: ICommonFields;
  revenue: ICommonFields;
  NPI: ICommonFields;
  operatingCashFlow: ICommonFields;
  freeCashFlow: ICommonFields;
  FFO: ICommonFields;
  AFFO: ICommonFields;
  distributionsPaid: ICommonFields;
}

const { Text } = Typography;
const Growth: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let growthData: Array<IGrowth> = [];
  const { account } = useUserContextValue();
  const GROWTH_QUERY = queryCompBuilder(COMP_GROWTH_FIELDS, history);
  const userLevel = account?.level || 0;
 
  const getPlans = (prodKey: string): ICommonFields => {
    return (
      KEY_GROWTH_FRAME.find((prod) => prod.name === prodKey)?.yearPlans || {
        threeYr: 0,
        fiveYr: 0,
        sevenYr: 0,
      }
    );
  };

  const { data, loading } = useQuery(GROWTH_QUERY);

  if (data) {
    for (let items in data) {
      growthData = [
        ...growthData,
        {
          ...data[items].growth,
        },
      ];
    }
  }

  return (
    <>
      <Text className="frame-subheader p-2 pl-3" strong>
        Growth
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : (
          <>
            {data ? (
              <>
                {/* /column headers 3-7yr/ */}
                <Row>
                  <Col>
                    <Text className="fs-14"></Text>
                  </Col>
                  {growthData.map((data, idx) => (
                    <Col
                      key={idx}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <Text
                        style={{ color: "#8C8C8C" }}
                        className="fs-14"
                        type="secondary"
                      >
                        3-Year
                      </Text>
                      <Text
                        style={{ color: "#8C8C8C" }}
                        className="fs-14"
                        type="secondary"
                      >
                        5-Year
                      </Text>
                      <Text
                        style={{ color: "#8C8C8C" }}
                        className="fs-14"
                        type="secondary"
                      >
                        7-Year
                      </Text>
                    </Col>
                  ))}
                </Row>
                {labels.map((label, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        <Text className="fs-14">
                          {label.title}
                          {label.metaNL ? <br /> : " "}
                          {label.meta ? label.meta : ""}
                        </Text>
                      </Col>
                      {growthData.map((reit, idx) => {
                        switch (label.key) {
                          case "dividends":
                          case "NAV":
                          case "revenue":
                          case "NPI":
                          case "operatingCashFlow":
                          case "freeCashFlow":
                          case "FFO":
                          case "AFFO":
                          case "distributionsPaid":
                            const { threeYr, fiveYr, sevenYr } = reit[
                              label.key
                            ];
                            const plan = getPlans(label.prodKey);
                            return (
                              <Col
                                key={idx}
                                className="text-right fs-14 d-flex align-items-center justify-content-between"
                              >
                                <Text className="">
                                  {userLevel < plan.threeYr ? (
                                    <span>
                                      <LockReitmodal
                                        minimumPlan={account?.level || 0}
                                      />
                                    </span>
                                  ) : (
                                    <>
                                      {threeYr
                                        ? `${truncateDecimals(threeYr * 100)}%`
                                        : "-"}
                                    </>
                                  )}
                                </Text>
                                <Text className="">
                                  {userLevel < plan.fiveYr ? (
                                    <span>
                                      <LockReitmodal
                                        minimumPlan={account?.level || 0}
                                      />
                                    </span>
                                  ) : (
                                    <>
                                      {fiveYr
                                        ? `${truncateDecimals(fiveYr * 100)}%`
                                        : "-"}
                                    </>
                                  )}
                                </Text>
                                <Text className="">
                                  {userLevel < plan.sevenYr ? (
                                    <span>
                                      <LockReitmodal
                                        minimumPlan={account?.level || 0}
                                      />
                                    </span>
                                  ) : (
                                    <>
                                      {sevenYr
                                        ? `${truncateDecimals(sevenYr * 100)}%`
                                        : "-"}
                                    </>
                                  )}
                                </Text>
                              </Col>
                            );
                          default:
                            return (
                              <Col key={idx} className="text-right fs-14">
                                test
                              </Col>
                            );
                        }
                      })}
                    </Row>
                  );
                })}
              </>
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(React.memo(Growth));
