import React from 'react';
import { Typography, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { TScreenerFilterContent } from 'utils/screenerUtils';
import { setSortOrder, TSortOrder } from 'redux/slices/filterConfigSlice';
import { IReduxState } from 'redux/reducers';

const { Text } = Typography;
const { Option } = Select;

interface ISortOrderControl {
  filters: TScreenerFilterContent[],
}

const SortOrderControl: React.FC<ISortOrderControl> = ({ filters }) => {
  const dispatch = useDispatch();
  const sortOrder = useSelector((state: IReduxState) => state.filterConfig.sortOrder);

  const setSortOrderAction = (sortOrder: TSortOrder) => {
    dispatch(setSortOrder(sortOrder));
  }

  return (
    <div className="d-flex">
      <div style={{ marginRight: 32 }}>
        <Text type="secondary" className="mr-2 fs-14">Sort by:</Text>
        <Select
          size="large"
          style={{ width: 180 }}
          className="text-capitalize"
          value={sortOrder.sortField}
          onChange={v => setSortOrderAction({
            ...sortOrder,
            sortField: v,
          })}
        >
          {filters.map(({ title, key }) => (
            <Option key={key} className="text-capitalize" value={key}>
              {title}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <Text type="secondary" className="mr-2 fs-14">Order by:</Text>
        <Select
          size="large"
          style={{ width: 180 }}
          className="text-capitalize"
          value={sortOrder.orderBy}
          onChange={v => setSortOrderAction({
            ...sortOrder,
            orderBy: v,
          })}
        >
          <Option className="text-capitalize" value="ASC">
            Ascending
          </Option>
          <Option className="text-capitalize" value="DESC">
            Descending
          </Option>
        </Select>
      </div>
    </div>
  );
}

export default SortOrderControl;
