import React, { createContext, useContext, useState } from "react";

type TFreeResources = {
  category: string;
  articleSlug: string | null;
};

interface IFreeResourcesContext {
  freeResources: TFreeResources[];
  setFreeResources: (freeResources: TFreeResources[]) => void;
}

const FreeResourcesContext = createContext<IFreeResourcesContext>({
  freeResources: [],
  setFreeResources: (_) => {},
});

const FreeResourcesProvider: React.FC<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const freeResourcesLS = localStorage.getItem("freeResources");
  const freeArticle: TFreeResources[] = freeResourcesLS ? JSON.parse(freeResourcesLS) : [
    {
      category: "reits",
      articleSlug: null,
    },
    {
      category: "blog",
      articleSlug: null,
    },
  ];
  
  const [freeResources, setFreeResourcesState] = useState<TFreeResources[]>(freeArticle);

  const setFreeResources = (free: TFreeResources[]) => {
    localStorage.setItem('freeResources', JSON.stringify(free))
    setFreeResourcesState(free);
  }

  return (
    <FreeResourcesContext.Provider value={{ freeResources, setFreeResources }}>
      {children}
    </FreeResourcesContext.Provider>
  );
};

const FreeResourcesConsumer = FreeResourcesContext.Consumer;

const useFreeResourcesContextValue = () => useContext(FreeResourcesContext);

export {
  FreeResourcesProvider,
  useFreeResourcesContextValue,
  FreeResourcesConsumer,
};

export default FreeResourcesContext;
