import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Dropdown, Menu, Button, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import UserPanel from "./components/UserPanel";
import { setAuthToken } from "utils/userUtils";
import { SignoutIcon, SettingsIcon } from "utils/iconUtils";

const { Text } = Typography;

const StyledUserMenuWrapper = styled.div`
  .ant-btn {
    width: 44px;
    height: 44px;
  }
  .ant-dropdown-trigger {
    background: #f0f1f3;
    border: 1px solid #e8e8e8;
  }
`;

const UserMenu: React.FC<RouteComponentProps> = ({ history }) => {
  const logoutAction = () => {
    localStorage.clear();
    setAuthToken("");
    history.push("/login");
  };

  return (
    <StyledUserMenuWrapper className="nav-secondary d-none d-md-flex align-items-center">
      <UserPanel />
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        overlayClassName="custom-shadow position-fixed user-menu"
        overlay={
          <Menu style={{ minWidth: 150 }}>
            <Menu.Item
              className="d-flex"
              key={0}
              onClick={() => history.push("/settings")}
            >
              <div className="mr-2 d-flex align-items-center">
                <SettingsIcon />
              </div>
              <Text className="fs-14">Settings</Text>
            </Menu.Item>
            <Menu.Item className="d-flex" key={1} onClick={logoutAction}>
              <div className="mr-2 d-flex align-items-center">
                <SignoutIcon />
              </div>
              <Text className="fs-14">Logout</Text>
            </Menu.Item>
          </Menu>
        }
      >
        <Button className="p-0" shape="circle" icon={<UserOutlined />} />
      </Dropdown>
    </StyledUserMenuWrapper>
  );
};

export default withRouter(UserMenu);
