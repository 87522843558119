import * as Yup from 'yup';
import { isDev } from 'config/env';
import domains from 'disposable-email-domains';
import wildcards from 'disposable-email-domains/wildcard.json';

const invalidDomainSource = [...domains, ...wildcards];

export const registerFormSchema = Yup.object({
  firstName: Yup.string()
    .min(2, 'First name must be 2 or more characters.')
    .required('First name is required.'),
  lastName: Yup.string()
    .min(2, 'Last name must be 2 or more characters.')
    .required('Last name is required.'),
  email: Yup.string()
    .email('Email is invalid.')
    .test('Email generator checker', 'Email is not allowed', (email) => {
      if (isDev) return true;
      if (!email) return false;
      const emailDomain = email.split('@')[1];
      return !invalidDomainSource.includes(emailDomain);
    })
    .required('Email is required.')
});
