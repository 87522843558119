import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";

const LOGO = "/assets/images/logo-dark.png";

interface IMetaTags extends RouteComponentProps {
  metaTitle?: string;
  metaDescription?: string;
  metaImg?: string;
}

const MetaTags: React.FC<IMetaTags> = ({
  match,
  metaTitle,
  metaDescription,
  metaImg,
}) => {
  const derivedTitle = metaTitle
    ? `${metaTitle} · ${process.env.REACT_APP_NAME}`
    : `${process.env.REACT_APP_NAME}`;
  const derivedDescription = metaDescription
    ? `${metaDescription}`
    : "REITScreener is a complete suite that powers your REIT investing with actionable market insights to improve your odds of outperforming the market.";
  const derivedImage = metaImg ? metaImg : LOGO;
  return (
    <Helmet>
      <title>{derivedTitle}</title>
      <meta name="title" content={derivedTitle} />
      <meta name="description" content={derivedDescription} />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.REACT_APP_NAME}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={match.url} />
      <meta property="og:title" content={derivedTitle} />
      <meta property="og:description" content={derivedDescription} />
      <meta property="og:image" content={derivedImage} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={match.url} />
      <meta property="twitter:title" content={derivedTitle} />
      <meta property="twitter:description" content={derivedDescription} />
      <meta property="twitter:image" content={derivedImage} />
    </Helmet>
  );
};
export default withRouter(MetaTags);
