import gql from 'graphql-tag';

export const UPSERT_CUSTOM_STRATEGY = gql`
  mutation UpsertStrategyFilter($input: StrategyFilterInput!) {
    upsertStrategyFilter(input: $input) {
      strategyFilterId
      name
      filter
      advanced
      order
    }
  }
`;

export const DELETE_CUSTOM_STRATEGY = gql`
  mutation DeleteStrategyFilter($strategyFilterId: Int!) {
    deleteStrategyFilter(strategyFilterId: $strategyFilterId)
  }
`;

export const ADD_SCREENER_WATCHLIST = gql`
  mutation AddToScreenerWatchlist($stockCode: String!) {
    addToScreenerWatchlist(stockCode: $stockCode)
  }
`;

export const REMOVE_SCREENER_WATCHLIST = gql`
  mutation RemoveFromScreenerWatchlist($stockCode: String!) {
    removeFromScreenerWatchlist(stockCode: $stockCode)
  }
`;
