import React from 'react';
import { useQuery } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Card } from 'antd';

import FooterNavigation from 'pages/Resources/components/FooterNavigation';
import AppLayout from 'components/AppLayout';
import Sidenav from './components/Sidenav';
import WPContent from './components/WPContent';
import CTAUnregistered from 'components/CTAUnregistered';
import CTAUpsell from 'components/CTAUpsell';
import { isLoggedIn } from 'utils/userUtils';
import { CATEGORY_POSTS } from 'apollo/queries/resources';
import { IWPPost } from 'apollo/types/cms';

const HERO_IMAGE = '/assets/images/bg-skyline.png';
const { Sider, Content } = Layout;

const SIDENAV_CATEGORIES = [{
  title: null,
  category: "reits_introduction",
}, {
  title: 'REITS 101 - Basics',
  category: "reits_101",
}, {
  title: 'REITS 102 - Advanced',
  category: "reits_102",
}, {
  title: 'Other REIT Resources',
  category: "other_reit_resources",
}];

interface IPostsQueryResult {
  posts: {
    slug: string,
    posts: IWPPost[],
  }[],
}

const StyledLayout = styled(Layout)`
  .ant-layout-sider {
    flex: 0 0 295px !important;
    max-width: 295px !important;
    min-width: 295px !important;
    width: 295px !important;
  }
  .ant-layout-content {
    padding-left: 80px;
  }
`;

const StyeldReitsWrapper = styled.div`
  padding-bottom: 100px;
  margin-top: -20px;
`;

const StyledHeroHeader = styled.div`
  min-height: 115px;
  background-position: bottom;
  ${(props: { img: string }) => {
    return `
      background-image: url(${props.img});
    `;
  }}
`;

const Reits: React.FC<RouteComponentProps<{ post_slug: string }>> = ({ match }) => {
  const { data, loading } = useQuery<IPostsQueryResult | undefined>(CATEGORY_POSTS, {
    variables: {
      categorySlugs: SIDENAV_CATEGORIES.map(({ category }) => category),
    },
  });

  const sidebarSource = data?.posts || [];
  const categoryPosts = data?.posts || [];

  return (
    <AppLayout>
      <StyledHeroHeader img={HERO_IMAGE} />
      <StyeldReitsWrapper className="max-width-1200 mx-auto">
        <StyledLayout className="bg-transparent">
          <Sider className="bg-transparent">
            <Card className="custom-shadow" bodyStyle={{ padding: '24px 0' }}>
              {SIDENAV_CATEGORIES.map(({ title, category }, idx) => (
                <Sidenav
                  key={title || (new Date()).toDateString()}
                  title={title}
                  category={category}
                  posts={sidebarSource[idx] ? sidebarSource[idx].posts : []}
                  loading={loading}
                />
              ))}
            </Card>
          </Sider>
          <Content>
            {match.params.post_slug && <WPContent />}
          </Content>
        </StyledLayout>
      </StyeldReitsWrapper>
      {match.params.post_slug && (
        <FooterNavigation
          postSlug={match.params.post_slug}
          categoryPosts={categoryPosts}
        />
      )}      
      {isLoggedIn() ? <CTAUpsell /> : <CTAUnregistered />}
    </AppLayout>
  );
}

export default withRouter(Reits);
