export const SCREENER_FILTER_FIELDS = `
  option
  range {
    from
    to
  }
`;

export const SCREENER_FIELDS = `
  reitId
  reitName
  stockCode
  exchange
  sector
  marketCap
  dividends
  priceToBookRatio
  borrowings
  totalBorrowings
  volatility
  threeYrDPUGrowthRate
  fiveYrDPUGrowthRate
  sevenYrDPUGrowthRate
  threeYrNAVGrowthRate
  fiveYrNAVGrowthRate
  sevenYrNAVGrowthRate
  NPIInterestCover
  loanInterestRates
  propertyYield
  rentGrowth
  leaseDuration
  loanDuration
  occupancyRate
  incomeSupport
`;
