import React from 'react';
import { withFormik, FormikProps } from 'formik';
import { Form, Input, Button } from 'antd';

import { loginFormSchema } from './validation';

export interface ILoginFormValues {
  email: string
}

interface IExternalProps {
  onSubmit: (
    values: ILoginFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void,
}

const LoginForm: React.FC<FormikProps<ILoginFormValues>> = ({
  values, isSubmitting, handleChange, handleBlur, handleSubmit, errors, touched, isValid,
}) => {
  return (
    <Form colon={false} onSubmitCapture={handleSubmit}>
      <Form.Item
        className="mb-2 d-block text-left"
        label="Email"
        {...(((touched.email && errors.email)) && {
          validateStatus: "error",
          help: errors.email,
        })}>
        <Input
          size="large"
          placeholder="example@domain.com"
          name="email"
          className="py-2"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          size="middle"
          htmlType="submit"
          block
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
          className="mt-2"
        >Sign in
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withFormik<IExternalProps, ILoginFormValues>({
  validationSchema: loginFormSchema,
  validateOnMount: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values, formikBag.setSubmitting);
  },
  displayName: 'LoginForm',
})(LoginForm);
