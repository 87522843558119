import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography, Button } from "antd";

import Spinner from "components/PageSpinner";
import LockReitmodal from "components/LockReitmodal";
import { FilePdfOutlined } from "@ant-design/icons";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_PROFILE_FIELDS } from "apollo/fields/reits";
import { PREMIUM_USER_INDEX } from "utils/appUtils";
import { useUserContextValue } from "contexts/UserContext";

const labels: Array<{
  title: string;
  key: string;
}> = [
  // {
  //   title: "Financial Reports",
  //   key: "financialReport",
  // },
  {
    title: "About the company",
    key: "about",
  },
];

interface IPortfolio {
  financialReport: {
    name: string;
    stockCode: string;
  };
  about: string;
  website: string;
}

const { Text, Paragraph } = Typography;
const Profile: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let profileData: Array<IPortfolio> = [];
  const { account } = useUserContextValue();
  const KEY_PROFILE_QUERY = queryCompBuilder(COMP_PROFILE_FIELDS, history);
  const userLevel = account?.level || 0;

  const { data, loading } = useQuery(KEY_PROFILE_QUERY);

  if (data) {
    for (let items in data) {
      const { name, stockCode, about, website } = data[items].profile;
      profileData = [
        ...profileData,
        {
          financialReport: { name, stockCode },
          about,
          website,
        },
      ];
    }
  }

  const FinancialReportBtn: React.FC<IPortfolio["financialReport"]> = ({
    name,
    stockCode,
  }) => {
    const redirectFinancial = () => {
      const mState = Object.assign({}, history.location.state, {
        reitName: name,
      });
      history.push(
        `/screener/table/reits/${stockCode}/financialReports`,
        mState
      );
    };
    return (
      <Button type="link" onClick={redirectFinancial} className="fs-18">
        <FilePdfOutlined />
      </Button>
    );
  };

  return (
    <>
      <Text className="frame-subheader p-2 pl-3" strong>
        Profile
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : (
          <>
            {data ? (
              <>
                {labels.map((label, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        <Text className="fs-14">{label.title}</Text>
                      </Col>
                      {profileData.map((reit, idx) => {
                        switch (label.key) {
                          case "financialReport":
                            const { stockCode, name } = reit.financialReport;
                            return (
                              <Col
                                key={idx}
                                className="text-right d-flex justify-content-center align-items-center"
                              >
                                {userLevel < PREMIUM_USER_INDEX ? (
                                  <>
                                    <LockReitmodal
                                      wrapChildren={true}
                                      minimumPlan={account?.level || 0}
                                    >
                                      <Button type="link" className="p-0">
                                        <FilePdfOutlined />
                                      </Button>
                                    </LockReitmodal>
                                  </>
                                ) : (
                                  <>
                                    <FinancialReportBtn
                                      name={name}
                                      stockCode={stockCode}
                                    />
                                  </>
                                )}
                              </Col>
                            );
                          case "about":
                            return (
                              <Col key={idx} className="text-left fs-14">
                                <Paragraph className="fs-14">
                                  {reit.about}
                                </Paragraph>
                                <a href={reit.website} target="_new">
                                  {reit.website}
                                </a>
                              </Col>
                            );

                          default:
                            return (
                              <Col key={idx} className="text-right fs-14">
                                test
                              </Col>
                            );
                        }
                      })}
                    </Row>
                  );
                })}
              </>
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(React.memo(Profile));
