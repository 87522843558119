import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, Button } from 'antd';

import { StyledModal, StyledUL } from '../styled';
import { ModalCloseIcon } from 'utils/iconUtils';

const { Text, Title } = Typography;

const StyledButton = styled(Button)`
  &.ant-btn {
    height: auto;
    min-height: auto;
    padding: 0;
    background: transparent;
    margin: 0 8px;
    font-size: 14px;
  }
`;

const PrivacyPolicy = () => {
  const [visisbility, setVisibility] = useState(false);
  return (
    <>
      <StyledButton type="text" onClick={() => setVisibility(true)}>Disclosure & Privacy Policy</StyledButton>
      <StyledModal
        wrapClassName="legalities"
        closeIcon={<ModalCloseIcon />}
        visible={visisbility}
        onCancel={() => setVisibility(false)}
        title="Disclosure & Privacy Policy"
        footer={false}
      >
        <div className="scrollable-area">
          <Text style={{ marginBottom: 32 }} className="fs-20">
            This is the web site of REITScreenr.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            For each visitor to our Web page, our Web server automatically recognizes no information regarding the domain or e-mail address.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            We collect the e-mail addresses of those who post messages to our bulletin board, the e-mail addresses of those who communicate with us via e-mail, the e-mail addresses of those who make postings to our chat areas, aggregate information on what pages consumers access or visit, information volunteered by the consumer, such as survey information and/or site registrations.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            The information we collect is used to improve the content of our Web page and used to customize the content and/or layout of our page for each individual visitor.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            With respect to cookies: We use cookies to store visitors preferences, record session information, such as items that consumers add to their shopping cart, record past activity at a site in order to provide better service when visitors return to our site.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future we will post the policy changes to our Web site to notify you of these changes and we will use for these new purposes only data collected from the time of the policy change forward. If you are concerned about how your information is used, you should check back at our Web site periodically.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            With respect to security: We have appropriate security measures in place in our physical facilities to protect against the loss, misuse or alteration of information that we have collected from you at our site.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            If you feel that this site is not following its stated information policy, you may contact us support@reitscreener.com
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            This Privacy Policy explains how information about you is collected, used and disclosed by REITScreener Pte Ltd , Inc. (“Company,” “we,” “us” or “our”). This Privacy Policy applies to information we collect when you use our websites, mobile applications and the services, content and materials made available through our websites or mobile applications (collectively, the “Services”), or when you otherwise interact with us.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the “Last Updated” date at the top of this policy and, in some cases, we may provide you with additional notice (such as by adding a statement to our website or by sending you a notification). We encourage you to review our Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the ways you can help protect your privacy.
          </Text>
          <Title className="mb-3" level={4}>Collection of Information</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            We collect information you provide directly to us. For example, we collect information when you create an account, participate in any interactive features of the Services, subscribe to a newsletter or email list, participate in an event, survey, contest or promotion, make a purchase, communicate with us via third-party social media sites, request customer support or otherwise communicate with us.
          </Text>
          <Text style={{ marginBottom: 32 }} className="fs-20">
            The types of information we may collect include your name, email address, password, postal address, phone number, gender, date of birth, occupation, employer information, photo, payment information (such as your credit or debit card and billing address), preference or interest data, and any other information you choose to provide.
          </Text>
          <Title className="mb-3" level={4}>Information We Collect Automatically</Title>
          <StyledUL>
            <li>
              <Text className="fs-20">
                <b>Log Information:</b> We collect log information about your use of the Services, including your Internet Protocol (“IP”) address, web request, access times, pages viewed, web browser, links clicked and the page you visited before navigating to the Services.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Mobile Device Information:</b> We collect information about the mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, mobile network information and information about your use of our mobile applications.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Information Collected by Cookies and Other Tracking Technologies:</b> We and our service providers use various technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies, and how to disable them, please see “Your Choices” below.
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                <b>Information We Collect From Other Sources:</b> We may also obtain information from other sources and combine that with information we collect through our Services. For example, if you create or log into your account through a third-party social media site, we will have access to certain information from that site, such as your name, account information and friends lists, in accordance with the authorization procedures determined by such social media site; we may also collect information about you when you post content to our pages/feeds on third-party social media sites.
              </Text>
            </li>
          </StyledUL>
          <Title className="mb-3" level={4}>Use of Information</Title>
          <Text className="fs-20 mb-3">
            We may use information about you for various purposes, including to:
          </Text>
          <StyledUL>
            <li>
              <Text className="fs-20">
                Provide, maintain, improve and promote our products and services;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Provide and deliver the information, products and services you request, process transactions and send you related information, including confirmations and receipts;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Send you technical notices, updates, security alerts, and support and administrative messages;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Respond to your comments, questions and requests, and provide customer service;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Communicate with you about products, services, surveys, offers, promotions, rewards and events offered by Company and others, and provide news and information we think will be of interest to you;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Monitor and analyze trends, usage and activities in connection with our Services;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Personalize and improve the Services and provide advertisements, content or features that match user profiles or interests;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Facilitate contests, sweepstakes and promotions, and process and deliver entries and rewards;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Link or combine with information we get from others to help understand your needs and provide you with better service; and
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                Carry out any other purpose for which the information was collected.
              </Text>
            </li>
          </StyledUL>
          <Title className="mb-3" level={4}>Sharing of Information</Title>
          <Text className="fs-20 mb-3">
            We may share information about you as follows or as otherwise described in this Privacy Policy:
          </Text>
          <StyledUL>
            <li>
              <Text className="fs-20">
                With vendors, consultants and other service providers who need access to such information to carry out work or perform services on our behalf;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                When you participate in the interactive areas of our Services, certain information you provide may be displayed to other users, such as your name, photo, comments and other information you choose to provide;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation or legal process;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Company or others;
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company; and
              </Text>
            </li>
            <li>
              <Text className="fs-20">
                With your consent or at your direction.
              </Text>
            </li>
          </StyledUL>
          <Title className="mb-3" level={4}>Social Sharing Features</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20 mb-3">
            The Services may offer social sharing features and other integrated tools (such as the Facebook “Like” button), which let you share actions you take on our Services with other media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.
          </Text>
          <Title className="mb-3" level={4}>Advertising and Analytics Services Provided by Others</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20 mb-3">
            We may allow others to serve advertisements on our behalf across the Internet and to provide analytics services. These entities may use cookies, web beacons and other technologies to collect information about your use of the Services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. This information may be used by Company and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites and better understand your online activity.
          </Text>
          <Title className="mb-3" level={4}>Security</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20 mb-3">
            Company takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.
          </Text>
          <Title className="mb-3" level={4}>Account Information</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20 mb-3">
            You may review, correct or modify information maintained in your online account at any time by logging into your account and updating your profile information or by emailing us at support@reitscreener.com. If you wish to delete or deactivate your account, please email us at support@reitscreener.com, but note that some information you provide through the Services may continue to be accessible (e.g., comments you submit through the Services) and that we may continue to store information about you as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about you for a certain period of time.
          </Text>
          <Title className="mb-3" level={4}>Cookies</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20 mb-3">
            Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability or functionality of our Services
          </Text>
          <Title className="mb-3" level={4}>Promotional Communications</Title>
          <Text style={{ marginBottom: 32 }} className="fs-20 mb-3">
            You may opt out of receiving promotional communications from us by following the instructions in those communications or by sending an email to support@reitscreener.com. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.
          </Text>
          <Title className="mb-3" level={4}>Contact Us</Title>
          <Text className="fs-20 mb-3">
            If you have any questions about this Privacy Policy, please contact us at support@reitscreener.com
          </Text>
        </div>
      </StyledModal>
    </>
  );
}

export default PrivacyPolicy;
