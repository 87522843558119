import React from "react";
import { Typography } from "antd";

import { ChevronTop, ChevronBottom, TuneStrategyIcon } from "utils/iconUtils";
import { IScreenerData } from "apollo/types/screener";

//will need to fetch data based on filters blablalbla!

const { Text } = Typography;

type TScreener = {
  count: number;
  rows: IScreenerData[];
};

interface ICollapsibleHeader {
  resultCount: number;
  activeKey: string | string[] | undefined;
}

const CollapsibleHeader: React.FC<ICollapsibleHeader> = ({
  activeKey,
  resultCount,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center">
        <div className="mr-2 d-flex">
          <TuneStrategyIcon />
        </div>
        <Text
          style={{ color: "#675BF5", marginRight: 12 }}
          className="fs-20"
          strong
        >
          Tune strategy
        </Text>
        {Boolean(resultCount) && (
          <Text type="secondary" className="fs-16">
            ({resultCount} results)
          </Text>
        )}
      </div>
      <div className="chevrons">
        {activeKey ? <ChevronTop /> : <ChevronBottom />}
      </div>
    </div>
  );
};

export default CollapsibleHeader;
