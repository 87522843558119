import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import AsyncScriptLoader from "react-async-script";

import { TRADING_VIEW_DEFAULT_CONFIG } from "utils/chartUtils";
import env from "config/env";

const DEFAULT_INTERVAL = "D";

const StyledTradingView = styled.div`
  width: 100%;
  height: 570px;
`;

const AsyncScriptLoaderTradingView = AsyncScriptLoader(
  "/assets/trading_chart/charting_library/charting_library.min.js"
)(StyledTradingView);

// const StyledWatermark = styled.div`
//   pointer-events: none;
//   &::before {
//     content: "REITScreener";
//     display: block;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     opacity: 0.05;
//     transform: translate(-50%, -50%);
//     font-weight: bolder;
//     font-size: 60px;
//     user-select: none;
//     z-index: 500;
//   }
// `;

interface ITradingViewState {
  widgetInstance: any;
  container_id: string;
  symbol: string;
}

interface ITradingViewProps extends RouteComponentProps<{ stockCode: string }> {
  externalStck?: string;
}

const TradingView: React.FC<ITradingViewProps> = ({
  match,
  externalStck = "",
}) => {
  const [widgetState, setWidgetState] = useState<ITradingViewState>({
    widgetInstance: null,
    container_id: "TRD-CHART",
    symbol: match.params.stockCode ? match.params.stockCode : externalStck,
  });

  const initTradingView = () => {
    if (!window) {
      return;
    }
    const { symbol, container_id } = widgetState;
    const tvConfig = {
      symbol,
      container_id,
      interval: DEFAULT_INTERVAL,
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
        process.env[`REACT_APP_${env}_DATA_FEED_URL`]
      ),
      ...TRADING_VIEW_DEFAULT_CONFIG,
    };
    setWidgetState({
      container_id,
      symbol,
      widgetInstance: new (window as any).TradingView.widget(tvConfig),
    });
  };

  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative">
      {/* <StyledWatermark /> */}
      <AsyncScriptLoaderTradingView
        asyncScriptOnLoad={initTradingView}
        id={widgetState.container_id}
      />
    </div>
  );
};

export default withRouter(TradingView);
