import React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StaticContext } from "react-router";
import { Row, Col, Typography } from "antd";

import Spinner from "components/PageSpinner";
import { queryCompBuilder } from "utils/reitUtils";
import { COMP_TRADINGVIEW_FIELDS } from "apollo/fields/reits";
import { HistoricalChart } from "components/Charts";

const labels: Array<{
  title: string;
  key: string;
}> = [
  {
    title: "52-week Price",
    key: "sharePrice",
  },
];

interface ICommonFields {
  value: number;
  label: string;
}
interface ITradingView {
  sharePrice: Array<ICommonFields>;
  stockCode: string;
}

const { Text } = Typography;
const TradingView: React.FC<RouteComponentProps<
  {},
  StaticContext,
  { cmpReitList: string[] }
>> = ({ history }) => {
  let tradingViewData: Array<ITradingView> = [];
  const TRADING_VIEW_QUERY = queryCompBuilder(COMP_TRADINGVIEW_FIELDS, history);

  const { data, loading } = useQuery(TRADING_VIEW_QUERY);

  if (data) {
    for (let items in data) {
      const { profile } = data[items];
      tradingViewData = [
        ...tradingViewData,
        {
          ...data[items].chart,
          stockCode: profile.stockCode,
        },
      ];
    }
  }

  return (
    <>
      <Text className="frame-subheader p-2 pl-3" strong>
        Chart
      </Text>
      <Row className="row-content">
        {loading ? (
          <Spinner label="" />
        ) : (
          <>
            {data ? (
              <>
                {labels.map((label, idx) => {
                  return (
                    <Row key={idx}>
                      <Col>
                        <Text className="fs-14">{label.title}</Text>
                      </Col>
                      {tradingViewData.map((reit, idx) => {
                        switch (label.key) {
                          case "sharePrice":
                            return (
                              <Col
                                key={idx}
                                className="text-right fs-14 d-flex justify-content-end align-items-center"
                              >
                                <HistoricalChart
                                  isTradingView={true}
                                  dataSource={reit.sharePrice}
                                  stockCode={reit.stockCode}
                                  isDataBoostFeature={false}
                                  chartLabel="52-week Price"
                                />
                              </Col>
                            );
                          default:
                            return (
                              <Col key={idx} className="text-right fs-14">
                                test
                              </Col>
                            );
                        }
                      })}
                    </Row>
                  );
                })}
              </>
            ) : (
              <p className="p-4">no data available</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default withRouter(React.memo(TradingView));
