import React, { useState } from "react";
import { Button, Menu, Dropdown, Typography } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";

import StrategyModal from "./components/StrategyModal";
import { getAuthToken } from "utils/userUtils";

const { Text } = Typography;

interface IFooterControls {
  loadingState: boolean;
  findReits: () => void;
  clearAllFilters: () => void;
}

const FooterControls: React.FC<IFooterControls> = ({
  findReits,
  loadingState,
  clearAllFilters,
}) => {
  const [customStrategyAction, setCustomStrategyAction] = useState<{
    update: boolean;
    modalState: boolean;
  }>({ modalState: false, update: false });

  return (
    <div className="mt-1">
      <Button
        className="mr-2"
        type="primary"
        icon={<SearchOutlined />}
        onClick={findReits}
        loading={loadingState}
      >
        Find REITs
      </Button>
      {getAuthToken() && (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  setCustomStrategyAction({ modalState: true, update: true })
                }
              >
                <Text className="fs-14" type="secondary">
                  Update existing
                </Text>
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  setCustomStrategyAction({ modalState: true, update: false })
                }
              >
                <Text className="fs-14" type="secondary">
                  Save as new
                </Text>
              </Menu.Item>
            </Menu>
          }
        >
          <Button>
            Save strategy <DownOutlined />
          </Button>
        </Dropdown>
      )}
      <StrategyModal
        customStrategyAction={customStrategyAction}
        setCustomStrategyAction={setCustomStrategyAction}
      />
      <Button className="ml-2" onClick={clearAllFilters}>
        Clear All Filters
      </Button>
    </div>
  );
};

export default FooterControls;
