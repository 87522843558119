import gql from "graphql-tag";
import {
  GROWTH_FIELDS,
  COMMON_CHART_FIELDS,
  STD_CHART_FIELDS,
} from "apollo/fields/reits";

export const REIT_STARTER_INFO = gql`
  query reitStarterInfo($stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      profile {
        name
        stockCode
        exchange
        logo
        price
        volume
        sector
        priceChange
        priceChangePerc
        about
      }
    }
  }
`;

export const REIT_PROFILE_INFO = gql`
  query reitProfileInfo($stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      profile {
        name
        about
        website
      }
    }
  }
`;

export const REIT_FINANCIALS_INFO = gql`
  query reitFinancialsInfo($stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      financials {
        revenue{
          ${COMMON_CHART_FIELDS}
        }
        NPI {
           ${COMMON_CHART_FIELDS}  
        }
        operatingCashFlow {
          ${COMMON_CHART_FIELDS}  
        }
        freeCashFlow {
          ${COMMON_CHART_FIELDS}  
        }
        FFO {
          ${COMMON_CHART_FIELDS}
        }
        AFFO {
          ${COMMON_CHART_FIELDS}
        }
        distributionsPaid {
          ${COMMON_CHART_FIELDS}
        }
      }
    }
  }
`;

export const REIT_KEY_STATISTICS_INFO = gql`
  query reitKeyStatistics($stockCode: String!, $range: Duration) {
    screenerReit(stockCode: $stockCode) {
      profile {
        exchange
      }
      keyStatistics {
        marketCap
        sharePriceRange {
          value
          min
          max
        }
        volatility
        dividendAmount {
         ${COMMON_CHART_FIELDS} 
        }
        NAV {
         ${COMMON_CHART_FIELDS}   
        }
        dividends(range: $range) {
            range {
              value
              min
              max
           }
        }
        priceToBookRatio(range: $range) {
          range {
            value
            min
            max
           }
        }
      }
    }
  }
`;

export const REIT_DEBT_INFO = gql`
  query reitProfileInfo($stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      debt {
        borrowings {
          ${COMMON_CHART_FIELDS}
        }
        totalBorrowings {
          ${COMMON_CHART_FIELDS}
        }
        loanInterestRates {
          ${COMMON_CHART_FIELDS}
        }
        loanDuration {
          ${COMMON_CHART_FIELDS}
        }
        NPIInterestCover {
          ${COMMON_CHART_FIELDS}
        }
      }
    }
  }
`;

export const REIT_52_WEEK_INFO = gql`
  query reitFinancialsInfo($stockCode: String!, $range: Duration) {
    screenerReit(stockCode: $stockCode) {
      chart {
        sharePrice(range: $range) {
          value
          label
        }
      }
    }
  }
`;

export const REIT_GROWTH_INFO = gql`
  query reitFinancialsInfo($stockCode: String!) {
     screenerReit(stockCode:$stockCode) {
      growth{ 
        dividends{
          ${GROWTH_FIELDS}
        }
        NAV{
          ${GROWTH_FIELDS}
        }
        revenue{
          ${GROWTH_FIELDS}
        }
        NPI{
          ${GROWTH_FIELDS}
        }
        operatingCashFlow{
          ${GROWTH_FIELDS}
        }
        freeCashFlow{
          ${GROWTH_FIELDS}
        }
        FFO {
          ${GROWTH_FIELDS}
        }
        AFFO {
          ${GROWTH_FIELDS}
        }
        distributionsPaid{
          ${GROWTH_FIELDS}
        }     
      }     
    }
  }
`;

export const REIT_KEY_PORTFOLIO_INFO = gql`
  query reitKeyPortfolio($stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      other: portfolio {
        incomeSupport
      }
      chartsInfo: portfolio {
        propertyYield {
         ${COMMON_CHART_FIELDS}
        }
        occupancyRate {
         ${COMMON_CHART_FIELDS}
        }
        rentGrowth {
        ${COMMON_CHART_FIELDS}
        }
        leaseDuration {
         ${COMMON_CHART_FIELDS}
        }
      }
    }
  }
`;

export const REIT_STD_YIELD_SPREAD_INFO = gql`
  query reitYieldSpread($range: Duration!, $stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      keyStatistics {
        dividends(range: $range) {
          chart {
            ${STD_CHART_FIELDS}
          }
        }
      }
    }
  }
`;

export const REIT_STD_PRICE_TO_BOOK_INFO = gql`
  query reitYieldSpread($range: Duration!, $stockCode: String!) {
    screenerReit(stockCode: $stockCode) {
      keyStatistics {
        priceToBookRatio(range: $range) {
          chart {
            ${STD_CHART_FIELDS}
          }
        }
      }
    }
  }
`;

export const REIT_FINANCIAL_REPORT = gql`
  query financialReports(
    $token: String!
    $stockCode: String!
    $type: FinancialReportType!
  ) {
    financialReports(token: $token, stockCode: $stockCode, type: $type) {
      count
      reports {
        type
        announcementDate
        yearEnded
        reportPdfLink
      }
    }
  }
`;
