import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Typography, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import PopoverHelper from 'components/PopoverHelper';
import CountryModalContent from './components/CountryModalContent';
import { COUNTRIES, SECTORS } from 'utils/appUtils';
import { ModalCloseIcon } from 'utils/iconUtils';
import { useUserContextValue } from 'contexts/UserContext';
import { setFixedFilterState, TFixedFilterState } from 'redux/slices/filterStateSlice';
import { IReduxState } from 'redux/reducers';

const { Text } = Typography;

const StyledFilterWrapper = styled.div`
  .ant-btn {
    padding: 5px 8px;
    min-height: 32px;
    font-size: 14px;
    color: #595959;
    font-weight: normal;
  }
  .ant-tag {
    font-size: 14px;
    padding: 5px 8px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 336px;
    margin: auto;
    .ant-modal-close {
      padding: 24px 16px;
      .ant-modal-close-x {
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
    }
    .ant-modal-header {
      padding: 16px 16px 0;
      border: 0;
    }
    .ant-modal-body {
      span {
        font-size: 14px;
      }
      .check-all {
        border-bottom: 1px solid #E8E8E8;
      }
      .ant-btn {
        min-height: 34px;
        padding: 6px 14px;
      }
    }
  }
`;

const FilterCountry = () => {
  const dispatch = useDispatch();
  const fixedFilterState = useSelector((state: IReduxState) => state.filterState.fixedFilterState);
  const { countries } = fixedFilterState;
  
  const [modalState, setModalState] = useState(false);
  const { account, defaultCountry } = useUserContextValue();

  const setFixedFilterStateAction = (newFixedFilterState: TFixedFilterState) => {
    dispatch(setFixedFilterState(newFixedFilterState));
  }

  return (
    <StyledFilterWrapper className="d-flex flex-wrap">
      {countries.map(({ name, exchange }) => (
        <Tag
          key={exchange}
          color="#675BF5"
          className="mb-2"
          closable={countries.length > 1}
          onClose={() => {
            const newSetOfCountries = countries.filter((c) => c.exchange !== exchange);
            // remove selected sectors for the specified market that is removed
            const newSetOfSectors = [...fixedFilterState.sectors].filter(({ markets }) => {
              const newSelectedMarkets = newSetOfCountries.map(({ exchange }) => exchange);
              const intersections = markets.filter(m => newSelectedMarkets.includes(m));
              return Boolean(intersections.length);
            });
            setFixedFilterStateAction({
              ...fixedFilterState,
              countries: newSetOfCountries,
              sectors: newSetOfSectors,
            });
          }}
        >
          {name}
        </Tag>
      ))}
      <div>
        <Button className="mb-2" onClick={() => setModalState(true)} size="small" type="dashed" icon={<PlusOutlined />}>
          Country
        </Button>
        <StyledModal
          maskClosable={false}
          visible={modalState}
          onCancel={() => setModalState(false)}
          bodyStyle={{ padding: 16 }}
          closeIcon={<ModalCloseIcon />}
          destroyOnClose
          footer={null}
          title={(
            <div className="fs-18 d-flex align-items-center">
              <Text className="mr-2">Country (Market)</Text>
              <PopoverHelper
                title="Country (Market)"
                content={(
                  <div className="fs-14">
                    <Text className="d-block" type="secondary">
                      This is the country (e.g. Singapore, US, Australia…etc) that the REIT is listed and traded in. For example, if a stock trades on the Singapore Stock Exchange, then the stock's country would be the Singapore.
                    </Text>
                  </div>
                )}
              />
            </div>
          )}
        >
          <CountryModalContent
            selectedCountries={countries}
            setSelectedCountries={(newSetOfCountries) => {
              // get new set of sectors for every markets selected
              const defaultSetOfSectors = SECTORS.filter(({ markets }) => {
                const newSelectedMarkets = newSetOfCountries.map(({ exchange }) => exchange);
                const intersections = markets.filter(m => newSelectedMarkets.includes(m));
                return Boolean(intersections.length);
              });

              // track previously removed sectors so we won't merged it back to the result
              const previouslyRemovedSectors = SECTORS.filter(({ value, markets }) => {
                const originalSelectedMarkets = fixedFilterState.countries.map(({ exchange }) => exchange);
                const currentSetOfSectors = fixedFilterState.sectors.map(({ value }) => value);
                const intersections = markets.filter(m => originalSelectedMarkets.includes(m));
                return Boolean(intersections.length) && !currentSetOfSectors.includes(value);
              });

              setFixedFilterStateAction({
                ...fixedFilterState,
                countries: newSetOfCountries,
                sectors: Boolean(previouslyRemovedSectors.length) ?
                  defaultSetOfSectors.filter(({ value }) => {
                    const removedSectors = previouslyRemovedSectors.map((s) => s.value);
                    return !removedSectors.includes(value);
                  }) : defaultSetOfSectors,
              });
            }}
            userLevel={account?.level || 0}
            userQuizCountry={COUNTRIES.find(({ exchange }) => exchange === account?.quiz?.country)}
            defaultCountry={defaultCountry}
            setModalState={setModalState}
          />
        </StyledModal>
      </div>
    </StyledFilterWrapper>
  );
}

export default FilterCountry;
