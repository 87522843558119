import React from "react";
import styled from "styled-components";
import { useQuery } from "react-apollo";

import { Spinner } from "components/PageSpinner";
import { VIDEOS } from "apollo/queries/resources";

import FeaturedSection from "./components/FeaturedSection";
import LatestSection from "./components/LatestSection";
import CategorySection from "./components/CategorySection";

const StyleOverride = styled.div`
  .ant-card {
    height: 100% !important;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    .ant-card-body {
      padding: 0;
    }
  }
  .video-container {
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      background-image: url("/assets/images/playButton.png");
      position: absolute;
      width: 68px;
      height: 48px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.15);
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .ant-card-body {
      padding: 0;
    }
  }
`;

export interface IVideoDetails {
  id: string;
  slug: string;
  date: string;
  tag: string;
  title: string;
  videoDate: string;
  videoGroup?: string;
  videoURL: string;
  permission?: String[];
  videoTimestamps: Array<{
    title: string;
    time: string;
  }>;
  media: string;
  content: string;
}

export type TVideoList = IVideoDetails[];

///call everything here then distribute
//api call for videos then distribute to each component

export default () => {
  let videosData: IVideoDetails[] = [];
  const { loading, data } = useQuery(VIDEOS);
  if (data) {
    videosData = data.posts[0].posts;
  }

  return (
    <StyleOverride>
      {loading ? (
        <Spinner label="" />
      ) : (
        <>
          {data ? (
            <>
              <FeaturedSection videoPosts={videosData} />
              <LatestSection videoPosts={videosData} />
              <CategorySection videoPosts={videosData} />
            </>
          ) : (
            <div className="my-5">No videos posted yet.</div>
          )}
        </>
      )}
    </StyleOverride>
  );
};
